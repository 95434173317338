import {config} from '@reedsy/studio.shared/config';

export default (timestamp: number | Date): string => {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const dateString = date.toLocaleDateString(
    config.ui.defaultLocale,
    {day: 'numeric', month: 'long', year: 'numeric'},
  );

  return `${hours}:${minutes}, ${dateString}`;
};
