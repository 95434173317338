import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/inlined/bookshelf.svg'


const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("rbe-welcome-bar", null, [
    _createElementVNode("rbe-text-wrapper", null, [
      _createElementVNode("h1", null, [
        _cache[0] || (_cache[0] = _createTextVNode("Welcome back")),
        (_ctx.userName)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, ", " + _toDisplayString(_ctx.userName), 1))
          : _createCommentVNode("", true)
      ]),
      _cache[1] || (_cache[1] = _createTextVNode()),
      _cache[2] || (_cache[2] = _createElementVNode("p", null, "This is your bookshelf, where you can write, plan, edit and typeset your books", -1))
    ]),
    _cache[3] || (_cache[3] = _createTextVNode()),
    _cache[4] || (_cache[4] = _createElementVNode("rbe-image-wrapper", null, [
      _createElementVNode("img", {
        class: "editor-landing-image hidden-until-md",
        src: _imports_0
      })
    ], -1))
  ]))
}