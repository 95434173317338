
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import PremiumBadge from './premium-badge.vue';

@Component({
  components: {
    PremiumBadge,
  },
})
export default class ScalingPremiumBadge extends ClientSharedVue {
}
