<template>
  <rbe-book-settings>
    <LayoutWithRightSideBar>
      <template #left-side>
        <BookSettingsPanel />
        <BookCoverUploader class="visible-until-sm mobile-book-cover-uploader" />
        <ImageResolutionInfoPanel class="visible-until-sm" />
        <BookNotificationsSettingsPanel />
        <BookArchivePanel
          v-if="isUserBookOwner"
          ref="bookArchivePanel"
        />
        <LeaveBookPanel
          v-if="!isUserBookOwner"
          ref="leaveBookPanel"
        />
      </template>
      <template #right-side>
        <BookCoverUploader />
        <ImageResolutionInfoPanel />
      </template>
    </LayoutWithRightSideBar>
  </rbe-book-settings>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import BookSettingsPanel from '@reedsy/studio.home.bookshelf/components/book-settings/book-settings-panel/book-settings-panel.vue';
import BookArchivePanel from '@reedsy/studio.home.bookshelf/components/book-settings/book-archive-panel/book-archive-panel.vue';
import LeaveBookPanel from '@reedsy/studio.home.bookshelf/components/book-settings/leave-book-panel/leave-book-panel.vue';
import BookCoverUploader from '@reedsy/studio.home.bookshelf/components/book-settings/book-cover-uploader/book-cover-uploader.vue';
import ImageResolutionInfoPanel from '@reedsy/studio.home.bookshelf/components/book-settings/image-resolution-info-panel/image-resolution-info-panel.vue';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import BookNotificationsSettingsPanel from '@reedsy/studio.home.bookshelf/components/book-settings/book-notifications-settings-panel/book-notifications-settings-panel.vue';
import LayoutWithRightSideBar from '@reedsy/studio.home.bookshelf/components/layout-with-right-side-bar/layout-with-right-side-bar.vue';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import {ResourcesRole} from '@reedsy/utils.reedsy-resources';

@Component({
  components: {
    BookSettingsPanel,
    BookArchivePanel,
    BookCoverUploader,
    LeaveBookPanel,
    ImageResolutionInfoPanel,
    BookNotificationsSettingsPanel,
    LayoutWithRightSideBar,
  },
})
export default class BookSettings extends BookshelfVue {
  @$lazyInjectStore(StoreName.Modals)
  public $modals: BookshelfModalsModule;

  @$lazyInjectStore(StoreName.CurrentBook)
  public $currentBook: CurrentBookModule;

  @$lazyInjectStore(SharedStoreName.User)
  public $user: SharedUserModule;

  public get isUserBookOwner(): boolean {
    const userRole = this.$currentBook.userRole(this.$user.uuid);
    return userRole === ResourcesRole.Owner;
  }

  public mounted(): void {
    if (this.$route.params.action === 'delete') {
      this.$modals.open('ConfirmBookDeletionModal');
      this.$router.replace({name: BookshelfRouteName.BookSettings});
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-book-cover-uploader {
  margin-top: $space-xl;
}

:deep(rbe-layout-with-right-side-bar) {
  rbe-right-column {
    @include screen-less-than(md) {
      display: none;
    }
  }
}
</style>
