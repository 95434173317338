import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconBolt = _resolveComponent("VuiIconBolt")!

  return (_ctx.config.features.subscriptions)
    ? (_openBlock(), _createElementBlock("rbe-premium-icon", _hoisted_1, [
        _createVNode(_component_VuiIconBolt, {
          class: _normalizeClass(`icon-circle icon-${_ctx.size}`)
        }, null, 8, ["class"])
      ]))
    : _createCommentVNode("", true)
}