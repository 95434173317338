import {injectable, named} from 'inversify';
import BaseCloseModalsPluginFactory from '@reedsy/studio.shared/store/plugins/close-modals/close-modals';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import BookshelfStoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';

@injectable()
export default class CloseModalsPluginFactory extends BaseCloseModalsPluginFactory {
  @$inject('StoreModule')
  @named(BookshelfStoreName.Modals)
  public override $modals: BookshelfModalsModule;
}
