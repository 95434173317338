
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import ContextMenu from '@reedsy/studio.shared/components/context-menu/context-menu.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {IDisplayedCollaborator} from '@reedsy/studio.isomorphic/controllers/api/v1/users/displayed-collaborator';
import {PropType} from 'vue';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedModalsModule} from '@reedsy/studio.shared/store/modules/modals';
import {IBookInfo} from '@reedsy/studio.shared/models/i-book-info';

@Component({
  components: {
    ContextMenu,
  },
})
export default class CollaboratorContextMenu extends ClientSharedVue {
  @$lazyInjectStore(SharedStoreName.Modals)
  public $modals: SharedModalsModule;

  @Prop({type: Object as PropType<IDisplayedCollaborator>, required: true})
  public collaborator: IDisplayedCollaborator;

  @Prop({type: Object as PropType<Pick<IBookInfo, '_id' | 'title'>>, required: true})
  public book: Pick<IBookInfo, 'uuid' | 'title'>;

  public openConfirmRevokePermissionsModal(): void {
    this.$modals.open({
      type: 'ConfirmRevokeAccess',
      context: {
        book: this.book,
        collaborator: this.collaborator,
      },
    });
  }
}
