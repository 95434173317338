import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookSkeletonLoader = _resolveComponent("BookSkeletonLoader")!
  const _component_GridLine = _resolveComponent("GridLine")!
  const _component_BooksGridWrapper = _resolveComponent("BooksGridWrapper")!

  return (_openBlock(), _createBlock(_component_BooksGridWrapper, {
    "is-empty": !_ctx.loading && !_ctx.bookshelfEntries.length
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(4 * _ctx.numberOfColumns, (i) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
              _createVNode(_component_BookSkeletonLoader, { class: "grid-item" }),
              _cache[0] || (_cache[0] = _createTextVNode()),
              (_ctx.wantsGridLine(i))
                ? (_openBlock(), _createBlock(_component_GridLine, { key: 0 }))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.bookshelfEntries, (entry, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: entry.id
            }, [
              (_ctx.wantsGridLine(i))
                ? (_openBlock(), _createBlock(_component_GridLine, { key: 0 }))
                : _createCommentVNode("", true),
              _cache[1] || (_cache[1] = _createTextVNode()),
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.entryComponent(entry)), {
                entry: entry,
                class: "grid-item"
              }, null, 8, ["entry"]))
            ], 64))
          }), 128)),
      _cache[2] || (_cache[2] = _createTextVNode()),
      _createVNode(_component_GridLine, {
        ref: "lastLine",
        "with-button": !_ctx.loading && _ctx.hasMoreEntriesToShow,
        class: "last-line"
      }, null, 8, ["with-button"])
    ]),
    _: 1
  }, 8, ["is-empty"]))
}