
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {IBookshelfBook} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-book';
import BookCover from '@reedsy/studio.home.bookshelf/components/book-cover/book-cover.vue';
import BookProgress from '@reedsy/studio.home.bookshelf/components/book-progress/book-progress.vue';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import {BookshelfEntryType, IBookshelfEntry} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-entry';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModule} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf';
import {BooksTotalWordCountsModule} from '@reedsy/studio.home.bookshelf/store/modules/books-total-word-counts';
import BookshelfEntry from './bookshelf-entry.vue';
import BookEntryActions from './book-entry-actions.vue';
import EntryAction from './entry-action.vue';
import {BookshelfCollaboratorsModule} from '@reedsy/studio.home.bookshelf/store/modules/collaborators';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';
import BookTitle from '@reedsy/studio.home.bookshelf/components/book-cover/book-title.vue';
import ShareIndicator from '@reedsy/studio.home.bookshelf/components/share-indicator/share-indicator.vue';

@Component({
  components: {
    BookCover,
    BookProgress,
    BookshelfEntry,
    BookEntryActions,
    EntryAction,
    BookTitle,
    ShareIndicator,
  },
})
export default class BookEntry extends BookshelfVue {
  @$lazyInject('Navigation')
  public navigation: INavigation;

  @$lazyInjectStore(StoreName.BooksTotalWordCounts)
  public $totalWordCounts: BooksTotalWordCountsModule;

  @$lazyInjectStore(StoreName.Collaborators)
  public $collaborators: BookshelfCollaboratorsModule;

  @$lazyInjectStore(StoreName.Bookshelf)
  public $bookshelf: BookshelfModule;

  @Prop({type: Object, required: true})
  public entry: IBookshelfEntry<BookshelfEntryType.Book>;

  public get book(): IBookshelfBook {
    return this.entry.item;
  }

  public get hasGoalSet(): boolean {
    return this.$bookshelf.hasGoalSet(this.book);
  }

  public get bookEditorLink(): string {
    return this.navigation.getBookEditorUrl(this.book.uuid);
  }

  public get coverImageClass(): string {
    return this.book.coverUrl ? 'with-cover-image' : 'without-cover-image';
  }

  public get author(): IUserInfo {
    return this.$collaborators.userById[this.book.ownerId];
  }
}
