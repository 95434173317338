
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import InfoTip from '@reedsy/studio.shared/components/info-tip/info-tip.vue';
import PremiumBadge from '@reedsy/studio.shared/components/subscriptions/premium-badge.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {config} from '@reedsy/studio.shared/config';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';
import PremiumBadgeInfo from '@reedsy/studio.shared/components/subscriptions/premium-badge-info.vue';
import {PropType} from 'vue';
import {IPaidFeature} from '@reedsy/utils.subscription';

@Component({
  components: {
    PremiumBadgeInfo,
    PremiumBadge,
    InfoTip,
  },
})
export default class PremiumInfo extends ClientSharedVue {
  @$lazyInject('SubscriptionModal')
  public $subscriptionModal: ISubscriptionModalService;

  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @Prop({type: String as PropType<IPaidFeature>, required: true})
  public paidFeature: IPaidFeature;

  public readonly config = config;
}
