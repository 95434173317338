import {$inject} from '@reedsy/studio.shared/types';
import {injectable} from 'inversify';
import {ResourcesRole} from '@reedsy/utils.reedsy-resources';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import {RouterPlugin} from '@reedsy/studio.shared/store/plugins/router-plugin';
import {NavigationGuardReturn, RouteLocationRaw} from 'vue-router';
import {numericResourcesRole} from '@reedsy/studio.isomorphic/utils/numeric-resources-role';
import {IMetaRoute} from '@reedsy/studio.shared/router/reedsy-router';

@injectable()
export abstract class BaseRoleAuthPluginFactory extends RouterPlugin {
  public abstract userRole: ResourcesRole;

  @$inject('Navigation')
  public readonly _navigation: INavigation;

  public override async beforeEach(to: IMetaRoute, from: IMetaRoute): Promise<NavigationGuardReturn> {
    const minimumRole = to.meta.minimumRole as ResourcesRole;
    if (!minimumRole) return;

    if (numericResourcesRole(this.userRole) >= numericResourcesRole(minimumRole)) return;
    return this.redirectToUnauthorizedRoute(to, from);
  }

  protected async redirectToUnauthorizedRoute(_to: IMetaRoute, _from: IMetaRoute): Promise<RouteLocationRaw> {
    await this._navigation.goToBookShelf();
    return null;
  }
}
