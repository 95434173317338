import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectedFileInfo = _resolveComponent("SelectedFileInfo")!
  const _component_VFileInput = _resolveComponent("VFileInput")!

  return (_openBlock(), _createBlock(_Transition, {
    name: "fade",
    mode: "out-in"
  }, {
    default: _withCtx(() => [
      (_ctx.importFile)
        ? (_openBlock(), _createBlock(_component_SelectedFileInfo, {
            key: 0,
            ref: "selectedFileInfo",
            "import-file": _ctx.importFile,
            class: "selected-file-info",
            "progress-percentage": _ctx.progressPercentage,
            onClearFile: _ctx.clearFile
          }, null, 8, ["import-file", "progress-percentage", "onClearFile"]))
        : (_openBlock(), _createBlock(_component_VFileInput, {
            key: 1,
            ref: "fileUpload",
            "accepted-types": _ctx.acceptedTypes,
            "additional-info": _ctx.fileUploadAdditionalInfo,
            "before-link-text": "or",
            "display-button": false,
            "link-text": "select from your device",
            "button-text": "Upload file",
            "max-size": _ctx.maxFileSizeWithUnit,
            title: "Upload your manuscript here",
            class: "import-doc-upload",
            required: "",
            onFileUpdated: _ctx.newFileSelected
          }, null, 8, ["accepted-types", "additional-info", "max-size", "onFileUpdated"]))
    ]),
    _: 1
  }))
}