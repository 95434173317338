<template>
  <input
    :ref="inputFieldRef"
    class="hidden"
    :class="{touched}"
    type="file"
    @change="onChange"
  >
</template>

<script lang="ts">
import {Component, Emit, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ValidationMixin from '@reedsy/studio.shared/components/forms/mixins/validation-mixin';

@Component({})
export default class BaseFileInput extends mixins(ValidationMixin) {
  @Emit('update:modelValue')
  public onChange(event: Event): Event {
    this.touched = true;
    this.validateField();
    return event;
  }
}
</script>

<style lang="scss" scoped>
input {
  &.hidden {
    display: none;
  }
}
</style>
