import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabOptions = _resolveComponent("TabOptions")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, {
    title: "Export options",
    class: "export-type-selector-panel"
  }, {
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createElementVNode("p", null, "\n      Every book written or uploaded with our editor can be perfectly converted,\n      ready for submission to online ebook stores and print on demand services.\n      Select the export format and options you would like.\n    ", -1)),
      _cache[2] || (_cache[2] = _createTextVNode()),
      _createVNode(_component_TabOptions, {
        modelValue: _ctx.exportType,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.exportType) = $event)),
        options: _ctx.exportOptions,
        class: "export-type-options"
      }, null, 8, ["modelValue", "options"])
    ]),
    _: 1
  }))
}