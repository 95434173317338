<template>
  <div class="test">
    Test
  </div>
</template>

<script lang="ts">
const component: any = {};
export default component;
</script>
