import {randomIntFromObjectId} from './random-int-from-object-id';
import {injectable} from 'inversify';
import {IBookColorPalette, IBookColorPool} from './i-book-color-pool';

@injectable()
export class BookColorPool implements IBookColorPool {
  public static readonly COLORS = Object.freeze([
    {
      cover: '#E5F7FF', // Blue
      text: '#A0C5E0',
    },
    {
      cover: '#E6F1FF', // Moave
      text: '#AFCAEC',
    },
    {
      cover: '#E6F3EE', // Mint
      text: '#B8D5CA',
    },
    {
      cover: '#EBF4DD', // Green
      text: '#C6D7AC',
    },
    {
      cover: '#000EF2', // Navy
      text: '#E0E5E7',
    },
    {
      cover: '#FF29CB', // Pink
      text: '#004182',
    },
    {
      cover: '#FFF6DD', // Yellow
      text: '#E1C577',
    },
  ] as const);

  public get(bookId: string): IBookColorPalette {
    const colorNumber = randomIntFromObjectId(bookId, BookColorPool.COLORS.length);
    return BookColorPool.COLORS[colorNumber];
  }
}
