export enum SignInRouteName {
  AuthBase = 'signInAuthBase',
  SignInCallback = 'signInCallback',
  TermsAndConditions = 'termsAndConditions',
  ThankYou = 'thankYou',
  Welcome = 'welcome',
  OneTapSignIn = 'oneTapSignIn',
  SocialSignIn = 'socialSignIn',
  SignIn = 'signIn',
  SignUp = 'signUp',
}
