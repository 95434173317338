import {Store, createStore} from 'vuex';
import {injectable} from 'inversify';
import {config as vmdConfig} from '@reedsy/vuex-module-decorators';

vmdConfig.rawError = true;

@injectable()
export default class StoreWrapper {
  public readonly store: Store<any>;

  public constructor() {
    this.store = createStore({});
  }
}
