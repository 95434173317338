<template>
  <rbe-subscription-period class="flex-justified">
    <label>
      <PeriodSummary
        class="period-option"
        :selected="!billAnnually"
        interval="month"
      />
      <input
        v-model="billAnnually"
        type="radio"
        :value="false"
        aria-label="Monthly"
      >
    </label>

    <label>
      <PeriodSummary
        class="period-option"
        :selected="billAnnually"
        interval="year"
      />
      <input
        v-model="billAnnually"
        type="radio"
        :value="true"
        aria-label="Annually"
      >
    </label>
  </rbe-subscription-period>
</template>

<script lang="ts">
import {Component, Model} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import PeriodSummary from './period-summary.vue';

@Component({
  components: {PeriodSummary},
})
export default class PeriodPicker extends ClientSharedVue {
  @Model({type: Boolean})
  public billAnnually: boolean;
}
</script>

<style lang="scss" scoped>
rbe-subscription-period {
  gap: $space-sm;

  input[type='radio'] {
    display: none;
  }

  label {
    display: contents;
  }

  .period-option {
    flex-grow: 1;
  }
}
</style>
