import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScalingPremiumBadge = _resolveComponent("ScalingPremiumBadge")!

  return (_ctx.shouldShow)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: "premium-ad-banner",
        type: "button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.$subscriptionModal.open && _ctx.$subscriptionModal.open(...args)))
      }, [
        (!_ctx.$subscription.hasAnyPaidFeature)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, "\n      Unlock more with\n    "))
          : (_ctx.$subscription.isTrial)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.trialMessage), 1))
            : _createCommentVNode("", true),
        _cache[1] || (_cache[1] = _createTextVNode()),
        _createVNode(_component_ScalingPremiumBadge)
      ]))
    : _createCommentVNode("", true)
}