import {injectable} from 'inversify';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import {ISignInCallbackParams} from './i-sign-in-callback-params';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import {IMetaRoute} from '@reedsy/studio.shared/router/reedsy-router';
import {SignInRouteName} from '@reedsy/studio.shared/router/route-names/sign-in-route-name';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {normalizeSocialProvider} from '@reedsy/studio.shared/utils/auth/social-provider/normalize-social-provider';
import {RouterPlugin} from '@reedsy/studio.shared/store/plugins/router-plugin';
import {matchesRoute} from '@reedsy/studio.shared/router/helpers/matches-route';

@injectable()
export default class AuthAppCallbacksPluginFactory extends RouterPlugin {
  @$inject('Navigation')
  public readonly _navigation: INavigation;

  @$inject('Api')
  public readonly _api: IApi;

  private readonly HANDLERS: Record<string, (to: IMetaRoute) => Promise<void>> = Object.freeze({
    [SignInRouteName.OneTapSignIn]: this.handleOneTapSignIn,
    [SignInRouteName.SignInCallback]: this.handleSignInCallback,
    [SignInRouteName.TermsAndConditions]: this.handleTermsAndConditions,
    [SignInRouteName.Welcome]: this.handleWelcome,
    [SignInRouteName.ThankYou]: this.handleThankYou,
    [SignInRouteName.SocialSignIn]: this.handleSocialSignIn,
    [SignInRouteName.SignIn]: this.handleSignIn,
    [SignInRouteName.SignUp]: this.handleSignUp,
  });

  public override async beforeEach(to: IMetaRoute): Promise<void> {
    let handler = this.HANDLERS[to.name.toString()];
    if (handler) {
      handler = handler.bind(this);
      await handler(to);
    }
  }

  protected override shouldApplyBefore(to: IMetaRoute, _from: IMetaRoute): boolean {
    return matchesRoute(SignInRouteName.AuthBase, to);
  }

  private async handleOneTapSignIn(to: IMetaRoute): Promise<void> {
    await this.navigateSignedInUser(to);
  }

  private async handleSignInCallback(to: IMetaRoute): Promise<void> {
    const {code}: ISignInCallbackParams = to.query as any;
    await this._api.exchangeCodeForSsoToken(code);
    await this.navigateSignedInUser(to);
  }

  private async navigateSignedInUser(to: IMetaRoute): Promise<void> {
    const {next}: ISignInCallbackParams = to.query as any;
    if (!next || next === '/') {
      return this._navigation.goToBookShelf();
    } else {
      return this._navigation.goToExternalUrl(next);
    }
  }

  private async handleTermsAndConditions(): Promise<void> {
    return this._navigation.goToTermsAndConditions();
  }

  private async handleWelcome(): Promise<void> {
    return this._navigation.goToWelcome();
  }

  private async handleThankYou(to: IMetaRoute): Promise<void> {
    return this._navigation.goToThankYouPage(to.query.email as string);
  }

  private async handleSocialSignIn(to: IMetaRoute): Promise<void> {
    const socialProvider = normalizeSocialProvider(to.params.socialProvider);
    return this._navigation.goToSocialSignIn(socialProvider);
  }

  private async handleSignIn(): Promise<void> {
    return this._navigation.goToSignIn({
      afterSignInPath: '/',
    });
  }

  private async handleSignUp(): Promise<void> {
    return this._navigation.goToSignUp();
  }
}
