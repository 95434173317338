import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "overflow-content" }
const _hoisted_2 = { class: "panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconSettings = _resolveComponent("VuiIconSettings")!
  const _component_AccountSettingsMenu = _resolveComponent("AccountSettingsMenu")!
  const _component_VuiIconArrowLeft = _resolveComponent("VuiIconArrowLeft")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_MainLayout = _resolveComponent("MainLayout")!

  return (_openBlock(), _createBlock(_component_MainLayout, null, {
    "above-content": _withCtx(() => _cache[2] || (_cache[2] = [
      _createElementVNode("rbe-overflow-background", null, null, -1)
    ])),
    default: _withCtx(() => [
      _cache[9] || (_cache[9] = _createTextVNode()),
      _createElementVNode("rbe-content", _hoisted_1, [
        _createElementVNode("rbe-settings-panel", _hoisted_2, [
          _createElementVNode("rbe-menu-title", null, [
            _createVNode(_component_VuiIconSettings, { class: "icon-xl" }),
            _cache[3] || (_cache[3] = _createTextVNode()),
            _cache[4] || (_cache[4] = _createElementVNode("p", null, "Settings", -1))
          ]),
          _cache[7] || (_cache[7] = _createTextVNode()),
          _createVNode(_component_AccountSettingsMenu, {
            ref: "accountSettingsMenu",
            onOpenDetails: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isMobileSettingsShown = true))
          }, null, 512),
          _cache[8] || (_cache[8] = _createTextVNode()),
          _createElementVNode("rbe-settings-wrapper", {
            class: _normalizeClass({
            'mobile-hidden': !_ctx.isMobileSettingsShown
          })
          }, [
            _createElementVNode("rbe-settings-title", null, [
              _createElementVNode("button", {
                ref: "closeMobileSetting",
                class: "close-mobile-setting button-icon reedsy-accented inverted",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isMobileSettingsShown = false))
              }, [
                _createVNode(_component_VuiIconArrowLeft, { class: "icon-white" })
              ], 512),
              _cache[5] || (_cache[5] = _createTextVNode()),
              _createElementVNode("p", { ref: "settingTitle" }, _toDisplayString(_ctx.settingTitle), 513)
            ]),
            _cache[6] || (_cache[6] = _createTextVNode()),
            _createElementVNode("rbe-settings-content", null, [
              _createVNode(_component_RouterView)
            ])
          ], 2)
        ])
      ])
    ]),
    _: 1
  }))
}