import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("rbe-account-settings-menu", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createBlock(_component_RouterLink, {
        key: item.routeName,
        ref_for: true,
        ref: "itemLink",
        to: {
        name: item.routeName
      },
        class: "setting-item",
        "data-test-route-name": item.routeName,
        onClick: _ctx.emitOpenDetails
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(item.title), 1)
        ]),
        _: 2
      }, 1032, ["to", "data-test-route-name", "onClick"]))
    }), 128))
  ]))
}