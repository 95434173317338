<template>
  <rbe-mobile-menu>
    <VuiMobileOpenMenu
      ref="mobileOpenMenu"
      :open="$layout.mobileMenuIsShown"
      :options="options"
      @close="$layout.closeMobileMenu()"
    />
    <VuiReedsyAppsMobileCard
      ref="reedsyAppsMobileCard"
      app-name="studio"
      :open="appsMenuIsShown"
      @close="closeAppsMenu"
    />
  </rbe-mobile-menu>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfLayoutModule} from '@reedsy/studio.home.bookshelf/store/modules/layout';
import {BookshelfCollaboratorsModule} from '@reedsy/studio.home.bookshelf/store/modules/collaborators';
import {IMobileMenuOption} from '@reedsy/studio.home.bookshelf/components/mobile-menu/i-mobile-menu-option';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import {config} from '@reedsy/studio.shared/config';
import {ICrispManager} from '@reedsy/studio.home.bookshelf/utils/crisp/i-crisp-manager';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';

@Component({})
export default class MobileMenu extends BookshelfVue {
  @$lazyInjectStore(StoreName.Layout)
  public $layout: BookshelfLayoutModule;

  @$lazyInjectStore(StoreName.Collaborators)
  public $collaborators: BookshelfCollaboratorsModule;

  @$lazyInjectStore(StoreName.Modals)
  public $modals: BookshelfModalsModule;

  @$lazyInject('CrispManager')
  public readonly $crispManager: ICrispManager;

  @$lazyInject('Navigation')
  public readonly $navigation: INavigation;

  public appsMenuIsShown = false;
  public get options(): IMobileMenuOption[] {
    return [
      {
        icon: 'VuiIconOpenBook',
        text: 'Other apps',
        onClick: this.openAppsMenu,
      },
      {
        icon: 'VuiIconInfo',
        target: '_blank',
        text: 'Help',
        onClick: this.openHelpModal,
        children: [
          {
            target: '_blank',
            text: 'FAQ',
            url: config.reedsyApps.faq.url,
          },
          {
            text: 'Chat with our team',
            onClick: this.openCrisp,
          },
        ],
      },
      {
        icon: 'VuiIconSettings',
        target: '_self',
        text: 'Account settings',
        url: this.$navigation.editorAccountSettingsUrl,
        children: [
          {target: '_self', text: 'General', url: this.$navigation.editorAccountSettingsUrl},
          {target: '_self', text: 'Devices', url: this.$navigation.devicesSettingsUrl},
        ],
      },
      {
        icon: 'VuiIconExternal',
        text: 'Logout',
        onClick: () => this.$collaborators.logout(),
      },
    ];
  }

  public openAppsMenu(): void {
    this.$layout.closeMobileMenu();
    this.appsMenuIsShown = true;
  }

  public closeAppsMenu(): void {
    this.appsMenuIsShown = false;
    this.$layout.closeMobileMenu();
  }

  public openCrisp(): void {
    this.$layout.closeMobileMenu();
    this.$crispManager.openChat();
  }

  public openHelpModal(): void {
    this.$layout.closeMobileMenu();
    this.$modals.open('HelpModal');
  }
}

</script>

<style lang="scss">
rbe-mobile-menu {
  .vui-mobile-card {
    z-index: $z-index-modals;
  }

  .child-options {
    list-style-type: disc;
  }
}
</style>
