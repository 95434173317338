import {WebsocketConnectionSource} from '@reedsy/studio.isomorphic/models/websocket-connection-source';
import {config} from '@reedsy/studio.shared/config';
import SESSION_ID from './session-id';

export interface IShareDbQueryStringData {
  source: WebsocketConnectionSource;
  connectionId?: string;
}

export function getConnectionQueryString({source, connectionId}: IShareDbQueryStringData): string {
  connectionId ||= SESSION_ID;

  return new URLSearchParams({
    v: config.app.version,
    s: connectionId,
    source,
  }).toString();
}
