<template>
  <transition
    name="expand"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
  >
    <slot />
  </transition>
</template>

<script lang="ts">
/**
 * This component is made using
 * this article https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/
 * and github repo https://github.com/maoberlehner/transition-to-height-auto-with-vue
 */
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({})
export default class ExpandTransition extends ClientSharedVue {
  public afterEnter(element: HTMLElement): void {
    element.style.height = 'auto';
  }

  public enter(element: HTMLElement): void {
    const {width} = getComputedStyle(element);
    element.style.width = width;
    element.style.position = 'absolute';
    element.style.visibility = 'hidden';
    element.style.height = 'auto';
    const {height} = getComputedStyle(element);

    element.style.width = null;
    element.style.position = null;
    element.style.visibility = null;
    element.style.height = '0';

    // Force repaint to make sure the
    // animation is triggered correctly.
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    getComputedStyle(element).height;
    requestAnimationFrame(() => {
      element.style.height = height;
    });
  }

  public leave(element: HTMLElement): void {
    const {height} = getComputedStyle(element);
    element.style.height = height;

    // Force repaint to make sure the
    // animation is triggered correctly.
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    getComputedStyle(element).height;
    requestAnimationFrame(() => {
      element.style.height = '0';
    });
  }
}
</script>

<style lang="scss" scoped>
* {
  will-change: height, margin-bottom, margin-top;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.expand-enter-active, .expand-leave-active {
  @include transition((height, margin-bottom, margin-top));
}

.expand-enter-from, .expand-leave-to {
  margin-bottom: 0!important;
  margin-top: 0!important;
}
</style>
