
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {PropType} from 'vue';
import {ICollaborationInvitation} from '@reedsy/reedsy-sharedb/lib/common/collaboration/collaboration-invitations';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import ContextMenu from '@reedsy/studio.shared/components/context-menu/context-menu.vue';
import ContextMenuButton from '@reedsy/studio.shared/components/context-menu/context-menu-button.vue';
import Avatar from '@reedsy/studio.shared/components/avatar/avatar.vue';
import {EmailSendingStatus} from '@reedsy/reedsy-sharedb/lib/common/collaboration/email-sending-status';
import {is} from '@reedsy/utils.date';

@Component({
  components: {
    ContextMenu,
    ContextMenuButton,
    Avatar,
  },
})
export default class Invitation extends BookshelfVue {
  @$lazyInjectStore(StoreName.Modals)
  public $modals: BookshelfModalsModule;

  @Prop({type: Object as PropType<ICollaborationInvitation>, required: true})
  public invitation: ICollaborationInvitation;

  public get isEmailPending(): boolean {
    return this.invitation.emailStatus === EmailSendingStatus.Pending;
  }

  public get hasEmailFailed(): boolean {
    return this.invitation.emailStatus === EmailSendingStatus.Failed;
  }

  public get isExpired(): boolean {
    const expiryDate = this.invitation.expiryDate;
    return expiryDate && is(this.invitation.expiryDate).inThePast;
  }

  public get statusText(): string {
    if (this.hasEmailFailed) return 'Failed';
    if (this.isExpired) return 'Expired';

    return 'Invited';
  }

  public openConfirmResendModal(): void {
    this.$modals.open({
      type: 'ConfirmResendInvitation',
      context: {
        email: this.invitation.inviteeEmail,
        bookId: this.invitation.metadata.bookId,
        invitationId: this.invitation.shortId,
      },
    });
  }

  public openConfirmRevokeModal(): void {
    this.$modals.open({
      type: 'ConfirmRevokeInvitation',
      context: {
        email: this.invitation.inviteeEmail,
        bookId: this.invitation.metadata.bookId,
        invitationId: this.invitation.shortId,
      },
    });
  }
}
