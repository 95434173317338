<template>
  <rbe-no-book-cover-placeholder>
    <rbe-cover-wrapper>
      <BookTitle
        :title="title"
        :author="author"
      />
      <rbe-slot-wrapper>
        <slot />
      </rbe-slot-wrapper>
      <rbe-spacer />
    </rbe-cover-wrapper>
    <rbe-book-cover-bottom>
      <slot name="bottom" />
    </rbe-book-cover-bottom>
  </rbe-no-book-cover-placeholder>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';
import BookTitle from './book-title.vue';

@Component({
  components: {
    BookTitle,
  },
})
export default class NoBookCoverPlaceholder extends BookshelfVue {
  @Prop({type: String, required: true})
  public title: string;

  @Prop({type: Object, required: true})
  public author: IUserInfo;
}
</script>

<style lang="scss" scoped>
rbe-no-book-cover-placeholder {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: $border-radius-lg;
  background: $cover-placeholder-background;
  color: $cover-placeholder-text-color;
  padding: $space-xl $space-md $space-lg calc(5% + $space-md);
  box-sizing: border-box;
  box-shadow: $cover-placeholder-shadow;

  // cover spine
  &::before {
    background-color: $cover-placeholder-spine-color;

    @include cover-spine;
  }

  rbe-slot-wrapper {
    width: 100%;
  }

  rbe-book-title, rbe-spacer {
    flex: 1;
  }

  rbe-cover-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  rbe-book-cover-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
  }
}
</style>
