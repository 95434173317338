<template>
  <div />
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';

@Component({
  components: {},
})
export default class Empty extends BookshelfVue {}
</script>
