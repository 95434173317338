
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';
import AutoLoadAvatar from '@reedsy/studio.home.bookshelf/components/auto-load-avatar/auto-load-avatar.vue';
import TickIcon from './tick-icon.vue';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';
import CollaboratorContextMenu from '@reedsy/studio.shared/components/collaborator-context-menu/collaborator-context-menu.vue';
import {getShortFullName} from '@reedsy/studio.isomorphic/utils/get-short-full-name';
import {BookshelfCollaboratorsModule} from '@reedsy/studio.home.bookshelf/store/modules/collaborators';
import CollaboratorPlaceholder from './collaborator-placeholder.vue';

@Component({
  components: {
    AutoLoadAvatar,
    TickIcon,
    CollaboratorContextMenu,
    CollaboratorPlaceholder,
  },
})
export default class Collaborator extends BookshelfVue {
  @$lazyInjectStore(StoreName.CurrentBook)
  public currentBook: CurrentBookModule;

  @$lazyInjectStore(StoreName.Collaborators)
  public users: BookshelfCollaboratorsModule;

  @Prop({type: String, required: true})
  public collaboratorUuid: string;

  public get collaboratorShortName(): string {
    return getShortFullName(
      this.collaborator.firstName,
      this.collaborator.lastName,
    );
  }

  public get isLoading(): boolean {
    return !this.users.isCollaboratorLoaded(this.collaboratorUuid);
  }

  public get collaborator(): IUserInfo {
    return this.users.userInfoByUuid[this.collaboratorUuid];
  }

  public mounted(): void {
    this.users.loadContributor({
      bookUuid: this.currentBook.uuid,
      contributorUuid: this.collaboratorUuid,
    });
  }
}
