import {oneLine} from 'common-tags';

export const PREMIUM_FEATURES_DESCRIPTIONS = Object.freeze({
  checkIn: oneLine`
    Check-ins are a great way to stay accountable and build a writing routine. 
    Think of them as a short message posted to your timeline each day recording what you worked on.
  `,
  goals: oneLine`
    Build a writing routine and stay accountable with custom goals.
    Create daily, weekly and monthly word count targets and get notified if you fall behind schedule.
  `,
  attributes: oneLine`
    Attributes are a premium feature, they allow you to structure your assets, characters, 
    locations and create relationships between them.
  `,
} as const);
