import IShareDb from '@reedsy/studio.shared/services/sharedb/i-sharedb';
import {Logger} from '@reedsy/reedsy-logger-js';
import ShareDbError from '@reedsy/studio.shared/errors/sharedb-error';
import {Source} from '@reedsy/studio.isomorphic/models/source';
import {Connection, Doc, Presence, Query, Snapshot} from 'sharedb/lib/client';
import {Collection, CollectionMutation, ICollectionOpMap, ICollectionPresenceMap, ICollectionTypeMap} from '@reedsy/reedsy-sharedb/lib/common/collection-types';
import {LoggerFactory} from '@reedsy/reedsy-logger-js';
import {isValidId} from '@reedsy/reedsy-sharedb/lib/common/is-valid-id';
import {injectable} from 'inversify';
import IShareDbConnection from './i-sharedb-connection';
import {ErrorCode} from '@reedsy/reedsy-sharedb/lib/errors/error-code';
import {SubscriptionRequiredError} from '@reedsy/studio.shared/errors/subscription-required-error';
import {wrapDoc} from '@reedsy/reedsy-sharedb/lib/common/wrapped-doc';

@injectable()
export default abstract class ShareDb<
  T extends Collection,
  TShareDBConnection extends IShareDbConnection<any> = IShareDbConnection<any>,
> implements IShareDb<T> {
  protected abstract handleUnauthorized(error: any, data: any): Promise<void>;

  public readonly logger: Logger;
  public readonly shareDbConnection: TShareDBConnection;

  public constructor(
    loggerFactory: LoggerFactory,
    shareDbConnection: TShareDBConnection,
  ) {
    this.logger = loggerFactory.create('ShareDbService');
    this.shareDbConnection = shareDbConnection;
  }

  public get connection(): Connection {
    return this.shareDbConnection.connection;
  }

  public get<C extends Collection>(collection: C, id: string): Doc<ICollectionTypeMap[C]> {
    if (!isValidId(collection, id)) {
      this.logger.error(new Error('Invalid ID'), {data: {collection, id}});
      return null;
    }

    const doc = this.connection.get(collection, id) as Doc<ICollectionTypeMap[C]>;
    doc.submitSource = true;

    return doc;
  }

  public subscribe<C extends Collection>(collection: C, id: string): Promise<Doc<ICollectionTypeMap[C]>> {
    return new Promise((resolve, reject) => {
      const doc = this.get(collection, id);
      doc.subscribe(async (error) => {
        if (!error) return resolve(doc);
        const wrappedError = await this.handleError(error, {id, collection});
        reject(wrappedError);
      });
    });
  }

  public fetchSnapshot<C extends Collection>(
    collection: C,
    id: string,
    timestamp: number,
  ): Promise<Snapshot<ICollectionTypeMap[C]>> {
    return new Promise((resolve, reject) => {
      this.connection.fetchSnapshotByTimestamp(collection, id, timestamp, async (error, snapshot) => {
        if (!error) return resolve(snapshot);
        const wrappedError = await this.handleError(error, {id, collection, timestamp});
        reject(wrappedError);
      });
    });
  }

  public submitOp<C extends Collection>(
    collection: C,
    id: string,
    op: ICollectionOpMap[C][],
    source: Source,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const doc = this.get(collection, id);
      const options: any = {source};
      doc.submitOp(op, options, async (error) => {
        if (!error) return resolve();
        const wrappedError = await this.handleError(error, {id, collection, op, data: doc.data});
        reject(wrappedError);
      });
    });
  }

  public async mutate<C extends Collection>(
    collection: C,
    id: string,
    mutation: CollectionMutation<C>,
    source: Source,
  ): Promise<void> {
    const doc = wrapDoc(this.get(collection, id));
    try {
      await doc.mutate({source}, mutation);
    } catch (error) {
      const wrappedError = await this.handleError(error, {id, collection, data: doc.data});
      throw wrappedError;
    }
  }

  public getPresence<
    C extends string,
    T = C extends keyof ICollectionPresenceMap ? ICollectionPresenceMap[C] : any,
  >(collection: C, id?: string): Presence<T> {
    if (!id) return this.connection.getPresence(collection);
    return this.connection.getDocPresence(collection, id);
  }

  public subscribePresence<C extends keyof ICollectionPresenceMap>(
    collection: C,
    id: string,
  ): Promise<Presence<ICollectionPresenceMap[C]>> {
    return new Promise((resolve, reject) => {
      const presence = this.getPresence(collection, id);
      presence.subscribe(async (error) => {
        if (!error) return resolve(presence);
        const wrappedError = await this.handleError(error, {id, collection});
        reject(wrappedError);
      });
    });
  }

  public subscribeQuery<C extends Collection>(collection: C, query: any = {}): Promise<Query<ICollectionTypeMap[C]>> {
    return new Promise((resolve, reject) => {
      const subscribeQuery = this.connection.createSubscribeQuery(collection, query, null, (error) => {
        if (error) return reject(error);
        resolve(subscribeQuery);
      });
    });
  }

  public fetchQuery<C extends Collection>(collection: C, query: any): Promise<Query<ICollectionTypeMap[C]>> {
    return new Promise((resolve, reject) => {
      const fetchQuery = this.connection.createFetchQuery(collection, query, null, (error) => {
        if (error) return reject(error);
        resolve(fetchQuery);
      });
    });
  }

  public destroy<C extends Collection>(collection: C, id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const doc = this.get(collection, id);
      doc.destroy(async (error) => {
        if (!error) return resolve();
        const wrappedError = await this.handleError(error, {id, collection});
        reject(wrappedError);
      });
    });
  }

  public del<C extends Collection>(collection: C, id: string, source: Source): Promise<void> {
    return new Promise((resolve, reject) => {
      const doc = this.get(collection, id);
      doc.del({source}, async (error) => {
        if (error) return reject(error);
        await this.destroy(collection, id);
        resolve();
      });
    });
  }

  private async handleError(error: any, data: any): Promise<Error> {
    switch (error.code) {
      case ErrorCode.Unauthorized:
        await this.handleUnauthorized(error, data);
      case ErrorCode.SubscriptionRequired:
        return new SubscriptionRequiredError(error);
      default:
        error = new ShareDbError(error);
        this.logger.error(error, {data});
        return error;
    }
  }
}
