import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PremiumBadgeInfo = _resolveComponent("PremiumBadgeInfo")!
  const _component_PremiumBadge = _resolveComponent("PremiumBadge")!
  const _component_InfoTip = _resolveComponent("InfoTip")!
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_ctx.config.features.subscriptions)
    ? (_openBlock(), _createBlock(_component_InfoTip, _normalizeProps(_mergeProps({ key: 0 }, _ctx.$attrs)), {
        trigger: _withCtx(() => [
          _renderSlot(_ctx.$slots, "trigger", {}, () => [
            _createVNode(_component_PremiumBadgeInfo)
          ], true)
        ]),
        default: _withCtx(() => [
          _cache[4] || (_cache[4] = _createTextVNode()),
          _createElementVNode("rbe-premium-info", null, [
            _createVNode(_component_PremiumBadge),
            _cache[2] || (_cache[2] = _createTextVNode()),
            _createElementVNode("rbe-premium-copy", null, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _cache[3] || (_cache[3] = _createTextVNode()),
            (!_ctx.$subscription.hasFeature(_ctx.paidFeature))
              ? _withDirectives((_openBlock(), _createElementBlock("button", {
                  key: 0,
                  ref: "freeTrialButton",
                  class: "free-trial-button",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.$subscriptionModal.open && _ctx.$subscriptionModal.open(...args)))
                }, _cache[1] || (_cache[1] = [
                  _createTextVNode("\n        Start your free trial →\n      ")
                ]))), [
                  [_directive_close_popper]
                ])
              : _createCommentVNode("", true)
          ])
        ]),
        _: 3
      }, 16))
    : _createCommentVNode("", true)
}