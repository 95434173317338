import browser from '@reedsy/studio.shared/utils/browser';
import {IKeyHandler} from '@reedsy/studio.shared/utils/keyboard/handle-keyboard-event';

type PressedKey = Omit<IKeyHandler, 'handler' | 'preventDefault'>;

export function keyIsPressed(event: KeyboardEvent, config: PressedKey): boolean {
  if (!event.key) return false;
  config.shortKey = !!config.shortKey;
  config.shiftKey = !!config.shiftKey;

  const shortKeyPressed = browser.is('macOS') ? event.metaKey : event.ctrlKey;
  const nonShortKeyPressed = browser.is('macOS') ? event.ctrlKey : event.metaKey;
  const shiftKeyPressed = event.shiftKey;
  const altPressed = event.altKey;

  // Check that all modifier keys are all exactly as expected.
  // This prevents us from overriding other hotkeys by accident.
  // For example, in Chrome, Ctrl  F is the in-page find, but
  // Ctrl  Meta  F triggers a Google search from the URL bar.
  // If we've specified Ctrl  F, we shouldn't match Ctrl  Meta  F
  // and override that behaviour.
  return (config.key.toLocaleUpperCase() === event.key.toLocaleUpperCase()) &&
    shortKeyPressed === config.shortKey &&
    shiftKeyPressed === config.shiftKey &&
    !nonShortKeyPressed && !altPressed;
}
