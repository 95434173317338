
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import ExportTypeIndicator from './export-type-indicator.vue';
import ExportListEntry from './exports-list-entry.vue';
import {IBookExport} from '@reedsy/reedsy-sharedb/lib/common/book/book-export';
import Panel from '@reedsy/studio.shared/components/panel/panel.vue';
import EmptyListPlaceholder from './empty-list-placeholder.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';

@Component({
  components: {
    ExportTypeIndicator,
    ExportListEntry,
    Panel,
    EmptyListPlaceholder,
  },
})
export default class ExportsList extends BookshelfVue {
  @$lazyInjectStore(StoreName.CurrentBook)
  public $currentBook: CurrentBookModule;

  public get bookExports(): IBookExport[] {
    return this.$currentBook.exports;
  }

  public get hasExports(): boolean {
    return !!this.$currentBook.exports.length;
  }
}
