import loggerFactory from '@reedsy/studio.shared/services/logger/logger-factory';
import {App, ComponentPublicInstance} from 'vue';
import {dig} from '@reedsy/utils.dig';
import vueStack from '@reedsy/studio.shared/utils/debug/vue-stack';
import {IGNORED_ERRORS} from '@reedsy/studio.shared/errors/ignored-errors';
import {Plugin} from 'vue';

const ErrorHandler: Plugin = {
  install(vue: App): void {
    const logger = loggerFactory.create('UncaughtClientError');

    const errorHandler = (event: ErrorEvent | PromiseRejectionEvent): void => {
      event.preventDefault();
      event.stopPropagation();
      let message: Error | string;
      if (event instanceof PromiseRejectionEvent) message = event.reason;
      else message = event.error || event.message || event.filename;

      if (isIgnored(message)) logger.debug(message, {data: {...event}});
      else if (message) logger.error(message, {data: {...event}});
      else logger.debug('Unknown error', {data: {...event}});
    };

    window.addEventListener('error', errorHandler);
    window.addEventListener('unhandledrejection', errorHandler);

    vue.config.errorHandler = (error: Error, component: ComponentPublicInstance, trace: string) => {
      const stack = vueStack(dig(component, '$el') as HTMLElement) + trace;
      const data = {stack};
      if (isIgnored(error)) logger.debug(error, {data});
      else logger.error(error, {data});
    };
  },
};

function isIgnored(error: Error | string): boolean {
  let message = '';
  if (typeof error === 'string') message = error;
  else if (dig(error, 'message')) message = error.message;
  return message && IGNORED_ERRORS.some((regex) => regex.test(message));
}

export default ErrorHandler;
