<template>
  <Panel class="bordered trial-panel">
    <rbe-trial-panel-body class="flex-justified">
      <rbe-trial-description class="flex-top">
        <h1>Start your 30 day free trial</h1>
        <p>Enable all premium add-ons for 30 days.</p>
      </rbe-trial-description>
      <button
        ref="start-trial-button"
        class="reedsy-accented button"
        type="button"
        @click="$subscriptionModal.open"
      >
        Start 30-day trial
      </button>
    </rbe-trial-panel-body>
  </Panel>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {$lazyInject} from '@reedsy/studio.home.bookshelf/inversify.config';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';
import Panel from '@reedsy/studio.shared/components/panel/panel.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({
  components: {
    Panel,
  },
})
export default class TrialPanel extends ClientSharedVue {
  @$lazyInject('SubscriptionModal')
  public $subscriptionModal: ISubscriptionModalService;
}
</script>

<style lang="scss" scoped>
@include screen-less-than(md) {
  rbe-trial-panel-body {
    flex-direction: column;
    gap: $space-base;
  }

  rbe-trial-description {
    align-items: center;
  }
}

.trial-panel {
  background-color: var(--local-color-muted);

  h1 {
    font-size: $font-size-lg;
    margin-bottom: $space-sm;

    @include font-family($controls, bold);
  }
}
</style>
