import {injectable} from 'inversify';
import {ISpotlightTour, Params} from './i-spotlight-tour';
import {TourName} from '@reedsy/studio.shared/services/spotlight-tour/tour-names';
import {$inject} from '@reedsy/studio.shared/types';
import {ReedsyRouter} from '@reedsy/studio.shared/router/reedsy-router';
import {TOUR_QUERY_KEY, TourRoutes} from './tour-routes';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';

@injectable()
export class SpotlightTour implements ISpotlightTour {
  public constructor(
    @$inject('Router')
    private readonly router: ReedsyRouter,

    @$inject('Navigation')
    private readonly navigation: INavigation,

    @$inject('TourRoutes')
    private readonly tourRoutes: TourRoutes,
  ) {}

  public async start<T extends TourName>(tour: T, params: Params<TourRoutes[T]['path']>): Promise<void> {
    const tourConfig = this.tourRoutes[tour];
    let path: string = tourConfig.path;
    Object.entries(params).forEach(([key, value]) => {
      path = path.replace(`/:${key.toString()}`, `/${value}`);
    });

    const notFound = this.router.resolve({path: '/not-found'});
    const resolved = this.router.resolve({path});

    if (resolved.name === notFound.name) {
      const url = this.navigation.getStudioUrl(tourConfig.app, path);
      url.searchParams.set(TOUR_QUERY_KEY, tour);
      return this.navigation.goToExternalUrl(url);
    }

    await this.router.push({
      path,
      query: {[TOUR_QUERY_KEY]: tour},
    });
  }
}
