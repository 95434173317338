import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/inlined/backup-icon.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconDownload = _resolveComponent("VuiIconDownload")!
  const _component_LoadingButton = _resolveComponent("LoadingButton")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, { class: "book-backup" }, {
    default: _withCtx(() => [
      _createElementVNode("rbe-book-backup-content", null, [
        _cache[1] || (_cache[1] = _createElementVNode("img", {
          class: "backup-icon",
          src: _imports_0
        }, null, -1)),
        _cache[2] || (_cache[2] = _createTextVNode()),
        _cache[3] || (_cache[3] = _createElementVNode("rbe-backup-book-text-wrapper", null, [
          _createElementVNode("h1", null, "\n          Back up your book\n        "),
          _createTextVNode(),
          _createElementVNode("p", null, "\n          Need an editable version of your book as a backup? Export a simple .docx version of your manuscript.\n        ")
        ], -1)),
        _cache[4] || (_cache[4] = _createTextVNode()),
        _createVNode(_component_LoadingButton, {
          ref: "backupButton",
          class: "button button-with-icon backup-button reedsy-accented",
          loading: _ctx.isSubmittingExportRequest,
          onClick: _ctx.exportDocx
        }, {
          default: _withCtx(() => [
            _cache[0] || (_cache[0] = _createElementVNode("span", null, "Back up", -1)),
            _createVNode(_component_VuiIconDownload)
          ]),
          _: 1
        }, 8, ["loading", "onClick"])
      ])
    ]),
    _: 1
  }))
}