<template>
  <section class="feature-summaries">
    <FeatureSummary
      v-for="summary in summaries"
      :key="summary.product"
      :ref="summary.product"
      v-model="selectedProducts[summary.product]"
      :summary="summary"
      :is-preview="featurePreview"
    />
  </section>
</template>

<script lang="ts">
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {SubscriptionProduct} from '@reedsy/utils.subscription';
import FeatureSummary from './feature-summary.vue';
import {FEATURE_SUMMARIES} from './feature-summaries';
import {IProductSummary} from './product-summary.interface';

@Component({
  components: {
    FeatureSummary,
  },
})
export default class FeatureSummaries extends ClientSharedVue {
  @Model({type: Object, default: {}})
  public selectedProducts: Partial<Record<SubscriptionProduct, boolean>>;

  @Prop({type: Boolean, default: false})
  public featurePreview: boolean;

  public get summaries(): ReadonlyArray<IProductSummary> {
    if (!this.featurePreview) return FEATURE_SUMMARIES;

    return FEATURE_SUMMARIES.filter((summary) => summary.product);
  }
}
</script>

<style lang="scss" scoped>
.feature-summaries {
  display: flex;
  flex-wrap: wrap;
  gap: $space-base;
  align-items: stretch;

  @include screen-less-than(lg) {
    flex-direction: column;
  }

  & > * {
    flex: 1 0 0;
  }
}
</style>
