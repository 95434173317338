
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import PeriodToggle from '@reedsy/studio.shared/components/modals/components/subscription/features/period-toggle.vue';
import CompactFeatureSummary from '@reedsy/studio.shared/components/modals/components/subscription/features/compact-feature-summary.vue';
import {FEATURE_SUMMARIES} from '@reedsy/studio.shared/components/modals/components/subscription/features/feature-summaries';
import AnnualSaving from '@reedsy/studio.shared/components/modals/components/subscription/annual-saving.vue';

@Component({
  components: {
    PeriodToggle,
    CompactFeatureSummary,
    AnnualSaving,
  },
})
export default class ManageAddonsPanel extends ClientSharedVue {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  public readonly availableSummaries = FEATURE_SUMMARIES.filter((summary) => summary.product);

  public get activeFeaturesHaveChanged(): boolean {
    // TODO: [subscriptions] get value
    return true;
  }

  public get billingPeriodHasChanged(): boolean {
    // TODO: [subscriptions] get value
    return true;
  }
}
