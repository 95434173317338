import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex-top" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiSlider = _resolveComponent("VuiSlider")!
  const _component_VTextarea = _resolveComponent("VTextarea")!
  const _component_ExpandTransition = _resolveComponent("ExpandTransition")!

  return (_openBlock(), _createElementBlock("rbe-rating", null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "question" }, "\n        How likely is it that you would recommend Reedsy Studio to a friend?\n      ", -1)),
      _cache[3] || (_cache[3] = _createTextVNode()),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex-justified" }, [
        _createElementVNode("label", null, "Not at all likely"),
        _createTextVNode(),
        _createElementVNode("label", null, "Extremely likely")
      ], -1)),
      _cache[5] || (_cache[5] = _createTextVNode()),
      _createElementVNode("rbe-slider-wrapper", null, [
        _createVNode(_component_VuiSlider, {
          ref: "ratingSlider",
          modelValue: _ctx.selectedRating,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedRating) = $event)),
          min: 1,
          max: 10,
          marks: "",
          tooltip: "none",
          class: "rating-slider"
        }, null, 8, ["modelValue"])
      ])
    ]),
    _cache[7] || (_cache[7] = _createTextVNode()),
    _createVNode(_component_ExpandTransition, null, {
      default: _withCtx(() => [
        (_ctx.explanationIsShown)
          ? (_openBlock(), _createElementBlock("rbe-low-rating-explanation-wrapper", _hoisted_2, [
              _createElementVNode("rbe-low-rating-explanation", _hoisted_3, [
                _createElementVNode("label", null, _toDisplayString(_ctx.adaptiveQuestion), 1),
                _cache[6] || (_cache[6] = _createTextVNode()),
                _createVNode(_component_VTextarea, {
                  ref: "textarea",
                  modelValue: _ctx.explanation,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.explanation) = $event)),
                  required: true,
                  placeholder: "Please specify any features or changes you would like to see"
                }, null, 8, ["modelValue"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}