import {VuexModule, Mutation} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {injectable, named} from 'inversify';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {Store} from 'vuex';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookDetailsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-details/book-details';
import {BookshelfModule} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf';
import {IBookshelfBook} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-book';
import {BookDataStoreCoordinator} from '@reedsy/studio.home.bookshelf/store/helpers/book-data-store-coordinator';
import {IBookExport} from '@reedsy/reedsy-sharedb/lib/common/book/book-export';
import {ResourcesRole} from '@reedsy/utils.reedsy-resources';
import {numericResourcesRole} from '@reedsy/studio.isomorphic/utils/numeric-resources-role';
import {ICollaboratorInfo} from '@reedsy/studio.home.bookshelf/store/modules/book-details/i-collaborator-info';
import {ICollaborationInvitation} from '@reedsy/reedsy-sharedb/lib/common/collaboration/collaboration-invitations';
import {IAction} from '@reedsy/studio.isomorphic/utils/permissions-requirements';
import {hasPermissionTo} from '@reedsy/studio.isomorphic/utils/has-permission-to';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';

@injectable()
export class CurrentBookModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,

    @$inject('StoreModule')
    @named(StoreName.BookDetails)
    bookDetails: BookDetailsModule,

    @$inject('StoreModule')
    @named(StoreName.Bookshelf)
    bookshelf: BookshelfModule,

    @$inject('StoreModule')
    @named(SharedStoreName.User)
    currentUser: SharedUserModule,

    @$inject('BookshelfBookDataStoreCoordinator')
    bookDataStoreCoordinator: BookDataStoreCoordinator,
  ) {
    @Module({name: StoreName.CurrentBook, store})
    class CurrentBook extends VuexModule {
      public uuid = '';

      public get id(): string {
        return bookDetails.bookId(this.uuid);
      }

      public get data(): IBookshelfBook {
        return bookshelf.book(this.id);
      }

      public get userRole() {
        return (userUuid: string): ResourcesRole => {
          return bookDetails.userBookRole({userUuid, bookId: this.id});
        };
      }

      public get hasMinimumPermission() {
        return (userUuid: string, minimumRole: ResourcesRole): boolean => {
          const userRole = this.userRole(userUuid);
          return numericResourcesRole(userRole) >= numericResourcesRole(minimumRole);
        };
      }

      public get hasPermissionTo() {
        return ({action, userUuid}: {action: IAction; userUuid: string}): boolean => {
          return hasPermissionTo(action, this.userRole(userUuid));
        };
      }

      public get hasCurrentUserPermissionTo() {
        return (action: IAction): boolean => {
          return this.hasPermissionTo({
            action,
            userUuid: currentUser.uuid,
          });
        };
      }

      public get owner(): ICollaboratorInfo {
        return this.collaborators.find((collaborator) => collaborator.role === ResourcesRole.Owner);
      }

      public get collaborators(): ICollaboratorInfo[] {
        return bookDetails.collaborators(this.id);
      }

      public get invitations(): ICollaborationInvitation[] {
        return bookDataStoreCoordinator.getModule('collaborationInvitations')?.allInvitations || [];
      }

      public get exports(): IBookExport[] {
        return bookDataStoreCoordinator.getModule('bookExports')?.allExports || [];
      }

      public get isHidden(): boolean {
        return !!this.data.hiddenAt;
      }

      @Mutation
      public UUID(bookUuid: string): void {
        this.uuid = bookUuid;
      }
    }
    this.Module = CurrentBook;
  }
}

export type CurrentBookModule = InstanceType<CurrentBookModuleFactory['Module']>;
