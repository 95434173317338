import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/inlined/export-started-modal/typewriter.png'


const _hoisted_1 = { class: "export-started-modal-content" }
const _hoisted_2 = { class: "share-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FacebookShareButton = _resolveComponent("FacebookShareButton")!
  const _component_LinkedInShareButton = _resolveComponent("LinkedInShareButton")!
  const _component_TwitterShareButton = _resolveComponent("TwitterShareButton")!
  const _component_PlainModal = _resolveComponent("PlainModal")!

  return (_openBlock(), _createBlock(_component_PlainModal, {
    id: _ctx.id,
    headless: "",
    class: "wide transparent full-height"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[3] || (_cache[3] = _createElementVNode("img", {
          alt: "Typewriter",
          src: _imports_0,
          width: "240"
        }, null, -1)),
        _cache[4] || (_cache[4] = _createTextVNode()),
        _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "title text-light" }, "\n        Your book is being typeset!\n      ", -1)),
        _cache[6] || (_cache[6] = _createTextVNode()),
        _cache[7] || (_cache[7] = _createElementVNode("p", { class: "description text-light font-size-large" }, "\n        You will receive an email shortly with a link\n        to download your fully formatted files.\n        Share this milestone with your networks!\n      ", -1)),
        _cache[8] || (_cache[8] = _createTextVNode()),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FacebookShareButton, {
            class: "button-lg button-full-xs",
            "shared-link": _ctx.sharedLink
          }, null, 8, ["shared-link"]),
          _cache[1] || (_cache[1] = _createTextVNode()),
          _createVNode(_component_LinkedInShareButton, {
            class: "button-lg button-full-xs",
            description: _ctx.sharedText,
            title: "Reedsy",
            "shared-link": _ctx.sharedLink
          }, null, 8, ["description", "shared-link"]),
          _cache[2] || (_cache[2] = _createTextVNode()),
          _createVNode(_component_TwitterShareButton, {
            class: "button-lg button-full-xs",
            hashtags: "amwriting",
            title: _ctx.sharedText,
            via: "ReedsyHQ",
            "shared-link": _ctx.sharedLink
          }, null, 8, ["title", "shared-link"])
        ]),
        _cache[9] || (_cache[9] = _createTextVNode()),
        _createElementVNode("button", {
          class: "button button-lg",
          title: "Close",
          type: "button",
          "data-test": "close-button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
        }, "\n        Close\n      ")
      ])
    ]),
    _: 1
  }, 8, ["id"]))
}