import {objectKeys} from '@reedsy/utils.object';
import ValidatableField from './mixins/validatable-field';

const NUMBER_ERROR_THRESHOLD = 1e7;

export const INVALID_URL_MESSAGE = 'Please enter a valid URL';
export const INVALID_EMAIL_MESSAGE = 'Please enter a valid email';

export const MESSAGE_FACTORIES: Partial<Record<keyof ValidityState, (field?: ValidatableField) => string>> = {
  valueMissing: () => 'This field is required',
  patternMismatch: () => 'This field doesn’t match the expected pattern',
  typeMismatch: (field: ValidatableField) => {
    // According to docs it is only used in email and url type
    // https://developer.mozilla.org/en-US/docs/Web/API/ValidityState/typeMismatch

    if (field.type === 'url') return INVALID_URL_MESSAGE;
    return INVALID_EMAIL_MESSAGE;
  },
  badInput: (field: ValidatableField) => {
    if (field.type === 'number') return 'Please enter a number';
    return 'Invalid format';
  },
  rangeOverflow: (field: ValidatableField) => {
    const max = Number(field.getAttribute('max'));
    if (max > NUMBER_ERROR_THRESHOLD) return 'The entered value is too large';
    return `The maximum value is ${Number(max)}`;
  },
  rangeUnderflow: (field: ValidatableField) => {
    const min = Number(field.getAttribute('min'));
    if (min < -NUMBER_ERROR_THRESHOLD) return 'The entered value is too small';
    return `The minimum value is ${min}`;
  },
  tooLong: (field: ValidatableField) => `This field must be shorter than ${field.getAttribute('maxlength')} characters`,
  tooShort: (field: ValidatableField) => `This field must be longer than ${field.getAttribute('minlength')} characters`,
  customError: ({validationMessage}: ValidatableField) => validationMessage,
} as const;

export default function(field: ValidatableField): string {
  for (const validityState of objectKeys(MESSAGE_FACTORIES)) {
    if (field.validity[validityState]) return MESSAGE_FACTORIES[validityState](field);
  }
}
