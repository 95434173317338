
import {Component, Model} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import PeriodSummary from './period-summary.vue';

@Component({
  components: {PeriodSummary},
})
export default class PeriodPicker extends ClientSharedVue {
  @Model({type: Boolean})
  public billAnnually: boolean;
}
