
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {IBookshelfEntry} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-entry';
import {IMetaLocation} from '@reedsy/studio.shared/router/reedsy-router';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import {IBookshelfBook} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-book';
import {encodeUUID} from '@reedsy/utils.uuid';
import {$lazyInject} from '@reedsy/studio.shared/inversify.config';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {IBookImport} from '@reedsy/reedsy-sharedb/lib/common/book/book-imports';
import {BookshelfModule} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf';
import {isBookEntry, isBookImportEntry} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/is-entry';
import {BooksImportsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-imports';

@Component({})
export default class SelectedBookActionBar extends BookshelfVue {
  @$lazyInject('Navigation')
  public navigation: INavigation;

  @$lazyInjectStore(StoreName.Bookshelf)
  public $bookshelf: BookshelfModule;

  @$lazyInjectStore(StoreName.BooksImports)
  public $booksImports: BooksImportsModule;

  public get selectedEntry(): IBookshelfEntry {
    return this.$bookshelf.activeEntry;
  }

  public get disabled(): boolean {
    return !this.selectedEntry || null;
  }

  public get isBookImport(): boolean {
    return isBookImportEntry(this.selectedEntry);
  }

  public get book(): IBookshelfBook {
    return isBookEntry(this.selectedEntry) ? this.selectedEntry.item : null;
  }

  public get bookImport(): IBookImport {
    return isBookImportEntry(this.selectedEntry) ? this.selectedEntry.item : null;
  }

  public get bookLocation(): IMetaLocation | string {
    return this.book ?
      {
        name: BookshelfRouteName.Book,
        params: {
          bookUuid: encodeUUID(this.book.uuid),
        },
      } :
      '#';
  }

  public get bookEditorLink(): string {
    return this.book ? this.navigation.getBookEditorUrl(this.book.uuid) : '#';
  }

  public async cancelImport(): Promise<void> {
    await this.$booksImports.cancelBookImport(this.bookImport._id);
  }
}
