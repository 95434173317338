import {readonly, ref} from 'vue';

const _isOsDarkMode = ref(false);
export const isOsDarkMode = readonly(_isOsDarkMode);

// This function is exported for easier testing
// and should not be used anywhere else
export function initialiseOsDarkModeListener(): void {
  if (!window.matchMedia) {
    _isOsDarkMode.value = false;
    return;
  };

  const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');

  _isOsDarkMode.value = matchMedia.matches;
  matchMedia.addEventListener('change', (event) => {
    _isOsDarkMode.value = event.matches;
  });
}
