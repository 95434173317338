import {IContributors} from '@reedsy/reedsy-sharedb/lib/common/book/contributors';
import {objectValues} from '@reedsy/utils.object';
import {ResourcesRole} from '@reedsy/utils.reedsy-resources';

export interface IGetAllContributorsOptions {
  includeNoneRole?: boolean;
}

export function getAllContributors(
  contributors: IContributors,
  {includeNoneRole}: IGetAllContributorsOptions = {},
): Set<string> {
  contributors = {...contributors};
  if (!includeNoneRole) delete contributors[ResourcesRole.None];

  return new Set(objectValues(contributors).flat());
}
