import {NotificationTime} from '@reedsy/studio.shared/models/notification-time';
import {GlobalVuiNotificationManager, INotifyOptions, Persistent} from '@reedsy/vui';

const notifier = new GlobalVuiNotificationManager();
notifier.defaultDisplayTime = NotificationTime.Default;

export interface INotifyArgs {
  message: string;
  persistent?: Persistent;
  millis?: number;
}

export default class Notify {
  public static message(args: INotifyArgs): void {
    notifier.message(args.message, argsToOptions(args));
  }

  public static success(args: INotifyArgs): void {
    notifier.success(args.message, argsToOptions(args));
  }

  public static error(args: INotifyArgs): void {
    notifier.error(args.message, argsToOptions(args));
  }
}

function argsToOptions({persistent, millis}: INotifyArgs): INotifyOptions {
  return {
    persistent: persistent || false,
    timer: millis,
  };
}
