<template>
  <rbe-lazy-renderer>
    <slot v-if="shouldRender" />
  </rbe-lazy-renderer>
</template>

<script lang="ts">
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {SECOND} from '@reedsy/utils.date';

@Component({})
export default class LazyRenderer extends ClientSharedVue {
  public shouldRender = false;
  public unmountTimer: number = null;
  private observer: IntersectionObserver = null;

  public mounted(): void {
    this.observer = new IntersectionObserver(
      (entries) => {
        const isInViewPort = entries.some((entry) => entry.isIntersecting);
        if (isInViewPort) {
          this.mountComponent();
        } else {
          this.unmountComponent();
        }
      },
      {rootMargin: '200px'},
    );
    this.observer.observe(this.$el);
  }

  public unmounted(): void {
    this.observer.disconnect();
  }

  public mountComponent(): void {
    clearTimeout(this.unmountTimer);
    this.shouldRender = true;
  }

  public unmountComponent(): void {
    this.unmountTimer = setTimeout(() => this.shouldRender = false, 1 * SECOND);
  }
}
</script>
