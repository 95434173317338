<template>
  <rbe-layout-with-right-side-bar>
    <rbe-left-column>
      <slot name="left-side" />
    </rbe-left-column>
    <rbe-right-column>
      <slot name="right-side" />
    </rbe-right-column>
  </rbe-layout-with-right-side-bar>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';

@Component({})
export default class LayoutWithRightSideBar extends BookshelfVue {}
</script>

<style lang="scss" scoped>
$left-column-width: 70%;

rbe-layout-with-right-side-bar {
  margin-top: $space-xl;
  display: flex;
  width: 100%;
  flex-direction: column;

  @include screen-more-than(sm) {
    flex-direction: row;
  }
}

rbe-left-column {
  width: 100%;

  @include screen-more-than(sm) {
    width: $left-column-width;
    box-sizing: border-box;
    padding-right: $space-xl;
  }
}

rbe-right-column {
  width: 100%;

  @include screen-more-than(sm) {
    width: 100% - $left-column-width;
  }
}
</style>
