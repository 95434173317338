
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import PremiumBadgeInfo from '@reedsy/studio.shared/components/subscriptions/premium-badge-info.vue';
import PremiumInfo from '@reedsy/studio.shared/components/subscriptions/premium-info.vue';
import {config} from '@reedsy/studio.shared/config';

@Component({
  components: {
    PremiumInfo,
    PremiumBadgeInfo,
  },
})
export default class PanelBodyTitle extends ClientSharedVue {
  public readonly config = config;
}
