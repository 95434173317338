import {Collection, ICollectionOpMap, ICollectionTypeMap} from '@reedsy/reedsy-sharedb/lib/common/collection-types';
import {WrappedDoc} from '@reedsy/reedsy-sharedb/lib/common/wrapped-doc';
import {Source} from '@reedsy/studio.isomorphic/models/source';
import {Doc} from 'sharedb/lib/sharedb';

export function subscribeVuexToDoc<C extends Collection>(
  doc: Doc<ICollectionTypeMap[C]> | WrappedDoc<ICollectionTypeMap[C]>,
  dataModule: IDataModule<C>,
): void {
  dataModule.DATA({id: doc.id, data: doc.data});
  doc.on('load', () => dataModule.DATA({id: doc.id, data: doc.data}));

  doc.on('op', (ops, source) => {
    dataModule.APPLY_OPS({
      id: doc.id,
      ops,
      newContent: doc.data,
      source,
    });
  });
}

export interface IDataModule<C extends Collection> {
  DATA(mutation: IDataMutation<C>): void;
  APPLY_OPS(mutation: IApplyOpsMutation<C>): void;
};

export interface IDataMutation<C extends Collection> {
  id: string;
  data: ICollectionTypeMap[C];
}

export interface IApplyOpsMutation<C extends Collection> {
  id: string;
  ops: ICollectionOpMap[C][];
  newContent: ICollectionTypeMap[C];
  source: Source;
}
