import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "modal-description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImportFileUploader = _resolveComponent("ImportFileUploader")!
  const _component_VInput = _resolveComponent("VInput")!
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!

  return (_openBlock(), _createBlock(_component_ConfirmModal, {
    id: _ctx.id,
    confirm: {
      label: _ctx.modalTitle,
      handler: _ctx.importBook,
    },
    title: _ctx.modalTitle,
    class: "import-book-modal"
  }, {
    default: _withCtx(() => [
      (_ctx.showFileUpload)
        ? (_openBlock(), _createBlock(_component_ImportFileUploader, {
            key: 0,
            "progress-percentage": _ctx.progressPercentage,
            "onUpdate:modelValue": _ctx.updateSelectedFile
          }, null, 8, ["progress-percentage", "onUpdate:modelValue"]))
        : _createCommentVNode("", true),
      _cache[2] || (_cache[2] = _createTextVNode()),
      (_ctx.context.modalDescription)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.context.modalDescription), 1))
        : _createCommentVNode("", true),
      _cache[3] || (_cache[3] = _createTextVNode()),
      _cache[4] || (_cache[4] = _createElementVNode("label", {
        class: "input-label",
        for: "book-title"
      }, "\n      Book title\n    ", -1)),
      _cache[5] || (_cache[5] = _createTextVNode()),
      _createVNode(_component_VInput, {
        id: "book-title",
        modelValue: _ctx.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
        type: "text",
        required: "",
        "custom-validators": [_ctx.noEmptyValidator],
        maxlength: _ctx.maxTitleLength,
        placeholder: "Enter a book title..."
      }, null, 8, ["modelValue", "custom-validators", "maxlength"]),
      _cache[6] || (_cache[6] = _createTextVNode()),
      _cache[7] || (_cache[7] = _createElementVNode("label", {
        class: "input-label subtitle-label optional",
        for: "book-subtitle"
      }, "\n      Subtitle\n    ", -1)),
      _cache[8] || (_cache[8] = _createTextVNode()),
      _createVNode(_component_VInput, {
        id: "book-subtitle",
        modelValue: _ctx.subtitle,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.subtitle) = $event)),
        type: "text",
        maxlength: _ctx.maxSubtitleLength,
        placeholder: "Enter a book subtitle..."
      }, null, 8, ["modelValue", "maxlength"])
    ]),
    _: 1
  }, 8, ["id", "confirm", "title"]))
}