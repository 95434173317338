import {keyIsPressed} from '@reedsy/studio.shared/utils/keyboard/key-is-pressed';

export default function handleKeyboardEvent(event: KeyboardEvent, ...configs: IKeyHandler[]): void {
  configs.forEach((config: IKeyHandler) => {
    if (!keyIsPressed(event, config)) return;
    if (config.preventDefault) event.preventDefault();
    config.handler(event);
  });
}

export interface IKeyHandler {
  handler: (event: KeyboardEvent) => void;
  key: string;
  shortKey?: boolean;
  shiftKey?: boolean;
  preventDefault?: boolean;
}
