import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {Component, Emit, Inject, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import errorMessage from '@reedsy/studio.shared/components/forms/error-message';
import ValidatableField from './validatable-field';

export interface IValidationError {
  hasError: boolean;
  message: string;
}

export const INPUT_FIELD_REF = 'validatedField';
@Component({})
export default class ValidationMixin extends ClientSharedVue {
  @Prop({type: Array, default: (): Validator[] => []})
  public customValidators: Validator[];

  @Inject({default: null})
  public readonly registerField: (field: ValidationMixin) => void;

  @Inject({default: null})
  public readonly unregisterField: (field: ValidationMixin) => void;

  public touched = false;
  public fieldExtraValidators: Validator[] = [];
  public validationError: IValidationError = {hasError: false, message: ''};
  public readonly inputFieldRef = INPUT_FIELD_REF;

  public mounted(): void {
    if (!this.$refs[this.inputFieldRef]) throw new Error('You must add an element with the inputFieldRef ref');
    if (this.registerField) this.registerField(this);
  }

  public beforeUnmount(): void {
    if (this.unregisterField) this.unregisterField(this);
  }

  public reset(): void {
    this.setError(false, '');
    this.touched = false;
  }

  public validateField(): boolean {
    const field = this.$refs[this.inputFieldRef] as ValidatableField;
    if (!field) return;
    for (const validator of [...this.customValidators, ...this.fieldExtraValidators]) {
      const error = validator(field.value);
      if (error) {
        this.setError(true, error);
        return false;
      }
    }
    field.checkValidity();
    const hasError = !field.validity.valid;
    const error = errorMessage(field);
    this.setError(hasError, error);
    return !hasError;
  }

  @Emit('validation-error')
  private setError(hasError: boolean, message: string): IValidationError {
    const error = {
      hasError: this.touched && hasError,
      message: this.touched ? message : '',
    };

    this.validationError = error;
    return error;
  }
}

export type Validator = (value: string) => string;
