<template>
  <VForm
    ref="form"
    @submit="submitFeedback"
  >
    <PlainModal
      :id="id"
      headless
      class="wide feedback-survey-modal"
    >
      <rbe-feedback-survey class="flex-top">
        <rbe-header class="flex-top">
          <div class="flex-justified">
            <h1 class="modal-title">
              {{ modalTitle }}
            </h1>
          </div>
          <p>
            Please take a few moments to reflect on your experience using Studio.
            Let us know which features you love and what we could be doing better.
          </p>
        </rbe-header>

        <UsageReasonSelector
          ref="usageReasonSelector"
          v-model:usage-reason="selectedUsageReason"
          v-model:different-usage-reason="differentUsageReason"
        />

        <rbe-feedback-wrapper class="flex-top">
          <label>Feedback</label>
          <p>
            Please share any feedback you have about Studio. What do you love?
            What could we improve? Are there any features you’d like to see in
            the future?
          </p>
          <VTextarea
            ref="textarea"
            v-model="text"
            required
            placeholder="Write here..."
          />
        </rbe-feedback-wrapper>

        <AppRating
          ref="appRating"
          v-model:rating="selectedRating"
          v-model:low-rating-explanation="lowRatingExplanation"
        />
      </rbe-feedback-survey>

      <template #footer>
        <rbe-footer class="flex-justified">
          <LoadingButton
            ref="skipFeedbackButton"
            class="button neutral"
            type="button"
            :loading="isSendingRequest"
            @click="skipSurvey"
          >
            Skip
          </LoadingButton>
          <LoadingButton
            ref="submitFeedbackButton"
            class="button"
            type="submit"
            :loading="isSendingRequest"
            :disabled="!isSubmitEnabled || null"
          >
            Submit
          </LoadingButton>
        </rbe-footer>
      </template>
    </PlainModal>
  </VForm>
</template>

<script lang="ts">
import {
  Component,
  mixins,
  Prop,
  Watch,
  Ref,
} from '@reedsy/studio.shared/utils/vue/decorators';
import PlainModal from '@reedsy/studio.shared/components/modals/templates/plain-modal.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {UsageReason} from '@reedsy/reedsy-sharedb/lib/common/feedback/studio-usage-reason';
import {IFeedbackSurveyContext} from '@reedsy/studio.shared/components/modals/i-feedback-survey-context';
import {PropType} from 'vue';
import {NotifyError} from '@reedsy/studio.shared/utils/decorators/notify-error';
import LoadingButton from '@reedsy/studio.shared/components/loading-button.vue';
import AppRating from './app-rating.vue';
import UsageReasonSelector from './usage-reason-selector.vue';
import {IFeedbackResponse} from '@reedsy/reedsy-sharedb/lib/common/feedback/feedback-response';
import {timeout} from '@reedsy/utils.timeout';
import SASS from '@reedsy/studio.shared/utils/sass';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';

@Component({
  components: {
    PlainModal,
    LoadingButton,
    AppRating,
    UsageReasonSelector,
  },
})
export default class FeedbackSurvey extends mixins(
  ModalMixin,
  ClientSharedVue,
) {
  @$lazyInjectStore(SharedStoreName.User)
  public $user: SharedUserModule;

  @Prop({type: Object as PropType<IFeedbackSurveyContext>, default: {}})
  public context: IFeedbackSurveyContext;

  @Ref('appRating')
  public appRatingComponent: AppRating;

  public selectedUsageReason: UsageReason = null;
  public differentUsageReason = '';
  public text: string = '';
  public selectedRating: number = null;
  public lowRatingExplanation = '';
  public isSendingRequest = false;

  public get isSubmitEnabled(): boolean {
    return (
      this.isValidUsageReason &&
      this.isValidText &&
      this.isValidRating
    );
  }

  public get userFirstName(): string {
    return this.$user.info?.firstName || '';
  }

  public get modalTitle(): string {
    const middleText = this.userFirstName ?
      `${this.userFirstName}, w` :
      'W';

    return `🙌 ${middleText}e’d love your feedback!`;
  }

  public get isValidUsageReason(): boolean {
    if (!this.selectedUsageReason) return false;
    return this.hasDifferentUsageReason ? !!this.differentUsageReason : true;
  }

  public get isValidText(): boolean {
    return !!this.text;
  }

  public get isValidRating(): boolean {
    if (!this.selectedRating) return false;
    return this.isRatingLow ? !!this.lowRatingExplanation : true;
  }

  public get hasDifferentUsageReason(): boolean {
    return this.selectedUsageReason === UsageReason.SomethingElse;
  }

  public get isRatingLow(): boolean {
    return this.selectedRating < 8;
  }

  public get feedbackResponse(): IFeedbackResponse {
    return {
      text: this.text,
      usageReason: this.selectedUsageReason,
      differentUsageReason: this.hasDifferentUsageReason ? this.differentUsageReason : undefined,
      rating: this.selectedRating,
      lowRatingExplanation: this.isRatingLow ? this.lowRatingExplanation : undefined,
    } as IFeedbackResponse;
  }

  public appRatingElement(): HTMLDivElement {
    return this.appRatingComponent.$el;
  }

  @NotifyError('Something went wrong while submitting feedback, please contact our support team')
  public async skipSurvey(): Promise<void> {
    await this.handleRequest(() => this.context.skipSurvey());
  }

  @NotifyError('Something went wrong while submitting feedback, please contact our support team')
  public async submitFeedback(): Promise<void> {
    await this.handleRequest(() => this.context.submitFeedback(this.feedbackResponse));
  }

  @Watch('selectedRating')
  public async ratingChange(newValue: number, oldValue: number): Promise<void> {
    if (oldValue < 8) return;
    if (newValue >= 8) return;

    await this.scrollWithExpandAnimation();
  }

  private async scrollWithExpandAnimation(): Promise<void> {
    let runningTime = 0;
    const scrollStepInMs = 10;
    const appRatingElement = this.appRatingElement();

    while (runningTime <= SASS.transitionTime.base) {
      appRatingElement.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
      });
      await timeout(scrollStepInMs);
      runningTime += scrollStepInMs;
    }

    // Ensure the element is fully visible
    appRatingElement.scrollIntoView({
      behavior: 'auto',
      block: 'nearest',
    });
  }

  private async handleRequest(callback: () => Promise<void>): Promise<void> {
    this.isSendingRequest = true;
    try {
      await callback();
      await this.close();
    } finally {
      this.isSendingRequest = false;
    }
  }
}
</script>

<style lang="scss" scoped>
$feedback-modal-extra-padding: $space-sm;

.feedback-survey-modal.modal {
  overflow: hidden;
}

.modal-title {
  font-size: $font-size-lg;
}

rbe-feedback-survey {
  gap: $space-lg;
  padding: $feedback-modal-extra-padding;
}

rbe-header {
  gap: $space-sm;
}

rbe-feedback-wrapper {
  gap: $space-sm;
}

:deep(textarea) {
  min-height: 7.125rem;
}

rbe-footer {
  z-index: 1;
  box-shadow: $box-shadow-up-base;
  padding: $space-md ($space-lg + $feedback-modal-extra-padding);
}
</style>
