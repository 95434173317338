
import {
  Component,
  mixins,
  Prop,
  Watch,
  Ref,
} from '@reedsy/studio.shared/utils/vue/decorators';
import PlainModal from '@reedsy/studio.shared/components/modals/templates/plain-modal.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {UsageReason} from '@reedsy/reedsy-sharedb/lib/common/feedback/studio-usage-reason';
import {IFeedbackSurveyContext} from '@reedsy/studio.shared/components/modals/i-feedback-survey-context';
import {PropType} from 'vue';
import {NotifyError} from '@reedsy/studio.shared/utils/decorators/notify-error';
import LoadingButton from '@reedsy/studio.shared/components/loading-button.vue';
import AppRating from './app-rating.vue';
import UsageReasonSelector from './usage-reason-selector.vue';
import {IFeedbackResponse} from '@reedsy/reedsy-sharedb/lib/common/feedback/feedback-response';
import {timeout} from '@reedsy/utils.timeout';
import SASS from '@reedsy/studio.shared/utils/sass';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';

@Component({
  components: {
    PlainModal,
    LoadingButton,
    AppRating,
    UsageReasonSelector,
  },
})
export default class FeedbackSurvey extends mixins(
  ModalMixin,
  ClientSharedVue,
) {
  @$lazyInjectStore(SharedStoreName.User)
  public $user: SharedUserModule;

  @Prop({type: Object as PropType<IFeedbackSurveyContext>, default: {}})
  public context: IFeedbackSurveyContext;

  @Ref('appRating')
  public appRatingComponent: AppRating;

  public selectedUsageReason: UsageReason = null;
  public differentUsageReason = '';
  public text: string = '';
  public selectedRating: number = null;
  public lowRatingExplanation = '';
  public isSendingRequest = false;

  public get isSubmitEnabled(): boolean {
    return (
      this.isValidUsageReason &&
      this.isValidText &&
      this.isValidRating
    );
  }

  public get userFirstName(): string {
    return this.$user.info?.firstName || '';
  }

  public get modalTitle(): string {
    const middleText = this.userFirstName ?
      `${this.userFirstName}, w` :
      'W';

    return `🙌 ${middleText}e’d love your feedback!`;
  }

  public get isValidUsageReason(): boolean {
    if (!this.selectedUsageReason) return false;
    return this.hasDifferentUsageReason ? !!this.differentUsageReason : true;
  }

  public get isValidText(): boolean {
    return !!this.text;
  }

  public get isValidRating(): boolean {
    if (!this.selectedRating) return false;
    return this.isRatingLow ? !!this.lowRatingExplanation : true;
  }

  public get hasDifferentUsageReason(): boolean {
    return this.selectedUsageReason === UsageReason.SomethingElse;
  }

  public get isRatingLow(): boolean {
    return this.selectedRating < 8;
  }

  public get feedbackResponse(): IFeedbackResponse {
    return {
      text: this.text,
      usageReason: this.selectedUsageReason,
      differentUsageReason: this.hasDifferentUsageReason ? this.differentUsageReason : undefined,
      rating: this.selectedRating,
      lowRatingExplanation: this.isRatingLow ? this.lowRatingExplanation : undefined,
    } as IFeedbackResponse;
  }

  public appRatingElement(): HTMLDivElement {
    return this.appRatingComponent.$el;
  }

  @NotifyError('Something went wrong while submitting feedback, please contact our support team')
  public async skipSurvey(): Promise<void> {
    await this.handleRequest(() => this.context.skipSurvey());
  }

  @NotifyError('Something went wrong while submitting feedback, please contact our support team')
  public async submitFeedback(): Promise<void> {
    await this.handleRequest(() => this.context.submitFeedback(this.feedbackResponse));
  }

  @Watch('selectedRating')
  public async ratingChange(newValue: number, oldValue: number): Promise<void> {
    if (oldValue < 8) return;
    if (newValue >= 8) return;

    await this.scrollWithExpandAnimation();
  }

  private async scrollWithExpandAnimation(): Promise<void> {
    let runningTime = 0;
    const scrollStepInMs = 10;
    const appRatingElement = this.appRatingElement();

    while (runningTime <= SASS.transitionTime.base) {
      appRatingElement.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
      });
      await timeout(scrollStepInMs);
      runningTime += scrollStepInMs;
    }

    // Ensure the element is fully visible
    appRatingElement.scrollIntoView({
      behavior: 'auto',
      block: 'nearest',
    });
  }

  private async handleRequest(callback: () => Promise<void>): Promise<void> {
    this.isSendingRequest = true;
    try {
      await callback();
      await this.close();
    } finally {
      this.isSendingRequest = false;
    }
  }
}
