<template>
  <Panel>
    <rbe-no-books-placeholder-wrapper>
      <img
        class="no-books-image"
        :src="pageData.imageSrc"
      >
      <rbe-not-found-details>
        <h1>{{ pageData.title }}</h1>
        <p>{{ pageData.description }}</p>
      </rbe-not-found-details>
      <rbe-action-buttons v-if="!isFiltered && showOnlyArchived">
        <router-link
          ref="showAllBooksButton"
          class="button reedsy-accented show-all-books-button"
          :to="booksListLocation"
        >
          Return to bookshelf
        </router-link>
      </rbe-action-buttons>
      <rbe-action-buttons v-else-if="!isFiltered">
        <button
          class="button import-book-button reedsy-accented neutral"
          type="button"
          @click="openImportBookModal"
        >
          <span class="button-inner-text">Import book</span>
          <VuiIconCloud />
        </button>

        <button
          class="button create-book-button reedsy-accented"
          type="button"
          @click="openCreateBookModal"
        >
          <span class="button-inner-text">Create book</span>
          <VuiIconPlus />
        </button>
      </rbe-action-buttons>
    </rbe-no-books-placeholder-wrapper>
  </Panel>
</template>
<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModule} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';
import {MetaRawLocation} from '@reedsy/studio.shared/router/reedsy-router';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import Panel from '@reedsy/studio.shared/components/panel/panel.vue';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import capitalize from 'capitalize';
import bookshelfNoResults from '@/assets/inlined/bookshelf-no-results.svg?inline';
import bookshelfNoBooks from '@/assets/inlined/bookshelf-no-books.jpg';
import bookshelfNoArchivedBooks from '@/assets/inlined/bookshelf-no-archived-books.svg?inline';

interface IPageData {
  title: string;
  description: string;
  imageSrc: string;
}

@Component({
  components: {
    Panel,
  },
})
export default class NoBooksPlaceholderWrapper extends BookshelfVue {
  @$lazyInjectStore(StoreName.Modals)
  public $modals: BookshelfModalsModule;

  @$lazyInjectStore(StoreName.Bookshelf)
  public $bookshelf: BookshelfModule;

  @$lazyInjectStore(SharedStoreName.User)
  public $user: SharedUserModule;

  public booksListLocation: MetaRawLocation = {
    name: BookshelfRouteName.BooksList,
  };

  public get isFiltered(): boolean {
    return this.$bookshelf.isFiltered;
  }

  public get showOnlyArchived(): boolean {
    return this.$bookshelf.showOnlyArchived;
  }

  public get userFirstName(): string {
    return this.$user.info.firstName;
  }

  public get pageData(): IPageData {
    if (this.isFiltered) {
      return {
        title: 'No results',
        description: `We couldn't find any books with title “${this.$bookshelf.searchValue}”`,
        imageSrc: bookshelfNoResults,
      };
    }

    if (this.showOnlyArchived) {
      return {
        title: 'Your archive is empty',
        description: 'Tidy up your bookshelf by archiving the books you no longer need.',
        imageSrc: bookshelfNoArchivedBooks,
      };
    }

    return {
      title: `Welcome ${capitalize(this.userFirstName)} 👋`,
      description: 'Your story starts here. Create or import a book to begin writing!',
      imageSrc: bookshelfNoBooks,
    };
  }

  public async openCreateBookModal(): Promise<void> {
    await this.$modals.open('CreateBook');
  }

  public async openImportBookModal(): Promise<void> {
    await this.$modals.open({
      type: 'ImportBook',
      context: {},
    });
  }
}
</script>

<style lang="scss" scoped>
rbe-no-books-placeholder-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  padding: 5rem $space-md;

  > img {
    border-radius: 100%;
    border: 0.7rem solid var(--reedsy-neutral-muted);
    width: min(50vw, 18rem);
    height: auto;
    margin-bottom: $space-xl;
  }

  rbe-not-found-details {
    width: 100%;

    p {
      margin-top: $space-md;
    }
  }

  rbe-action-buttons {
    margin-top: $space-xl;

    .import-book-button {
      margin-right: $space-md;
    }

    .button-inner-text {
      margin-right: $space-sm;
    }
  }
}
</style>
