
import {Component, Emit, Prop, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {VuiFileUpload} from '@reedsy/studio.shared/mixins/vui/vui-file-upload';

@Component({})
export default class ImageUploader extends mixins(BookshelfVue, VuiFileUpload) {
  @Prop({type: String, required: true})
  public url: string;

  public get hasImage(): boolean {
    return !!this.url;
  }

  @Emit('remove-file')
  public removeFile(): void {
    return;
  }
}
