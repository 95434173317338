import {INVALID_EMAIL_MESSAGE} from '@reedsy/studio.shared/components/forms/error-message';
import {ValidatableInput} from './validatable-input';
import {isValidEmail} from '@reedsy/utils.io-ts';

class EmailInput extends ValidatableInput {
  public override readonly invalidMessage = INVALID_EMAIL_MESSAGE;

  public constructor() {
    super();
    this.type = 'email';
  }

  protected override isValid(): boolean {
    return isValidEmail(this.value);
  }
}

customElements.define('email-input', EmailInput, {extends: 'input'});
