<template>
  <input
    :ref="inputFieldRef"
    v-model="value"
    :class="{touched}"
    :spellcheck="false"
    @compositionupdate="updateValueFromComposition"
    @blur="onBlur"
  >
</template>

<script lang="ts">
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import {ValidatableInput} from '@reedsy/studio.shared/components/forms/mixins/validatable-input';

@Component({})
export default class BaseInput extends mixins(ValidatableInput) {}
</script>

<style lang="scss" scoped>
input {
  width: 100%;

  &[type='number'] {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      /* stylelint-disable-next-line property-no-vendor-prefix */
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
</style>
