// eslint-disable-next-line no-restricted-imports
import {Emit as _Emit} from 'vue-facing-decorator';

type NotDOMEvent<T> = T extends keyof GlobalEventHandlersEventMap ? never : T;

export const Emit = <T extends string>(eventName: T & NotDOMEvent<T>): ReturnType<typeof _Emit> => {
  return _Emit(eventName);
};

// eslint-disable-next-line no-restricted-imports
export * from 'vue-facing-decorator';
