<template>
  <ConfirmModal
    :id="id"
    title="Oops, something went wrong!"
    class="accent-secondary"
  >
    <div class="desynchronisation-modal">
      <p>
        Your last change couldn’t be saved. Please reach out to our support team and let
        us know what you were trying to insert or change.
      </p>
      <p>
        Don’t worry, your book is fine — the only thing that will be lost is the last
        thing you did, like inserting, deleting, or formatting a selection.
      </p>
      <p>
        You can reload to fix this issue.
      </p>

      <div class="buttons">
        <button
          ref="reload"
          class="button reedsy-accented neutral"
          @click="reload"
        >
          Reload
        </button>
        <!-- TODO: Support button -->
      </div>
    </div>
  </ConfirmModal>
</template>

<script lang="ts">
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import ConfirmModal from '@reedsy/studio.shared/components/modals/templates/confirm-modal.vue';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import {$lazyInject} from '@reedsy/studio.shared/inversify.config';

@Component({
  components: {
    ConfirmModal,
  },
})
export default class DesynchronisationModal extends mixins(ModalMixin, ClientSharedVue) {
  @$lazyInject('Navigation')
  public readonly navigation: INavigation;

  public readonly cancelable = false;

  public reload(): void {
    this.navigation.reloadPage();
  }
}
</script>

<style lang="scss" scoped>
.desynchronisation-modal {
  p {
    margin-bottom: $space-sm;
  }

  .buttons {
    margin-top: $space-lg;
    text-align: right;

    button {
      margin-left: $space-sm;
    }
  }
}
</style>
