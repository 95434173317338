import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoPanel = _resolveComponent("InfoPanel")!

  return (_openBlock(), _createBlock(_component_InfoPanel, {
    title: "Image resolution",
    class: "image-resolution-info-panel"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createTextVNode("\n    For the best results, your book cover image should have a dimension ratio of 1:1.6,\n    and measure at least 2500px on the longest side.\n  ")
    ])),
    _: 1
  }))
}