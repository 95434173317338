import {VuexModule, Mutation, Action} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {injectable} from 'inversify';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {$inject} from '@reedsy/studio.shared/types';
import {Store} from 'vuex';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import {reactiveDate} from '@reedsy/studio.shared/utils/vue/reactive-date';
import {ISubscriptionTrialEndDateResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-subscription-trial-end-date';
import {HTTPStatus} from '@reedsy/utils.http';
import {ISubscriptionIntentClientSecretResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-subscription-intent-client-secret-response';
import {IPriceCurrencyOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-currency-options-response';
import {IPaidFeature, IPaidFeatures} from '@reedsy/utils.subscription';
import {objectKeys, objectValues} from '@reedsy/utils.object';
import {SupportedCurrency} from '@reedsy/schemas.editor-collections';
import {ICreateTrialSubscriptionPayload} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/create-trial-subscription-payload';
import {ICalculatePriceRequest} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/calculate-price-request';
import {formatSubscriptionPrice} from '@reedsy/studio.shared/utils/currency/format-subscription-price';
import {ICurrentSubscription} from './current-subscription.interface';
import {ICalculateSubscriptionPrice} from './calculate-subscription-price.interface';
import {memoize} from '@reedsy/utils.decorator';
import shortDateWithYear from '@reedsy/studio.shared/filters/short-date-with-year';

@injectable()
export class SharedSubscriptionModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,

    @$inject('Api')
    api: IApi,
  ) {
    @Module({name: SharedStoreName.Subscription, store})
    class Subscription extends VuexModule {
      public paidFeatures: IPaidFeatures = null;
      public trialEnd: Date = null;

      public get hasAnyPaidFeature(): boolean {
        return !!objectValues(this.paidFeatures || {}).find(Boolean);
      }

      public get isTrial(): boolean {
        const now = reactiveDate().value;
        return this.trialEnd > now;
      }

      public get hasEverHadSubscription(): boolean {
        return !!this.trialEnd;
      }

      public get hasFeature() {
        return (feature: IPaidFeature) => !!this.paidFeatures?.[feature];
      }

      @Mutation
      public PAID_FEATURES(paidFeatures: IPaidFeatures): void {
        this.paidFeatures = paidFeatures || {};
      }

      @Action
      public async fetchTrialEnd(): Promise<void> {
        if (this.trialEnd) return;
        const {trialEndDate} = await api.get<ISubscriptionTrialEndDateResponse>('/subscription/trial/end-date');
        this.TRIAL_END(trialEndDate);
      }

      @Action
      public async startTrial(currency: SupportedCurrency): Promise<void> {
        if (this.trialEnd) return;
        const payload: ICreateTrialSubscriptionPayload = {
          currency,
        };
        await api.post('subscription/trial', payload);
      }

      @Action
      public async fetchIntentClientSecret(): Promise<string> {
        await this.ensureSubscription();
        const {clientSecret} = await this.getIntentClientSecret();
        return clientSecret;
      }

      @Action
      public async fetchCurrentSubscriptionInfo(): Promise<ICurrentSubscription> {
        const subscriptionInfo = await api.fetchCurrentSubscriptionInfo();
        if (!subscriptionInfo.hasActiveSubscription) return null;

        const {currency, nextBilling, interval} = subscriptionInfo.info;
        const products = new Set(objectKeys(subscriptionInfo.info.activeProducts));
        const nextBillingPrice = formatSubscriptionPrice(currency, nextBilling.amount, interval);
        const nextBillingDate = shortDateWithYear(new Date(subscriptionInfo.info.nextBilling.date), 'numeric');

        return {
          currency,
          interval,
          products,
          nextBillingPrice,
          nextBillingDate,
        };
      }

      @Action
      @memoize
      public async calculateSubscriptionPrice(request: ICalculatePriceRequest): Promise<ICalculateSubscriptionPrice> {
        const price = await api.calculatePrice(request);
        const {currency, totalBeforeDiscounts, totalDiscountsAmount, total, interval} = price;
        return {
          subtotal: formatSubscriptionPrice(currency, totalBeforeDiscounts, interval),
          discount: formatSubscriptionPrice(currency, totalDiscountsAmount, interval),
          total: formatSubscriptionPrice(currency, total, interval),
        };
      }

      @Action
      public async fetchPrice(): Promise<IPriceCurrencyOptionsResponse> {
        return api.get('/subscription/price/currency-options');
      }

      @Mutation
      private TRIAL_END(date: Date): void {
        this.trialEnd = date;
      }

      @Action
      private async getIntentClientSecret(): Promise<ISubscriptionIntentClientSecretResponse> {
        return api.get('/subscription/intent/client-secret');
      }

      @Action
      private async ensureSubscription(): Promise<void> {
        try {
          await api.post('/subscription');
        } catch (error) {
          // If a subscription already exists, that's fine: we're just trying to ensure
          // one exists before getting the client intent secret, which needs a subscription
          if (error.response?.status === HTTPStatus.Conflict) return;
          throw error;
        }
      }
    }

    this.Module = Subscription;
  }
}

export type SharedSubscriptionModule = InstanceType<SharedSubscriptionModuleFactory['Module']>;
