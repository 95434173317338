
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import MainLayout from '@reedsy/studio.home.bookshelf/components/layouts/main-layout.vue';
import BooksPageFilter from '@reedsy/studio.home.bookshelf/components/books-page-filter/books-page-filter.vue';
import WelcomeBar from '@reedsy/studio.home.bookshelf/components/welcome-bar/welcome-bar.vue';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfLayoutModule} from '@reedsy/studio.home.bookshelf/store/modules/layout';

@Component({
  components: {
    MainLayout,
    BooksPageFilter,
    WelcomeBar,
  },
})
export default class Bookshelf extends BookshelfVue {
  @$lazyInjectStore(StoreName.Layout)
  public $layout: BookshelfLayoutModule;

  public get isBooksListMobileLayout(): boolean {
    return (
      this.$layout.isMobile &&
      this.$route
        .matched
        .map((route) => route.name)
        .includes(BookshelfRouteName.Bookshelf)
    );
  }
}
