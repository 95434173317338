import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex-left flex-gap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_VuiLoadingIndicator = _resolveComponent("VuiLoadingIndicator")!
  const _component_ContextMenuButton = _resolveComponent("ContextMenuButton")!
  const _component_ContextMenu = _resolveComponent("ContextMenu")!

  return (_openBlock(), _createElementBlock("rbe-invitation", {
    class: _normalizeClass({
      failed: _ctx.hasEmailFailed,
      expired: _ctx.isExpired,
    })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Avatar, {
        class: "hidden-until-sm",
        placeholder: ""
      }),
      _cache[2] || (_cache[2] = _createTextVNode()),
      _createElementVNode("p", {
        ref: "inviteeEmail",
        class: "ellipsis"
      }, _toDisplayString(_ctx.invitation.inviteeEmail), 513)
    ]),
    _cache[5] || (_cache[5] = _createTextVNode()),
    (_ctx.isEmailPending)
      ? (_openBlock(), _createBlock(_component_VuiLoadingIndicator, {
          key: 0,
          ref: "pendingStatusIndicator"
        }, null, 512))
      : (_openBlock(), _createElementBlock("p", {
          key: 1,
          ref: "invitationStatus"
        }, _toDisplayString(_ctx.statusText), 513)),
    _cache[6] || (_cache[6] = _createTextVNode()),
    _createVNode(_component_ContextMenu, null, {
      trigger: _withCtx(() => [
        _createVNode(_component_ContextMenuButton, {
          disabled: _ctx.isEmailPending || null
        }, null, 8, ["disabled"])
      ]),
      default: _withCtx(() => [
        _cache[3] || (_cache[3] = _createTextVNode()),
        _createElementVNode("button", {
          ref: "revokeButton",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openConfirmRevokeModal && _ctx.openConfirmRevokeModal(...args)))
        }, "\n        Revoke\n      ", 512),
        _cache[4] || (_cache[4] = _createTextVNode()),
        _createElementVNode("button", {
          ref: "resendButton",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openConfirmResendModal && _ctx.openConfirmResendModal(...args)))
        }, "\n        Resend\n      ", 512)
      ]),
      _: 1
    })
  ], 2))
}