<template>
  <rbe-general-account-settings class="flex-top">
    <AuthAccountSettingsBanner />
    <UserNotificationsSettings />
  </rbe-general-account-settings>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import AuthAccountSettingsBanner from '@reedsy/studio.home.bookshelf/components/auth-account-settings-banner/auth-account-settings-banner.vue';
import UserNotificationsSettings from '@reedsy/studio.home.bookshelf/components/user-notifications-settings/user-notifications-settings.vue';

@Component({
  components: {
    AuthAccountSettingsBanner,
    UserNotificationsSettings,
  },
})
export default class GeneralAccountSettings extends BookshelfVue {
}
</script>

<style lang="scss" scoped>
rbe-general-account-settings {
  gap: $space-xl;
}
</style>
