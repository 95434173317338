import {Constructor} from '@reedsy/utils.types';
import {SharedBrowserModuleFactory} from './modules/browser';
import {IModuleFactory} from './modules/i-module-factory';
import {SharedModalsModuleFactory} from './modules/modals';
import {SharedModeModuleFactory} from './modules/mode';
import {SharedUserColorModuleFactory} from './modules/user-color';
import {SharedStoreName} from './store-name';
import {SharedSavesModuleFactory} from './modules/saves';
import {SharedUserModuleFactory} from './modules/user';
import {SharedSubscriptionModuleFactory} from './modules/subscription';

export const SHARED_STORE_FACTORY_MAPPING = {
  [SharedStoreName.Browser]: SharedBrowserModuleFactory,
  [SharedStoreName.Modals]: SharedModalsModuleFactory,
  [SharedStoreName.Mode]: SharedModeModuleFactory,
  [SharedStoreName.Subscription]: SharedSubscriptionModuleFactory,
  [SharedStoreName.User]: SharedUserModuleFactory,
  [SharedStoreName.UserColor]: SharedUserColorModuleFactory,
  [SharedStoreName.Saves]: SharedSavesModuleFactory,
} as const satisfies Record<string, Constructor<IModuleFactory>>;

type SharedStoreFactoryMapping = typeof SHARED_STORE_FACTORY_MAPPING;

export type ISharedStoreMapping = {
  [K in keyof SharedStoreFactoryMapping]: InstanceType<InstanceType<SharedStoreFactoryMapping[K]>['Module']>;
};
