<template>
  <ConfirmModal
    :id="id"
    :confirm="{
      label: action,
      handler: toggleBookHiddenStatus,
    }"
    :title="title"
  >
    <p>{{ description }}</p>
  </ConfirmModal>
</template>

<script lang="ts">
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {Component, mixins, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import ConfirmModal from '@reedsy/studio.shared/components/modals/templates/confirm-modal.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import BaseInput from '@reedsy/studio.shared/components/forms/base-input.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import Notify from '@reedsy/studio.shared/services/notify/notify';
import {BookActionsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-actions/book-actions';
import {IConfirmModalArchiveArgs} from '@reedsy/studio.home.bookshelf/components/modals/i-confirm-modal-archive-args';
import {PropType} from 'vue';
import {BookDetailsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-details/book-details';

@Component({
  components: {
    ConfirmModal,
    BaseInput,
  },
})
export default class ConfirmBookArchiveModal extends mixins(ModalMixin, BookshelfVue) {
  @Prop({type: Object as PropType<IConfirmModalArchiveArgs>, required: true})
  public context: IConfirmModalArchiveArgs;

  @$lazyInjectStore(StoreName.BookDetails)
  public $bookDetails: BookDetailsModule;

  @$lazyInjectStore(StoreName.BookActions)
  public $bookActions: BookActionsModule;

  public readonly cancelable = true;

  public get isHidden(): boolean {
    return !!this.$bookDetails.details(this.context.bookId).hiddenAt;
  }

  public get description(): string {
    if (this.isHidden) {
      return 'Your book will be restored and will be visible in the bookshelf.';
    } else {
      return 'Your book will be moved to archive and can be restored at any time.';
    }
  }

  public get title(): string {
    return `Are you sure you want to ${this.action.toLocaleLowerCase()} this book`;
  }

  public get action(): 'Restore' | 'Archive' {
    return this.isHidden ? 'Restore' : 'Archive';
  }

  public async toggleBookHiddenStatus(): Promise<void> {
    const bookId = this.context.bookId;

    if (this.isHidden) {
      await this.$bookActions.unarchiveBook(bookId);
      Notify.success({message: 'Book has been restored.'});
    } else {
      await this.$bookActions.archiveBook(bookId);
      await this.$router.push({
        name: BookshelfRouteName.ArchivedBooks,
      });
      Notify.success({message: 'Book has been archived.'});
    }
  }
}
</script>
