
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';
import Avatar from '@reedsy/studio.shared/components/avatar/avatar.vue';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfCollaboratorsModule} from '@reedsy/studio.home.bookshelf/store/modules/collaborators';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';

interface IOption {
  id: string;
  text: string;
  onClick?: () => Promise<void>;
  url?: string;
}

@Component({
  components: {
    Avatar,
  },
})
export default class UserSettingsMenu extends BookshelfVue {
  @$lazyInjectStore(SharedStoreName.User)
  public $user: SharedUserModule;

  @$lazyInjectStore(StoreName.Collaborators)
  public $collaborators: BookshelfCollaboratorsModule;

  @$lazyInjectStore(StoreName.Modals)
  public $modals: BookshelfModalsModule;

  @$lazyInject('Navigation')
  public readonly _navigation: INavigation;

  public readonly options: IOption[] = [
    {id: 'account-settings', text: 'Account settings', url: this.accountSettingsUrl},
    {id: 'help', text: 'Help', onClick: this.openHelpModal},
    {id: 'log-out', text: 'Log out', onClick: this.logOut},
  ];

  public get accountSettingsUrl(): string {
    return this._navigation.editorAccountSettingsUrl;
  }

  public get user(): IUserInfo {
    return this.$user.info;
  }

  public async logOut(): Promise<void> {
    await this.$collaborators.logout();
  }

  public async openHelpModal(): Promise<void> {
    this.$modals.open('HelpModal');
  }
}
