export enum SharedStoreName {
  Analytics = 'shared_analytics',
  Browser = 'shared_browser',
  Saves = 'shared_saves',
  Subscription = 'shared_subscription',
  Modals = 'shared_modals',
  Mode = 'shared_mode',
  User = 'shared_user',
  UserColor = 'shared_userColor',
  Feedback = 'shared_feedback',
}
