import {injectable, named} from 'inversify';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import RouterPlugin from '@reedsy/studio.home.bookshelf/store/plugins/router-plugin';
import {IBookshelfRoute} from '@reedsy/studio.home.bookshelf/router/i-bookshelf-route';
import {BookshelfModule} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf';

@injectable()
export default class SetShowArchivedFlagPluginFactory extends RouterPlugin {
  @$inject('StoreModule')
  @named(StoreName.Bookshelf)
  public readonly _bookshelf: BookshelfModule;

  public override async beforeEach(to: IBookshelfRoute): Promise<void> {
    if (to.name === BookshelfRouteName.ArchivedBooks) {
      this._bookshelf.SHOW_ONLY_ARCHIVED(true);
    } else {
      this._bookshelf.SHOW_ONLY_ARCHIVED(false);
    }
  }
}
