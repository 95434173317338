import {injectable} from 'inversify';
import {OfflineModalBasePluginFactory} from '@reedsy/studio.shared/plugins/offline-modal';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {IBookshelfShareDbConnection} from '@reedsy/studio.home.bookshelf/services/bookshelf-sharedb-connection.interface';

@injectable()
export class OfflineModalPluginFactory extends OfflineModalBasePluginFactory<IBookshelfShareDbConnection> {
  @$inject('BookshelfShareDbConnection')
  public readonly _shareDbConnection: IBookshelfShareDbConnection;
}
