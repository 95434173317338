<template>
  <BooksGridWrapper
    :is-empty="!loading && !bookshelfEntries.length"
  >
    <template v-if="loading">
      <template
        v-for="i in 4 * numberOfColumns"
        :key="i"
      >
        <BookSkeletonLoader class="grid-item" />
        <GridLine v-if="wantsGridLine(i)" />
      </template>
    </template>
    <template v-else>
      <template
        v-for="(entry, i) in bookshelfEntries"
        :key="entry.id"
      >
        <GridLine v-if="wantsGridLine(i)" />
        <component
          :is="entryComponent(entry)"
          :entry="entry"
          class="grid-item"
        />
      </template>
    </template>

    <GridLine
      ref="lastLine"
      :with-button="!loading && hasMoreEntriesToShow"
      class="last-line"
    />
  </BooksGridWrapper>
</template>

<script lang="ts">
import {Component, Ref} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import BookEntry from './book-entry.vue';
import BookImportEntry from './book-import-entry.vue';
import GridLine from './grid-line.vue';
import BookSkeletonLoader from './book-skeleton-loader.vue';
import {BookshelfEntryType, IBookshelfEntry} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-entry';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {SharedBrowserModule} from '@reedsy/studio.shared/store/modules/browser';
import {BookshelfStoreListener} from '@reedsy/studio.home.bookshelf/store/bookshelf-store-listener';
import BooksGridWrapper from './books-grid-wrapper.vue';
import {timeout} from '@reedsy/utils.timeout';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModule} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf';
import {BookshelfLayoutModule} from '@reedsy/studio.home.bookshelf/store/modules/layout';

const BOOKSHELF_ENTRY_COMPONENT_MAPPING = Object.freeze({
  [BookshelfEntryType.Book]: 'BookEntry',
  [BookshelfEntryType.BookImport]: 'BookImportEntry',
} as const satisfies Record<BookshelfEntryType, string>);

@Component({
  components: {
    BookEntry,
    BookImportEntry,
    GridLine,
    BookSkeletonLoader,
    BooksGridWrapper,
  },
})
export default class BooksGrid extends BookshelfVue {
  @$lazyInjectStore(StoreName.Layout)
  public $layout: BookshelfLayoutModule;

  @$lazyInjectStore(StoreName.Bookshelf)
  public $bookshelf: BookshelfModule;

  @$lazyInjectStore(SharedStoreName.Browser)
  public $browser: SharedBrowserModule;

  @$lazyInject('StoreListener')
  public $storeListener: BookshelfStoreListener;

  @Ref('booksGridWrapper')
  public booksGrid: HTMLElement;

  public numberOfColumns: number = null;
  private observer: ResizeObserver;

  public get bookshelfEntries(): IBookshelfEntry[] {
    return this.$bookshelf.displayedBookshelfEntries;
  }

  public get hasMoreEntriesToShow(): boolean {
    return this.$bookshelf.hasMoreItemsToShow;
  }

  public get loading(): boolean {
    return !!this.$layout.isLoadingBooksList;
  }

  public entryComponent(entry: IBookshelfEntry): string {
    return BOOKSHELF_ENTRY_COMPONENT_MAPPING[entry.type];
  }

  public async mounted(): Promise<void> {
    this.updateNumberOfColumns();
    this.observer = new ResizeObserver(this.updateNumberOfColumns);
    this.observer.observe(this.$el);
  }

  public unmounted(): void {
    this.observer.disconnect();
  }

  public async updateNumberOfColumns(): Promise<void> {
    await timeout();
    const el = this.$el as HTMLElement;
    const columns = window.getComputedStyle(el.firstElementChild).gridTemplateColumns;
    this.numberOfColumns = columns.split(/\s+/g).length;
  }

  public wantsGridLine(index: number): boolean {
    return index % this.numberOfColumns === this.numberOfColumns - 1;
  }
}
</script>

<style lang="scss">
rbe-books-grid-wrapper {
  rbe-book-skeleton-loader.grid-item {
    height: auto;
    width: 100%;
    aspect-ratio: $cover-aspect-ratio;
  }
}
</style>
