<template>
  <div
    class="file-upload"
    :class="{invalid: error.hasError}"
  >
    <VuiFileUpload
      ref="vuiFileUpload"
      v-bind="$attrs"
      class="vui-file-upload"
      @error="handleVuiFileError"
      @file-updated="fileUpdated"
    />
    <BaseFileInput
      :model-value="file"
      :required="required || null"
      @validation-error="error = $event"
    />
    <ErrorMessage
      v-if="error.hasError"
      :error="error.message"
    />
  </div>
</template>
<script lang="ts">
import {Component, Emit, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import ErrorMessage from '@reedsy/studio.shared/components/forms/error-message.vue';
import BaseFileInput from './base-file-input.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({
  inheritAttrs: false,
  components: {
    ErrorMessage,
    BaseFileInput,
  },
})
export default class ImportFileUploader extends ClientSharedVue {
  @Prop({type: Boolean, default: false})
  public required: boolean;

  public file: File = null;
  public error = {hasError: false, message: ''};

  @Emit('file-updated')
  public fileUpdated(file: File): File {
    this.file = file;
    return file;
  }

  public handleVuiFileError(): void {
    this.error = {hasError: true, message: 'Selected file is invalid.'};
  }
}
</script>

<style lang="scss" scoped>
.file-upload {
  &.invalid .vui-file-upload {
    border-color: var(--reedsy-danger);
    border-style: solid;
  }

  .error-message {
    color: var(--reedsy-danger);
  }
}

</style>
