import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("fieldset", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
      return (_openBlock(), _createElementBlock("button", {
        key: tab.value,
        type: "button",
        class: "reedsy-accented tab-wrapper",
        onClick: ($event: any) => (_ctx.selected = tab.value)
      }, [
        _createElementVNode("span", {
          ref_for: true,
          ref: `tab-${tab.value}`,
          class: _normalizeClass(["tab", {
          active: tab.value === _ctx.selected,
          [`tab-${tab.value}`]: true
        }])
        }, [
          _renderSlot(_ctx.$slots, `tab-${tab.value}`, {
            tab: tab,
            active: tab.value === _ctx.selected
          }, () => [
            _createElementVNode("span", null, _toDisplayString(tab.text), 1)
          ], true)
        ], 2)
      ], 8, _hoisted_2))
    }), 128)),
    _cache[0] || (_cache[0] = _createTextVNode()),
    _createElementVNode("rbe-active-tab-underline", {
      style: _normalizeStyle(_ctx.underlineStyles)
    }, null, 4)
  ]))
}