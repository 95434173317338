
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Panel from '@reedsy/studio.shared/components/panel/panel.vue';
import Logo from '@reedsy/studio.shared/components/logo/logo.vue';
import Avatar from '@reedsy/studio.shared/components/avatar/avatar.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {InvitationApprovalFlowModule} from '@reedsy/studio.home.bookshelf/store/modules/invitation-approval-flow';

@Component({
  components: {
    Panel,
    Logo,
    Avatar,
  },
})
export default class AcceptInvitationPanel extends BookshelfVue {
  @$lazyInjectStore(SharedStoreName.User)
  public $user: SharedUserModule;

  @$lazyInjectStore(StoreName.InvitationApprovalFlow)
  public $invitationApprovalFlow: InvitationApprovalFlowModule;

  public get isLoading(): boolean {
    return this.$invitationApprovalFlow.isLoading;
  }

  public get currentUserEmail(): string {
    return this.$user.info.email;
  }

  public get bookTitle(): string {
    return this.$invitationApprovalFlow.invitationData.book.title;
  }

  public get bookOwnerName(): string {
    return this.$invitationApprovalFlow.invitationData.bookOwner.name;
  }
}
