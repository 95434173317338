import {IMetaRoute, ReedsyRouter} from '@reedsy/studio.shared/router/reedsy-router';
import {injectable} from 'inversify';
import {IStorePluginFactory} from '@reedsy/studio.shared/store/plugins/i-store-plugin-factory';
import {$inject} from '@reedsy/studio.shared/types';
import {NavigationGuardReturn} from 'vue-router';

@injectable()
export class RouterPlugin<T extends IMetaRoute = IMetaRoute> implements IStorePluginFactory {
  @$inject('Router')
  public readonly _router: ReedsyRouter<T>;

  public plugin(): void {
    this.setup?.();

    this._router.beforeEach(async (to: T, from: T) => {
      if (!this.shouldApplyBefore(to, from)) return;
      return this.beforeEach?.(to, from);
    });

    this._router.afterEach((to: T, from: T) => {
      if (!this.shouldApplyAfter(to, from)) return;
      this.afterEach?.(to, from);
    });
  }

  protected shouldApplyBefore(_to: T, _from: T): boolean {
    return true;
  }

  protected shouldApplyAfter(_to: T, _from: T): boolean {
    return true;
  }

  protected setup?(): void;
  protected beforeEach?(_to: T, _from: T): NavigationGuardReturn | Promise<NavigationGuardReturn>;
  protected afterEach?(_to: T, _from: T): void;
}
