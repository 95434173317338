import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "book-progress-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiProgressBar = _resolveComponent("VuiProgressBar")!
  const _component_SkeletonLoader = _resolveComponent("SkeletonLoader")!

  return (_openBlock(), _createElementBlock("rbe-book-progress", null, [
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.hasWordCountLoaded)
          ? (_openBlock(), _createElementBlock("rbe-book-progress-content", _hoisted_1, [
              (_ctx.bookWordGoal)
                ? (_openBlock(), _createBlock(_component_VuiProgressBar, {
                    key: 0,
                    class: "bg-grey book-progress-bar sm",
                    percentage: _ctx.progressPercentage
                  }, null, 8, ["percentage"]))
                : _createCommentVNode("", true),
              _cache[0] || (_cache[0] = _createTextVNode()),
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.wordCountText), 1)
            ]))
          : (_openBlock(), _createBlock(_component_SkeletonLoader, {
              key: 1,
              class: "skeleton-loader-word-count"
            }))
      ]),
      _: 1
    })
  ]))
}