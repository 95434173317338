
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {Component, Emit, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {approximatelyEqual} from '@reedsy/studio.isomorphic/utils/approximately-equal';

@Component({
  components: {
    SelectedFileInfo,
  },
})
export default class SelectedFileInfo extends BookshelfVue {
  @Prop({type: File})
  public importFile: File;

  @Prop({type: Number})
  public progressPercentage: number;

  public get isUploading(): boolean {
    return typeof this.progressPercentage === 'number';
  }

  public get isUploadingFinished(): boolean {
    return approximatelyEqual(this.progressPercentage, 100);
  }

  @Emit('clear-file')
  public clearFileSelection(): File {
    return null;
  }
}
