export const IGNORED_ERRORS: readonly RegExp[] = Object.freeze([
  // https://stackoverflow.com/a/50387233/4003671
  /ResizeObserver loop limit exceeded/,
  // https://www.w3.org/TR/resize-observer/#html-event-loop
  /ResizeObserver loop completed with undelivered notifications/,
  // Exceptions from remote scripts (eg extensions), which we don't care about
  /Script error/,
  // As per this issue https://github.com/reedsy/reedsy-editor/issues/8125
  // There is lot of errors coming from browsers extensions like honey,
  // and there is no way for us decide which one of them is a legit error on our side
  // and which one is coming from for example honey browser extension
  /unhandledrejection/,
]);
