
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import {ResourcesRole} from '@reedsy/utils.reedsy-resources';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookDetailsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-details/book-details';
import {BookshelfCollaboratorsModule} from '@reedsy/studio.home.bookshelf/store/modules/collaborators';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';
import {IBookDetails} from '@reedsy/reedsy-sharedb/lib/common/book/book-details';
import Avatar from '@reedsy/studio.shared/components/avatar/avatar.vue';

@Component({
  components: {
    Avatar,
  },
})
export default class ShareIndicator extends BookshelfVue {
  @Prop({type: String, required: true})
  public bookId: string;

  @Prop({type: String, default: ''})
  public size: string;

  @$lazyInjectStore(SharedStoreName.User)
  public readonly $user: SharedUserModule;

  @$lazyInjectStore(StoreName.Collaborators)
  public readonly $collaborators: BookshelfCollaboratorsModule;

  @$lazyInjectStore(StoreName.BookDetails)
  public readonly $bookDetails: BookDetailsModule;

  public get isShared(): boolean {
    return this.$bookDetails.isShared(this.bookId);
  }

  public get isCurrentUserOwner(): boolean {
    return this.currentUserRole === ResourcesRole.Owner;
  }

  public get currentUserRole(): ResourcesRole {
    return this.$bookDetails.userBookRole({bookId: this.bookId, userUuid: this.$user.uuid});
  }

  public get bookOwner(): IUserInfo {
    return this.$collaborators.userById[this.book.ownerId];
  }

  public get book(): IBookDetails {
    return this.$bookDetails.details(this.bookId);
  }
}
