<template>
  <rbe-settings-panel-title>
    <h1><slot /></h1>
  </rbe-settings-panel-title>
</template>

<style lang="scss" scoped>
rbe-settings-panel-title {
  padding-bottom: $space-sm;

  h1 {
    font-size: $font-size-base;
  }
}
</style>
