import {enumValues} from '@reedsy/utils.enum';
import {SocialProvider} from './social-provider';
import {deepFreeze} from '@reedsy/utils.object';
import {has} from '@reedsy/utils.set';

const PROVIDERS = deepFreeze(new Set(enumValues(SocialProvider)));

export function normalizeSocialProvider(provider: unknown): SocialProvider {
  if (typeof provider !== 'string') {
    throw new Error(`${provider} is not valid option for SocialProvider`);
  };

  const normalizedSocialProvider = provider.toLocaleLowerCase();

  if (!has(PROVIDERS, normalizedSocialProvider)) {
    throw new Error(`${provider} is not valid option for SocialProvider`);
  }
  return normalizedSocialProvider;
}
