import {config} from '@reedsy/studio.shared/config';
import {RouteRecordRaw, createWebHashHistory, createWebHistory} from 'vue-router';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import {createReedsyRouter} from '@reedsy/studio.shared/router/reedsy-router';
import {SignInRouteName} from '@reedsy/studio.shared/router/route-names/sign-in-route-name';
import {PERMISSIONS_REQUIREMENTS} from '@reedsy/studio.isomorphic/utils/permissions-requirements';

// If we import these components directly, then we trigger a large dependency cascade,
// which delays the availability of the router to the Vuex store plugins (which get
// initialised virtually immediately in the Inversify DI). Instead, we use the lazy
// loading approach, which short-circuits this dependency cascade.
const components = {
  BookshelfApp: () => import('../BookshelfApp.vue'),
  BaseView: () => import('../views/BaseView.vue'),
  Bookshelf: () => import('../views/Bookshelf.vue'),
  BooksList: () => import('../views/BooksList.vue'),

  AccountSettings: () => import('../views/account-settings/AccountSettings.vue'),
  GeneralAccountSettings: () => import('../views/account-settings/GeneralAccountSettings.vue'),
  SubscriptionAccountSettings: () => import('../views/account-settings/SubscriptionAccountSettings.vue'),

  Book: () => import('../views/book/Book.vue'),
  BookAccess: () => import('../views/book/BookAccess.vue'),
  BookExports: () => import('../views/book/exports/BookExports.vue'),
  BookExportsList: () => import('../views/book/exports/BookExportsList.vue'),
  BookExportSubmission: () => import('../views/book/exports/BookExportSubmission.vue'),
  BookSettings: () => import('../views/book/BookSettings.vue'),

  InvitationAcceptance: () => import('../views/InvitationAcceptanceView.vue'),

  NotFound: () => import('../views/NotFound.vue'),

  // Other
  ExportPageActions: () => import('../components/exports-page-actions/exports-page-actions.vue'),
  ExportPageDescription: () => import('../components/page-descriptions/export-page-description.vue'),
  BookSettingsPageDescription: () => import('../components/page-descriptions/book-settings-page-description.vue'),
  Empty: () => import('../views/Empty.vue'),
};

const component = (name: keyof typeof components): any => {
  if (config.env.test) return () => import('../../../../shared/test/fixtures/test-component.vue');
  return components[name];
};

const accountSettingsRoute: RouteRecordRaw = {
  path: 'settings/account',
  name: BookshelfRouteName.AccountSettings,
  component: component('AccountSettings'),
  redirect: {name: BookshelfRouteName.GeneralAccountSettings},
  children: [
    {
      path: 'general',
      name: BookshelfRouteName.GeneralAccountSettings,
      component: component('GeneralAccountSettings'),
    },
  ],
};

const bookRoute: RouteRecordRaw = {
  name: BookshelfRouteName.Book,
  // Book uuid may be optionally base64-encoded
  path: 'books/:bookUuid',
  component: component('Book'),

  redirect: {
    name: BookshelfRouteName.BookSettings,
  },
  children: [
    {
      path: 'exports',
      name: BookshelfRouteName.BookExports,
      components: {
        default: component('BookExports'),
        aboveContent: component('ExportPageActions'),
        routeDescription: component('ExportPageDescription'),
      },
      redirect: {
        name: BookshelfRouteName.BookExportSubmission,
      },
      children: [
        {
          name: BookshelfRouteName.BookExportsList,
          path: '',
          component: component('BookExportsList'),
        },
        {
          name: BookshelfRouteName.BookExportSubmission,
          path: 'new',
          component: component('BookExportSubmission'),
        },
      ],
    },
    {
      name: BookshelfRouteName.BookSettings,
      path: 'settings',
      alias: [
        'settings/:action(delete)',
      ],
      components: {
        default: component('BookSettings'),
        routeDescription: component('BookSettingsPageDescription'),
      },
    },
    {
      name: BookshelfRouteName.BookAccess,
      path: 'access',
      component: component('BookAccess'),
      meta: {
        minimumRole: PERMISSIONS_REQUIREMENTS.userAccessManagement,
      },
    },
  ],
};

const bookshelfRootRoute: RouteRecordRaw = {
  path: '/',
  name: BookshelfRouteName.App,
  component: component('BaseView'),
  redirect: {name: 'notFound'},
  children: [
    accountSettingsRoute,
    {
      name: BookshelfRouteName.Bookshelf,
      path: '',
      component: component('Bookshelf'),
      redirect: {
        name: BookshelfRouteName.BooksList,
      },
      children: [
        {
          name: BookshelfRouteName.BooksList,
          path: '',
          component: component('BooksList'),
          alias: [
            ':action(import)',
          ],
        },
        {
          name: BookshelfRouteName.ArchivedBooks,
          path: 'archive',
          component: component('BooksList'),
        },
      ],
    },
    bookRoute,
    {
      path: 'invitations/:invitationShortId/books/:bookId/acceptance',
      name: BookshelfRouteName.InvitationAcceptance,
      component: component('InvitationAcceptance'),
    },
  ],
};

const authRoute: RouteRecordRaw = {
  path: '/auth',
  redirect: {name: 'notFound'},
  component: component('Empty'),
  name: SignInRouteName.AuthBase,
  children: [
    {
      path: 'social-sign-in/:socialProvider',
      name: SignInRouteName.SocialSignIn,
      component: component('Empty'),
    },
    {
      path: 'sign-in',
      name: SignInRouteName.SignIn,
      component: component('Empty'),
    },
    {
      path: 'sign-up',
      name: SignInRouteName.SignUp,
      component: component('Empty'),
    },
    {
      path: 'sign-in-callback',
      name: SignInRouteName.SignInCallback,
      component: component('Empty'),
      alias: [
        'confirmation-callback',
        'magic-link-callback',
        'social-sign-in-callback',
      ],
    },
    {
      path: 'one_tap_sign_in',
      name: SignInRouteName.OneTapSignIn,
      component: component('Empty'),
    },
    {
      path: 'terms-and-conditions',
      name: SignInRouteName.TermsAndConditions,
      component: component('Empty'),
    },
    {
      path: 'thank-you',
      name: SignInRouteName.ThankYou,
      component: component('Empty'),
    },
    {
      path: 'welcome',
      name: SignInRouteName.Welcome,
      component: component('Empty'),
    },
  ],
};

export const routes: RouteRecordRaw[] = [
  bookshelfRootRoute,
  authRoute,
  {
    path: '/:pathMatch(.*)*',
    name: BookshelfRouteName.NotFound,
    component: component('NotFound'),
    meta: {
      skipPageInitialisation: true,
    },
  },
];

if (config.features.subscriptions) {
  accountSettingsRoute.children.push({
    path: 'subscription',
    name: BookshelfRouteName.SubscriptionAccountSettings,
    component: component('SubscriptionAccountSettings'),
  });
}

const router = createReedsyRouter({
  history: config.env.test ? createWebHashHistory() : createWebHistory(),
  routes,
});

export default router;
