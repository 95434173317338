import {injectable, named} from 'inversify';
import {RouterPlugin} from '@reedsy/studio.shared/store/plugins/router-plugin';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import {SharedModalsModule} from '@reedsy/studio.shared/store/modules/modals';
import {ISettings} from '@reedsy/reedsy-sharedb/lib/common/user-settings/settings';
import {$inject} from '@reedsy/studio.shared/types';
import {IFeedbackResponse} from '@reedsy/reedsy-sharedb/lib/common/feedback/feedback-response';
import {GoogleAnalyticsEvent} from '@reedsy/utils.analytics';
import {IMetaRoute} from '@reedsy/studio.shared/router/reedsy-router';
import {SharedModeModule} from '@reedsy/studio.shared/store/modules/mode';

@injectable()
export abstract class TenHoursSurveyBasePluginFactory<T extends IMetaRoute = IMetaRoute> extends RouterPlugin<T> {
  protected abstract get userSettings(): ISettings;
  protected abstract skipSurvey(): Promise<void>;
  protected abstract submitFeedback(feedback: IFeedbackResponse): Promise<void>;

  @$inject('StoreModule')
  @named(SharedStoreName.User)
  public readonly $user: SharedUserModule;

  @$inject('StoreModule')
  @named(SharedStoreName.Modals)
  public readonly $modals: SharedModalsModule;

  @$inject('StoreModule')
  @named(SharedStoreName.Mode)
  public $mode: SharedModeModule;

  public override async afterEach(): Promise<void> {
    await this.$modals.open({
      type: 'FeedbackSurvey',
      context: {
        skipSurvey: this.skipSurvey.bind(this),
        submitFeedback: this.submitFeedback.bind(this),
      },
    });
  }

  protected override shouldApplyAfter(to: T, from: T): boolean {
    if (!this.userSettings) return false;
    if (this.userSettings.feedbackAfterTenHours !== undefined) return false;
    if (!this.$user.events[GoogleAnalyticsEvent.TotalSessionsGte10h]) return false;
    if (this.$modals.hasOpenModals) return false;
    if (this.$mode.readOnly) return false;

    return super.shouldApplyAfter(to, from);
  }
}
