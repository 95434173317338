import LocalStorageHelper from '@reedsy/studio.shared/services/local-storage/local-storage-helper';
import {IStorePluginFactory} from '@reedsy/studio.shared/store/plugins/i-store-plugin-factory';
import {SharedBindings} from '@reedsy/studio.shared/types';
import {$getDecorators} from '@reedsy/utils.inversify';
import {IBookshelfBookShareDbConnection} from './services/bookshelf-book-sharedb-connection.interface';
import {IBookshelfShareDbConnection} from './services/bookshelf-sharedb-connection.interface';
import {IBookshelfBookShareDb, IBookshelfShareDb} from './services/i-bookshelf-sharedb';
import {IUsersLoader} from './store/modules/collaborators/i-user-loader';
import {IBookDataStoreCoordinator} from '@reedsy/studio.home.bookshelf/store/helpers/i-book-data-store-coordinator';
import {BookColorPool} from './utils/book-color-pool/book-color-pool';
import {BookshelfStoreListener} from './store/bookshelf-store-listener';
import {IBookshelfBookShareDbManager} from './services/i-bookshelf-book-sharedb-manager';
import {ISingleUseBookShareDb} from './utils/single-use-book-sharedb/i-single-use-book-sharedb';
import {ICrispManager} from './utils/crisp/i-crisp-manager';

export type BookshelfBindings = SharedBindings & {
  BookshelfStorePlugins: IStorePluginFactory;
  BookshelfShareDb: IBookshelfShareDb;
  BookshelfBookShareDb: IBookshelfBookShareDb;
  BookshelfShareDbConnection: IBookshelfShareDbConnection;
  BookshelfBookShareDbConnection: IBookshelfBookShareDbConnection;
  BookshelfBookShareDbFactory: () => IBookshelfBookShareDb;
  BookshelfBookShareDbManager: IBookshelfBookShareDbManager;
  LocalStorageHelper: LocalStorageHelper;
  UsersLoader: IUsersLoader;
  BookshelfBookDataStoreCoordinator: IBookDataStoreCoordinator;
  BookColorPool: BookColorPool;
  StoreListener: BookshelfStoreListener;
  SingleUseBookShareDb: ISingleUseBookShareDb;
  CrispManager: ICrispManager;
};

export const {$inject} = $getDecorators<BookshelfBindings>();
