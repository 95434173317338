
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import ExportSubmissionCreator from '@reedsy/studio.home.bookshelf/components/export-submission-creator/export-submission-creator.vue';

@Component({
  components: {
    ExportSubmissionCreator,
  },
})
export default class BookExportSubmission extends BookshelfVue {
}
