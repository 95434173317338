(window as any).renderJsx = renderJsx;

// Adapted from https://stackoverflow.com/a/42405694/4003671
function renderJsx(tag: any, attributes: any, ...children: any[]): Node {
  let innerHTML = '';
  attributes = attributes || {};
  if (attributes.dangerouslySetInnerHTML) {
    innerHTML = attributes.dangerouslySetInnerHTML;
    delete attributes.dangerouslySetInnerHTML;
  }

  if (typeof tag !== 'string') {
    const rendered = new tag({...attributes, children}).doRender();
    if (typeof rendered.then !== 'function') return rendered;
    // Since our fake JSX components aren't reactive, we let them render asynchronously.
    // In this case, we insert a placeholder node to avoid blocking the tree render, and
    // then replace the placeholder once the component is done rendering.
    const placeholder = document.createComment('');
    rendered.then((asyncElement: Node) => {
      placeholder.parentNode.insertBefore(asyncElement, placeholder);
      placeholder.remove();
    });
    return placeholder;
  }

  const element = renderDomNode(tag, attributes);
  if (innerHTML) (element as HTMLElement).innerHTML = innerHTML;
  else appendChildren(element, children);
  return element;
}

function renderDomNode(tag: string, attributes: any): HTMLElement {
  const options: ElementCreationOptions = {};
  if (attributes.is) options.is = attributes.is;
  const element = document.createElement(tag, options);

  if (attributes.style && typeof attributes.style !== 'string') {
    Object.keys(attributes.style).forEach((property: any) => element.style[property] = attributes.style[property]);
    delete attributes.style;
  }

  if (attributes.class && typeof attributes.class !== 'string') {
    Object.keys(attributes.class)
      .forEach((className: string) => element.classList.toggle(className, attributes.class[className]));
    delete attributes.class;
  }

  for (const key in attributes) {
    const value = attributes[key];
    if (value) {
      if (typeof value === 'function') {
        const eventName = key.substring(2).toLowerCase();
        element.addEventListener(eventName, value);
      } else {
        element.setAttribute(key, value.toString());
      }
    }
  }

  return element;
}

function appendChildren(element: Node, children: any[]): void {
  children = [].concat(...children).filter(Boolean);
  children.forEach((child: any) => {
    const childElement = child.nodeType == null ? document.createTextNode(child.toString()) : child;
    element.appendChild(childElement);
  });
}
