import {VuexModule, Action, Mutation} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {injectable, named} from 'inversify';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {Store} from 'vuex';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import applyOp from '@reedsy/studio.shared/store/helpers/apply-op/apply-op';
import {ShareDBModule} from '@reedsy/studio.home.bookshelf/store/modules/sharedb';
import {clone} from '@reedsy/utils.clone';
import {Doc} from 'sharedb/lib/client';
import {memoize} from '@reedsy/utils.decorator';
import {ICleanUserBookMetadata} from './i-clean-book-metadata';
import {omit} from '@reedsy/utils.object';
import {IUserBookMetadata} from '@reedsy/reedsy-sharedb/lib/common/user-book-metadata/user-book-metadata';
import {getUserBookIdComponents} from '@reedsy/reedsy-sharedb/lib/utils/get-user-book-id';
import {IApplyOpsMutation, IDataMutation, subscribeVuexToDoc} from '@reedsy/studio.shared/store/modules/sharedb/subscribe-vuex-to-doc';

@injectable()
export class UserBookMetadataModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,

    @$inject('StoreModule')
    @named(StoreName.ShareDb)
    shareDb: ShareDBModule,

  ) {
    @Module({name: StoreName.UserBookMetadata, store})
    class UserBookMetadata extends VuexModule {
      public booksOrder: string[] = [];
      private userBookMetadatas: {[bookId: string]: IUserBookMetadata} = {};

      public get userBookMetadata() {
        return (bookId: string): ICleanUserBookMetadata => {
          const userBookMetadata = this.userBookMetadatas[bookId];

          if (!userBookMetadata) return null;
          return {
            ...omit(
              userBookMetadata,
              '_id',
              'metadata',
              'schemaVersion',
              'lastModifiedAt',
            ),
            // We have a collision with the lastModifiedAt property in book details
            lastModifiedByCurrentUserAt: userBookMetadata.lastModifiedAt,
          };
        };
      }

      public get lastEditedAt() {
        return (bookId: string) => {
          const userBookMetadata = this.userBookMetadata(bookId);
          if (!userBookMetadata) return 0;
          return Math.max(
            userBookMetadata.addedToBookshelfAt || 0,
            userBookMetadata.lastModifiedByCurrentUserAt || 0,
          );
        };
      }

      @Mutation
      public DATA({data}: IDataMutation<'userBookMetadata'>): void {
        this.userBookMetadatas[data.metadata.bookId] = clone(data);
      }

      @Mutation
      public APPLY_OPS({id, ops}: IApplyOpsMutation<'userBookMetadata'>): void {
        const {bookId} = getUserBookIdComponents(id);
        ops.forEach((op) => applyOp(this.userBookMetadatas[bookId], op));
      }

      @Action
      @memoize
      public async initialise(): Promise<void> {
        await this.subscribeToUserBookMetadatas();
      }

      @Action
      @memoize
      private async subscribeToUserBookMetadatas(): Promise<void> {
        const query = await shareDb.subscribeQuery({
          collection: 'userBookMetadata',
        });

        const subscribeDocs = (docs: Doc<IUserBookMetadata>[]): void => {
          docs.forEach((doc) => {
            subscribeVuexToDoc(doc, this);
            const {bookId} = getUserBookIdComponents(doc.id);
            this.addBookToOrderArray(bookId);
          });
        };

        subscribeDocs(query.results);
        query.on('insert', subscribeDocs);

        query.on('remove', (removedDocs) => {
          removedDocs.forEach((doc) => {
            const {bookId} = getUserBookIdComponents(doc.id);
            this.REMOVE_USER_BOOK_METADATAS(bookId);
            this.REMOVE_FROM_BOOKS_ORDER(bookId);
            doc.destroy();
          });
        });
      }

      @Action
      private addBookToOrderArray(bookToAddId: string): void {
        const lastEditOfBookToAddAt = this.lastEditedAt(bookToAddId);

        for (let index = 0; index < this.booksOrder.length; index++) {
          const bookId = this.booksOrder[index];
          const lastEditAt = this.lastEditedAt(bookId);
          if (lastEditAt < lastEditOfBookToAddAt) {
            return this.ADD_TO_BOOKS_ORDER({index, bookId: bookToAddId});
          }
        }

        this.ADD_TO_BOOKS_ORDER({
          index: this.booksOrder.length,
          bookId: bookToAddId,
        });
      }

      @Mutation
      private ADD_TO_BOOKS_ORDER({index, bookId}: {index: number; bookId: string}): void {
        this.booksOrder.splice(index, 0, bookId);
      }

      @Mutation
      private REMOVE_FROM_BOOKS_ORDER(toRemoveBookId: string): void {
        const foundIndex = this.booksOrder.findIndex((bookId) => bookId === toRemoveBookId);
        if (foundIndex >= 0) this.booksOrder.splice(foundIndex, 1);
      }

      @Mutation
      private REMOVE_USER_BOOK_METADATAS(bookId: string): void {
        delete this.userBookMetadatas[bookId];
      }
    }
    this.Module = UserBookMetadata;
  }
}

export type UserBookMetadataModule = InstanceType<UserBookMetadataModuleFactory['Module']>;
