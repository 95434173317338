import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiToggle = _resolveComponent("VuiToggle")!

  return (_openBlock(), _createElementBlock("rbe-add-on-toggle", null, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
    _cache[1] || (_cache[1] = _createTextVNode()),
    _createVNode(_component_VuiToggle, {
      modelValue: _ctx.enabled,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.enabled) = $event)),
      label: "Add-on enabled",
      class: "label-left"
    }, null, 8, ["modelValue"])
  ]))
}