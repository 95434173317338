import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconInfo = _resolveComponent("VuiIconInfo")!
  const _component_PremiumIcon = _resolveComponent("PremiumIcon")!

  return (_openBlock(), _createElementBlock("rbe-premium-badge-info", null, [
    _createVNode(_component_VuiIconInfo, { class: "info-icon icon-sm" }),
    _cache[0] || (_cache[0] = _createTextVNode()),
    _createVNode(_component_PremiumIcon)
  ]))
}