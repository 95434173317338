<template>
  <rbe-main-layout
    :class="{
      'inverted-background': !$layout.contentIsDark
    }"
  >
    <MobileMenu />
    <rbe-top-part-wrapper>
      <TopBar />
      <ExpandTransition>
        <rbe-top-slot-wrapper
          v-if="$layout.expandedLayoutTop"
          class="vui-wrapper"
        >
          <slot name="top-part" />
        </rbe-top-slot-wrapper>
      </ExpandTransition>
    </rbe-top-part-wrapper>
    <slot name="above-content" />
    <rbe-content
      class="themed vui-wrapper"
      :class="{
        'light-theme': !$layout.contentIsDark,
        'dark-theme': $layout.contentIsDark,
      }"
    >
      <slot />
    </rbe-content>
    <rbe-footer-wrapper>
      <LayoutFooter class="bookshelf-layout-footer" />
    </rbe-footer-wrapper>
  </rbe-main-layout>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import LayoutContent from './layout-content.vue';
import TopBar from '@reedsy/studio.home.bookshelf/components/top-bar/default-top-bar/top-bar.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import ExpandTransition from '@reedsy/studio.shared/components/transitions/expand-transition.vue';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfLayoutModule} from '@reedsy/studio.home.bookshelf/store/modules/layout';
import MobileMenu from '@reedsy/studio.home.bookshelf/components/mobile-menu/mobile-menu.vue';
import LayoutFooter from './layout-footer.vue';

@Component({
  components: {
    LayoutContent,
    TopBar,
    ExpandTransition,
    MobileMenu,
    LayoutFooter,
  },
})
export default class MainLayout extends BookshelfVue {
  @$lazyInjectStore(StoreName.Layout)
  public $layout: BookshelfLayoutModule;
}
</script>

<style lang="scss" scoped>
@include orientation-notch-padding('.bookshelf-main-layout .layout-content > .content', $space-md);

rbe-main-layout {
  height: safe-vh(100);
  width: 100%;
  box-sizing: border-box;
  overflow: hidden scroll;
  background-color: var(--bookshelf-layout-background);
  display: flex;
  flex-direction: column;

  &.inverted-background {
    background-color: var(--bookshelf-layout-inverted-background);
  }

  rbe-top-part-wrapper {
    background-color: var(--bookshelf-layout-background);
  }

  &.full-width-content {
    rbe-content {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.remove-vertical-scroll {
    overflow-y: hidden;
  }

  rbe-content {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;

    @include screen-less-than(md) {
      max-width: 100vw;
    }
  }

  .bookshelf-layout-footer {
    margin-top: $space-xl;
  }
}
</style>
