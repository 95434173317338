import {DebugEvent} from './debug-event';

export default function debug() {
  return function(target: any, propertyKey: string, descriptor: PropertyDescriptor): void {
    const className = target.constructor.name === 'Function' ?
      target.name :
      target.constructor.name;

    const method = descriptor.value;
    const name = `${className}.${propertyKey}`;

    descriptor.value = function(...args: any[]) {
      DebugEvent.log(name, 'called', args);
      let result = method.apply(this, args);
      const isPromiseLike = !!result &&
        typeof result.then === 'function' &&
        typeof result.catch === 'function';

      if (isPromiseLike) {
        result = result
          .then((resolved: any) => {
            DebugEvent.log(name, 'resolved');
            return resolved;
          })
          .catch((error: any) => {
            DebugEvent.log(name, 'rejected');
            return Promise.reject(error);
          });
      }

      DebugEvent.log(name, 'returned');
      return result;
    };
  };
}
