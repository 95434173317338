import {injectable, named} from 'inversify';
import {ISettings} from '@reedsy/reedsy-sharedb/lib/common/user-settings/settings';
import {TenHoursSurveyBasePluginFactory} from '@reedsy/studio.shared/store/plugins/ten-hours-survey-base';
import {UserSettingsModule} from '@reedsy/studio.home.bookshelf/store/modules/user-settings';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {IFeedbackResponse} from '@reedsy/reedsy-sharedb/lib/common/feedback/feedback-response';
import {FeedbackModule} from '@reedsy/studio.home.bookshelf/store/modules/feedback';

@injectable()
export class TenHoursSurveyPluginFactory extends TenHoursSurveyBasePluginFactory {
  @$inject('StoreModule')
  @named(StoreName.UserSettings)
  public readonly $userSettings: UserSettingsModule;

  @$inject('StoreModule')
  @named(StoreName.Feedback)
  public readonly $feedback: FeedbackModule;

  protected get userSettings(): ISettings {
    return this.$userSettings.settings;
  }

  protected override async skipSurvey(): Promise<void> {
    await this.$feedback.skipFeedbackSurvey();
  }

  protected override async submitFeedback(feedback: IFeedbackResponse): Promise<void> {
    await this.$feedback.submitFeedback(feedback);
  }
}
