<template>
  <BookTopInfoGrid class="book-top-info">
    <template #book-cover>
      <BookCover
        class="book-cover sm static-width"
        :book="book"
      />
      <ShareIndicator
        class="share-indicator"
        size="sm"
        :book-id="book.id"
      />
    </template>
    <template #book-titles>
      <h1 class="book-title-text ellipsis">
        <slot name="book-title">
          {{ book.title }}
        </slot>
      </h1>
      <p class="book-subtitle-text large">
        <slot name="book-subtitle">
          {{ book.subtitle }}
        </slot>
      </p>
    </template>

    <template #action>
      <ContinueWritingButton
        :book="book"
        class="continue-writing-button"
      />
    </template>
  </BookTopInfoGrid>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import BookCover from '@reedsy/studio.home.bookshelf/components/book-cover/book-cover.vue';
import {IBookshelfBook} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-book';
import BookTopInfoGrid from '@reedsy/studio.home.bookshelf/components/book-top-info/book-top-info-grid.vue';
import {PropType} from 'vue';
import ContinueWritingButton from '@reedsy/studio.home.bookshelf/components/continue-writing-button/continue-writing-button.vue';
import ShareIndicator from '@reedsy/studio.home.bookshelf/components/share-indicator/share-indicator.vue';

@Component({
  components: {
    BookCover,
    ContinueWritingButton,
    BookTopInfoGrid,
    ShareIndicator,
  },
})
export default class BookTopInfo extends BookshelfVue {
  @Prop({type: Object as PropType<IBookshelfBook>, required: true})
  public book: IBookshelfBook;
}
</script>

<style lang="scss" scoped>
.book-top-info {
  .book-cover {
    box-shadow: $box-shadow-lg;
  }

  h1.book-title-text {
    margin-bottom: $space-sm;
  }

  .continue-writing-button {
    width: 100%;
    min-width: 10rem;
  }
}
</style>
