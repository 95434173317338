import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArchivedBookAction = _resolveComponent("ArchivedBookAction")!
  const _component_BookActions = _resolveComponent("BookActions")!

  return (_ctx.isArchived)
    ? (_openBlock(), _createBlock(_component_ArchivedBookAction, {
        key: 0,
        book: _ctx.book
      }, null, 8, ["book"]))
    : (_openBlock(), _createBlock(_component_BookActions, {
        key: 1,
        book: _ctx.book
      }, null, 8, ["book"]))
}