import {injectable} from 'inversify';
import {buildSignInUrl} from '@reedsy/studio.shared/utils/auth/build-sign-in-url';
import {config} from '@reedsy/studio.shared/config';
import {INavigationOptions, ISignInOptions} from './i-navigation-options';
import {StudioApp} from './studio-app';
import {Timers} from '@reedsy/studio.shared/utils/timers';
import {INavigation} from './i-navigation';
import {buildSocialSignInUrl} from '@reedsy/studio.shared/utils/auth/build-social-sign-in-url';
import {SocialProvider} from '@reedsy/studio.shared/utils/auth/social-provider/social-provider';
import {buildSignUpUrl} from '@reedsy/studio.shared/utils/auth/build-sign-up-url';
import {ReedsyLocation} from './location';

@injectable()
export default class Navigation implements INavigation {
  public guardEnabled = true;

  public get authDashboardUrl(): string {
    return `${this.getAuthUrl('dashboard')}`;
  }

  public get authAccountSettingsUrl(): string {
    return `${this.getAuthUrl('settings')}`;
  }

  public get editorAccountSettingsUrl(): string {
    return `${this.getStudioUrl('home', 'settings', 'account')}`;
  }

  public get devicesSettingsUrl(): string {
    return `${this.getAuthUrl('devices')}`;
  }

  public get privacyUrl(): string {
    return `${this.getMarketplaceUrl('about', 'privacy')}`;
  }

  public get termsOfUseUrl(): string {
    return `${this.getMarketplaceUrl('about', 'tou')}`;
  }

  public get homeUrl(): string {
    return `${this.getStudioUrl('home')}`;
  }

  public get writeABookUrl(): string {
    return `${this.getMarketplaceUrl('write-a-book')}`;
  }

  public get marketplaceUrl(): string {
    return `${this.getMarketplaceUrl()}`;
  }

  public get billingPortalUrl(): string {
    return `${config.app.server.api}/subscription/billing-portal/url`;
  }

  public get userNotificationsSettingsUrl(): string {
    return `${this.getStudioUrl(
      'home',
      'settings',
      'account',
      'general',
    )}`;
  }

  /* istanbul ignore next */
  public reloadPage(): void {
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.append('forceUpdate', 'true');
    window.location.href = newUrl.href;
  }

  public buildBookSettingsUrl(bookUuid: string): string {
    return `${this.getStudioUrl(
      'home',
      'books',
      bookUuid,
      'settings',
    )}`;
  }

  public buildExportBookUrl(bookUuid: string): string {
    return `${this.getStudioUrl(
      'home',
      'books',
      bookUuid,
      'exports',
      'new',
    )}`;
  }

  public buildAcceptInvitationUrl({bookId, invitationId}: {bookId: string; invitationId: string}): string {
    return `${this.getStudioUrl(
      'home',
      'invitations',
      invitationId,
      'books',
      bookId,
      'acceptance',
    )}`;
  }

  public buildBookAccessPageUrl(bookUuid: string): string {
    return `${this.getStudioUrl(
      'home',
      'books',
      bookUuid,
      'access',
    )}`;
  }

  public getBookEditorUrl(bookUuid: string): string {
    return `${this.getStudioUrl('bookEditor', 'book', bookUuid)}`;
  }

  public goToSignIn(options: ISignInOptions = {}): Promise<void> {
    if (!config.api.real) {
      return this.goToExternalUrl(location.origin, {force: true});
    }

    let {afterSignInPath, ...otherOptions} = options;

    if (!afterSignInPath) {
      const currentUrl = window.location.href;
      afterSignInPath = this.getPath(currentUrl);
    }

    const url = buildSignInUrl({
      afterSignInPath,
    });
    return this.goToExternalUrl(url, {...otherOptions, force: true});
  }

  public goToSocialSignIn(socialProvider: SocialProvider, options?: INavigationOptions): Promise<void> {
    const url = buildSocialSignInUrl(socialProvider);
    return this.goToExternalUrl(url, {...options, force: true});
  }

  public goToSignUp(options?: INavigationOptions): Promise<void> {
    const url = buildSignUpUrl();
    return this.goToExternalUrl(url, {...options, force: true});
  }

  public goToMarketplace(options?: INavigationOptions): Promise<void> {
    return this.goToExternalUrl(this.marketplaceUrl, {...options, force: true});
  }

  public goToBook(bookUuid: string, options?: INavigationOptions): Promise<void> {
    const url = this.getBookEditorUrl(bookUuid);
    return this.goToExternalUrl(url, {...options, force: true});
  }

  public goToTermsAndConditions(options?: INavigationOptions): Promise<void> {
    const url = this.getMarketplaceUrl('about', 'tos');
    return this.goToExternalUrl(url, {...options, force: true});
  }

  public goToThankYouPage(email: string, options?: INavigationOptions): Promise<void> {
    const url = this.getLandingPageUrl('complete');
    url.searchParams.set('email', email);
    return this.goToExternalUrl(url, {...options, force: true});
  }

  public goToBookShelf(options?: INavigationOptions): Promise<void> {
    return this.goToExternalUrl(this.homeUrl, {...options, force: true});
  }

  public goToAuthDashboard(options?: INavigationOptions): Promise<void> {
    return this.goToExternalUrl(this.authDashboardUrl, {...options, force: true});
  }

  public goToWelcome(options?: INavigationOptions): Promise<void> {
    return this.goToLandingPage(options);
  }

  public goToLandingPage(options?: INavigationOptions): Promise<void> {
    return this.goToExternalUrl(
      this.getLandingPageUrl(),
      {...options, force: true},
    );
  }

  public goToNotFoundPage(options?: INavigationOptions): Promise<void> {
    return this.goToExternalUrl(
      this.getStudioUrl('home', 'not-found'),
      {...options, force: true},
    );
  }

  /* istanbul ignore next */
  public async goToExternalUrl(url: string | URL, options?: INavigationOptions): Promise<void> {
    const {force, openInNewTab} = {
      openInNewTab: false,
      force: false,
      ...options,
    };
    if (force) this.guardEnabled = false;

    if (openInNewTab) {
      window.open(url, '_blank').focus();
    } else {
      ReedsyLocation.goToExternalUrl(url);
      await Timers.forever();
    }
  }

  public getStudioUrl(app: StudioApp, ...paths: string[]): URL {
    return new URL(paths.join('/'), config.app[app].origin);
  }

  public getUrl(origin: string, ...paths: string[]): URL {
    if (origin.endsWith('/')) origin = origin.substring(0, origin.length - 1);
    const url = new URL(paths.join('/'), origin);
    if (url.origin !== origin) throw new Error('bad origin');
    return url;
  }

  private getAuthUrl(page: string): URL {
    const url = this.getUrl(config.auth.origin, page);
    url.searchParams.append('ca_uuid', config.auth.client.uuid);
    return url;
  }

  private getMarketplaceUrl(...paths: string[]): URL {
    return this.getUrl(config.reedsyApps.marketplace.origin, ...paths);
  }

  private getLandingPageUrl(...paths: string[]): URL {
    const url = new URL(config.reedsyApps.studioLandingPage.url);
    return this.getUrl(url.origin, url.pathname, ...paths);
  }

  private getPath(url: string | URL): string {
    url = new URL(url);
    return url.toString().substring(url.origin.length);
  }
}
