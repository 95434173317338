import {classesToObject} from '@reedsy/studio.shared/utils/vue/classes-to-object';
import {DirectiveHook, ObjectDirective} from 'vue';

const ADDED_CLASSES = new WeakMap<Element, Set<string>>();

const updateClasses: DirectiveHook = (element, {value}): void => {
  clearClasses(element);
  const classObject = classesToObject(value);
  for (const key in classObject) {
    if (classObject[key]) addClass(element, key);
  }
};

export const rootClass: ObjectDirective = {
  beforeMount: updateClasses,
  updated: updateClasses,
};

function clearClasses(element: Element): void {
  for (const className of addedClasses(element)) {
    element.classList.remove(className);
  }
  ADDED_CLASSES.delete(element);
}

function addClass(element: Element, className: string): void {
  element.classList.add(className);
  addedClasses(element).add(className);
}

function addedClasses(element: Element): Set<string> {
  if (!ADDED_CLASSES.has(element)) ADDED_CLASSES.set(element, new Set());
  return ADDED_CLASSES.get(element);
}
