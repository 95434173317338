<template>
  <rbe-invitation-acceptance-view class="flex-center">
    <AcceptInvitationPanel />
  </rbe-invitation-acceptance-view>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import AcceptInvitationPanel from '@reedsy/studio.home.bookshelf/components/accept-invitation-panel/accept-invitation-panel.vue';

@Component({
  components: {
    AcceptInvitationPanel,
  },
})
export default class InvitationAcceptanceView extends BookshelfVue {}
</script>

<style lang="scss" scoped>
rbe-invitation-acceptance-view {
  background-color: var(--bookshelf-layout-inverted-background);
  min-height: safe-vh(100);
}
</style>
