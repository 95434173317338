<template>
  <div class="book-skeleton-loader">
    <BookTopInfoGrid>
      <template #book-cover>
        <SkeletonLoader class="book-cover" />
      </template>
      <template #book-titles>
        <SkeletonLoader class="book-title" />
        <SkeletonLoader class="book-subtitle" />
      </template>
    </BookTopInfoGrid>
  </div>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';
import BookTopInfoGrid from '@reedsy/studio.home.bookshelf/components/book-top-info/book-top-info-grid.vue';

@Component({
  components: {
    SkeletonLoader,
    BookTopInfoGrid,
  },
})
export default class BookSkeletonLoader extends BookshelfVue {}
</script>

<style lang="scss" scoped>
.book-skeleton-loader {
  .book-cover {
    flex-shrink: 0;
    box-shadow: $box-shadow-base;
    border-radius: $border-radius-lg;
    width: $sm-cover-width;
    height: $sm-cover-height;
  }

  .book-title {
    width: 10rem;
    height: 2rem;
  }

  .book-subtitle {
    margin-top: $space-md;
    width: 12rem;
    height: $font-size-lg;
  }

  @include screen-more-than(xs) {
    .book-title {
      width: 15rem;
    }

    .book-subtitle {
      width: 25rem;
    }
  }

  .book-action{
    width: 25%;
    height: 50%;
  }
}
</style>
