import {one} from '@reedsy/utils.fluent-date';
import {objectKeys} from '@reedsy/utils.object';
import {restartableInterval} from '@reedsy/utils.timeout';
import {ComputedRef, computed, ref} from 'vue';

const DATE_FRAGMENTS = {
  year: ref(0),
  month: ref(0),
  date: ref(0),
  hours: ref(0),
  minutes: ref(0),
  seconds: ref(0),
};

// Disallow year: we need at least 2 args for the Date constructor, otherwise it's
// parsed as a timestamp
type DateResolution = Exclude<keyof typeof DATE_FRAGMENTS, 'year'>;

function update(): void {
  const now = new Date();
  DATE_FRAGMENTS.year.value = now.getFullYear();
  DATE_FRAGMENTS.month.value = now.getMonth();
  DATE_FRAGMENTS.date.value = now.getDate();
  DATE_FRAGMENTS.hours.value = now.getHours();
  DATE_FRAGMENTS.minutes.value = now.getMinutes();
  DATE_FRAGMENTS.seconds.value = now.getSeconds();
}

const {start, stop} = restartableInterval(update, one.second);

// Should only be used in tests for controlling the setInterval()
export const _start = start;
// Should only be used in tests for controlling the setInterval()
export const _stop = stop;

export function reactiveDate(resolution?: DateResolution): ComputedRef<Date> {
  start();
  return computed(() => {
    const args: Parameters<DateConstructor['UTC']> = [] as any;
    for (const key of objectKeys(DATE_FRAGMENTS)) {
      args.push(DATE_FRAGMENTS[key].value);
      if (key === resolution) break;
    }
    return new Date(...args);
  });
}
