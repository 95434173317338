import {ComponentBase, Vue} from '@reedsy/studio.shared/utils/vue/decorators';
import {ReedsyRouter} from '@reedsy/studio.shared/router/reedsy-router';
import {GlobalVuiNotificationManager} from '@reedsy/vui';

/**
 * Simple helper class that adds our prototype modifications for type safety
 */
@ComponentBase({})
export class ClientSharedVue extends Vue {
  public override $router: ReedsyRouter;
  public $vuiNotificationManager: GlobalVuiNotificationManager;
  public readonly modelValue?: any;
}
