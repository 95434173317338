import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_VTextarea = _resolveComponent("VTextarea")!
  const _component_FeedbackField = _resolveComponent("FeedbackField")!

  return (_openBlock(), _createBlock(_component_FeedbackField, {
    label: "What’s your main reason for using Studio?",
    "show-extra-field": _ctx.isDifferentReason
  }, {
    "extra-field": _withCtx(() => [
      _createVNode(_component_VTextarea, {
        ref: "textarea",
        modelValue: _ctx.usageReasonDifferent,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.usageReasonDifferent) = $event)),
        required: "",
        placeholder: "Please specify your reason"
      }, null, 8, ["modelValue"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Dropdown, {
        ref: "dropdown",
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        required: "",
        options: _ctx.usageReasonsOptions
      }, null, 8, ["modelValue", "options"]),
      _cache[2] || (_cache[2] = _createTextVNode())
    ]),
    _: 1
  }, 8, ["show-extra-field"]))
}