import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import Notify from '@reedsy/studio.shared/services/notify/notify';
import {ResourcesRole} from '@reedsy/utils.reedsy-resources';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import {$lazyInject} from '@reedsy/studio.shared/inversify.config';

@Component({})
export class InviteCollaboratorMixin extends ClientSharedVue {
  @$lazyInject('Api')
  public readonly api: IApi;

  public inviteesEmails: string[] = [];
  public failedEmails: Record<string, string> = {};
  public hasUnknownError = false;
  public isSendingRequest = false;

  public get hasError(): boolean {
    return !!Object.keys(this.failedEmails).length;
  }

  public async sendInvitations(bookId: string): Promise<void> {
    try {
      this.isSendingRequest = true;
      this.failedEmails = {};
      this.hasUnknownError = false;

      await Promise.allSettled(
        this.inviteesEmails.map((email) => this.sendInvitation(bookId, email)),
      );

      if (Object.keys(this.failedEmails).length) {
        this.inviteesEmails = Object.keys(this.failedEmails);
        throw new Error('Cannot send all collaboration invitations');
      } else {
        Notify.success({message: `Invitation sent to ${this.inviteesEmails.join(', ')}`});
        this.inviteesEmails = [];
      }
    } finally {
      this.isSendingRequest = false;
    }
  }

  private async sendInvitation(bookId: string, email: string): Promise<void> {
    try {
      await this.api.inviteCollaborator({
        bookId,
        inviteeEmail: email,
        role: ResourcesRole.CoAuthor,
      });
    } catch (error) {
      const errorCode = error.response?.data || 'unknown';
      this.failedEmails[email] = errorCode;
      if (errorCode === 'unknown') this.hasUnknownError = true;
      throw error;
    }
  }
}
