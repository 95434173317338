
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import BookCover from '@reedsy/studio.home.bookshelf/components/book-cover/book-cover.vue';
import BookProgress from '@reedsy/studio.home.bookshelf/components/book-progress/book-progress.vue';
import {IBookImport} from '@reedsy/reedsy-sharedb/lib/common/book/book-imports';
import NoBookCoverPlaceholder from '@reedsy/studio.home.bookshelf/components/book-cover/no-book-cover-placeholder.vue';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import {BookshelfEntryType, IBookshelfEntry} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-entry';
import BookshelfEntry from './bookshelf-entry.vue';
import EntryAction from './entry-action.vue';
import {BooksImportsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-imports';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';

@Component({
  components: {
    BookCover,
    BookProgress,
    NoBookCoverPlaceholder,
    BookshelfEntry,
    EntryAction,
  },
})
export default class BookImportEntry extends BookshelfVue {
  @$lazyInjectStore(SharedStoreName.User)
  public $user: SharedUserModule;

  @$lazyInjectStore(StoreName.BooksImports)
  public $booksImports: BooksImportsModule;

  @$lazyInject('Api')
  public api: IApi;

  @Prop({type: Object, required: true})
  public entry: IBookshelfEntry<BookshelfEntryType.BookImport>;

  public get bookImport(): IBookImport {
    return this.entry.item;
  }

  public get bookTitle(): string {
    return this.bookImport.importData.title;
  }

  public async cancelImport(): Promise<void> {
    await this.$booksImports.cancelBookImport(this.bookImport._id);
  }
}
