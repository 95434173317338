import {IBookImport} from '@reedsy/reedsy-sharedb/lib/common/book/book-imports';
import {IBookshelfBook} from './i-bookshelf-book';
import {setUpFuseSearch} from './set-up-fuse-search';

const _searchBook = setUpFuseSearch<IBookshelfBook>({
  keys: [
    {name: 'title', weight: 2},
    {name: 'subtitle', weight: 1},
  ],
});

const _searchBookImport = setUpFuseSearch<IBookImport>({
  keys: [
    {name: 'importData.title', weight: 2},
    {name: 'importData.subtitle', weight: 1},
  ],
});

export const searchBook = (books: IBookshelfBook[], search: string): IBookshelfBook[] => {
  if (!search) return books;
  return _searchBook(books, search);
};

export const searchBookImport = (bookImports: IBookImport[], search: string): IBookImport[] => {
  if (!search) return bookImports;
  return _searchBookImport(bookImports, search);
};
