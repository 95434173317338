
import {Component, Prop, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ConfirmModal from '@reedsy/studio.shared/components/modals/templates/confirm-modal.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import ErrorMessageBox from '@reedsy/studio.shared/components/error-message-box/error-message-box.vue';
import {InviteCollaboratorMixin} from '@reedsy/studio.shared/mixins/collaboration/invite-collaborator-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {PropType} from 'vue';
import {IInviteCollaboratorModalArgs} from '@reedsy/studio.shared/components/modals/i-invite-collaborator-modal-args';
import UsersSelector from '@reedsy/studio.shared/components/users-selector/users-selector.vue';

@Component({
  components: {
    ConfirmModal,
    UsersSelector,
    ErrorMessageBox,
  },
})
export default class InviteCollaboratorModal extends mixins(ModalMixin, InviteCollaboratorMixin, ClientSharedVue) {
  @Prop({type: Object as PropType<IInviteCollaboratorModalArgs>, default: {}})
  public context: IInviteCollaboratorModalArgs;

  public readonly cancelable = true;

  public async sendInvitationToCurrentBook(): Promise<void> {
    await this.sendInvitations(this.context.book.id);
  }

  public mounted(): void {
    this.$el.querySelector('input[type="email"]').focus();
  }
}
