import JsxComponent from '@reedsy/studio.shared/utils/html/jsx/jsx-component';

export default abstract class DangerousHtmlJsxComponent extends JsxComponent {
  public abstract get dangerousHtml(): string;

  public render(): HTMLElement {
    const node = document.createRange().createContextualFragment(this.dangerousHtml);

    if (node.childElementCount !== 1) throw new Error('Expected exactly one child element in the HTML fragment');

    return node.firstElementChild as HTMLElement;
  }
}
