
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModule} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';

@Component({})
export default class GridLine extends BookshelfVue {
  @$lazyInjectStore(StoreName.Bookshelf)
  public $bookshelf: BookshelfModule;

  @Prop({type: Boolean, default: false})
  public withButton: boolean;

  public showMore(): void {
    this.$bookshelf.showMoreBookshelfEntries();
  }
}
