<template>
  <span class="vui-icon">
    <svg width="72px" height="50px" viewBox="0 0 72 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>rounded-corners</title>
        <g id="icon-fill" transform="translate(-152.000000, -87.000000)" fill-rule="evenodd">
            <path d="M160.008216,87 L152,87 L152,87 L152,76 L224,76 L224,87 L216,87 C211.581722,87 208,90.581722 208,95 L208,106.5 C208,117.269553 199.269553,126 188.5,126 L187.540086,126 C176.767288,126 168.031107,117.272792 168.020038,106.5 L168.008212,94.9917794 C168.003675,90.5767138 164.423284,87 160.008216,87 Z" id="rounded-corners"></path>
        </g>
    </svg>
  </span>
</template>
