import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_SkeletonLoader = _resolveComponent("SkeletonLoader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("rbe-info-placeholder", _hoisted_1, [
      _createVNode(_component_Avatar, {
        class: "avatar-placeholder",
        placeholder: ""
      }),
      _cache[0] || (_cache[0] = _createTextVNode()),
      _createVNode(_component_SkeletonLoader, { class: "loader loader-name" })
    ]),
    _cache[1] || (_cache[1] = _createTextVNode()),
    _createVNode(_component_SkeletonLoader, { class: "loader loader-text" }),
    _cache[2] || (_cache[2] = _createTextVNode()),
    _createVNode(_component_SkeletonLoader, { class: "loader loader-menu" })
  ], 64))
}