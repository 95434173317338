
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {Component, mixins, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import ConfirmModal from '@reedsy/studio.shared/components/modals/templates/confirm-modal.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import BaseInput from '@reedsy/studio.shared/components/forms/base-input.vue';
import {$lazyInject} from '@reedsy/studio.shared/inversify.config';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import Notify from '@reedsy/studio.shared/services/notify/notify';
import ImportFileUploader from './import-file-uploader/import-file-uploader.vue';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {AxiosProgressEvent} from 'axios';
import {config} from '@reedsy/studio.shared/config';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {BooksImportsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-imports';
import {BookshelfStoreListener} from '@reedsy/studio.home.bookshelf/store/bookshelf-store-listener';
import {NotifyError} from '@reedsy/studio.shared/utils/decorators/notify-error';
import {BookshelfModule} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf';
import {noEmpty} from '@reedsy/studio.shared/components/forms/input-validators/no-empty';
import {IImportBookModalArgs} from '@reedsy/studio.home.bookshelf/components/modals/i-import-book-modal-args';
import {PropType} from 'vue';
import {StudioAnalyticsManager} from '@reedsy/studio.shared/services/analytics/studio-analytics-manager';
import {GoogleAnalyticsEvent} from '@reedsy/utils.analytics';

@Component({
  components: {
    ConfirmModal,
    BaseInput,
    ImportFileUploader,
  },
})
export default class ImportBookModal extends mixins(ModalMixin, BookshelfVue) {
  @Prop({type: Object as PropType<IImportBookModalArgs>, default: {}})
  public context: IImportBookModalArgs;

  @$lazyInjectStore(StoreName.BooksImports)
  public $booksImports: BooksImportsModule;

  @$lazyInjectStore(StoreName.Bookshelf)
  public $bookshelf: BookshelfModule;

  @$lazyInject('StoreListener')
  public $storeListener: BookshelfStoreListener;

  @$lazyInject('Api')
  public api: IApi;

  @$lazyInject('StudioAnalyticsManager')
  public $analytics: StudioAnalyticsManager;

  public readonly cancelable = true;
  public readonly maxTitleLength = config.validations.maxBookTitleLength;
  public readonly maxSubtitleLength = config.validations.maxBookSubtitleLength;
  public readonly noEmptyValidator = noEmpty;

  public title = '';
  public subtitle = '';
  public importFile: File = null;
  public progressPercentage: number = null;
  public isUploading = false;

  public get modalTitle(): string {
    return this.context.modalTitle || 'Import book';
  }

  public get showFileUpload(): boolean {
    return !this.context.manuscriptFilename;
  }

  public mounted(): void {
    this.title = this.context.bookTitle || '';
    this.subtitle = this.context.bookSubtitle || '';
  }

  @NotifyError('Cannot import the book. Please try again later.')
  public async importBook(): Promise<void> {
    this.progressPercentage = 0;

    let result: {importId: string};

    if (this.context.manuscriptFilename) {
      result = await this.api.importAlreadyUploadedFile({
        title: this.title,
        subtitle: this.subtitle,
        source: this.context.source,
        filename: this.context.manuscriptFilename,
      });
    } else {
      result = await this.$booksImports.importBook({
        title: this.title,
        subtitle: this.subtitle,
        importFile: this.importFile,
        options: {
          onUploadProgress: this.onUploadProgress,
        },
      });
    }

    const {importId} = result;

    await this.ensureImportLoaded(importId);
    this.$bookshelf.setActiveEntry({
      entryId: importId,
      clearSearch: true,
    });

    this.$analytics.trackEvent(GoogleAnalyticsEvent.BookImportStarted);
    Notify.success({message: 'Book is being imported.'});
  }

  public updateSelectedFile(file: File): void {
    this.importFile = file;

    if (this.title) return;
    this.setBookTitleFromFileName();
  }

  private setBookTitleFromFileName(): void {
    const filename = this.importFile.name;
    const fileExtensionRegex = /\.[^.]+$/;
    this.title = filename.replace(fileExtensionRegex, '');
  }

  private onUploadProgress(progressEvent: AxiosProgressEvent): void {
    this.progressPercentage = progressEvent.loaded / progressEvent.total * 100;
  }

  private async ensureImportLoaded(waitForImportId: string): Promise<void> {
    if (this.$booksImports.data(waitForImportId)) return;

    return new Promise((resolve) => {
      this.$storeListener.subscribe(
        StoreName.BooksImports,
        '_BOOK_IMPORT_DATA',
        () => resolve(),
        {onceWhen: ({importId}) => importId === waitForImportId},
      );
    });
  }
}
