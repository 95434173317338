
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import Panel from '@reedsy/studio.shared/components/panel/panel.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import Navigation from '@reedsy/studio.shared/services/navigation';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {ICurrentSubscription} from '@reedsy/studio.shared/store/modules/subscription/current-subscription.interface';
import PaymentInfoItem from './payment-info-item.vue';

@Component({
  components: {
    Panel,
    PaymentInfoItem,
  },
})
export default class PaymentInfo extends ClientSharedVue {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @$lazyInject('Navigation')
  public $navigation: Navigation;

  public subscription: ICurrentSubscription = null;

  public get loading(): boolean {
    return !this.subscription;
  }

  public get billingPortalUrl(): string {
    return this.$navigation.billingPortalUrl;
  }

  public async created(): Promise<void> {
    this.subscription = await this.$subscription.fetchCurrentSubscriptionInfo();
  }
}
