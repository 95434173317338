
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import PlainModal from '@reedsy/studio.shared/components/modals/templates/plain-modal.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {config} from '@reedsy/studio.shared/config';
import RicardoIcon from './ricardo-icon.vue';
import InfoIcon from './info-icon.vue';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {ICrispManager} from '@reedsy/studio.home.bookshelf/utils/crisp/i-crisp-manager';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';

@Component({
  components: {
    PlainModal,
    InfoIcon,
    RicardoIcon,
  },
})
export default class HelpModal extends mixins(ModalMixin, BookshelfVue) {
  @$lazyInject('CrispManager')
  public readonly $crispManager: ICrispManager;

  @$lazyInjectStore(StoreName.Modals)
  public readonly $modals: BookshelfModalsModule;

  public readonly cancelable = true;

  public get faqLink(): string {
    return config.reedsyApps.faq.url;
  }

  public openCrisp(): void {
    this.$crispManager.openChat();
    this.closeModal();
  }

  public closeModal(): void {
    this.$modals.CLOSE(this.id);
  }
}
