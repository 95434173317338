import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UsersSelector = _resolveComponent("UsersSelector")!
  const _component_ErrorMessageBox = _resolveComponent("ErrorMessageBox")!
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!

  return (_openBlock(), _createBlock(_component_ConfirmModal, {
    id: _ctx.id,
    title: "Add another writer",
    cancel: {label: 'Cancel'},
    confirm: {
      label: 'Send',
      handler: _ctx.sendInvitationToCurrentBook,
    }
  }, {
    default: _withCtx(() => [
      _createElementVNode("rbe-invite-collaborator-modal", null, [
        _cache[2] || (_cache[2] = _createElementVNode("p", null, "\n        Are you writing this book as a team?\n        Share your project with a co-writer, editor, or trusted advisor.\n      ", -1)),
        _cache[3] || (_cache[3] = _createTextVNode()),
        _createVNode(_component_UsersSelector, {
          modelValue: _ctx.inviteesEmails,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inviteesEmails) = $event)),
          class: "users-selector",
          "failed-emails": _ctx.failedEmails,
          disabled: _ctx.isSendingRequest
        }, null, 8, ["modelValue", "failed-emails", "disabled"]),
        _cache[4] || (_cache[4] = _createTextVNode()),
        (_ctx.hasUnknownError)
          ? (_openBlock(), _createBlock(_component_ErrorMessageBox, {
              key: 0,
              "with-icon": ""
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("\n        Could not send the invitation, please try again later.\n        If the error persists, contact our support team.\n      ")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["id", "confirm"]))
}