import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoIcon = _resolveComponent("InfoIcon")!
  const _component_RicardoIcon = _resolveComponent("RicardoIcon")!
  const _component_PlainModal = _resolveComponent("PlainModal")!

  return (_openBlock(), _createBlock(_component_PlainModal, {
    id: _ctx.id,
    class: "wide help-modal",
    title: "Need some help?"
  }, {
    default: _withCtx(() => [
      _cache[7] || (_cache[7] = _createElementVNode("rbe-description-wrapper", null, [
        _createElementVNode("p", null, "Check the FAQ or chat with a member of the Reedsy Team.")
      ], -1)),
      _cache[8] || (_cache[8] = _createTextVNode()),
      _createElementVNode("rbe-help-options", null, [
        _createElementVNode("a", {
          class: "help-option",
          href: _ctx.faqLink,
          target: "_blank",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
        }, [
          _createVNode(_component_InfoIcon, { class: "icon" }),
          _cache[2] || (_cache[2] = _createTextVNode()),
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "option-title" }, "\n          Visit the FAQ\n        ", -1))
        ], 8, _hoisted_1),
        _cache[6] || (_cache[6] = _createTextVNode()),
        _createElementVNode("button", {
          type: "button",
          class: "help-option open-crisp-option",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openCrisp && _ctx.openCrisp(...args)))
        }, [
          _createVNode(_component_RicardoIcon, { class: "icon" }),
          _cache[4] || (_cache[4] = _createTextVNode()),
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "option-title" }, "\n          Chat with our team\n        ", -1))
        ])
      ])
    ]),
    _: 1
  }, 8, ["id"]))
}