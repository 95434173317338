import axios, {AxiosError, AxiosResponse, RawAxiosRequestConfig} from 'axios';
import HttpMethod from './http-method';
import debug from '@reedsy/studio.shared/utils/debug/debug';
import {injectable} from 'inversify';
import {dig} from '@reedsy/utils.dig';
import {timeout} from '@reedsy/utils.timeout';
import {Logger} from '@reedsy/reedsy-logger-js';
import {HTTPStatus} from '@reedsy/utils.http';

@injectable()
export default class HTTPClient {
  protected logger: Logger;

  public get<T = any>(path: string, options?: RawAxiosRequestConfig): Promise<T> {
    return this.sendRequest<T>(HttpMethod.Get, path, options);
  }

  public post<T = any>(path: string, data?: any, options: RawAxiosRequestConfig = {}): Promise<T> {
    options.data = data;
    return this.sendRequest<T>(HttpMethod.Post, path, options);
  }

  public put<T = any>(path: string, data?: any, options: RawAxiosRequestConfig = {}): Promise<T> {
    options.data = data;
    return this.sendRequest<T>(HttpMethod.Put, path, options);
  }

  public patch<T = any>(path: string, data?: any, options: RawAxiosRequestConfig = {}): Promise<T> {
    options.data = data;
    return this.sendRequest<T>(HttpMethod.Patch, path, options);
  }

  public delete<T = any>(path: string, options?: RawAxiosRequestConfig): Promise<T> {
    return this.sendRequest<T>(HttpMethod.Delete, path, options);
  }

  protected validateResponse(_response: AxiosResponse): void {
    return;
  }

  @debug()
  protected async sendRequest<T = any>(
    method: HttpMethod,
    path: string,
    options: RawAxiosRequestConfig = {},
  ): Promise<T> {
    try {
      options.method = method;
      options.url = path;
      const response = await axios.request(options);
      this.validateResponse(response);
      return response.data;
    } catch (error) {
      if (!dig(error, 'response', 'status')) {
        // If there's no status code attached to the error, then we've probably got a generic
        // Network Error (eg the client is disconnected from the internet). Let's just try the
        // request again until it succeeds
        this.logger.debug('Invalid status code, trying request again', {error, data: {method, path, options}});
        await timeout(5000);
        return this.sendRequest(method, path, options);
      }
      await this.handleError(error);
      return Promise.reject(error);
    }
  }

  protected handleError(error: AxiosError): Promise<void> {
    switch (dig(error, 'response', 'status')) {
      case HTTPStatus.Unauthorized:
        return this.handleUnauthorized(error);
      case HTTPStatus.Forbidden:
        return this.handleForbidden(error);
      case HTTPStatus.TooManyRequests:
        return this.handleTooManyRequestsError(error);
      case HTTPStatus.BadRequest:
      case HTTPStatus.NotFound:
      case HTTPStatus.Conflict:
        return this.handleNonCriticalError(error);
      default:
        return this.handleCriticalError(error);
    }
  }

  protected async handleUnauthorized(error: AxiosError): Promise<void> {
    this.logger.debug(error);
  }

  protected async handleForbidden(error: AxiosError): Promise<void> {
    this.logger.debug(error);
  }

  protected async handleNonCriticalError(error: AxiosError): Promise<void> {
    this.logger.debug(error);
  }

  protected async handleCriticalError(error: AxiosError): Promise<void> {
    this.logger.error(error);
  }

  protected async handleTooManyRequestsError(error: AxiosError): Promise<void> {
    this.logger.debug(error);
  }
}
