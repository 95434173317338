<template>
  <rbe-book-cover :style="coverStyles">
    <NoBookCoverPlaceholder
      v-if="!coverUrl"
      :title="book.title"
      :author="author"
      :class="{'small-font': smallFont}"
    />
    <rbe-book-cover-bottom>
      <slot name="bottom" />
    </rbe-book-cover-bottom>
  </rbe-book-cover>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {IBookDetails} from '@reedsy/reedsy-sharedb/lib/common/book/book-details';
import NoBookCoverPlaceholder from './no-book-cover-placeholder.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfCollaboratorsModule} from '@reedsy/studio.home.bookshelf/store/modules/collaborators';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';
import {CSSProperties} from 'vue';

@Component({
  components: {
    NoBookCoverPlaceholder,
  },
})
export default class BookCover extends BookshelfVue {
  @$lazyInjectStore(StoreName.Collaborators)
  public $collaborators: BookshelfCollaboratorsModule;

  @Prop({type: Object, required: true})
  public book: IBookDetails;

  @Prop({type: Boolean})
  public smallFont: boolean;

  public get coverUrl(): string {
    return this.book.coverUrl;
  }

  public get author(): IUserInfo {
    return this.$collaborators.userById[this.book.ownerId];
  }

  public get coverStyles(): CSSProperties {
    if (!this.coverUrl) return {};

    return {
      'background-image': `url('${this.coverUrl}')`,
    };
  }
}
</script>

<style lang="scss" scoped>
rbe-book-cover {
  flex-shrink: 0;
  box-shadow: $box-shadow-base;
  border-radius: $border-radius-lg;
  width: 100%;
  height: auto;
  position: relative;
  aspect-ratio: $cover-aspect-ratio;
  background-size: cover;

  &.static-width {
    width: $lg-cover-width;
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.sm {
    $cover-scale: math.div($sm-cover-width, $lg-cover-width);

    min-width: $sm-cover-width;

    &.static-width {
      width: $sm-cover-width;
    }

    rbe-no-book-cover-placeholder {
      padding: $space-base $space-sm $space-base calc(5% + $space-sm);

      :deep(.book-title) {
        font-size: $cover-scale * $font-size-xl;
        margin-bottom: $cover-scale * $space-sm;
      }

      :deep(.author-name) {
        font-size: $cover-scale * $font-size-sm;
      }
    }
  }

  rbe-book-cover-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
  }
}
</style>
