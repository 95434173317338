
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import AcceptInvitationPanel from '@reedsy/studio.home.bookshelf/components/accept-invitation-panel/accept-invitation-panel.vue';

@Component({
  components: {
    AcceptInvitationPanel,
  },
})
export default class InvitationAcceptanceView extends BookshelfVue {}
