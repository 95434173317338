import Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent);
export default browser;

export class Browser {
  public static isDesktopSafari(): boolean {
    return !!Bowser.getParser(window.navigator.userAgent)
      .satisfies({macos: {safari: '>0'}});
  }
}
