
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {IBookshelfEntry} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-entry';
import BooksSlider from './books-slider/books-slider.vue';
import NewBookActionBar from './new-book-action-bar.vue';
import SelectedBookActionBar from './selected-book-action-bar.vue';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModule} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import BookLoader from './book-loader.vue';
import NoBooksPlaceholder from '@reedsy/studio.home.bookshelf/components/no-books-placeholder/no-books-placeholder.vue';
import {BookshelfLayoutModule} from '@reedsy/studio.home.bookshelf/store/modules/layout';

@Component({
  components: {
    BooksSlider,
    NewBookActionBar,
    SelectedBookActionBar,
    NoBooksPlaceholder,
    BookLoader,
  },
})
export default class MobileBooksList extends BookshelfVue {
  @$lazyInjectStore(StoreName.Layout)
  public $layout: BookshelfLayoutModule;

  @$lazyInjectStore(StoreName.Bookshelf)
  public $bookshelf: BookshelfModule;

  public get bookshelfEntries(): IBookshelfEntry[] {
    return this.$bookshelf.displayedBookshelfEntries;
  }

  public get isLoadingBooksList(): boolean {
    return !!this.$layout.isLoadingBooksList;
  }
}
