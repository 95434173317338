<template>
  <rbe-period-toggle>
    <button
      class="reedsy-accented"
      type="button"
      @click="annual = false"
    >
      Billed monthly
    </button>

    <VuiToggle v-model="annual" />

    <button
      class="reedsy-accented"
      type="button"
      @click="annual = true"
    >
      Annually
      <AnnualSaving />
    </button>
  </rbe-period-toggle>
</template>

<script lang="ts">
import {Component, Model} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import AnnualSaving from '@reedsy/studio.shared/components/modals/components/subscription/annual-saving.vue';

@Component({
  components: {
    AnnualSaving,
  },
})
export default class PeriodToggle extends ClientSharedVue {
  @Model
  public annual: boolean;
}
</script>

<style lang="scss" scoped>
rbe-period-toggle {
  display: flex;
  align-content: center;
  gap: $space-sm;

  button {
    @include font-family($controls);

    display: flex;
    gap: $space-xs;
  }
}
</style>
