import WebSocket from '@reedsy/reconnecting-websocket';
import {Connection} from 'sharedb/lib/client';
import {WebSocketCode} from '@reedsy/studio.isomorphic/models/websocket-code';
import WebSocketAuthenticationError from '@reedsy/studio.shared/errors/websocket-authentication-error';
import {injectable} from 'inversify';
import {LoggerFactory} from '@reedsy/reedsy-logger-js';
import {Logger} from '@reedsy/reedsy-logger-js';
import AbstractShareDbConnection from '@reedsy/studio.shared/services/sharedb/abstract-sharedb-connection';
import {ErrorEvent, CloseEvent} from '@reedsy/reconnecting-websocket';
import {MINUTES, SECONDS} from '@reedsy/utils.date';
import {DebugEvent} from '@reedsy/studio.shared/utils/debug/debug-event';
import {WebsocketConnectionSource} from '@reedsy/studio.isomorphic/models/websocket-connection-source';
import {getConnectionQueryString} from './get-connection-query-string';
import {$inject} from '@reedsy/studio.shared/types';
import {IWebSocketFactory} from '@reedsy/studio.shared/services/ws/i-websocket-factory';

const PING_INTERVAL = 30 * SECONDS;
const PONG_TIMEOUT = 5 * MINUTES;

const CLOSING_INTERVAL = 30 * SECONDS;
const CLOSING_TIMEOUT = 5 * MINUTES;

@injectable()
export default abstract class WebSocketShareDbConnection<TInitData>
  extends AbstractShareDbConnection<TInitData> {
  protected abstract socketUrl: string;
  protected abstract source: WebsocketConnectionSource;
  protected abstract connectionId: string;

  @$inject('WebSocketFactory')
  public readonly socketFactory: IWebSocketFactory;

  protected readonly logger: Logger;
  protected socket: WebSocket;
  protected initData: TInitData;

  private lastPing = Date.now();
  private lastPong: number;
  private closingSince: number;
  private closingInterval: number;

  private offlineListener: () => void;
  private onlineListener: () => void;
  private visibilitychangeListener: () => void;

  private socketOpenListener: () => void;
  private socketCloseListener: (event: CloseEvent) => void;
  private socketErrorListener: (event: ErrorEvent) => void;
  private pingPongIntervalId: number;

  public constructor(
    loggerFactory: LoggerFactory,
  ) {
    super();
    this.logger = loggerFactory.create('ShareDbConnectionService');

    this.offlineListener = () => this.handleOnLine(false);
    this.onlineListener = () => this.handleOnLine(true);

    this.socketOpenListener = () => this.onSocketOpen();
    this.socketCloseListener = (event: CloseEvent) => this.onSocketClose(event);
    this.socketErrorListener = (event: ErrorEvent) => this.onSocketError(event);
  }

  protected get socketUrlQuery(): string {
    return getConnectionQueryString({
      source: this.source,
      connectionId: this.connectionId,
    });
  }

  public init(initData?: TInitData): void {
    if (this.connection) return;
    this.initData = initData;

    this.socket = this.socketFactory.connect(this.socketUrl, [], {
      // We don't want to buffer messages when the socket isn't connected.
      // ShareDB already handles dropped messages due to closed sockets, but
      // doesn't act well when messages are carried over from one socket to
      // the next.
      maxEnqueuedMessages: 0,
    });
    this.connection = new Connection(this.socket);
    this.connection.id = this.connectionId;

    const onopen = this.socket.onopen;
    this.socket.onopen = (event) => {
      const shareDbState = this.connection.state;
      if (shareDbState === 'connected') {
        this.logger.warn('ShareDB about to have state transition error', {data: this.logInfo()});
      }
      onopen(event);
    };

    this.connection.on('receive', this.emit.bind(this, 'receive'));

    this.connection.on('send', (message) => {
      DebugEvent.log('ShareDB Connection', 'send', message);
    });

    this.connection.on('receive', (message) => {
      DebugEvent.log('ShareDB Connection', 'receive', message);
    });

    this.connection.on('state', (state) => {
      DebugEvent.log('ShareDB Connection', 'state', state);
    });

    this.socket.addEventListener('open', this.socketOpenListener);
    this.socket.addEventListener('close', this.socketCloseListener);
    this.socket.addEventListener('error', this.socketErrorListener);

    this.pingPongIntervalId = setInterval(this.ping.bind(this), PING_INTERVAL);
    this.connection.on('pong', () => this.lastPong = Date.now());

    window.addEventListener('offline', this.offlineListener);
    window.addEventListener('online', this.onlineListener);
  }

  public override doneInitialLoad(): void {
    super.doneInitialLoad();
    if (this.visibilitychangeListener) return;
    this.visibilitychangeListener = () => this.handleVisibilityChange();
    document.addEventListener('visibilitychange', this.visibilitychangeListener);
  }

  protected _closeConnection(): void {
    clearInterval(this.pingPongIntervalId);
    clearInterval(this.closingInterval);
    document.removeEventListener('visibilitychange', this.visibilitychangeListener);
    window.removeEventListener('offline', this.offlineListener);
    window.removeEventListener('online', this.onlineListener);

    this.socket.removeEventListener('open', this.socketOpenListener);
    this.socket.removeEventListener('close', this.socketCloseListener);
    this.socket.removeEventListener('error', this.socketErrorListener);
    this.connection.close();

    this.pingPongIntervalId = undefined;
    this.connection = undefined;
    this.socket = undefined;
    this.initData = undefined;
    this.visibilitychangeListener = undefined;
  }

  protected onSocketOpen(): void {
    DebugEvent.log('WebSocketShareDbConnection', 'onSocketOpen', this.logInfo());
    this.lastPong = Date.now();
    this.closingInterval = setInterval(this.checkClosing.bind(this), CLOSING_INTERVAL);
    this.setSocketConnected(true);
  }

  protected onSocketClose(event?: CloseEvent): void {
    DebugEvent.log('WebSocketShareDbConnection', 'onSocketClose', {...this.logInfo(), event});
    this.setSocketConnected(false);
    this.lastPong = null;
    clearInterval(this.closingInterval);
    if (this.incompatibleApi) this.close(WebSocketCode.Normal, 'incompatible API version');
    if (event?.code !== WebSocketCode.Unauthorized) return;
    this.logger.debug(
      new WebSocketAuthenticationError(),
      {
        data: {
          socketUrl: this.socketUrl,
          initData: this.initData,
        },
      },
    );
  }

  protected onSocketError({error, message}: ErrorEvent): void {
    DebugEvent.log('WebSocketShareDbConnection', 'error', {...this.logInfo(), error, message});
    this.logger.debug('Socket error', {error, data: {message}});
  }

  private ping(): void {
    // Check if the page was "sleeping". If we haven't tried pinging in a while,
    // our event loop was paused for some reason, and we've only just been "woken up"
    // after some long time. In this case, let's rest the lastPong (if set)
    // to give the browser some time to wake up and re-establish network, etc.
    const pageWasSleeping = Date.now() - this.lastPing > 2 * PING_INTERVAL;
    if (pageWasSleeping && typeof this.lastPong === 'number') this.lastPong = Date.now();
    this.lastPing = Date.now();

    if (this.pongHasTimedOut()) this.pongTimedOut();
    if (this.connection.canSend) this.connection.ping();
  }

  private pongHasTimedOut(): boolean {
    return typeof this.lastPong === 'number' &&
      Date.now() - this.lastPong > PONG_TIMEOUT;
  }

  private pongTimedOut(): void {
    // Alert as an Error, because in theory we shouldn't reach this state.
    // If we haven't received a 'pong', we're offline, and the socket should
    // have emitted a 'close' event and triggered onSocketClose, which clears
    // this timeout.
    this.logger.error('ShareDB server pong timeout', {
      data: {
        socketConnected: this.socketConnected,
        ...this.logInfo(),
      },
    });

    this.onSocketClose();
  }

  private checkClosing(): void {
    if (this.socket.readyState !== WebSocket.CLOSING) return this.closingSince = null;
    if (!this.closingSince) this.closingSince = Date.now();
    const closingDuration = Date.now() - this.closingSince;
    if (closingDuration < CLOSING_TIMEOUT) return;

    this.logger.error('WebSocket stuck in CLOSING state', {
      data: {
        socketConnected: this.socketConnected,
        ...this.logInfo(),
      },
    });

    this.onSocketClose();
  }

  private handleVisibilityChange(): void {
    const method = document.visibilityState === 'visible' ? 'reconnect' : 'close';
    this[method](WebSocketCode.Normal, 'visibilitychange');
  }

  // Browsers don't seem to cleanly close the sockets when losing network,
  // so let's handle this ourselves.
  private handleOnLine(online: boolean): void {
    const method = online ? 'reconnect' : 'close';
    const reason = online ? 'online' : 'offline';
    this[method](WebSocketCode.Normal, reason);
  }

  private reconnect(code: WebSocketCode, reason: string): void {
    DebugEvent.log('WebSocketShareDbConnection', 'reconnect', {...this.logInfo(), code, reason});
    if (typeof this.lastPong === 'number') this.lastPong = Date.now();

    // Sometimes Chrome kills the socket, but fails to emit the 'close' event.
    // This means we get into a state where ShareDB thinks it's still connected,
    // when it isn't. Attempting to reconnect in this state causes ShareDB to
    // throw, so let's check if this event was missed, and - if it was - manually
    // invoke the socket's onclose() method to resync ShareDB's state to the socket.
    if (this.socket.readyState === WebSocket.CLOSED && this.connection.state !== 'disconnected') {
      this.socket.onclose(null);
    }

    this.socket.reconnect(code, reason);
  }

  private close(code: WebSocketCode, reason: string): void {
    DebugEvent.log('WebSocketShareDbConnection', 'close', {...this.logInfo(), code, reason});
    this.socket.close(code, reason);
  }

  private logInfo(): any {
    return {
      navigator: {
        onLine: navigator.onLine,
      },
      shareDb: {
        state: this.connection?.state,
        canSend: this.connection?.canSend,
        lastPing: this.lastPing,
        lastPong: this.lastPong,
        pongHasTimedOut: this.pongHasTimedOut(),
      },
      socket: {
        readyState: this.socket.readyState,
        retryCount: this.socket.retryCount,
        shouldReconnect: (this.socket as any)._shouldReconnect,
        connectLock: (this.socket as any)._connectLock,
        closeCalled: (this.socket as any)._closeCalled,
      },
    };
  }
}
