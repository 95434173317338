<template>
  <rbe-items-box>
    <slot />
  </rbe-items-box>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({})
export default class ItemsBox extends ClientSharedVue {}
</script>

<style lang="scss" scoped>
rbe-items-box {
  border: $border-lg;
  border-radius: $border-radius-lg;

  > :nth-child(n) {
    padding: $space-base;
    border-bottom: $border;
  }

  > :last-child {
    border-bottom: none;
  }

  &.filled {
    background-color: var(--reedsy-neutral-muted);
    border: none;
  }
}
</style>
