import {injectable, named} from 'inversify';
import RouterPlugin from '@reedsy/studio.home.bookshelf/store/plugins/router-plugin';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {InvitationApprovalFlowModule} from '@reedsy/studio.home.bookshelf/store/modules/invitation-approval-flow';
import {IBookshelfRoute} from '@reedsy/studio.home.bookshelf/router/i-bookshelf-route';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';

@injectable()
export class InitInvitationAcceptanceInfoPluginFactory extends RouterPlugin {
  @$inject('StoreModule')
  @named(StoreName.InvitationApprovalFlow)
  public readonly _invitationApprovalFlow: InvitationApprovalFlowModule;

  public override async beforeEach(): Promise<void> {
    this._invitationApprovalFlow.CLEAN();
  }

  public override async afterEach(to: IBookshelfRoute): Promise<void> {
    const {bookId, invitationShortId} = to.params as {bookId: string; invitationShortId: string};
    await this._invitationApprovalFlow.initialiseInvitation({bookId, invitationId: invitationShortId});
  }

  protected override shouldApplyBefore(to?: IBookshelfRoute): boolean {
    return to.name === BookshelfRouteName.InvitationAcceptance;
  }

  protected override shouldApplyAfter(to?: IBookshelfRoute): boolean {
    return to.name === BookshelfRouteName.InvitationAcceptance;
  }
}
