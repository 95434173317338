<template>
  <SubscriptionBaseModal
    :id="id"
    :title="title"
    subtitle="Unlock Studio with powerful add-ons."
    class="subscription-features-modal"
  >
    <template #header-append>
      <PeriodToggle v-model="billAnnually" />
    </template>

    <FeatureSummaries v-model="selectedProducts" />

    <template #footer-prepend>
      <span>
        <AnnualSaving />
        when you purchase multiple add-ons
      </span>
    </template>

    <template #action>
      <!-- TODO: [subscriptions] add pricing -->
      <!-- TODO: [subscriptions] hook up Continue button -->
      <button
        ref="continue"
        class="button reedsy-accented"
        type="button"
        :disabled="!hasSelectedAnything"
        @click="continueToPayment"
      >
        Continue
      </button>
    </template>
  </SubscriptionBaseModal>
</template>

<script lang="ts">
import {Component, mixins, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import SubscriptionBaseModal from '@reedsy/studio.shared/components/modals/components/subscription/subscription-base.vue';
import PeriodToggle from './period-toggle.vue';
import AnnualSaving from '@reedsy/studio.shared/components/modals/components/subscription/annual-saving.vue';
import {SubscriptionProduct} from '@reedsy/utils.subscription';
import {PropType} from 'vue';
import {ISubscriptionFeaturesModalArgs} from './subscription-features-modal-args.interface';
import FeatureSummaries from './feature-summaries.vue';
import {$lazyInject} from '@reedsy/studio.shared/inversify.config';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';
import {objectKeys} from '@reedsy/utils.object';

@Component({
  components: {
    AnnualSaving,
    PeriodToggle,
    SubscriptionBaseModal,
    FeatureSummaries,
  },
})
export default class SubscriptionFeatures extends mixins(ModalMixin, ClientSharedVue) {
  @Prop({type: Object as PropType<ISubscriptionFeaturesModalArgs>, default: {}})
  public context: ISubscriptionFeaturesModalArgs;

  @$lazyInject('SubscriptionModal')
  public $subscriptionModal: ISubscriptionModalService;

  public readonly cancelable = true;
  public billAnnually = false;

  public selectedProducts: Partial<Record<SubscriptionProduct, boolean>> = {};

  public get hasSelectedAnything(): boolean {
    return Object.values(this.selectedProducts).some(Boolean);
  }

  public get title(): string {
    return this.context.isPaywall ? 'Upgrade to access this feature!' : 'Ready to upgrade?';
  }

  public created(): void {
    const products = new Set(this.context.products);
    this.selectedProducts = {};
    for (const product of products) this.selectedProducts[product] = true;
  }

  public continueToPayment(): void {
    const selectedProductKeys = objectKeys(this.selectedProducts)
      .filter((productKey) => this.selectedProducts[productKey]);

    this.$subscriptionModal.openPayment({
      products: new Set(selectedProductKeys),
      billAnnually: this.billAnnually,
    });
  }
}
</script>
