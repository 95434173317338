<template>
  <InfoPanel
    title="Image resolution"
    class="image-resolution-info-panel"
  >
    For the best results, your book cover image should have a dimension ratio of 1:1.6,
    and measure at least 2500px on the longest side.
  </InfoPanel>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import InfoPanel from '@reedsy/studio.home.bookshelf/components/info-panel/info-panel.vue';

@Component({
  components: {
    InfoPanel,
  },
})
export default class ImageResolutionInfoPanel extends BookshelfVue {
}
</script>

<style lang="scss" scoped>
.image-resolution-info-panel {
  margin-top: $space-xl;
}
</style>
