import {IBookshelfBookCollections} from '@reedsy/studio.home.bookshelf/services/i-bookshelf-sharedb';
import {injectable} from 'inversify';
import {wrapDoc} from '@reedsy/reedsy-sharedb/lib/common/wrapped-doc';
import {IUseDocRequest} from './i-use-doc-request';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {BookshelfBookShareDbManager} from '@reedsy/studio.home.bookshelf/services/bookshelf-book-sharedb-manager';
import {ISingleUseBookShareDb} from './i-single-use-book-sharedb';
import {TypedDoc} from '@reedsy/reedsy-sharedb/lib/server/typed-doc.interface';

@injectable()
export class SingleUseBookShareDb implements ISingleUseBookShareDb {
  @$inject('BookshelfBookShareDbManager')
  public bookShareDbManager: BookshelfBookShareDbManager;

  /**
   * Fetches a document with the given ID, then calls the callback with its data. Finally destroys the document. Note
   * that this method moves away from the non-nesting paradigm promoted by async/await by using a callback. This is
   * deliberate. Forcing the caller to execute the function within the closure of this function allows us to
   * automatically destroy the document after processing. Failing to do so can cause a memory leak.
   */
  public async useDoc<C extends IBookshelfBookCollections>({
    collection,
    bookUuid,
    docId,
    callback,
  }: IUseDocRequest<C>): Promise<void> {
    const {shareDb, leaseId} = this.bookShareDbManager.leaseConnection(bookUuid);
    const doc = wrapDoc(shareDb.get(collection, docId)) as TypedDoc<C>;

    await callback(doc)
      .finally(() => doc.destroy())
      .finally(() => this.bookShareDbManager.freeConnectionLease(leaseId));
  }
}
