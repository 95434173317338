import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {Doc} from 'sharedb/lib/client';
import {ICollectionTypeMap} from '@reedsy/reedsy-sharedb/lib/common/collection-types';
import {injectable, named} from 'inversify';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {Store} from 'vuex';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {IBookshelfCollections, IBookshelfShareDb} from '@reedsy/studio.home.bookshelf/services/i-bookshelf-sharedb';
import {baseShareDBModuleFactory} from '@reedsy/studio.shared/store/modules/sharedb/base-sharedb-module';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedModeModule} from '@reedsy/studio.shared/store/modules/mode';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';

/**
 * This store module isn't strictly a store (because it doesn't store any information).
 * Instead, it's a useful abstraction so that other store modules can interact with
 * ShareDB without worrying about the details of getting a ShareDB instance.
 *
 * This module also provides us with a convenient "catch-all" location for updating
 * other store modules when interacting with ShareDB (eg updating the Saves store).
 */
@injectable()
export class ShareDBModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,

    @$inject('BookshelfShareDb')
    shareDb: IBookshelfShareDb,

    @$inject('SubscriptionModal')
    subscriptionModal: ISubscriptionModalService,

    @$inject('StoreModule')
    @named(SharedStoreName.Mode)
    mode: SharedModeModule,
  ) {
    @Module({name: StoreName.ShareDb, store})
    class ShareDB extends baseShareDBModuleFactory(shareDb, subscriptionModal, mode) {
      public get get() {
        return <C extends IBookshelfCollections>(collection: C, id: string): Doc<ICollectionTypeMap[C]> => {
          return shareDb.get(collection, id);
        };
      }
    }

    this.Module = ShareDB;
  }
}

export type ShareDBModule = InstanceType<ShareDBModuleFactory['Module']>;
