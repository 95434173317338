<template>
  <rbe-loading v-if="!currencies">
    <DelayedLoadingIndicator :delay="1_000" />
  </rbe-loading>
  <SubscriptionBaseModal
    v-else
    :id="id"
    :title="`Start your free ${config.stripe.trialDays}-day trial`"
    subtitle="Unlock Studio by test driving our premium add-ons."
    class="subscription-trial-modal"
  >
    <template #left>
      <FeatureSummaries feature-preview />
    </template>

    <template #right>
      TODO: [subscriptions] Add videos
    </template>

    <template #footer-prepend>
      <CurrencySelector
        v-model="selectedCurrency"
        :available-currencies="availableCurrencies"
      />
    </template>

    <template #action>
      <rbe-trial-actions>
        <button
          class="button"
          type="button"
          @click="close"
        >
          Not yet
        </button>

        <LoadingButton
          class="button"
          type="button"
          :loading="loading"
          @click="startTrial"
        >
          Start free trial
        </LoadingButton>
      </rbe-trial-actions>
    </template>
  </SubscriptionBaseModal>
</template>

<script lang="ts">
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedModalsModule} from '@reedsy/studio.shared/store/modules/modals';
import SubscriptionBaseModal from './subscription-base.vue';
import AddOnToggle from './add-on-toggle.vue';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import LoadingButton from '@reedsy/studio.shared/components/loading-button.vue';
import {DisposableFunction, finallyCall} from '@reedsy/studio.shared/utils/finally-call/finally-call';
import {config} from '@reedsy/studio.shared/config';
import {IPriceCurrencyOptionsResponse} from '@reedsy/studio.isomorphic/controllers/api/v1/subscriptions/i-price-currency-options-response';
import DelayedLoadingIndicator from '@reedsy/studio.shared/components/loader/delayed-loading-indicator.vue';
import FeatureSummaries from './features/feature-summaries.vue';
import CurrencySelector from './currency-selector.vue';
import {SupportedCurrency} from '@reedsy/schemas.editor-collections';
import {localCurrency} from '@reedsy/studio.shared/services/stripe/local-currency';
import {getOfferingsCurrencies} from '@reedsy/studio.shared/utils/currency/get-offerings-currencies';

@Component({
  components: {
    AddOnToggle,
    DelayedLoadingIndicator,
    LoadingButton,
    SubscriptionBaseModal,
    FeatureSummaries,
    CurrencySelector,
  },
})
export default class SubscriptionTrial extends mixins(ModalMixin, ClientSharedVue) {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @$lazyInjectStore(SharedStoreName.Modals)
  public $modals: SharedModalsModule;

  public readonly config = config;
  public readonly cancelable = true;

  public loading = false;
  public currencies: IPriceCurrencyOptionsResponse = null;
  public selectedCurrency: SupportedCurrency = localCurrency();

  public get availableCurrencies(): SupportedCurrency[] {
    return Array.from(getOfferingsCurrencies(this.currencies));
  }

  public async created(): Promise<void> {
    this.currencies = await this.$subscription.fetchPrice();
  }

  public async startTrial(): Promise<void> {
    using doneLoading = this.startLoading();
    await this.$subscription.startTrial(this.selectedCurrency);
    doneLoading();
    this.close();
    this.$modals.open('SubscriptionTrialStarted');
  }

  private startLoading(): DisposableFunction<() => void> {
    this.loading = true;
    return finallyCall(() => this.loading = false);
  }
}
</script>

<style lang="scss" scoped>
.subscription-trial-modal {
  rbe-trial-actions {
    display: flex;
    gap: $space-sm;
  }
}
</style>
