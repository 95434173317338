import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tabs = _resolveComponent("Tabs")!

  return (_openBlock(), _createBlock(_component_Tabs, {
    modelValue: _ctx.activeTab,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
    tabs: _ctx.tabs,
    class: "page-selector"
  }, null, 8, ["modelValue", "tabs"]))
}