import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Collaborator = _resolveComponent("Collaborator")!
  const _component_Invitation = _resolveComponent("Invitation")!
  const _component_NoCollaboratorsPlaceholder = _resolveComponent("NoCollaboratorsPlaceholder")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, {
    title: "Manage access",
    class: "manage-access-panel"
  }, {
    default: _withCtx(() => [
      _createElementVNode("rbe-content", _hoisted_1, [
        (_ctx.hasItems)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.collaborators, (collaborator) => {
                return (_openBlock(), _createBlock(_component_Collaborator, {
                  key: collaborator.uuid,
                  "collaborator-uuid": collaborator.uuid,
                  class: "manage-access-item-grid"
                }, null, 8, ["collaborator-uuid"]))
              }), 128)),
              _cache[0] || (_cache[0] = _createTextVNode()),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentBook.invitations, (invitation) => {
                return (_openBlock(), _createBlock(_component_Invitation, {
                  key: invitation.shortId,
                  invitation: invitation,
                  class: "manage-access-item-grid"
                }, null, 8, ["invitation"]))
              }), 128))
            ], 64))
          : (_openBlock(), _createBlock(_component_NoCollaboratorsPlaceholder, { key: 1 }))
      ])
    ]),
    _: 1
  }))
}