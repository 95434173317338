import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/inlined/blurb.svg'


const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconExternal = _resolveComponent("VuiIconExternal")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, { class: "print-on-demand" }, {
    default: _withCtx(() => [
      _createElementVNode("rbe-print-on-demand-content", null, [
        _cache[6] || (_cache[6] = _createElementVNode("h1", null, "\n        Print-on-demand with Blurb\n      ", -1)),
        _cache[7] || (_cache[7] = _createTextVNode()),
        _createElementVNode("rbe-blurb-details", null, [
          _createElementVNode("p", null, [
            _cache[0] || (_cache[0] = _createTextVNode("\n          Looking to print physical copies, or make your book available to stores near you?\n          Reedsy has partnered with Blurb to help you print and distribute your book to readers around the world.\n          Take one step closer to publishing and send your Reedsy PDF to Blurb.\n          ")),
            _createElementVNode("a", {
              href: _ctx.blurbUploaderUrl,
              target: "_blank",
              title: "Learn more",
              class: "link"
            }, "Learn more", 8, _hoisted_1),
            _cache[1] || (_cache[1] = _createTextVNode(".\n        "))
          ]),
          _cache[2] || (_cache[2] = _createTextVNode()),
          _cache[3] || (_cache[3] = _createElementVNode("img", {
            src: _imports_0,
            alt: "blurb logo",
            class: "blurb-logo"
          }, null, -1))
        ]),
        _cache[8] || (_cache[8] = _createTextVNode()),
        _createElementVNode("a", {
          href: _ctx.blurbUploaderUrl,
          class: "go-to-blurb-button button button-with-icon reedsy-accented",
          target: "_blank"
        }, [
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text" }, "Go to Blurb", -1)),
          _cache[5] || (_cache[5] = _createTextVNode()),
          _createVNode(_component_VuiIconExternal)
        ], 8, _hoisted_2)
      ])
    ]),
    _: 1
  }))
}