<template>
  <rbe-welcome-bar>
    <rbe-text-wrapper>
      <h1>Welcome back<span v-if="userName">, {{ userName }}</span></h1>
      <p>This is your bookshelf, where you can write, plan, edit and typeset your books</p>
    </rbe-text-wrapper>
    <rbe-image-wrapper>
      <img
        class="editor-landing-image hidden-until-md"
        src="@/assets/inlined/bookshelf.svg"
      >
    </rbe-image-wrapper>
  </rbe-welcome-bar>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import capitalize from 'capitalize';

@Component({
  components: {
  },
})
export default class WelcomeBar extends BookshelfVue {
  @$lazyInjectStore(SharedStoreName.User)
  public $user: SharedUserModule;

  public get userName(): string {
    return capitalize(this.$user.info.firstName || '');
  }
}
</script>

<style lang="scss" scoped>
rbe-welcome-bar {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 2.5rem;
  color: var(--reedsy-fgColor-light);

  h1 {
    margin-bottom: $space-sm;
  }

  rbe-text-wrapper {
    padding-bottom: $space-lg;
  }

  rbe-image-wrapper {
    display: flex;

    img.editor-landing-image {
      width: 20rem;
    }
  }

}
</style>
