
<template>
  <span class="vui-icon">
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>Icons/Moon</title>
      <g
        id="icon-fill"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <path
          d="M8.52459614,0.0169254361 C12.6983435,0.287096705 16,3.75797676 16,8 C16,12.418278 12.418278,16 8,16 C5.57209007,16 3.3967922,14.9184392 1.92962614,13.2108373 C2.58643908,13.3989428 3.28141796,13.5 4,13.5 C8.14213562,13.5 11.5,10.1421356 11.5,6 C11.5,3.56344963 10.3381092,1.3982734 8.53816096,0.0283046272 L8.52459614,0.0169254361 Z M4,6 L4.25,6.75 L5,7 L4.25,7.25 L4,8 L3.75,7.25 L3,7 L3.75,6.75 L4,6 Z M6.5,2 L7.125,3.875 L9,4.5 L7.125,5.125 L6.5,7 L5.875,5.125 L4,4.5 L5.875,3.875 L6.5,2 Z"
        ></path>
      </g>
    </svg>
  </span>
</template>
