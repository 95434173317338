import IShareDb from '@reedsy/studio.shared/services/sharedb/i-sharedb';
import {Collection, collectionTypes, ICollectionTypeMap} from '@reedsy/reedsy-sharedb/lib/common/collection-types';
import {Doc, Query, Snapshot} from 'sharedb/lib/client';
import {Action, VuexModule} from '@reedsy/vuex-module-decorators';
import {IFetchSnapshot} from './i-fetch-snapshot';
import {IDocAddressSource} from './i-doc-address-source';
import {IDocAddress} from './i-doc-address';
import {ISubscribeQuery} from './i-subscribe-query';
import {IFetchQuery} from './i-fetch-query';
import {ISubmitOp} from './i-submit-op';
import {SharedModeModule} from '@reedsy/studio.shared/store/modules/mode';
import {ConnectionEventMap} from 'sharedb/lib/sharedb';
import {parseSubtypeId} from '@reedsy/studio.isomorphic/utils/subtypes/subtype-id';
import {SubscriptionRequiredError} from '@reedsy/studio.shared/errors/subscription-required-error';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';
import {IMutateDoc} from './i-mutate-doc';
import {IListenConnection, IShareDbModule} from './i-share-db-module';
import {ICreateDoc} from './i-create-doc';
import {wrapDoc} from '@reedsy/reedsy-sharedb/lib/common/wrapped-doc';

// eslint-disable-next-line
export function baseShareDBModuleFactory<T extends Collection>(
  shareDB: IShareDb<T>,
  $subscriptionModal: ISubscriptionModalService,
  $mode: SharedModeModule,
) {
  class BaseShareDBModule extends VuexModule implements IShareDbModule<T> {
    public get getData() {
      return <C extends T>(collection: C, id: string): ICollectionTypeMap[C] => {
        return shareDB.get<C>(collection, id).data;
      };
    }

    public get getPresence(): IShareDb<T>['getPresence'] {
      return shareDB.getPresence.bind(shareDB);
    }

    public get version() {
      return (collection: T, id: string): number => {
        return shareDB.get(collection, id).version;
      };
    }

    @Action
    public async del<C extends T>({collection, id, source}: IDocAddressSource<C>): Promise<void> {
      await shareDB.del(collection, id, source);
    }

    @Action
    public fetchSnapshot<C extends T>(
      {collection, id, timestamp}: IFetchSnapshot<C>,
    ): Promise<Snapshot<ICollectionTypeMap[C]>> {
      return shareDB.fetchSnapshot(collection, id, timestamp);
    }

    @Action
    public async subscribe<C extends T>(
      {collection, id}: IDocAddress<C>,
    ): Promise<Doc<ICollectionTypeMap[C]>> {
      return await handleError(shareDB.subscribe(collection, id));
    }

    @Action
    public async subscribeQuery<C extends T>(
      {collection, query}: ISubscribeQuery<C>,
    ): Promise<Query<ICollectionTypeMap[C]>> {
      return await shareDB.subscribeQuery(collection, query);
    }

    @Action
    public async fetchQuery<C extends T>(
      {collection, query}: IFetchQuery<C>,
    ): Promise<Query<ICollectionTypeMap[C]>> {
      return await shareDB.fetchQuery(collection, query);
    }

    @Action
    public async submitOp<C extends T>({id, op, source, collection}: ISubmitOp<C>): Promise<void> {
      if ($mode.readOnly) {
        throw new Error('Submitted to shareDB in readonly-mode');
      }

      const subtypeId = parseSubtypeId(id);
      if (subtypeId) {
        return this.submitOp({
          collection: subtypeId.collection as any,
          id: subtypeId.id,
          op: [{p: subtypeId.subpath, t: 'rich-text', o: op}],
          source,
        });
      }

      if (!Array.isArray(op)) op = [op];
      op = op.filter(Boolean);
      if (!op.length) return;

      await handleError(shareDB.submitOp(collection, id, op, source));
    }

    @Action
    public async mutate<C extends T>({id, mutation, source, collection}: IMutateDoc<C>): Promise<void> {
      if ($mode.readOnly) {
        throw new Error('Mutating ShareDB Doc in readonly-mode');
      }

      await handleError(shareDB.mutate(collection, id, mutation, source));
    }

    @Action
    public async create<C extends T>({
      id, source, collection, data,
    }: ICreateDoc<C>): Promise<void> {
      if ($mode.readOnly) {
        throw new Error('Mutating ShareDB Doc in readonly-mode');
      }

      await using doc = wrapDoc(shareDB.get(collection, id));
      await handleError(doc.create(data, collectionTypes[collection], {source}));
    }

    @Action
    public async destroy<C extends T>({collection, id}: IDocAddress<C>): Promise<void> {
      await shareDB.destroy(collection, id);
    }

    @Action
    public on<
      E extends keyof ConnectionEventMap,
      F extends ConnectionEventMap[E],
    >({event, handler}: IListenConnection<E, F>): void {
      shareDB.connection.addListener(event, handler);
    }
  }

  return BaseShareDBModule;

  async function handleError<T>(promise: Promise<T>): Promise<T> {
    try {
      return await promise;
    } catch (error) {
      if (error instanceof SubscriptionRequiredError) {
        $subscriptionModal.open();
      } else {
        throw error;
      }
    }
  }
}

export type BaseShareDBModule<T extends Collection> = ReturnType<typeof baseShareDBModuleFactory<T>>;
