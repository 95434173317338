<template>
  <rbe-book-creation-buttons>
    <button
      class="button create-book-button flat"
      @click="openNewBookModal"
    >
      <span class="button button-lg button-icon reedsy-accented accent-primary">
        <VuiIconPlus class="icon-lg" />
      </span>
      <span class="text">
        Create book
      </span>
    </button>
    <hr>
    <button
      class="button import-book-button"
      @click="openImportBookModal"
    >
      <span class="button-lg button-icon reedsy-accented inverted">
        <VuiIconCloud class="icon-lg" />
      </span>
      <span class="text">
        Import book
      </span>
    </button>
  </rbe-book-creation-buttons>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';

@Component({})
export default class BookCreationButtons extends BookshelfVue {
  @$lazyInjectStore(StoreName.Modals)
  public $modals: BookshelfModalsModule;

  public async openNewBookModal(): Promise<void> {
    await this.$modals.open('CreateBook');
  }

  public async openImportBookModal(): Promise<void> {
    await this.$modals.open({
      type: 'ImportBook',
      context: {},
    });
  }
}
</script>

<style lang="scss" scoped>
$space-between-line-and-button: $space-lg;

rbe-book-creation-buttons {
  min-width: $sm-cover-width;
  height: auto;
  aspect-ratio: $cover-aspect-ratio;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: $border-radius-lg;
  background-color: var(--reedsy-neutral);

  hr {
    $lg-button-size: $vui-6em;

    width: $lg-button-size; // The line width should be equal the width of the button-icon above
    height: $border-width;
    background-color: var(--reedsy-neutral-highContrast);
  }

  button {
    display: flex;
    flex-direction: column;
    background-color: transparent;

    @include transition(transform);

    &:first-child {
      padding-bottom: 0;
      padding-top: $space-between-line-and-button;
    }

    &:last-child {
      padding-bottom: $space-between-line-and-button;
      padding-top: 0;
    }

    &:active {
      background-color: transparent;
    }

    span.text {
      @include font-family($controls);

      margin-top: $space-md;
    }
  }
}
</style>
