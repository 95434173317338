export default function parseDates(obj: any): any {
  if (typeof obj !== 'object') return obj;

  for (const key in obj) {
    if (typeof obj[key] === 'string') obj[key] = tryParseDate(obj[key]);
    if (obj[key] != null && typeof obj[key] === 'object') parseDates(obj[key]);
  }

  return obj;
}

function tryParseDate(date: string): string | Date {
  const parsed = new Date(date);
  if (isNaN(parsed.getTime()) || parsed.toISOString() !== date) return date;
  return parsed;
}
