import {injectable, named} from 'inversify';
import RouterPlugin from '@reedsy/studio.home.bookshelf/store/plugins/router-plugin';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {UserSettingsModule} from '@reedsy/studio.home.bookshelf/store/modules/user-settings';

@injectable()
export default class LoadUserSettingsPluginFactory extends RouterPlugin {
  @$inject('StoreModule')
  @named(StoreName.UserSettings)
  public readonly _userSettings: UserSettingsModule;

  public override async beforeEach(): Promise<void> {
    await this._userSettings.initialise();
  }
}
