import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/inlined/logo/reedsy-gray.svg'


const _hoisted_1 = { class: "hidden-until-sm" }
const _hoisted_2 = { class: "vui-wrapper" }
const _hoisted_3 = { "data-test-id": "copy-date" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("rbe-footer-content", _hoisted_2, [
      _createElementVNode("rbe-left-side", null, [
        _createVNode(_component_RouterLink, {
          ref: "logoLink",
          to: _ctx.home,
          title: "Reedsy",
          class: "logo-link"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("img", {
              src: _imports_0,
              class: "logo"
            }, null, -1)
          ])),
          _: 1
        }, 8, ["to"]),
        _cache[2] || (_cache[2] = _createTextVNode()),
        _createElementVNode("span", null, [
          _cache[1] || (_cache[1] = _createTextVNode("© ")),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.currentYear), 1)
        ])
      ]),
      _cache[6] || (_cache[6] = _createTextVNode()),
      _createElementVNode("rbe-right-side", null, [
        _createElementVNode("a", {
          href: _ctx.navigation.termsOfUseUrl,
          target: "_blank"
        }, "Terms", 8, _hoisted_4),
        _cache[3] || (_cache[3] = _createTextVNode()),
        _cache[4] || (_cache[4] = _createElementVNode("p", { class: "separator" }, "\n          •\n        ", -1)),
        _cache[5] || (_cache[5] = _createTextVNode()),
        _createElementVNode("a", {
          href: _ctx.navigation.privacyUrl,
          target: "_blank"
        }, "Privacy", 8, _hoisted_5)
      ])
    ])
  ]))
}