import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_VuiIconCloud = _resolveComponent("VuiIconCloud")!
  const _component_VuiIconPlus = _resolveComponent("VuiIconPlus")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, null, {
    default: _withCtx(() => [
      _createElementVNode("rbe-no-books-placeholder-wrapper", null, [
        _createElementVNode("img", {
          class: "no-books-image",
          src: _ctx.pageData.imageSrc
        }, null, 8, _hoisted_1),
        _cache[9] || (_cache[9] = _createTextVNode()),
        _createElementVNode("rbe-not-found-details", null, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.pageData.title), 1),
          _cache[2] || (_cache[2] = _createTextVNode()),
          _createElementVNode("p", null, _toDisplayString(_ctx.pageData.description), 1)
        ]),
        _cache[10] || (_cache[10] = _createTextVNode()),
        (!_ctx.isFiltered && _ctx.showOnlyArchived)
          ? (_openBlock(), _createElementBlock("rbe-action-buttons", _hoisted_2, [
              _createVNode(_component_router_link, {
                ref: "showAllBooksButton",
                class: "button reedsy-accented show-all-books-button",
                to: _ctx.booksListLocation
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("\n          Return to bookshelf\n        ")
                ])),
                _: 1
              }, 8, ["to"])
            ]))
          : (!_ctx.isFiltered)
            ? (_openBlock(), _createElementBlock("rbe-action-buttons", _hoisted_3, [
                _createElementVNode("button", {
                  class: "button import-book-button reedsy-accented neutral",
                  type: "button",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openImportBookModal && _ctx.openImportBookModal(...args)))
                }, [
                  _cache[4] || (_cache[4] = _createElementVNode("span", { class: "button-inner-text" }, "Import book", -1)),
                  _cache[5] || (_cache[5] = _createTextVNode()),
                  _createVNode(_component_VuiIconCloud)
                ]),
                _cache[8] || (_cache[8] = _createTextVNode()),
                _createElementVNode("button", {
                  class: "button create-book-button reedsy-accented",
                  type: "button",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openCreateBookModal && _ctx.openCreateBookModal(...args)))
                }, [
                  _cache[6] || (_cache[6] = _createElementVNode("span", { class: "button-inner-text" }, "Create book", -1)),
                  _cache[7] || (_cache[7] = _createTextVNode()),
                  _createVNode(_component_VuiIconPlus)
                ])
              ]))
            : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}