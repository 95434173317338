import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {AVuiFileUpload} from '@reedsy/vui';

@Component({
  mixins: [AVuiFileUpload],
})
export class VuiFileUpload extends ClientSharedVue {
  public isUploading: boolean;
  public isDropping: boolean;
  public acceptedFileTypes: string;
  public uploadProgress: number | null;
  public pickFile: (event: MouseEvent) => void;
}
