<template>
  <div class="not-found-view">
    <div class="vui-wrapper-sm text-center">
      <h1 class="four-oh-four">
        404
      </h1>
      <h1>We couldn’t find the page you were looking for</h1>
      <p class="large space-top-2em">
        <slot name="home-link" />
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';

@Component({})
export default class NotFoundPartial extends ClientSharedVue {}
</script>

<style lang="scss" scoped>
.not-found-view {
  align-items: center;
  background: url('@/assets/404.png') bottom center no-repeat;
  background-size: 160% auto;
  display: flex;
  height: safe-vh(100);
  padding: 0 2rem;

  @include screen-more-than(md) {
    background-size: 100% auto;
    max-width: $screen-lg-min;
    margin: 0 auto;
  }

  .vui-wrapper-sm {
    @include screen-more-than(md) {
      margin-bottom: 3rem;
    }
  }

  .four-oh-four {
    color: var(--reedsy-form-info-textColor);
    margin-bottom: 1rem;
    font-weight: 400;
    font-size: 4rem;
  }
}
</style>
