import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NoBookCoverPlaceholder = _resolveComponent("NoBookCoverPlaceholder")!

  return (_openBlock(), _createElementBlock("rbe-book-cover", {
    style: _normalizeStyle(_ctx.coverStyles)
  }, [
    (!_ctx.coverUrl)
      ? (_openBlock(), _createBlock(_component_NoBookCoverPlaceholder, {
          key: 0,
          title: _ctx.book.title,
          author: _ctx.author,
          class: _normalizeClass({'small-font': _ctx.smallFont})
        }, null, 8, ["title", "author", "class"]))
      : _createCommentVNode("", true),
    _cache[0] || (_cache[0] = _createTextVNode()),
    _createElementVNode("rbe-book-cover-bottom", null, [
      _renderSlot(_ctx.$slots, "bottom", {}, undefined, true)
    ])
  ], 4))
}