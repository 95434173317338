<template>
  <Avatar
    :user-info="userInfo"
  />
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';
import Avatar from '@reedsy/studio.shared/components/avatar/avatar.vue';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfCollaboratorsModule} from '@reedsy/studio.home.bookshelf/store/modules/collaborators';

@Component({
  components: {
    Avatar,
  },
})
export default class AutoLoadAvatar extends BookshelfVue {
  @$lazyInjectStore(StoreName.Collaborators)
  public $collaborators: BookshelfCollaboratorsModule;

  @Prop({type: String, required: true})
  public bookUuid: string;

  @Prop({type: String, required: true})
  public userUuid: string;

  public get userInfo(): IUserInfo {
    return this.$collaborators.userInfoByUuid[this.userUuid];
  }

  public async mounted(): Promise<void> {
    await this.$collaborators.loadContributor({
      bookUuid: this.bookUuid,
      contributorUuid: this.userUuid,
    });
  }
}
</script>
