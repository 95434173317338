import IApi from '@reedsy/studio.shared/services/api/i-api';
import {$inject} from '@reedsy/studio.shared/types';
import {IDisplayedCollaborator} from '@reedsy/studio.isomorphic/controllers/api/v1/users/displayed-collaborator';
import {injectable} from 'inversify';
import {IUsersLoader} from './i-user-loader';

@injectable()
export class UsersLoader implements IUsersLoader {
  @$inject('Api')
  public readonly _api: IApi;

  private userLoadingPromise: {[userUuid: string]: Promise<IDisplayedCollaborator>} = {};

  public async loadUser(
    {bookUuid, contributorUuid}: {bookUuid: string; contributorUuid: string},
  ): Promise<IDisplayedCollaborator> {
    try {
      if (!this.userLoadingPromise[contributorUuid]) {
        this.userLoadingPromise[contributorUuid] = this._api.getUserInfo(bookUuid, contributorUuid);
      }
      return await this.userLoadingPromise[contributorUuid];
    } finally {
      delete this.userLoadingPromise[contributorUuid];
    }
  }
}
