import {injectable, named} from 'inversify';
import RouterPlugin from '@reedsy/studio.home.bookshelf/store/plugins/router-plugin';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {ICrispManager} from '@reedsy/studio.home.bookshelf/utils/crisp/i-crisp-manager';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';

@injectable()
export default class InitialiseCrispPluginFactory extends RouterPlugin {
  @$inject('StoreModule')
  @named(SharedStoreName.User)
  public readonly _user: SharedUserModule;

  @$inject('CrispManager')
  public readonly _crispManager: ICrispManager;

  public override async afterEach(): Promise<void> {
    if (this._crispManager.isInitialised) return;
    this._crispManager.initialise();
    this._crispManager.setUser(this._user.info);
  }
}
