import Fuse, {IFuseOptions} from 'fuse.js';

export function setUpFuseSearch<T extends object>(options: IFuseOptions<T>) {
  return function fuseSearchIn(list: T[], searchValue: string): T[] {
    const fuseSearch = new Fuse(
      list,
      {
        isCaseSensitive: false,
        findAllMatches: true,
        shouldSort: true,
        // The threshold and distance are decreased to decrease the number of results
        // and to make the search more precise. The numbers are chosen arbitrarily
        threshold: 0.4,
        distance: 25,
        ...options,
      },
    );

    const searchResult = fuseSearch.search(searchValue);
    return searchResult.map((searchResult) => searchResult.item);
  };
}
