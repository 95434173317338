import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedModalsModule} from '@reedsy/studio.shared/store/modules/modals';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';

@Component({})
export default class ModalMixin extends ClientSharedVue {
  @$lazyInjectStore(SharedStoreName.Modals)
  public _sharedModals: SharedModalsModule;

  @Prop({type: String, required: true})
  public id: string;

  public close(): void {
    this._sharedModals.CLOSE(this.id);
  }
}
