export default function mergeAttributes(element: HTMLElement, attributes: any): void {
  if (attributes.style) {
    if (typeof attributes.style === 'string') {
      const existing = element.getAttribute('style');
      element.setAttribute('style', `${existing} ${attributes.style}`);
    } else {
      Object.keys(attributes.style).forEach((property: any) => element.style[property] = attributes.style[property]);
    }
    delete attributes.style;
  }

  if (attributes.class) {
    if (typeof attributes.class === 'string') {
      const existing = element.getAttribute('class');
      element.setAttribute('class', `${existing} ${attributes.class}`);
    } else {
      Object.keys(attributes.class)
        .forEach((className: string) => element.classList.toggle(className, attributes.class[className]));
    }

    delete attributes.class;
  }
}
