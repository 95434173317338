
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import LayoutContent from './layout-content.vue';
import TopBar from '@reedsy/studio.home.bookshelf/components/top-bar/default-top-bar/top-bar.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import ExpandTransition from '@reedsy/studio.shared/components/transitions/expand-transition.vue';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfLayoutModule} from '@reedsy/studio.home.bookshelf/store/modules/layout';
import MobileMenu from '@reedsy/studio.home.bookshelf/components/mobile-menu/mobile-menu.vue';
import LayoutFooter from './layout-footer.vue';

@Component({
  components: {
    LayoutContent,
    TopBar,
    ExpandTransition,
    MobileMenu,
    LayoutFooter,
  },
})
export default class MainLayout extends BookshelfVue {
  @$lazyInjectStore(StoreName.Layout)
  public $layout: BookshelfLayoutModule;
}
