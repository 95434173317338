import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex-justified" }
const _hoisted_2 = { class: "flex-center" }
const _hoisted_3 = {
  key: 0,
  class: "flex-top"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiToggle = _resolveComponent("VuiToggle")!
  const _component_ExpandTransition = _resolveComponent("ExpandTransition")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["feature-summary reedsy-accented", {active: _ctx.isProduct && _ctx.selected}])
  }, [
    _createElementVNode("rbe-feature-header", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.summary.title), 1),
      _cache[3] || (_cache[3] = _createTextVNode()),
      _createElementVNode("rbe-feature-toggle", _hoisted_2, [
        _createVNode(_component_VuiToggle, {
          modelValue: _ctx.selected,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
          class: "label-left font-weight-bold",
          label: _ctx.selected ? 'Add-on enabled' : 'Enable add-on'
        }, null, 8, ["modelValue", "label"]),
        _cache[2] || (_cache[2] = _createTextVNode()),
        _createElementVNode("button", {
          type: "button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.expanded = !_ctx.expanded))
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.expanded ? 'VuiIconUp' : 'VuiIconDown')))
        ])
      ])
    ]),
    _cache[4] || (_cache[4] = _createTextVNode()),
    _createVNode(_component_ExpandTransition, null, {
      default: _withCtx(() => [
        (_ctx.expanded)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summary.features, (feature) => {
                return (_openBlock(), _createElementBlock("li", { key: feature }, _toDisplayString(feature), 1))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 2))
}