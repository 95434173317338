import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContextMenuButton = _resolveComponent("ContextMenuButton")!
  const _component_VDropdown = _resolveComponent("VDropdown")!
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createBlock(_component_VDropdown, {
    "popper-class": "v-popper--no-arrow v-popper--no-padding",
    placement: "bottom-end"
  }, {
    popper: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("rbe-context-menu", {
        class: _normalizeClass(["v-popper__context-menu", {
          'condensed': _ctx.condensed
        }])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2)), [
        [_directive_close_popper]
      ])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "trigger", {}, () => [
        _createVNode(_component_ContextMenuButton)
      ]),
      _cache[0] || (_cache[0] = _createTextVNode())
    ]),
    _: 3
  }))
}