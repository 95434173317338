
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {INetworkDetails, SocialNetwork, SUPPORTED_NETWORKS} from './supported-networks';

@Component({
  components: {
  },
})
export default class ShareButton extends BookshelfVue {
  @Prop({type: String, required: true})
  public network: SocialNetwork;

  @Prop({type: String, default: ''})
  public url: string;

  public get networkClass(): string {
    return `button-${this.network}`;
  }

  public get networkDetails(): INetworkDetails {
    return SUPPORTED_NETWORKS[this.network];
  }

  public get networkIcon(): string {
    return this.networkDetails.icon;
  }

  public get networkName(): string {
    return this.networkDetails.name;
  }
}
