import {TimeOfDay, ZonelessDate} from '@reedsy/utils.date';

export function humanReadableTime(timeOfDay: TimeOfDay): string {
  const timeZone = 'UTC';
  return timeOfDay.toDate(new ZonelessDate(1970, 0, 0), timeZone)
    .toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZone,
    });
}
