
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import ContextMenuButton from './context-menu-button.vue';

@Component({
  components: {
    ContextMenuButton,
  },
})
export default class ContextMenu extends ClientSharedVue {
  @Prop({type: Boolean, default: false})
  public condensed: boolean;
}
