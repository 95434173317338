import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "export-type" }
const _hoisted_2 = { class: "export-creation-date" }
const _hoisted_3 = { class: "visible-until-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExportTypeIndicator = _resolveComponent("ExportTypeIndicator")!
  const _component_ExportAction = _resolveComponent("ExportAction")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.wrapperComponent), {
    href: _ctx.downloadExportUrl,
    class: _normalizeClass(["exports-list-entry", {
      loading: _ctx.isPending,
      error: _ctx.isFailed
    }])
  }, {
    default: _withCtx(() => [
      _createElementVNode("rbe-row", null, [
        _createVNode(_component_ExportTypeIndicator, { "book-export": _ctx.bookExport }, null, 8, ["book-export"]),
        _cache[3] || (_cache[3] = _createTextVNode()),
        _createElementVNode("rbe-right-column", null, [
          _createElementVNode("rbe-titles-wrapper", null, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
            _cache[1] || (_cache[1] = _createTextVNode()),
            _createElementVNode("p", null, [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.exportTypeDisplayValue), 1),
              _cache[0] || (_cache[0] = _createTextVNode("\n            •\n            ")),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.createdAt), 1)
            ])
          ]),
          _cache[2] || (_cache[2] = _createTextVNode()),
          _createVNode(_component_ExportAction, {
            "book-export": _ctx.bookExport,
            class: "hidden-until-md"
          }, null, 8, ["book-export"])
        ])
      ]),
      _cache[4] || (_cache[4] = _createTextVNode()),
      _createElementVNode("rbe-row", _hoisted_3, [
        _createVNode(_component_ExportAction, { "book-export": _ctx.bookExport }, null, 8, ["book-export"])
      ])
    ]),
    _: 1
  }, 8, ["href", "class"])), [
    [_directive_tooltip, _ctx.isFailed && _ctx.tooltipOptions]
  ])
}