<template>
  <rbe-account-settings-menu>
    <RouterLink
      v-for="item in items"
      :key="item.routeName"
      ref="itemLink"
      :to="{
        name: item.routeName
      }"
      class="setting-item"
      :data-test-route-name="item.routeName"
      @click="emitOpenDetails"
    >
      <span>{{ item.title }}</span>
    </RouterLink>
  </rbe-account-settings-menu>
</template>

<script lang="ts">
import {Component, Emit} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import MainLayout from '@reedsy/studio.home.bookshelf/components/layouts/main-layout.vue';
import {ACCOUNT_SETTINGS_ROUTE_NAME_MAPPING} from '@reedsy/studio.home.bookshelf/utils/account-settings-route-name-mapping';
import {objectKeys} from '@reedsy/utils.object';

@Component({
  components: {
    MainLayout,
    AccountSettingsMenu,
  },
})
export default class AccountSettingsMenu extends BookshelfVue {
  public readonly settingsRouteNames = objectKeys(ACCOUNT_SETTINGS_ROUTE_NAME_MAPPING);

  public get items(): any[] {
    return this.settingsRouteNames.map((routeName) => {
      return {
        routeName,
        title: ACCOUNT_SETTINGS_ROUTE_NAME_MAPPING[routeName],
      };
    });
  }

  @Emit('open-details')
  public emitOpenDetails(): void {
    return;
  }
}
</script>

<style lang="scss" scoped>
.setting-item {
  position: relative;
  display: flex;
  padding: $space-sm $bookshelf-desktop-overflow;
  border-bottom: $border;

  @include transition(background-color);

  &::before {
    position: absolute;
    content: '';
    height: 100%;
    left: 0;
    top: 0;
    width: 0.25rem;
    opacity: 0;

    @include transition(opacity);
  }

  &:hover {
    background-color: var(--reedsy-neutral-muted);

    &::before {
      background-color: var(--reedsy-neutral);
      opacity: 1;
    }
  }

  &.router-link-exact-active {
    background-color: var(--local-color-muted);

    &::before {
      opacity: 1;
      background-color: var(--local-color-default);
    }
  }
}
</style>
