import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthAccountSettingsBanner = _resolveComponent("AuthAccountSettingsBanner")!
  const _component_UserNotificationsSettings = _resolveComponent("UserNotificationsSettings")!

  return (_openBlock(), _createElementBlock("rbe-general-account-settings", _hoisted_1, [
    _createVNode(_component_AuthAccountSettingsBanner),
    _cache[0] || (_cache[0] = _createTextVNode()),
    _createVNode(_component_UserNotificationsSettings)
  ]))
}