<template>
  <rbe-book-top-info-grid>
    <rbe-book-top-info-row>
      <rbe-book-cover-wrapper class="hidden-until-md">
        <slot name="book-cover" />
      </rbe-book-cover-wrapper>
      <rbe-book-info-right-side>
        <rbe-book-titles>
          <slot name="book-titles" />
        </rbe-book-titles>
        <rbe-action class="visible-until-sm ">
          <slot name="action" />
        </rbe-action>
      </rbe-book-info-right-side>
    </rbe-book-top-info-row>
  </rbe-book-top-info-grid>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';

@Component({})
export default class BookTopInfoGrid extends BookshelfVue {}
</script>

<style lang="scss" scoped>
rbe-book-top-info-grid {
  padding-bottom: $space-xxl;

  rbe-book-top-info-row {
    padding-top: 2.5rem;
    display: flex;

    rbe-book-cover-wrapper {
      position: relative;
    }

    rbe-book-info-right-side {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;

      @include screen-more-than(sm) {
        padding-top: $space-xxl;
        padding-left: $space-xxl;
        max-width: calc(100% - $lg-cover-width - 2 * $space-md);
      }

      rbe-book-titles {
        display: flex;
        flex-direction: column;
      }

      rbe-book-subtitle-text {
        padding-top: $space-md;

        @include font-family($controls, normal);
      }

      rbe-action {
        margin-top: $space-md;
        width: 100%;
      }
    }

  }
}
</style>
