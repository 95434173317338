
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import ConfirmModal from '@reedsy/studio.shared/components/modals/templates/confirm-modal.vue';

@Component({
  components: {
    ConfirmModal,
  },
})
export default class OfflineModal extends mixins(ModalMixin, ClientSharedVue) {
  public readonly cancelable = false;
}
