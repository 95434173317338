import debounce from '@reedsy/studio.shared/utils/debounce';
import {injectable, named} from 'inversify';
import {IStorePluginFactory} from '@reedsy/studio.shared/store/plugins/i-store-plugin-factory';
import {$inject} from '@reedsy/studio.shared/types';
import {Store} from 'vuex';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedBrowserModule} from '@reedsy/studio.shared/store/modules/browser';

@injectable()
export default class ScreenSizePluginFactory implements IStorePluginFactory {
  @$inject('StoreModule')
  @named(SharedStoreName.Browser)
  public readonly _Browser: SharedBrowserModule;

  @$inject('Store')
  public readonly _store: Store<any>;

  public plugin(): void {
    const setScreenWidth = (): void => {
      this._Browser.setScreenWidth(window.innerWidth);
    };

    const setOneVh = (): void => {
      document.documentElement.style.setProperty('--one-vh', `${document.documentElement.clientHeight / 100}px`);
    };

    // Run once on store initialisation to set initial size
    const unsubscribe = this._store.subscribe(() => {
      setScreenWidth();
      setOneVh();
      unsubscribe();
    });

    window.addEventListener('resize', debounce(() => {
      setScreenWidth();
    }));

    const vhObserver = new ResizeObserver(() => {
      window.requestAnimationFrame(() => setOneVh());
    });
    vhObserver.observe(document.body);
  }
}
