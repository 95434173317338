import {SharedBindings} from '@reedsy/studio.shared/types';
import {DependencyCycleDetector} from '@reedsy/studio.shared/utils/dependency-cycle-detector';
import {TypedContainer} from '@reedsy/utils.inversify';
import {Constructor} from '@reedsy/utils.types';
import {Store} from 'vuex';
import {getModule, VuexModule} from '@reedsy/vuex-module-decorators';
import {IModuleFactory} from './modules/i-module-factory';
import {config} from '@reedsy/studio.shared/config';

const MODULE_CACHE: WeakMap<Store<any>, Record<PropertyKey, VuexModule>> = new WeakMap();
const DEBUG_STACK = new DependencyCycleDetector();

export function bindModules<T extends SharedBindings>(
  container: TypedContainer<T>,
  mapping: Record<string, Constructor<IModuleFactory>>,
): void {
  for (const key in mapping) {
    const storeName = key as keyof typeof mapping;
    bindModule(container, storeName, mapping[storeName]);
  }
}

export function bindModule<T extends SharedBindings>(
  container: TypedContainer<T>,
  storeName: string,
  ModuleFactory: Constructor<IModuleFactory>,
): void {
  // Bind the factory so dependencies are resolved by the framework
  container.bind('StoreModuleFactory')
    .to(ModuleFactory)
    .whenTargetNamed(storeName);

  container.bind('StoreModule')
    .toDynamicValue(({container}) => {
      DEBUG_STACK.push(storeName.toString());
      try {
        return getModuleFromCache(container, storeName);
      } finally {
        DEBUG_STACK.pop(storeName.toString());
      }
    })
    .whenTargetNamed(storeName);
}

export function _rebindModule<T extends SharedBindings>(
  container: TypedContainer<T>,
  storeName: string,
  module: any,
): void {
  if (!config.env.test) throw new Error('Cannot rebind modules outside test environment');
  const modules = getModuleCache(container);
  modules[storeName] = module;
}

function getModuleFromCache<T extends SharedBindings>(container: TypedContainer<T>, storeName: string): VuexModule {
  const modules = getModuleCache(container);
  if (!modules[storeName]) {
    // Fetch the bound factory, now with dependencies resolved
    const moduleFactory = container.getNamed('StoreModuleFactory', storeName);
    modules[storeName] = getModule(moduleFactory.Module);
  }
  return modules[storeName];
}

function getModuleCache<T extends SharedBindings>(container: TypedContainer<T>): Record<PropertyKey, VuexModule> {
  const {store} = container.get('StoreWrapper');
  // Check if we've swapped the underlying store. If we have, we
  // need to regenerate our module. If not, we use a cached one.
  if (!MODULE_CACHE.has(store)) MODULE_CACHE.set(store, {});
  return MODULE_CACHE.get(store);
}
