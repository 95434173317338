import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal reedsy-accented" }
const _hoisted_2 = {
  key: 0,
  class: "modal-head"
}
const _hoisted_3 = { class: "ellipsis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalCloseButton = _resolveComponent("ModalCloseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.headless)
      ? (_openBlock(), _createElementBlock("header", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, [
            _renderSlot(_ctx.$slots, "title", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ], true)
          ]),
          _cache[0] || (_cache[0] = _createTextVNode()),
          (_ctx.cancelable)
            ? (_openBlock(), _createBlock(_component_ModalCloseButton, {
                key: 0,
                onClick: _ctx.close
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _cache[1] || (_cache[1] = _createTextVNode()),
    _createElementVNode("rbe-modal-content", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _cache[2] || (_cache[2] = _createTextVNode()),
    _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
  ]))
}