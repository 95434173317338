import {IBookshelfEntry, BookshelfEntryType} from './i-bookshelf-entry';

export function isBookEntry(
  entry: IBookshelfEntry<any>,
): entry is IBookshelfEntry<BookshelfEntryType.Book> {
  return entry?.type === BookshelfEntryType.Book;
}

export function isBookImportEntry(
  entry: IBookshelfEntry<any>,
): entry is IBookshelfEntry<BookshelfEntryType.BookImport> {
  return entry?.type === BookshelfEntryType.BookImport;
}
