<template>
  <SubscriptionBaseModal
    :id="id"
    class="subscription-payment-modal equal-columns"
  >
    <template #title>
      <rbe-subscription-back>
        <button
          type="button"
          class="button-with-icon"
          @click="backToPreview"
        >
          <VuiIconArrowLeft />
          <span>Go back</span>
        </button>
      </rbe-subscription-back>
    </template>

    <rbe-subscription-payment>
      <rbe-payment-section>
        <h1>
          Confirm your subscription
        </h1>
        <p>
          Please enter your card details to enable the add-ons you selected.
        </p>
      </rbe-payment-section>

      <rbe-payment-section>
        <h2>Billing frequency</h2>
        <PeriodPicker v-model="billAnnually" />
      </rbe-payment-section>

      <rbe-payment-section>
        <h2>Your subscription</h2>
        <rbe-selected-product
          v-for="product in activeProductsInfo"
          :key="product"
          class="flex-justified"
        >
          <span class="font-weight-bold">{{ product.title }}</span>
          <span>TODO: [subscriptions] price</span>
        </rbe-selected-product>
      </rbe-payment-section>

      <rbe-payment-section>
        <rbe-price-item>
          <span>Subtotal</span>
          <span>TODO: [subscriptions] price</span>
        </rbe-price-item>
        <rbe-price-item>
          <span>Bundle discount</span>
          <span>- TODO: [subscriptions] price</span>
        </rbe-price-item>

        <hr>

        <rbe-price-item class="price-headline">
          <span>Total</span>
          <span>TODO: [subscriptions] price</span>
        </rbe-price-item>
        <rbe-price-item>
          <span>Next billing date</span>
          <span>TODO: [subscriptions] billing date</span>
        </rbe-price-item>
      </rbe-payment-section>
    </rbe-subscription-payment>

    <template #right>
      TODO: [subscriptions] Add payment form
    </template>
  </SubscriptionBaseModal>
</template>

<script lang="ts">
import {Component, mixins, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import SubscriptionBaseModal from '@reedsy/studio.shared/components/modals/components/subscription/subscription-base.vue';
import {PropType} from 'vue';
import {ISubscriptionPaymentModalArgs} from './subscription-payment-modal-args.interface';
import {$lazyInject} from '@reedsy/studio.shared/inversify.config';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';
import {FEATURE_SUMMARIES} from './feature-summaries';
import {IProductSummary} from './product-summary.interface';
import PeriodPicker from './period-picker.vue';

@Component({
  components: {
    SubscriptionBaseModal,
    PeriodPicker,
  },
})
export default class SubscriptionPayment extends mixins(ModalMixin, ClientSharedVue) {
  @Prop({type: Object as PropType<ISubscriptionPaymentModalArgs>, default: {}})
  public context: ISubscriptionPaymentModalArgs;

  @$lazyInject('SubscriptionModal')
  public $subscriptionModal: ISubscriptionModalService;

  public readonly cancelable = true;
  public billAnnually = false;

  public get activeProductsInfo(): ReadonlyArray<IProductSummary> {
    return FEATURE_SUMMARIES.filter(({product}) => this.context.products.has(product));
  }

  public created(): void {
    this.billAnnually = this.context.billAnnually;
  }

  public backToPreview(): void {
    this.$subscriptionModal.openFeatures({products: new Set(this.context.products)});
  }
}
</script>

<style lang="scss" scoped>
.subscription-payment-modal {
  --selection-background: var(--reedsy-neutral);

  width: 52rem;

  rbe-subscription-payment {
    display: flex;
    flex-direction: column;
    gap: $space-base;
  }

  rbe-payment-section {
    display: flex;
    flex-direction: column;
    gap: $space-sm;

    hr {
      background-color: var(--reedsy-plain-onDefault);
      width: 100%;
      height: 0.1875rem;
    }

    rbe-price-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &.price-headline {
        @include font-family($controls, bold);

        font-size: $font-size-lg;
      }
    }
  }

  h1, h2 {
    @include font-family($controls, bold);
  }

  h1 {
    font-size: $font-size-xxl;
  }

  h2 {
    font-size: $font-size-base;
  }

  rbe-selected-product {
    padding: $space-md $space-base;
    border-radius: $border-radius-base;
    border: $border-lg;
  }
}
</style>
