
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';

@Component({})
export default class Panel extends ClientSharedVue {
  @Prop({type: String, default: ''})
  public title: string;

  @Prop({type: String, default: ''})
  public bodyTitle: string;

  public get hasFooter(): boolean {
    return !!this.$slots.footer;
  }

  public get hasTitle(): boolean {
    return !!this.$slots.title || !!this.title;
  }
}
