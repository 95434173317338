<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <SelectedFileInfo
      v-if="importFile"
      ref="selectedFileInfo"
      :import-file="importFile"
      class="selected-file-info"
      :progress-percentage="progressPercentage"
      @clear-file="clearFile"
    />
    <VFileInput
      v-else
      ref="fileUpload"
      :accepted-types="acceptedTypes"
      :additional-info="fileUploadAdditionalInfo"
      before-link-text="or"
      :display-button="false"
      link-text="select from your device"
      button-text="Upload file"
      :max-size="maxFileSizeWithUnit"
      title="Upload your manuscript here"
      class="import-doc-upload"
      required
      @file-updated="newFileSelected"
    />
  </transition>
</template>
<script lang="ts">
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {Component, Prop, Emit} from '@reedsy/studio.shared/utils/vue/decorators';
import SelectedFileInfo from './selected-file-info.vue';
import {SUPPORTED_UPLOADER_MIME_TYPES} from '@reedsy/vui';

@Component({
  components: {
    SelectedFileInfo,
  },
})
export default class ImportFileUploader extends BookshelfVue {
  @Prop({type: Number})
  public progressPercentage: number;

  public maxFileSizeMb = 250;
  public readonly acceptedTypes = Object.freeze([
    SUPPORTED_UPLOADER_MIME_TYPES.docx,
    SUPPORTED_UPLOADER_MIME_TYPES.odt,
  ]);

  public importFile: File = null;

  public get maxFileSizeWithUnit(): string {
    return `${this.maxFileSizeMb}Mb`;
  }

  public get fileUploadAdditionalInfo(): string {
    return `We accept DOCX and ODT files, up to ${this.maxFileSizeWithUnit}`;
  }

  public clearFile(): void {
    this.updateFile(null);
  }

  public newFileSelected(newFile: File): void {
    this.updateFile(newFile);
  }

  @Emit('update:modelValue')
  public updateFile(file: File): File {
    this.importFile = file;
    return this.importFile;
  }
}
</script>

<style lang="scss" scoped>
.import-doc-upload, .selected-file-info {
  min-height: 11rem;
}
</style>
