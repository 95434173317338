
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {IProductSummary} from './product-summary.interface';
import {PropType} from 'vue';
import ExpandTransition from '@reedsy/studio.shared/components/transitions/expand-transition.vue';

@Component({
  components: {
    ExpandTransition,
  },
})
export default class CompactFeatureSummary extends ClientSharedVue {
  @Model({type: Boolean})
  public selected: boolean;

  @Prop({type: Object as PropType<IProductSummary>, required: true})
  public summary: IProductSummary;

  public expanded = false;

  public get isProduct(): boolean {
    return !!this.summary.product;
  }
}
