<template>
  <rbe-invitee
    :class="{error: hasError}"
  >
    <rbe-invitee-details class="flex-left">
      <p class="ellipsis email">
        {{ email }}
      </p>
      <button
        class="button-icon button-xs reedsy-accented neutral"
        type="button"
        @click="remove"
      >
        <VuiIconCross class="icon-sm" />
      </button>
    </rbe-invitee-details>
  </rbe-invitee>
</template>

<script lang="ts">
import {Component, Emit, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import ErrorMessageBox from '@reedsy/studio.shared/components/error-message-box/error-message-box.vue';

@Component({
  components: {
    ErrorMessageBox,
  },
})
export default class Invitee extends ClientSharedVue {
  @Prop({type: String, required: true})
  public email: string;

  @Prop({type: String, default: ''})
  public errorCode: string;

  public get hasError(): boolean {
    return !!this.errorCode;
  }

  @Emit('remove')
  public remove(): string {
    return this.email;
  }
}
</script>

<style lang="scss" scoped>
rbe-invitee {
  padding: $space-lg;
  border: $border;
  border-radius: $border-radius-base;
  gap: $space-base;

  .email {
    margin-right: auto;
  }

  &.error {
    border-color: var(--reedsy-danger);
  }

  button {
    margin-left: $space-base;
    flex: 0 0 auto;
  }
}
</style>
