import {$inject} from '@reedsy/studio.shared/types';
import {injectable} from 'inversify';
import {ActionPayload, Store} from 'vuex';
import {VuexModule} from '@reedsy/vuex-module-decorators';
import {IActionSubscribeOptions, IMutationSubscribeOptions, IStoreListener, LooseParameters, OnlyActions, OnlyMutations, Unsubscribe} from './i-store-listener';

@injectable()
export class StoreListener<Mapping extends Record<string, VuexModule>> implements IStoreListener<Mapping> {
  @$inject('Store')
  public readonly _store: Store<any>;

  public subscribe<
    Name extends keyof Mapping,
    Module extends Mapping[Name],
    Mutation extends OnlyMutations<Module>,
  >(
    name: Name,
    mutation: Mutation,
    callback: (payload: LooseParameters<Module[Mutation]>[0]) => any,
    options?: IMutationSubscribeOptions<LooseParameters<Module[Mutation]>[0]>,
  ): Unsubscribe {
    let onceWhen = options?.onceWhen;
    onceWhen = options?.once ? () => true : onceWhen;

    const unsubscribe = this._store.subscribe(({type, payload}) => {
      const mutationPath = [name, mutation].join('/');
      if (type !== mutationPath) return;

      if (!onceWhen) {
        callback(payload);
        return;
      }

      if (!onceWhen(payload)) return;
      unsubscribe();
      callback(payload);
    }, options);

    return unsubscribe;
  }

  public subscribeAction<
    Name extends keyof Mapping,
    Module extends Mapping[Name],
    Action extends OnlyActions<Module>,
  >(
    name: Name,
    action: Action,
    callback: (payload: LooseParameters<Module[Action]>[0]) => any,
    options?: IActionSubscribeOptions<LooseParameters<Module[Action]>[0]>,
  ): Unsubscribe {
    const key = options?.modification || 'before';
    let onceWhen = options?.onceWhen;
    onceWhen = options?.once ? () => true : onceWhen;

    const unsubscribe = this._store.subscribeAction({
      [key]: ({type, payload}: ActionPayload) => {
        const actionPath = [name, action].join('/');
        if (type !== actionPath) return;

        if (!onceWhen) {
          callback(payload);
          return;
        }

        if (!onceWhen(payload)) return;
        unsubscribe();
        callback(payload);
      }}, options);
    return unsubscribe;
  }
}
