import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "modal-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Overlay = _resolveComponent("Overlay")!
  const _component_VuiFocusTrap = _resolveComponent("VuiFocusTrap")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createVNode(_component_VuiFocusTrap, {
          "is-active": !!_ctx.modals.length
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_Overlay, { onClick: _ctx.closeAll }, null, 8, ["onClick"]),
              _cache[0] || (_cache[0] = _createTextVNode()),
              _createVNode(_TransitionGroup, { name: "zoom" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modals, (modal) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: modal.id,
                      class: "modal-wrapper"
                    }, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modalComponent(modal.type)), {
                        id: modal.id,
                        context: modal.context
                      }, null, 8, ["id", "context"]))
                    ]))
                  }), 128))
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["is-active"])
      ], 512), [
        [_vShow, _ctx.modals.length]
      ])
    ]),
    _: 1
  }))
}