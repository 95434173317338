
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ITabOption} from './i-tab-option';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {AVuiTabOptions} from '@reedsy/vui';

@Component({
  mixins: [AVuiTabOptions],
})
export default class TabOptions extends ClientSharedVue {
  @Prop({type: Array, required: true})
  public options: ITabOption<any>[];
}
