import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "book-title-text ellipsis" }
const _hoisted_2 = { class: "book-subtitle-text large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookCover = _resolveComponent("BookCover")!
  const _component_ShareIndicator = _resolveComponent("ShareIndicator")!
  const _component_ContinueWritingButton = _resolveComponent("ContinueWritingButton")!
  const _component_BookTopInfoGrid = _resolveComponent("BookTopInfoGrid")!

  return (_openBlock(), _createBlock(_component_BookTopInfoGrid, { class: "book-top-info" }, {
    "book-cover": _withCtx(() => [
      _createVNode(_component_BookCover, {
        class: "book-cover sm static-width",
        book: _ctx.book
      }, null, 8, ["book"]),
      _cache[0] || (_cache[0] = _createTextVNode()),
      _createVNode(_component_ShareIndicator, {
        class: "share-indicator",
        size: "sm",
        "book-id": _ctx.book.id
      }, null, 8, ["book-id"])
    ]),
    "book-titles": _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, [
        _renderSlot(_ctx.$slots, "book-title", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.book.title), 1)
        ], true)
      ]),
      _cache[1] || (_cache[1] = _createTextVNode()),
      _createElementVNode("p", _hoisted_2, [
        _renderSlot(_ctx.$slots, "book-subtitle", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.book.subtitle), 1)
        ], true)
      ])
    ]),
    action: _withCtx(() => [
      _createVNode(_component_ContinueWritingButton, {
        book: _ctx.book,
        class: "continue-writing-button"
      }, null, 8, ["book"])
    ]),
    _: 3
  }))
}