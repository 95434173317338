import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiAccountDropdown = _resolveComponent("VuiAccountDropdown")!

  return (_openBlock(), _createBlock(_component_VuiAccountDropdown, {
    class: "user-settings-menu",
    options: _ctx.options,
    "user-firstname": _ctx.user.firstName,
    "user-lastname": _ctx.user.lastName,
    "user-avatar": _ctx.user.avatarUrl
  }, null, 8, ["options", "user-firstname", "user-lastname", "user-avatar"]))
}