import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExportListEntry = _resolveComponent("ExportListEntry")!
  const _component_EmptyListPlaceholder = _resolveComponent("EmptyListPlaceholder")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, {
    title: "Exported files",
    class: "exports-list"
  }, {
    footer: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("p", null, "\n        We are striving to improve our typesetting capabilities.\n        If there are errors with your downloaded files,\n        please reach out to us via Help! button and we and our support team will assist you.\n      ", -1)
    ])),
    default: _withCtx(() => [
      _createVNode(_Transition, {
        name: "fade",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (_ctx.hasExports)
            ? (_openBlock(), _createBlock(_TransitionGroup, {
                key: 0,
                name: "list-complete",
                tag: "rbe-exports-list"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bookExports, (bookExport) => {
                    return (_openBlock(), _createElementBlock("rbe-export-list-entry-wrapper", {
                      key: bookExport._id,
                      class: "list-complete-item"
                    }, [
                      _createVNode(_component_ExportListEntry, {
                        class: "exports-list-entry",
                        "book-export": bookExport
                      }, null, 8, ["book-export"])
                    ]))
                  }), 128))
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_EmptyListPlaceholder, { key: 1 }))
        ]),
        _: 1
      }),
      _cache[1] || (_cache[1] = _createTextVNode())
    ]),
    _: 1
  }))
}