
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Panel from '@reedsy/studio.shared/components/panel/panel.vue';
import ExportTrimSizes from './export-trim-sizes.vue';
import {TrimSize} from '@reedsy/schemas.workers.book-export';
import Dropdown from '@reedsy/studio.shared/components/forms/dropdown/dropdown.vue';
import {IDropdownOption} from '@reedsy/studio.shared/components/forms/dropdown/i-option';
import {TRIM_SIZE_DETAILS} from './trim-sizes-details';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {BookExportSubmissionModule} from '@reedsy/studio.home.bookshelf/store/modules/book-export-submission';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import PanelBodyTitle from '@reedsy/studio.shared/components/panel/panel-body-title.vue';

@Component({
  components: {
    Panel,
    ExportTrimSizes,
    Dropdown,
    PanelBodyTitle,
  },
})
export default class TrimSizeSelector extends BookshelfVue {
  @$lazyInjectStore(StoreName.BookExportSubmission)
  public $bookExportSubmission: BookExportSubmissionModule;

  public get dropDownOptions(): IDropdownOption[] {
    return TRIM_SIZE_DETAILS
      .map((trimSizeDetails) => {
        return {
          value: trimSizeDetails.sizeId,
          text: `${trimSizeDetails.name} (${trimSizeDetails.width}” x ${trimSizeDetails.height}”)`,
        };
      });
  }

  public get trimSize(): TrimSize {
    return this.$bookExportSubmission.trimSize;
  }

  public set trimSize(trimSize: TrimSize) {
    this.$bookExportSubmission.TRIM_SIZE(trimSize);
  }
}
