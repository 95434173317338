
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({})
export default class AddOnToggle extends ClientSharedVue {
  @Prop({type: String, required: true})
  public title: string;

  @Model
  public enabled: boolean;
}
