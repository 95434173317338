/* eslint-disable @stylistic/max-len */
import {SHARED_MODAL_COMPONENT_MAP} from '@reedsy/studio.shared/components/modals/modal-component-map';

export const MODAL_COMPONENT_MAP = {
  ...SHARED_MODAL_COMPONENT_MAP,
  CreateBook: (): any => import('@reedsy/studio.home.bookshelf/components/modals/components/create-book-modal.vue'),
  ImportBook: (): any => import('@reedsy/studio.home.bookshelf/components/modals/components/import-book-modal.vue'),
  ExportStartedModal: (): any => import('@reedsy/studio.home.bookshelf/components/modals/components/export-started-modal/export-started-modal.vue'),
  ConfirmBookArchive: (): any => import('@reedsy/studio.home.bookshelf/components/modals/components/confirm-book-archive-modal.vue'),
  ConfirmBookDeletionModal: (): any => import('@reedsy/studio.home.bookshelf/components/modals/components/confirm-book-deletion-modal.vue'),
  HelpModal: (): any => import('@reedsy/studio.home.bookshelf/components/modals/components/help-modal/help-modal.vue'),
  AfterSignUpSurvey: (): any => import('@reedsy/studio.home.bookshelf/components/modals/components/after-sign-up-survey/after-sign-up-survey.vue'),
};
