
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import ShareButton from './share-button.vue';
import {SocialNetwork} from './supported-networks';

@Component({
  components: {
    ShareButton,
  },
})
export default class LinkedInShareButton extends BookshelfVue {
  @Prop({type: String, required: true})
  public sharedLink: string;

  @Prop({type: String, required: true})
  public description: string;

  @Prop({type: String, required: true})
  public title: string;

  public readonly SocialNetwork = SocialNetwork;

  public get shareUrl(): string {
    return `http://www.linkedin.com/shareArticle?url=${this.sharedLink}&title=${this.title}&summary=${this.description}`;
  }
}
