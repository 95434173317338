<template>
  <ConfirmModal
    :id="id"
    title="Leave book"
    :cancel="{label: 'Cancel'}"
    :confirm="{
      label: 'Leave book',
      handler: leaveBook,
    }"
    class="danger"
  >
    <rbe-confirm-leave-book-modal>
      <p>
        Are you sure you want to leave “{{ bookTitle }}”?
        This will remove the book from your bookshelf and you will lose access to it.
      </p>
    </rbe-confirm-leave-book-modal>
  </ConfirmModal>
</template>

<script lang="ts">
import {Component, Prop, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ConfirmModal from '@reedsy/studio.shared/components/modals/templates/confirm-modal.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {PropType} from 'vue';
import Notify from '@reedsy/studio.shared/services/notify/notify';
import {$lazyInject} from '@reedsy/studio.shared/inversify.config';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import Navigation from '@reedsy/studio.shared/services/navigation';
import {IConfirmLeaveBookModalArgs} from '@reedsy/studio.shared/components/modals/i-confirm-leave-book-modal-args';

@Component({
  components: {
    ConfirmModal,
  },
})
export default class ConfirmLeaveBookModal extends mixins(ModalMixin, ClientSharedVue) {
  @$lazyInject('Api')
  public readonly api: IApi;

  @$lazyInject('Navigation')
  public readonly navigation: Navigation;

  @Prop({type: Object as PropType<IConfirmLeaveBookModalArgs>, default: {}})
  public context: IConfirmLeaveBookModalArgs;

  public readonly cancelable = true;

  public get bookTitle(): string {
    return this.context.bookTitle;
  }

  public async leaveBook(): Promise<void> {
    try {
      await this.api.revokeMyAccess(this.context.bookId);
      await this.navigation.goToBookShelf();
    } catch (error) {
      Notify.error({message: 'Cannot leave book'});
      throw error;
    }
  }
}
</script>
