import {injectable} from 'inversify';
import RouterPlugin from '@reedsy/studio.home.bookshelf/store/plugins/router-plugin';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import {IMetaRoute} from '@reedsy/studio.shared/router/reedsy-router';

@injectable()
export class BooksListRouterPlugin extends RouterPlugin {
  protected override shouldApplyAfter(to: IMetaRoute): boolean {
    return this.isBooksListRoute(to);
  }

  protected override shouldApplyBefore(to: IMetaRoute): boolean {
    return this.isBooksListRoute(to);
  }

  protected isBooksListRoute(to: IMetaRoute): boolean {
    return !!to.matched.find((r) => r.name === BookshelfRouteName.Bookshelf);
  }
}
