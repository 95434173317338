import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "new-export-button" }
const _hoisted_2 = { class: "button button-icon button-xs reedsy-accented accent-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconPlus = _resolveComponent("VuiIconPlus")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("span", null, "New export", -1)),
    _cache[1] || (_cache[1] = _createTextVNode()),
    _createElementVNode("span", _hoisted_2, [
      _createVNode(_component_VuiIconPlus, { class: "icon-sm" })
    ])
  ]))
}