import {getInitials} from './get-initials';

export function getShortFullName(firstName: string, lastName: string): string {
  firstName = (firstName || '').trim();
  lastName = (lastName || '').trim();

  if (!firstName) {
    return lastName;
  }

  if (!lastName) {
    return firstName;
  }

  const firstNameInitial = getInitials(firstName);

  return `${firstNameInitial}. ${lastName}`;
}
