import {VuexModule, Action, Mutation} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {injectable, named} from 'inversify';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {Store} from 'vuex';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {ShareDBModule} from '@reedsy/studio.home.bookshelf/store/modules/sharedb';
import {IUserWordCountGoal} from '@reedsy/reedsy-sharedb/lib/common/word-count-goal/user-word-count-goal';
import {clone} from '@reedsy/utils.clone';
import {Doc} from 'sharedb/lib/client';
import applyOp from '@reedsy/studio.shared/store/helpers/apply-op/apply-op';
import {memoize} from '@reedsy/utils.decorator';
import {IApplyOpsMutation, IDataMutation, subscribeVuexToDoc} from '@reedsy/studio.shared/store/modules/sharedb/subscribe-vuex-to-doc';

@injectable()
export class UserWordCountGoalsModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,

    @$inject('StoreModule')
    @named(StoreName.ShareDb)
    shareDb: ShareDBModule,
  ) {
    @Module({name: StoreName.UserWordCountGoals, store})
    class UserWordCountGoals extends VuexModule {
      public goals: {[goalId: string]: IUserWordCountGoal} = {};

      public get forBook() {
        return (bookId: string) => {
          return Object
            .values(this.goals)
            .filter((goal) => goal.metadata.bookId === bookId);
        };
      }

      @Mutation
      public DATA({id, data}: IDataMutation<'userWordCountGoals'>): void {
        const goal = clone(data);
        goal._id = id;
        this.goals[id] = goal;
      }

      @Mutation
      public APPLY_OPS({id, ops}: IApplyOpsMutation<'userWordCountGoals'>): void {
        ops.forEach((op) => applyOp(this.goals[id], op));
      }

      @Action
      @memoize
      public async initialise(): Promise<void> {
        const query = await shareDb.subscribeQuery({
          collection: 'userWordCountGoals',
        });

        const subscribeDocs = (docs: Doc<IUserWordCountGoal>[]): void => {
          docs.forEach((doc) => subscribeVuexToDoc(doc, this));
        };

        subscribeDocs(query.results);
        query.on('insert', subscribeDocs);

        query.on('remove', (removedDocs) => {
          removedDocs.forEach((doc) => {
            const goalId = doc.id;
            this.REMOVE_BOOK_WORD_GOAL(goalId);
            doc.destroy();
          });
        });
      }

      @Mutation
      private REMOVE_BOOK_WORD_GOAL(goalId: string): void {
        delete this.goals[goalId];
      }
    }

    this.Module = UserWordCountGoals;
  }
}

export type UserWordCountGoalsModule = InstanceType<UserWordCountGoalsModuleFactory['Module']>;
