import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "visible-until-sm" }
const _hoisted_2 = { class: "mobile" }
const _hoisted_3 = { class: "hidden-until-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelBodyTitle = _resolveComponent("PanelBodyTitle")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_ExportTrimSizes = _resolveComponent("ExportTrimSizes")!
  const _component_Panel = _resolveComponent("Panel")!
  const _directive_root_class = _resolveDirective("root-class")!

  return (_openBlock(), _createBlock(_component_Panel, { class: "trim-size-selector" }, {
    default: _withCtx(() => [
      _createVNode(_component_PanelBodyTitle, { class: "no-top-space" }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("\n      Trim sizes\n    ")
        ])),
        _: 1
      }),
      _cache[8] || (_cache[8] = _createTextVNode()),
      _createElementVNode("rbe-trim-size-selector-content", null, [
        _createElementVNode("rbe-trim-size-selector-left-column", null, [
          _createElementVNode("rbe-trim-size-dropdown-wrapper", null, [
            _cache[4] || (_cache[4] = _createElementVNode("p", null, "We have kept things simple. Choose from standard US trade book formats.", -1)),
            _cache[5] || (_cache[5] = _createTextVNode()),
            _withDirectives(_createVNode(_component_Dropdown, {
              ref: "trimSizeDropdown",
              modelValue: _ctx.trimSize,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.trimSize) = $event)),
              options: _ctx.dropDownOptions
            }, null, 8, ["modelValue", "options"]), [
              [_directive_root_class, 'trim-size-dropdown']
            ])
          ]),
          _cache[6] || (_cache[6] = _createTextVNode()),
          _createElementVNode("rbe-export-sizes-row", _hoisted_1, [
            _createElementVNode("rbe-export-sizes-wrapper", _hoisted_2, [
              _createVNode(_component_ExportTrimSizes, {
                modelValue: _ctx.trimSize,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.trimSize) = $event))
              }, null, 8, ["modelValue"])
            ])
          ])
        ]),
        _cache[7] || (_cache[7] = _createTextVNode()),
        _createElementVNode("rbe-trim-size-selector-right-column", _hoisted_3, [
          _createVNode(_component_ExportTrimSizes, {
            ref: "exportTrimSizes",
            modelValue: _ctx.trimSize,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.trimSize) = $event))
          }, null, 8, ["modelValue"])
        ])
      ])
    ]),
    _: 1
  }))
}