<template>
  <rbe-no-collaborators-placeholder
    class="flex-top"
  >
    <img
      src="@/assets/inlined/bookshelf-no-results.svg"
      alt="No collaborators placeholder"
    >
    <p>You are not currently working with any collaborators.</p>
  </rbe-no-collaborators-placeholder>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';

@Component({})
export default class NoCollaboratorsPlaceholder extends BookshelfVue {}
</script>

<style lang="scss" scoped>
rbe-no-collaborators-placeholder {
  align-items: center;
  gap: $space-base
}
</style>
