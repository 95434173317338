export abstract class ValidatableInput extends HTMLInputElement {
  protected abstract readonly invalidMessage: string;
  protected abstract isValid(): boolean;

  public constructor() {
    super();
    this.addEventListener('input', this.validate.bind(this));
  }

  public override set value(value: string) {
    super.value = value;
    this.validate();
  }

  // Can't override just the setter without overriding getter, too
  public override get value(): string {
    return super.value;
  }

  private get shouldValidate(): boolean {
    return !this.validationMessage || this.validationMessage === this.invalidMessage;
  }

  private validate(): void {
    if (!this.value) return;
    if (!this.shouldValidate) return;
    this.setCustomValidity(this.isValid() ? '' : this.invalidMessage);
  }
}
