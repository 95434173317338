
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {IBookshelfEntry} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-entry';
import NoBookCoverPlaceholder from '@reedsy/studio.home.bookshelf/components/book-cover/no-book-cover-placeholder.vue';
import {IBookImport} from '@reedsy/reedsy-sharedb/lib/common/book/book-imports';

@Component({
  components: {
    NoBookCoverPlaceholder,
  },
})
export default class BookImportEntry extends BookshelfVue {
  @Prop({type: Object, required: true})
  public entry: IBookshelfEntry;

  public get bookImport(): IBookImport {
    return this.entry.item as IBookImport;
  }

  public get title(): string {
    return this.bookImport.importData.title;
  }
}
