<template>
  <rbe-export-theme-preview>
    <transition
      name="fade"
      mode="out-in"
    >
      <img
        :key="themeImage"
        :src="themeImage"
      >
    </transition>
  </rbe-export-theme-preview>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {BookExportSubmissionModule} from '@reedsy/studio.home.bookshelf/store/modules/book-export-submission';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';

import CLASSIC_NO_NUMBER_DROPCAP from '@/assets/inlined/theme/preview/classic/no_number-dropcap.png';
import CLASSIC_NO_NUMBER_NO_DROPCAP from '@/assets/inlined/theme/preview/classic/no_number-no_dropcap.png';
import CLASSIC_NUMBER_DROPCAP from '@/assets/inlined/theme/preview/classic/number-dropcap.png';
import CLASSIC_NUMBER_NO_DROPCAP from '@/assets/inlined/theme/preview/classic/number-no_dropcap.png';

import REEDSY_NO_NUMBER_DROPCAP from '@/assets/inlined/theme/preview/reedsy/no_number-dropcap.png';
import REEDSY_NO_NUMBER_NO_DROPCAP from '@/assets/inlined/theme/preview/reedsy/no_number-no_dropcap.png';
import REEDSY_NUMBER_DROPCAP from '@/assets/inlined/theme/preview/reedsy/number-dropcap.png';
import REEDSY_NUMBER_NO_DROPCAP from '@/assets/inlined/theme/preview/reedsy/number-no_dropcap.png';

import ROMANCE_NO_NUMBER_DROPCAP from '@/assets/inlined/theme/preview/romance/no_number-dropcap.png';
import ROMANCE_NO_NUMBER_NO_DROPCAP from '@/assets/inlined/theme/preview/romance/no_number-no_dropcap.png';
import ROMANCE_NUMBER_DROPCAP from '@/assets/inlined/theme/preview/romance/number-dropcap.png';
import ROMANCE_NUMBER_NO_DROPCAP from '@/assets/inlined/theme/preview/romance/number-no_dropcap.png';
import {deepFreeze} from '@reedsy/utils.object';

const THEME_IMAGES = deepFreeze({
  classic: {
    number: [
      {
        dropcap: [
          CLASSIC_NO_NUMBER_NO_DROPCAP,
          CLASSIC_NO_NUMBER_DROPCAP,
        ],
      },
      {
        dropcap: [
          CLASSIC_NUMBER_NO_DROPCAP,
          CLASSIC_NUMBER_DROPCAP,
        ],
      },
    ],
  },
  reedsy: {
    number: [
      {
        dropcap: [
          REEDSY_NO_NUMBER_NO_DROPCAP,
          REEDSY_NO_NUMBER_DROPCAP,
        ],
      },
      {
        dropcap: [
          REEDSY_NUMBER_NO_DROPCAP,
          REEDSY_NUMBER_DROPCAP,
        ],
      },
    ],
  },
  romance: {
    number: [
      {
        dropcap: [
          ROMANCE_NO_NUMBER_NO_DROPCAP,
          ROMANCE_NO_NUMBER_DROPCAP,
        ],
      },
      {
        dropcap: [
          ROMANCE_NUMBER_NO_DROPCAP,
          ROMANCE_NUMBER_DROPCAP,
        ],
      },
    ],
  },
});

@Component({
  components: {
  },
})
export default class ExportThemePreview extends BookshelfVue {
  @$lazyInjectStore(StoreName.BookExportSubmission)
  public $bookExportSubmission: BookExportSubmissionModule;

  public get themeImage(): string {
    return THEME_IMAGES[this.$bookExportSubmission.theme]
      .number[+this.$bookExportSubmission.chapterNumber]
      .dropcap[+this.$bookExportSubmission.dropCaps];
  }
}
</script>

<style lang="scss" scoped>
rbe-export-theme-preview {
  padding: 3rem 4rem 0;
  background-color: var(--reedsy-neutral-muted);
  border-radius: $border-radius-base;
  overflow: hidden;

  @include screen-less-than(sm) {
    padding: $space-md 0 0 0;
  }

  img {
    border-top-left-radius: $border-radius-base;
    border-top-right-radius: $border-radius-base;
    display: block;
    box-shadow: $box-shadow-base;
    aspect-ratio: 1.025;
    width: 100%;
  }
}
</style>
