<template>
  <rbe-rating>
    <div class="flex-top">
      <p class="question">
        How likely is it that you would recommend Reedsy Studio to a friend?
      </p>
      <div class="flex-justified">
        <label>Not at all likely</label>
        <label>Extremely likely</label>
      </div>
      <rbe-slider-wrapper>
        <VuiSlider
          ref="ratingSlider"
          v-model="selectedRating"
          :min="1"
          :max="10"
          marks
          tooltip="none"
          class="rating-slider"
        />
      </rbe-slider-wrapper>
    </div>
    <!--
        This element is used for smooth expand animation as
        it doesn't work well with paddings and gaps on animated element
      -->
    <ExpandTransition>
      <rbe-low-rating-explanation-wrapper
        v-if="explanationIsShown"
      >
        <rbe-low-rating-explanation class="flex-top">
          <label>{{ adaptiveQuestion }}</label>
          <VTextarea
            ref="textarea"
            v-model="explanation"
            :required="true"
            placeholder="Please specify any features or changes you would like to see"
          />
        </rbe-low-rating-explanation>
      </rbe-low-rating-explanation-wrapper>
    </ExpandTransition>
  </rbe-rating>
</template>

<script lang="ts">
import {
  Component,
  Model,
} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import ExpandTransition from '@reedsy/studio.shared/components/transitions/expand-transition.vue';

@Component({
  components: {
    ExpandTransition,
  },
})
export default class AppRating extends ClientSharedVue {
  @Model({type: Number, name: 'rating', default: null})
  public selectedRating: number;

  @Model({type: String, name: 'lowRatingExplanation'})
  public explanation: string;

  public get explanationIsShown(): boolean {
    return this.selectedRating && this.selectedRating < 8;
  }

  public get adaptiveQuestion(): string {
    if (this.selectedRating <= 6) {
      return 'What was missing or disappointing in your experience with us?';
    }

    return 'How can we improve your experience?';
  }
}
</script>

<style lang="scss" scoped>
rbe-rating {
  border: $border-lg;
  border-color: var(--accent-color);
  border-radius: $border-radius-base;
  padding: $space-base;

  .question {
    margin-bottom: $space-base;
  }

  $label-adjustment: -2.2rem;

  // Hide dots on slider
  :deep(.vue-slider .vue-slider-rail .vue-slider-mark .vue-slider-mark-step) {
    display: none;
  }

  // -- Make the mark label above the slider --
  :deep(.vue-slider-ltr .vue-slider-mark-label) {
    top: $label-adjustment;
  }

  rbe-slider-wrapper {
    padding-top: -0.6 * $label-adjustment;
  }

  rbe-low-rating-explanation {
    padding-top: $space-base;
    gap: $space-sm;
  }
}
</style>
