import StoreName from './store-name';
import {SHARED_STORE_FACTORY_MAPPING} from '@reedsy/studio.shared/store/store-mapping';
import {BookshelfModalsModuleFactory} from './modules/modals';
import {BookshelfModuleFactory} from './modules/bookshelf';
import {ShareDBModuleFactory} from './modules/sharedb';
import {BooksTotalWordCountsModuleFactory} from './modules/books-total-word-counts';
import {BookshelfLayoutModuleFactory} from './modules/layout';
import {BookshelfCollaboratorsModuleFactory} from './modules/collaborators';
import {Constructor} from '@reedsy/utils.types';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {BookWordCountGoalsModuleFactory} from './modules/book-word-count-goals';
import {BookDetailsModuleFactory} from './modules/book-details/book-details';
import {UserBookMetadataModuleFactory} from './modules/user-book-metadata';
import {CurrentBookModuleFactory} from './modules/current-book';
import {BookExportSubmissionModuleFactory} from './modules/book-export-submission';
import {BooksImportsModuleFactory} from './modules/book-imports';
import {BookActionsModuleFactory} from './modules/book-actions/book-actions';
import {UserSettingsModuleFactory} from './modules/user-settings';
import {UserWordCountGoalsModuleFactory} from './modules/user-word-count-goals/user-word-count-goals';
import {UserBookSettingsModuleFactory} from './modules/user-book-settings';
import {InvitationApprovalFlowModuleFactory} from './modules/invitation-approval-flow';
import {FeedbackModuleFactory} from './modules/feedback';

export const BOOKSHELF_STORE_FACTORY_MAPPING = {
  ...SHARED_STORE_FACTORY_MAPPING,
  [StoreName.Modals]: BookshelfModalsModuleFactory,
  [StoreName.Bookshelf]: BookshelfModuleFactory,
  [StoreName.ShareDb]: ShareDBModuleFactory,
  [StoreName.Layout]: BookshelfLayoutModuleFactory,
  [StoreName.BooksTotalWordCounts]: BooksTotalWordCountsModuleFactory,
  [StoreName.Collaborators]: BookshelfCollaboratorsModuleFactory,
  [StoreName.BookWordCountGoals]: BookWordCountGoalsModuleFactory,
  [StoreName.BookDetails]: BookDetailsModuleFactory,
  [StoreName.UserBookMetadata]: UserBookMetadataModuleFactory,
  [StoreName.CurrentBook]: CurrentBookModuleFactory,
  [StoreName.BookExportSubmission]: BookExportSubmissionModuleFactory,
  [StoreName.BooksImports]: BooksImportsModuleFactory,
  [StoreName.BookActions]: BookActionsModuleFactory,
  [StoreName.UserSettings]: UserSettingsModuleFactory,
  [StoreName.Feedback]: FeedbackModuleFactory,
  [StoreName.UserWordCountGoals]: UserWordCountGoalsModuleFactory,
  [StoreName.UserBookSettings]: UserBookSettingsModuleFactory,
  [StoreName.InvitationApprovalFlow]: InvitationApprovalFlowModuleFactory,

} as const satisfies Record<string, Constructor<IModuleFactory>>;

type BookshelfStoreFactoryMapping = typeof BOOKSHELF_STORE_FACTORY_MAPPING;

export type IBookshelfStoreFactoryMapping = {
  [K in keyof BookshelfStoreFactoryMapping]: InstanceType<InstanceType<BookshelfStoreFactoryMapping[K]>['Module']>;
};

type IBookshelfStoreMapping = IBookshelfStoreFactoryMapping;

export default IBookshelfStoreMapping;
