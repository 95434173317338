<template>
  <div
    class="feature-summary reedsy-accented"
    :class="{active: isProduct && selected}"
  >
    <rbe-feature-header class="flex-justified">
      <h2>
        {{ summary.title }}
        <!-- TODO: [subscriptions] add pricing -->
      </h2>

      <rbe-feature-toggle class="flex-center">
        <VuiToggle
          v-model="selected"
          class="label-left font-weight-bold"
          :label="selected ? 'Add-on enabled' : 'Enable add-on'"
        />

        <button
          type="button"
          @click="expanded = !expanded"
        >
          <component :is="expanded ? 'VuiIconUp' : 'VuiIconDown'" />
        </button>
      </rbe-feature-toggle>
    </rbe-feature-header>

    <ExpandTransition>
      <ul
        v-if="expanded"
        class="flex-top"
      >
        <li
          v-for="feature in summary.features"
          :key="feature"
        >
          {{ feature }}
        </li>
      </ul>
    </ExpandTransition>
  </div>
</template>

<script lang="ts">
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {IProductSummary} from './product-summary.interface';
import {PropType} from 'vue';
import ExpandTransition from '@reedsy/studio.shared/components/transitions/expand-transition.vue';

@Component({
  components: {
    ExpandTransition,
  },
})
export default class CompactFeatureSummary extends ClientSharedVue {
  @Model({type: Boolean})
  public selected: boolean;

  @Prop({type: Object as PropType<IProductSummary>, required: true})
  public summary: IProductSummary;

  public expanded = false;

  public get isProduct(): boolean {
    return !!this.summary.product;
  }
}
</script>

<style lang="scss" scoped>
.feature-summary {
  display: flex;
  flex-direction: column;
  border-radius: $border-radius-lg;
  border: $border-width-lg solid var(--reedsy-neutral);
  box-shadow: 0 $space-xxxs $space-xs var(--reedsy-shadowColor-muted);
  background-color: var(--reedsy-plain);
  width: 100%;

  &.active {
    border-color: var(--local-color-default);
  }

  > * {
    padding: $space-base;
  }

  rbe-feature-toggle {
    gap: $space-base;

    @include screen-less-than(sm) {
      :deep(.vui-toggle) .toggle-label {
        display: none;
      }
    }
  }

  ul {
    border-top: $border-width-lg solid var(--reedsy-neutral);
    flex: 1 0 auto;
    gap: $space-sm;
    padding: 0 $space-base;

    li {
      @include font-family($controls);

      &:first-child {
        margin-top: $space-base;
      }

      &:last-child {
        margin-bottom: $space-base;
      }
    }
  }
}
</style>
