<template>
  <FeedbackField
    label="What’s your main reason for using Studio?"
    :show-extra-field="isDifferentReason"
  >
    <Dropdown
      ref="dropdown"
      v-model="value"
      required
      :options="usageReasonsOptions"
    />

    <template #extra-field>
      <VTextarea
        ref="textarea"
        v-model="usageReasonDifferent"
        required
        placeholder="Please specify your reason"
      />
    </template>
  </FeedbackField>
</template>

<script lang="ts">
import {
  Component,
  Model,
} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import Dropdown from '@reedsy/studio.shared/components/forms/dropdown/dropdown.vue';
import {UsageReason} from '@reedsy/reedsy-sharedb/lib/common/feedback/studio-usage-reason';
import FeedbackField from './feedback-field.vue';
import {asDropdownOptions} from '@reedsy/studio.shared/utils/as-dropdown-options';

@Component({
  components: {
    Dropdown,
    FeedbackField,
  },
})
export default class UsageReasonSelector extends ClientSharedVue {
  @Model({type: String, name: 'usageReason'})
  public value: UsageReason;

  @Model({type: String, name: 'differentUsageReason'})
  public usageReasonDifferent: string;

  public readonly usageReasonsOptions = asDropdownOptions({
    [UsageReason.WritingMyBook]: 'Writing my book',
    [UsageReason.BuildingAWritingRoutine]: 'Building a writing routine',
    [UsageReason.ExportingMyBookForPublication]: 'Exporting my book for publication',
    [UsageReason.PlanningAndOutliningMyBook]: 'Planning and outlining my book',
    [UsageReason.SharingMyBookWithOthers]: 'Sharing my book with others',
    [UsageReason.SomethingElse]: 'Something else',
  } as const satisfies Record<UsageReason, string>);

  public get isDifferentReason(): boolean {
    return this.value === UsageReason.SomethingElse;
  }
}
</script>
