<template>
  <rbe-book-title>
    <p class="book-title">
      <!--
        TODO: Add hyphens to long words so that the text doesn't get outside of book cover
        https://github.com/reedsy/reedsy-editor/pull/5197#discussion_r1168949107
      -->
      {{ title }}
    </p>
    <rbe-author-name-wrapper>
      <p class="ellipsis author-name">
        <!--
          TODO: Add hyphens to long words so that the text doesn't get outside of book cover
          https://github.com/reedsy/reedsy-editor/pull/5197#discussion_r1168949107
        -->
        {{ authorName }}
      </p>
    </rbe-author-name-wrapper>
  </rbe-book-title>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';

@Component({})
export default class BookTitle extends BookshelfVue {
  @Prop({type: String, required: true})
  public title: string;

  @Prop({type: Object, required: true})
  public author: IUserInfo;

  public get firstName(): string {
    return this.author.firstName || '';
  }

  public get lastName(): string {
    return this.author.lastName;
  }

  public get authorName(): string {
    const firstInitial = this.firstName[0];
    return [firstInitial, this.lastName]
      .filter(Boolean)
      .join('. ');
  }
}
</script>

<style lang="scss" scoped>
$line-height: 1.1;

rbe-book-title {
  display: flex;
  flex-direction: column;
}

.book-title {
  width: 100%;
  font-size: $font-size-xl;
  line-height: $line-height;
  margin-bottom: $space-sm;
  color: $cover-placeholder-title-color;

  @include font-family($cover-placeholder-font, regular);
  @include line-clamp(3);
}

rbe-author-name-wrapper {
  width: 100%;

  .author-name {
    text-transform: uppercase;
    width: 100%;
    font-size: $font-size-sm;
    line-height: $line-height;

    @include font-family($controls, light);
  }
}
</style>
