import {config} from '@reedsy/studio.shared/config';
// eslint-disable-next-line no-restricted-imports
import {Module as vmdModule} from '@reedsy/vuex-module-decorators';
import {ModuleOptions} from '@reedsy/vuex-module-decorators/dist/types/moduleoptions';

export const Module: typeof vmdModule = (options: ModuleOptions): ReturnType<typeof vmdModule> => {
  options = {
    namespaced: true,
    stateFactory: true,
    dynamic: true,
    configurableGetters: !!config.env.test,
    ...options,
  };
  return vmdModule(options);
};
