
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';
import BookTopInfoGrid from '@reedsy/studio.home.bookshelf/components/book-top-info/book-top-info-grid.vue';

@Component({
  components: {
    SkeletonLoader,
    BookTopInfoGrid,
  },
})
export default class BookSkeletonLoader extends BookshelfVue {}
