<template>
  <rbe-panel
    class="panel"
    :class="{
      'with-footer': true
    }"
  >
    <rbe-panel-header
      v-if="hasTitle"
      class="panel-header border-bottom-dark"
    >
      <slot name="title">
        <h1>{{ title }}</h1>
      </slot>
    </rbe-panel-header>
    <rbe-panel-body class="panel-body">
      <slot />
    </rbe-panel-body>

    <rbe-panel-footer
      v-if="hasFooter"
      class="panel-footer"
    >
      <slot name="footer" />
    </rbe-panel-footer>
  </rbe-panel>
</template>

<script lang="ts">
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';

@Component({})
export default class Panel extends ClientSharedVue {
  @Prop({type: String, default: ''})
  public title: string;

  @Prop({type: String, default: ''})
  public bodyTitle: string;

  public get hasFooter(): boolean {
    return !!this.$slots.footer;
  }

  public get hasTitle(): boolean {
    return !!this.$slots.title || !!this.title;
  }
}
</script>

<style lang="scss" scoped>
rbe-panel {
  position: relative;
  background-color: var(--reedsy-plain);

  &.flat {
    box-shadow: none;
  }

  &.no-gutter {
    padding: 0;

    .panel-footer {
      padding: 0;
      margin: 0;
    }
  }

  rbe-panel-header {
    position: relative;

    h1 {
      vertical-align: center;
      font-size: $font-size-lg;
    }
  }

  rbe-panel-footer {
    padding-top: $space-lg;
  }
}
</style>
