
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {IBookshelfBook} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-book';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import ArchivedBookAction from './archived-book-action.vue';
import BookActions from './book-actions.vue';
import {BookshelfModule} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf';

@Component({
  components: {
    BookActions,
    ArchivedBookAction,
  },
})
export default class BookEntryActions extends BookshelfVue {
  @$lazyInjectStore(StoreName.Bookshelf)
  public $bookshelf: BookshelfModule;

  @Prop({type: Object, required: true})
  public book: IBookshelfBook;

  public get isArchived(): boolean {
    return this.$bookshelf.isArchived(this.book);
  }
}
