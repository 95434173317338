import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiMobileOpenMenu = _resolveComponent("VuiMobileOpenMenu")!
  const _component_VuiReedsyAppsMobileCard = _resolveComponent("VuiReedsyAppsMobileCard")!

  return (_openBlock(), _createElementBlock("rbe-mobile-menu", null, [
    _createVNode(_component_VuiMobileOpenMenu, {
      ref: "mobileOpenMenu",
      open: _ctx.$layout.mobileMenuIsShown,
      options: _ctx.options,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$layout.closeMobileMenu()))
    }, null, 8, ["open", "options"]),
    _cache[1] || (_cache[1] = _createTextVNode()),
    _createVNode(_component_VuiReedsyAppsMobileCard, {
      ref: "reedsyAppsMobileCard",
      "app-name": "studio",
      open: _ctx.appsMenuIsShown,
      onClose: _ctx.closeAppsMenu
    }, null, 8, ["open", "onClose"])
  ]))
}