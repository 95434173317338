
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import ExportsPageActions from '@reedsy/studio.home.bookshelf/components/exports-page-actions/exports-page-actions.vue';

@Component({
  components: {
    ExportsPageActions,
  },
})
export default class BookExports extends BookshelfVue {}
