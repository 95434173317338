
import {Component, Emit, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import ErrorMessage from '@reedsy/studio.shared/components/forms/error-message.vue';
import BaseFileInput from './base-file-input.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({
  inheritAttrs: false,
  components: {
    ErrorMessage,
    BaseFileInput,
  },
})
export default class ImportFileUploader extends ClientSharedVue {
  @Prop({type: Boolean, default: false})
  public required: boolean;

  public file: File = null;
  public error = {hasError: false, message: ''};

  @Emit('file-updated')
  public fileUpdated(file: File): File {
    this.file = file;
    return file;
  }

  public handleVuiFileError(): void {
    this.error = {hasError: true, message: 'Selected file is invalid.'};
  }
}
