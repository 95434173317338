
import {Component, Emit, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import ErrorMessageBox from '@reedsy/studio.shared/components/error-message-box/error-message-box.vue';

@Component({
  components: {
    ErrorMessageBox,
  },
})
export default class Invitee extends ClientSharedVue {
  @Prop({type: String, required: true})
  public email: string;

  @Prop({type: String, default: ''})
  public errorCode: string;

  public get hasError(): boolean {
    return !!this.errorCode;
  }

  @Emit('remove')
  public remove(): string {
    return this.email;
  }
}
