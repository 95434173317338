
import {
  Component,
  Model,
} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import ExpandTransition from '@reedsy/studio.shared/components/transitions/expand-transition.vue';

@Component({
  components: {
    ExpandTransition,
  },
})
export default class AppRating extends ClientSharedVue {
  @Model({type: Number, name: 'rating', default: null})
  public selectedRating: number;

  @Model({type: String, name: 'lowRatingExplanation'})
  public explanation: string;

  public get explanationIsShown(): boolean {
    return this.selectedRating && this.selectedRating < 8;
  }

  public get adaptiveQuestion(): string {
    if (this.selectedRating <= 6) {
      return 'What was missing or disappointing in your experience with us?';
    }

    return 'How can we improve your experience?';
  }
}
