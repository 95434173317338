
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import BookCover from '@reedsy/studio.home.bookshelf/components/book-cover/book-cover.vue';
import {IBookshelfBook} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-book';
import BookTopInfoGrid from '@reedsy/studio.home.bookshelf/components/book-top-info/book-top-info-grid.vue';
import {PropType} from 'vue';
import ContinueWritingButton from '@reedsy/studio.home.bookshelf/components/continue-writing-button/continue-writing-button.vue';
import ShareIndicator from '@reedsy/studio.home.bookshelf/components/share-indicator/share-indicator.vue';

@Component({
  components: {
    BookCover,
    ContinueWritingButton,
    BookTopInfoGrid,
    ShareIndicator,
  },
})
export default class BookTopInfo extends BookshelfVue {
  @Prop({type: Object as PropType<IBookshelfBook>, required: true})
  public book: IBookshelfBook;
}
