import {injectable, named} from 'inversify';
import {IAnalyticsService} from '@reedsy/studio.shared/services/analytics/i-analytics-service';
import {IStoreListener} from '@reedsy/studio.shared/store/helpers/store-listener/i-store-listener';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import {IStorePluginFactory} from '@reedsy/studio.shared/store/plugins/i-store-plugin-factory';
import {ISharedStoreMapping} from '@reedsy/studio.shared/store/store-mapping';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {$inject} from '@reedsy/studio.shared/types';
import {StudioAnalyticsManager} from '@reedsy/studio.shared/services/analytics/studio-analytics-manager';

@injectable()
export class AnalyticsPluginFactory implements IStorePluginFactory {
  @$inject('Analytics')
  public readonly _analytics: IAnalyticsService;

  @$inject('StudioAnalyticsManager')
  public readonly _analyticsManager: StudioAnalyticsManager;

  @$inject('StoreListener')
  public readonly _storeListener: IStoreListener<ISharedStoreMapping>;

  @$inject('StoreModule')
  @named(SharedStoreName.User)
  public readonly _user: SharedUserModule;

  public plugin(): void {
    this._storeListener.subscribeAction(
      SharedStoreName.User,
      'initialise',
      this.initialise.bind(this),
      {once: true, modification: 'after'},
    );
  }

  private initialise(): void {
    const info = this._user.info;
    this._analyticsManager.triggerAnalytics();
    this._analytics.initialise(info.uuid, info.email);
  }
}
