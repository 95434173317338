export class DependencyCycleDetector {
  private readonly stack: string[] = [];
  private readonly values: Set<string> = new Set();

  public push(value: string): void {
    this.stack.push(value);
    if (this.values.has(value)) {
      throw new Error(`Dependency cycle found: ${this.toString()}`);
    }
    this.values.add(value);
  }

  public pop(value: string): void {
    const popped = this.stack.pop();
    if (popped !== value) {
      throw new Error(`Unexpected pop: ${value}`);
    }
    this.values.delete(value);
  }

  public toString(): string {
    return this.stack.join(' -> ');
  }
}
