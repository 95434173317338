import {injectable, named} from 'inversify';
import {Store} from 'vuex';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import {feedbackModuleFactory} from '@reedsy/studio.shared/store/modules/feedback/feedback-module-factory';
import BookshelfStoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {ShareDBModule} from '@reedsy/studio.home.bookshelf/store/modules/sharedb';
import {UserSettingsModule} from '@reedsy/studio.home.bookshelf/store/modules/user-settings';

@injectable()
export class FeedbackModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,

    @$inject('StoreModule')
    @named(SharedStoreName.User)
    User: SharedUserModule,

    @$inject('StoreModule')
    @named(BookshelfStoreName.ShareDb)
    ShareDB: ShareDBModule,

    @$inject('StoreModule')
    @named(BookshelfStoreName.UserSettings)
    UserSettings: UserSettingsModule,
  ) {
    this.Module = feedbackModuleFactory(
      store,
      ShareDB,
      User,
      UserSettings,
    );
  }
}

export type FeedbackModule = InstanceType<FeedbackModuleFactory['Module']>;
