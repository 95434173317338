import {config} from '@reedsy/studio.shared/config';

export default function shortDateWithYear(
  dateInput: Date | number,
  yearFormat: Intl.DateTimeFormatOptions['year'] = '2-digit',
): string {
  dateInput = new Date(dateInput);
  const shortMonth = dateInput.toLocaleString(config.ui.defaultLocale, {month: 'short'});
  const year = dateInput.toLocaleString(config.ui.defaultLocale, {year: yearFormat});
  const day = dateInput.toLocaleString(config.ui.defaultLocale, {day: '2-digit'});
  return `${shortMonth} ${day}, ${year}`;
}
