export enum BookshelfRouteName {
  App = 'bookshelf_app',
  Activity = 'bookshelf_activity',
  Bookshelf = 'bookshelf_bookshelf',
  BooksList = 'bookshelf_booksList',
  ArchivedBooks = 'bookshelf_archivedBooks',

  // Account settings
  AccountSettings = 'bookshelf_accountSettings',
  GeneralAccountSettings = 'bookshelf_generalAccountSettings',
  SubscriptionAccountSettings = 'bookshelf_subscriptionAccountSettings',

  // Book paths
  Book = 'bookshelf_book',
  BookAccess = 'bookshelf_bookAccess',
  BookExports = 'bookshelf_bookExports',
  BookExportsList = 'bookshelf_bookExportsList',
  BookExportSubmission = 'bookshelf_bookExportSubmission',
  BookSettings = 'bookshelf_bookSettings',

  // Invitations
  InvitationAcceptance = 'bookshelf_invitationAcceptance',

  NotFound = 'bookshelf_notFound',
}
