
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {IBookDetails} from '@reedsy/reedsy-sharedb/lib/common/book/book-details';
import NoBookCoverPlaceholder from './no-book-cover-placeholder.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfCollaboratorsModule} from '@reedsy/studio.home.bookshelf/store/modules/collaborators';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';
import {CSSProperties} from 'vue';

@Component({
  components: {
    NoBookCoverPlaceholder,
  },
})
export default class BookCover extends BookshelfVue {
  @$lazyInjectStore(StoreName.Collaborators)
  public $collaborators: BookshelfCollaboratorsModule;

  @Prop({type: Object, required: true})
  public book: IBookDetails;

  @Prop({type: Boolean})
  public smallFont: boolean;

  public get coverUrl(): string {
    return this.book.coverUrl;
  }

  public get author(): IUserInfo {
    return this.$collaborators.userById[this.book.ownerId];
  }

  public get coverStyles(): CSSProperties {
    if (!this.coverUrl) return {};

    return {
      'background-image': `url('${this.coverUrl}')`,
    };
  }
}
