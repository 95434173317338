
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {IBookshelfBook} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-book';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import EntryAction from './entry-action.vue';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';

@Component({
  components: {
    EntryAction,
  },
})
export default class ArchivedBookAction extends BookshelfVue {
  @$lazyInject('Navigation')
  public navigation: INavigation;

  @$lazyInjectStore(StoreName.Modals)
  public $modals: BookshelfModalsModule;

  @$lazyInjectStore(SharedStoreName.User)
  public $user: SharedUserModule;

  @Prop({type: Object, required: true})
  public book: IBookshelfBook;

  public get isUserBookOwner(): boolean {
    return this.book.ownerId === this.$user.id;
  }

  public get restoreButtonTooltip(): string {
    return this.isUserBookOwner ? undefined : 'Only the book owner can restore the book';
  }

  public get bookEditorLink(): string {
    return this.navigation.getBookEditorUrl(this.book.uuid);
  }

  public openRestoreBookModal(): void {
    this.$modals.open({
      type: 'ConfirmBookArchive',
      context: {
        bookId: this.book.id,
      },
    });
  }
}
