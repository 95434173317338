import {VuexModule, Action, Mutation} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {injectable} from 'inversify';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {Store} from 'vuex';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import IApi from '@reedsy/studio.shared/services/api/i-api';

@injectable()
export class BooksTotalWordCountsModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,

    @$inject('Api')
    api: IApi,
  ) {
    @Module({name: StoreName.BooksTotalWordCounts, store})
    class BooksTotalWordCounts extends VuexModule {
      private booksTotalWordCounts: {[bookId: string]: number} = {};

      public get hasWordCountLoaded() {
        return (bookId: string) => {
          return bookId in this.booksTotalWordCounts;
        };
      }

      public get bookTotalWordCount() {
        return (bookId: string) => {
          return this.booksTotalWordCounts[bookId] || 0;
        };
      }

      @Action
      public async ensureTotalWordCountsLoaded(bookId: string): Promise<void> {
        if (this.hasWordCountLoaded(bookId)) return;
        const result = await api.getTotalWordCount(bookId);
        const {totalWordCount} = result;
        this.SET_BOOK_TOTAL_WORD_COUNT({
          bookId,
          totalWordCount,
        });
      }

      @Action
      public async removeBookTotalWordCount(bookId: string): Promise<void> {
        this.REMOVE_BOOK_TOTAL_WORD_COUNT(bookId);
      }

      @Mutation
      private SET_BOOK_TOTAL_WORD_COUNT({
        bookId,
        totalWordCount,
      }: {bookId: string; totalWordCount: number}): void {
        this.booksTotalWordCounts[bookId] = totalWordCount;
      }

      @Mutation
      private REMOVE_BOOK_TOTAL_WORD_COUNT(bookId: string): void {
        delete this.booksTotalWordCounts[bookId];
      }
    }
    this.Module = BooksTotalWordCounts;
  }
}

export type BooksTotalWordCountsModule = InstanceType<BooksTotalWordCountsModuleFactory['Module']>;
