import Notify from '@reedsy/studio.shared/services/notify/notify';
import loggerFactory from '@reedsy/studio.shared/services/logger/logger-factory';

const logger = loggerFactory.create('NotifyError');

export function NotifyError(message: string) {
  return function(target: any, propertyKey: string, descriptor: PropertyDescriptor): void {
    const method = descriptor.value;

    descriptor.value = function(...args: any[]) {
      try {
        const result = method
          .apply(this, args);

        if (result instanceof Promise) {
          return result.catch((error) => processError(error, message));
        } else {
          return result;
        }
      } catch (error) {
        processError(error, message);
      }
    };
  };
}

function processError(error: any, message: string): void {
  logger.error(error, {
    data: {message},
  });

  Notify.error({message});
}
