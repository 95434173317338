
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import ShareButton from './share-button.vue';
import {SocialNetwork} from './supported-networks';

@Component({
  components: {
    ShareButton,
  },
})
export default class TwitterShareButton extends BookshelfVue {
  @Prop({type: String, required: true})
  public sharedLink: string;

  @Prop({type: String, required: true})
  public title: string;

  @Prop({type: String, required: true})
  public via: string;

  @Prop({type: String, required: true})
  public hashtags: string;

  public readonly SocialNetwork = SocialNetwork;

  public get shareUrl(): string {
    return `https://twitter.com/share?url=${this.sharedLink}&text=${this.title}&via=${this.via}&hashtags=${this.hashtags}`;
  }
}
