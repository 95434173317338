<template>
  <rbe-export-action>
    <transition
      name="fade"
      mode="out-in"
    >
      <rbe-download-button
        v-if="isDownloadable"
        class="button button-with-icon reedsy-accented download-export-button"
      >
        <span>Download</span>
        <VuiIconDownload class="export-action-right-asset" />
      </rbe-download-button>
      <LoadingButton
        v-else
        type="button"
        class="button neutral cancel-export-button"
        :loading="isCancelling"
        @click="cancelExport"
      >
        Cancel
      </LoadingButton>
    </transition>
  </rbe-export-action>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {ExportState} from '@reedsy/reedsy-sharedb/lib/common/book-export/export-state';
import {IBookExport} from '@reedsy/reedsy-sharedb/lib/common/book/book-export';
import {BookDataStoreCoordinator} from '@reedsy/studio.home.bookshelf/store/helpers/book-data-store-coordinator';
import {$lazyInject} from '@reedsy/studio.home.bookshelf/inversify.config';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import LoadingButton from '@reedsy/studio.shared/components/loading-button.vue';
import Notify from '@reedsy/studio.shared/services/notify/notify';

@Component({
  components: {
    LoadingButton,
  },
})
export default class ExportAction extends BookshelfVue {
  @$lazyInject('BookshelfBookDataStoreCoordinator')
  public $bookDataStoreCoordinator: BookDataStoreCoordinator;

  @$lazyInject('Api')
  public $api: IApi;

  @Prop({type: Object, required: true})
  public bookExport: IBookExport;

  public isCancelling = false;

  public get isDownloadable(): boolean {
    return this.bookExport.exportData.state === ExportState.Processed;
  }

  public async cancelExport(): Promise<void> {
    try {
      this.isCancelling = true;
      await this.$api.cancelBookExport(this.bookExport.metadata.bookId, this.bookExport._id);
    } catch {
      this.isCancelling = false;
      Notify.error({message: 'Cannot cancel book export.'});
    }
  }
}
</script>
