import {injectable} from 'inversify';
import {config} from '@reedsy/studio.shared/config';
import {Crisp} from 'crisp-sdk-web';
import {ICrispManager} from './i-crisp-manager';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';

@injectable()
export class CrispManager implements ICrispManager {
  public get isInitialised(): boolean {
    return !!window.$crisp;
  }

  public initialise(): void {
    Crisp.configure(config.crisp.websiteId);
  }

  public setUser(user: IUserInfo): void {
    if (!this.isInitialised) {
      throw new Error('Crisp is not initialised');
    }

    if (!user) {
      throw new Error('Cannot set user details in crisp using falsy user');
    }

    window.$crisp.push([
      'set',
      'user:email',
      [user.email],
    ]);
    window.$crisp.push([
      'set',
      'user:nickname',
      [user.name],
    ]);
    window.$crisp.push([
      'set',
      'user:avatar',
      [user.avatarUrl],
    ]);
  }

  public openChat(): void {
    if (!this.isInitialised) {
      throw new Error('Crisp is not initialised');
    }

    window.$crisp.push([
      'do',
      'chat:open',
    ]);
  }
}
