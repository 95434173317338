import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!

  return (_openBlock(), _createBlock(_component_ConfirmModal, {
    id: _ctx.id,
    title: "Leave book",
    cancel: {label: 'Cancel'},
    confirm: {
      label: 'Leave book',
      handler: _ctx.leaveBook,
    },
    class: "danger"
  }, {
    default: _withCtx(() => [
      _createElementVNode("rbe-confirm-leave-book-modal", null, [
        _createElementVNode("p", null, "\n        Are you sure you want to leave “" + _toDisplayString(_ctx.bookTitle) + "”?\n        This will remove the book from your bookshelf and you will lose access to it.\n      ", 1)
      ])
    ]),
    _: 1
  }, 8, ["id", "confirm"]))
}