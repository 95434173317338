<template>
  <div :class="`font-size-${size}`">
    <rbe-premium-badge>
      <span>
        Premium
      </span>
      <PremiumIcon :size="size" />
    </rbe-premium-badge>
  </div>
</template>

<script lang="ts">
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import PremiumIcon from './premium-icon.vue';

@Component({
  components: {
    PremiumIcon,
  },
})
export default class PremiumBadge extends ClientSharedVue {
  @Prop({default: 'xs'})
  public size: string;
}
</script>

<style lang="scss" scoped>
rbe-premium-badge {
  @include font-family($controls, bold);

  color: var(--reedsy-accent-premium-onEmphasis);
  line-height: 1;
  background-color: var(--reedsy-accent-premium-emphasis);
  border-radius: 9999px;
  display: inline-flex;
  align-items: center;

  & > span {
    padding: 0 calc(2em / 3);
  }
}
</style>
