
import {Component, Inject, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import PlainModal from '@reedsy/studio.shared/components/modals/templates/plain-modal.vue';
import AddOnToggle from './add-on-toggle.vue';
import {config} from '@reedsy/studio.shared/config';
import {$lazyInject} from '@reedsy/studio.shared/inversify.config';
import {EditorTourName} from '@reedsy/studio.shared/services/spotlight-tour/tour-names';
import {ISpotlightTour} from '@reedsy/studio.shared/services/spotlight-tour/i-spotlight-tour';

@Component({
  components: {
    AddOnToggle,
    PlainModal,
  },
})
export default class SubscriptionTrialStarted extends mixins(ModalMixin, ClientSharedVue) {
  @$lazyInject('SpotlightTour')
  public $tour: ISpotlightTour;

  @Inject
  public tourBookUuid: string;

  public readonly config = config;
  public readonly cancelable = true;

  public startTour(): void {
    this.close();
    // TODO: [subscriptions] subscription-specific tour?
    this.$tour.start(EditorTourName.PlanFeatures, {bookUuid: this.tourBookUuid});
  }
}
