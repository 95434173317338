<template>
  <div
    class="error-message-wrapper"
    :class="{'is-visible': !!error}"
  >
    <transition name="fade">
      <p
        v-if="error"
        class="xs error-message"
      >
        {{ error }}
      </p>
    </transition>
  </div>
</template>
<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({})
export default class ErrorMessage extends ClientSharedVue {
  @Prop()
  public error: string;
}
</script>

<style lang="scss" scoped>
.error-message-wrapper {
  @include transition(height);

  height: 0;

  &.is-visible {
    height: #{$error-message-height + $space-xs};
  }
}

.error-message {
  padding-top: $space-xs;
  height: $error-message-height;
  color: var(--reedsy-danger);
}
</style>
