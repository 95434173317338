
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import {MetaRawLocation} from '@reedsy/studio.shared/router/reedsy-router';
import NewExportButton from '@reedsy/studio.home.bookshelf/components/exports-page-actions/new-export-button.vue';

@Component({
  components: {
    NewExportButton,
  },
})
export default class EmptyListPlaceholder extends BookshelfVue {
  public get bookExportSubmissionRoute(): MetaRawLocation {
    return {
      name: BookshelfRouteName.BookExportSubmission,
    };
  }
}
