
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {IBookshelfBook} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-book';
import {$lazyInject} from '@reedsy/studio.home.bookshelf/inversify.config';
import EntryAction from './entry-action.vue';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import {encodeUUID} from '@reedsy/utils.uuid';
import {IMetaLocation} from '@reedsy/studio.shared/router/reedsy-router';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';

@Component({
  components: {
    EntryAction,
  },
})
export default class BookActions extends BookshelfVue {
  @$lazyInject('Navigation')
  public navigation: INavigation;

  @Prop({type: Object, required: true})
  public book: IBookshelfBook;

  public get bookLocation(): IMetaLocation {
    return {
      name: BookshelfRouteName.Book,
      params: {
        bookUuid: encodeUUID(this.book.uuid),
      },
    };
  }

  public get bookEditorLink(): string {
    return this.navigation.getBookEditorUrl(this.book.uuid);
  }
}
