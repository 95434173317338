import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelBodyTitle = _resolveComponent("PanelBodyTitle")!
  const _component_TabOptions = _resolveComponent("TabOptions")!
  const _component_ExportThemePreview = _resolveComponent("ExportThemePreview")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, { class: "theme-selector-panel" }, {
    default: _withCtx(() => [
      _createVNode(_component_PanelBodyTitle, { class: "no-top-space" }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("\n      Choose a template\n    ")
        ])),
        _: 1
      }),
      _cache[2] || (_cache[2] = _createTextVNode()),
      _cache[3] || (_cache[3] = _createElementVNode("p", null, "How would you like us to typeset your book? Choose from a range of themes crafted by professional designers.", -1)),
      _cache[4] || (_cache[4] = _createTextVNode()),
      _createVNode(_component_TabOptions, {
        modelValue: _ctx.theme,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.theme) = $event)),
        options: _ctx.themeOptions,
        class: "theme-options"
      }, null, 8, ["modelValue", "options"]),
      _cache[5] || (_cache[5] = _createTextVNode()),
      _createVNode(_component_ExportThemePreview)
    ]),
    _: 1
  }))
}