<template>
  <rbe-book-entry class="slide-entry">
    <a
      :href="bookEditorLink"
      target="_blank"
      class="book-link"
      :aria-label="`Continue writing ${book.title}`"
    >

      <BookCover
        :book="book"
        class="book-cover"
      />

      <rbe-book-title>
        <p
          class="ellipsis"
        >
          {{ book.title }}
        </p>
      </rbe-book-title>
    </a>
    <ShareIndicator
      class="share-indicator"
      :book-id="book.id"
    />
  </rbe-book-entry>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {IBookshelfEntry} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-entry';
import {IBookshelfBook} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-book';
import BookCover from '@reedsy/studio.home.bookshelf/components/book-cover/book-cover.vue';
import {$lazyInject} from '@reedsy/studio.home.bookshelf/inversify.config';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import ShareIndicator from '@reedsy/studio.home.bookshelf/components/share-indicator/share-indicator.vue';

@Component({
  components: {
    BookCover,
    ShareIndicator,
  },
})
export default class BookEntry extends BookshelfVue {
  @$lazyInject('Navigation')
  public navigation: INavigation;

  @Prop({type: Object, required: true})
  public entry: IBookshelfEntry;

  public get book(): IBookshelfBook {
    return this.entry.item as IBookshelfBook;
  }

  public get bookEditorLink(): string {
    return this.navigation.getBookEditorUrl(this.book.uuid);
  }
}
</script>

<style lang="scss" scoped>
rbe-book-entry {
  position: relative;

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  .book-cover {
    width: 100%;
    height: 100%;
  }
}
</style>
