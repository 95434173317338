
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import NotFoundPartial from '@reedsy/studio.shared/components/not-found/not-found-partial.vue';
import {MetaRawLocation} from '@reedsy/studio.shared/router/reedsy-router';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({
  components: {
    NotFoundPartial,
  },
})
export default class NotFound extends ClientSharedVue {
  public get bookshelfRoute(): MetaRawLocation {
    return {
      name: BookshelfRouteName.Bookshelf,
    };
  }
}
