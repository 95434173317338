import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "not-found-view" }
const _hoisted_2 = { class: "vui-wrapper-sm text-center" }
const _hoisted_3 = { class: "large space-top-2em" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "four-oh-four" }, "\n        404\n      ", -1)),
      _cache[1] || (_cache[1] = _createTextVNode()),
      _cache[2] || (_cache[2] = _createElementVNode("h1", null, "We couldn’t find the page you were looking for", -1)),
      _cache[3] || (_cache[3] = _createTextVNode()),
      _createElementVNode("p", _hoisted_3, [
        _renderSlot(_ctx.$slots, "home-link", {}, undefined, true)
      ])
    ])
  ]))
}