import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.hasInputGroup ? 'VuiInputGroup' : 'div'), null, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "left", {}, undefined, true),
        _cache[2] || (_cache[2] = _createTextVNode()),
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), _mergeProps(_ctx.$attrs, {
          ref: _ctx.inputFieldRef,
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
          class: {invalid: _ctx.error.hasError},
          onValidationError: _cache[1] || (_cache[1] = ($event: any) => (_ctx.error = $event))
        }), null, 16, ["modelValue", "class"])),
        _cache[3] || (_cache[3] = _createTextVNode()),
        _renderSlot(_ctx.$slots, "right", {}, undefined, true)
      ]),
      _: 3
    })),
    _cache[4] || (_cache[4] = _createTextVNode()),
    _createVNode(_component_ErrorMessage, {
      error: _ctx.error.message
    }, null, 8, ["error"])
  ]))
}