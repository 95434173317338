
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import BookPageSelector from './book-page-selector.vue';
import BookBackLink from './book-back-link.vue';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import ContinueWritingButton from '@reedsy/studio.home.bookshelf/components/continue-writing-button/continue-writing-button.vue';

@Component({
  components: {
    BookPageSelector,
    BookBackLink,
    ContinueWritingButton,
  },
})
export default class BookTopBar extends BookshelfVue {
  @$lazyInjectStore(StoreName.CurrentBook)
  public $currentBook: CurrentBookModule;
}
