import {injectable} from 'inversify';
import ReedsyAnalyticsManager from '@reedsy/analytics-manager';
import {$inject} from '@reedsy/studio.shared/types';
import {AnalyticsEvent} from './analytics-event';
import {IAnalyticsService} from './i-analytics-service';

@injectable()
export class AnalyticsService implements IAnalyticsService {
  @$inject('StudioAnalyticsManager')
  public _manager: ReedsyAnalyticsManager;

  public async initialise(uuid: string, email: string): Promise<void> {
    this._manager.pushEvent(AnalyticsEvent.PageView, {
      user_uuid: uuid,
      user_email: email,
    });
  }
}
