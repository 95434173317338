<template>
  <Panel
    class="submit-book-export-panel"
  >
    <PanelBodyTitle class="no-top-space">
      Free conversion to EPUB and Print-ready PDF
    </PanelBodyTitle>
    <p>Configure your export options, then typeset your book.</p>
    <LoadingButton
      class="button export-button"
      type="button"
      data-test="export-book-button"
      :loading="isSubmittingExportRequest"
      @click="exportBook"
    >
      Export book
    </LoadingButton>
  </Panel>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Panel from '@reedsy/studio.shared/components/panel/panel.vue';
import LoadingButton from '@reedsy/studio.shared/components/loading-button.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {BookExportSubmissionModule} from '@reedsy/studio.home.bookshelf/store/modules/book-export-submission';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import PanelBodyTitle from '@reedsy/studio.shared/components/panel/panel-body-title.vue';

@Component({
  components: {
    Panel,
    LoadingButton,
    PanelBodyTitle,
  },
})
export default class SubmitSection extends BookshelfVue {
  @$lazyInjectStore(StoreName.BookExportSubmission)
  public $bookExportSubmission: BookExportSubmissionModule;

  public get isSubmittingExportRequest(): boolean {
    return this.$bookExportSubmission.isSubmittingExportRequest;
  }

  public exportBook(): void {
    this.$bookExportSubmission.startBookExport();
  }
}
</script>

<style lang="scss" scoped>
.submit-book-export-panel {
  .export-button {
    margin-top: $space-md;
    width: 100%;
  }
}
</style>
