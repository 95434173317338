
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import MainLayout from '@reedsy/studio.home.bookshelf/components/layouts/main-layout.vue';
import AccountSettingsMenu from '@reedsy/studio.home.bookshelf/components/account-settings-menu/account-settings-menu.vue';
import {ACCOUNT_SETTINGS_ROUTE_NAME_MAPPING} from '@reedsy/studio.home.bookshelf/utils/account-settings-route-name-mapping';

@Component({
  components: {
    MainLayout,
    AccountSettingsMenu,
  },
})
export default class AccountSettings extends BookshelfVue {
  public isMobileSettingsShown = true;

  public get settingTitle(): string {
    const routeName = this.$route.name as string;
    return ACCOUNT_SETTINGS_ROUTE_NAME_MAPPING[routeName];
  }
}
