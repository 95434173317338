
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import {MetaRawLocation} from '@reedsy/studio.shared/router/reedsy-router';

@Component({})
export default class BookBackLink extends BookshelfVue {
  public get bookshelfRoute(): MetaRawLocation {
    return {
      name: BookshelfRouteName.Bookshelf,
    };
  }
}
