import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageUploader = _resolveComponent("ImageUploader")!

  return (_openBlock(), _createBlock(_component_ImageUploader, {
    ref: "imageUploader",
    url: _ctx.coverUrl,
    "is-uploading": _ctx.isUploadingCover,
    disabled: _ctx.isRemovingCover || null,
    "upload-progress": _ctx.progressPercentage,
    onError: _ctx.handleError,
    onFileUpdated: _ctx.uploadBookCover,
    onRemoveFile: _ctx.removeBookCover
  }, null, 8, ["url", "is-uploading", "disabled", "upload-progress", "onError", "onFileUpdated", "onRemoveFile"]))
}