<template>
  <div>
    <div
      ref="wrapper"
      class="dropdown"
      :class="{invalid: error.hasError}"
    >
      <BaseInput
        ref="input"
        :model-value="value"
        v-bind="$attrs"
        class="hidden-input"
        @validation-error="error = $event"
      />
      <VuiDropdown
        ref="dropdown"
        v-model="value"
        v-bind="$props"
        class="full-width"
        :class="theme"
        :sync-options-width="true"
      />
    </div>
    <ErrorMessage
      :error="error.message"
    />
  </div>
</template>

<script lang="ts">
import {Component, Emit, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {IDropdownOption} from './i-option';
import ErrorMessage from '@reedsy/studio.shared/components/forms/error-message.vue';
import BaseInput from '@reedsy/studio.shared/components/forms/base-input.vue';
import ValidationMixin from '@reedsy/studio.shared/components/forms/mixins/validation-mixin';

@Component({
  inheritAttrs: false,
  components: {
    ErrorMessage,
    BaseInput,
  },
})
export default class Dropdown extends ClientSharedVue {
  @Prop({required: true})
  public override readonly modelValue: any;

  @Prop({type: Array, required: true})
  public options: IDropdownOption[];

  @Prop({type: String, required: false})
  public placeholder: string;

  @Prop({type: Boolean, default: null})
  public disabled: boolean;

  @Prop({type: Number})
  public maxWidth: number;

  @Prop({type: Boolean, default: false})
  public syncOptionsWidth: boolean;

  @Prop({type: Boolean, default: false})
  public selectParent: boolean;

  @Prop({type: String})
  public theme: string;

  @Prop({type: String})
  public label: string;

  public error = {hasError: false, message: ''};

  public get value(): any {
    return this.modelValue;
  }

  public set value(value: any) {
    this.update(value);
    this.$nextTick(this.validateField);
  }

  @Emit('update:modelValue')
  private update(value: any): any {
    return value;
  }

  private validateField(): void {
    const input = this.$refs.input as ValidationMixin;
    input.validateField();
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
  .hidden-input {
    display: none;
  }

  &.invalid .vui-dropdown {
    border-color:  var(--reedsy-danger);
  }
}
</style>
