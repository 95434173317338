import {injectable, named} from 'inversify';
import {BooksListRouterPlugin} from '@reedsy/studio.home.bookshelf/store/plugins/books-list-router-plugin';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModule} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf';
import {IBooksListQueryParams} from './books-list-query-params';
import {BookFilterType} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/book-filter-type';
import {IStoreListener} from '@reedsy/studio.shared/store/helpers/store-listener/i-store-listener';
import IBookshelfStoreMapping from '@reedsy/studio.home.bookshelf/store/bookshelf-store-mapping';
import {IBookshelfRoute} from '@reedsy/studio.home.bookshelf/router/i-bookshelf-route';

@injectable()
export class BookshelfQueryParamsPluginFactory extends BooksListRouterPlugin {
  @$inject('StoreModule')
  @named(StoreName.Bookshelf)
  public readonly _bookshelf: BookshelfModule;

  @$inject('StoreListener')
  public readonly _storeListener: IStoreListener<IBookshelfStoreMapping>;

  public override setup(): void {
    this._storeListener.subscribe(
      StoreName.Bookshelf,
      '_SEARCH_VALUE',
      () => this.updateQuerySearchValue(),
    );

    this._storeListener.subscribe(
      StoreName.Bookshelf,
      'BOOKS_FILTER_TYPE',
      () => this.updateQueryBookFilterType(),
    );
  }

  public override async afterEach(to: IBookshelfRoute): Promise<void> {
    this.setInitialSearchValues(to.query);
  }

  private updateQuerySearchValue(): void {
    this.checkQuery('search', this._bookshelf.searchValue);
  }

  private updateQueryBookFilterType(): void {
    this.checkQuery('bookFilterType', this._bookshelf.bookFilterType, BookFilterType.AllBooks);
  }

  private setInitialSearchValues(query: IBooksListQueryParams): void {
    const searchValue = query.search;
    const bookFilterType = query.bookFilterType;

    if (searchValue !== this._bookshelf.searchValue) {
      this._bookshelf.search(searchValue);
    }
    if (bookFilterType !== this._bookshelf.bookFilterType) {
      this._bookshelf.BOOKS_FILTER_TYPE(bookFilterType);
    }
  }

  private checkQuery(key: keyof IBooksListQueryParams, storeValue: string, defaultValue = ''): void {
    const route = this._router.currentRoute.value;
    if (!this.isBooksListRoute(route)) return;
    if (storeValue === (route.query[key] || defaultValue)) return;

    const query = {
      ...route.query,
      [key]: storeValue,
    };

    if (query[key] === defaultValue) delete query[key];

    this._router.replace({query});
  }
}
