import {Constructor} from '@reedsy/utils.types';
import {AnalyticsPluginFactory} from './analytics';
import OrientationPluginFactory from './browser/orientation';
import BrowserRoutePluginFactory from './browser/route';
import ScreenSizePluginFactory from './browser/screen-size';
import {IStorePluginFactory} from './i-store-plugin-factory';

export const PLUGINS: readonly Constructor<IStorePluginFactory>[] = Object.freeze([
  AnalyticsPluginFactory,
  BrowserRoutePluginFactory,
  ScreenSizePluginFactory,
  OrientationPluginFactory,
]);
