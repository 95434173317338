import {Container} from 'inversify';
import {clientSharedContainer} from '@reedsy/studio.shared/inversify.config';
import {PLUGINS} from '@reedsy/studio.home.bookshelf/store/plugins';
import IBookshelfStoreMapping, {BOOKSHELF_STORE_FACTORY_MAPPING} from './store/bookshelf-store-mapping';
import BookshelfShareDbConnection from './services/bookshelf-sharedb-connection';
import {BookshelfRedirectingShareDb} from '@reedsy/studio.home.bookshelf/services/bookshelf-redirecting-sharedb';
import {bindModules} from '@reedsy/studio.shared/store/bind-modules';
import {UsersLoader} from './store/modules/collaborators/users-loader';
import {TypedContainer} from '@reedsy/utils.inversify';
import {BookshelfBindings} from './types';
import {$getLazyDecorators} from '@reedsy/utils.inversify';
import {BookDataStoreCoordinator} from './store/helpers/book-data-store-coordinator';
import {BookColorPool} from './utils/book-color-pool/book-color-pool';
import {TypedDecorator} from '@reedsy/utils.types';
import {BookshelfBookShareDbManager} from './services/bookshelf-book-sharedb-manager';
import BookshelfBookShareDbConnection from './services/bookshelf-book-sharedb-connection';
import {BookshelfBookRedirectingShareDb} from './services/bookshelf-book-redirecting-sharedb';
import {SingleUseBookShareDb} from './utils/single-use-book-sharedb/single-use-book-sharedb';
import {CrispManager} from './utils/crisp/crisp-manager';

export const bookshelfContainer: TypedContainer<BookshelfBindings> = new Container();
bookshelfContainer.parent = clientSharedContainer as Container;

bindModules(bookshelfContainer, BOOKSHELF_STORE_FACTORY_MAPPING);

PLUGINS.forEach((plugin) => {
  bookshelfContainer.bind('BookshelfStorePlugins')
    .to(plugin);
});

bookshelfContainer.bind('UsersLoader').to(UsersLoader).inSingletonScope();
bookshelfContainer.bind('BookshelfShareDb')
  .to(BookshelfRedirectingShareDb)
  .inSingletonScope();

bookshelfContainer.bind('BookshelfBookDataStoreCoordinator')
  .to(BookDataStoreCoordinator)
  .inSingletonScope();

bookshelfContainer.bind('BookshelfShareDbConnection')
  .to(BookshelfShareDbConnection)
  .inSingletonScope();

bookshelfContainer
  .bind('BookshelfBookShareDbConnection')
  .to(BookshelfBookShareDbConnection);

bookshelfContainer
  .bind('BookshelfBookShareDb')
  .to(BookshelfBookRedirectingShareDb);

bookshelfContainer
  .bind('BookshelfBookShareDbFactory')
  .toDynamicValue(({container}) => {
    return () => container.get('BookshelfBookShareDb');
  });

bookshelfContainer.bind('BookshelfBookShareDbManager')
  .to(BookshelfBookShareDbManager)
  .inSingletonScope();

bookshelfContainer.bind('BookColorPool')
  .to(BookColorPool);

bookshelfContainer.bind('SingleUseBookShareDb')
  .to(SingleUseBookShareDb);

bookshelfContainer.bind('CrispManager')
  .to(CrispManager)
  .inSingletonScope();

export const {$lazyInject, $lazyInjectNamed} = $getLazyDecorators(bookshelfContainer);
export function $lazyInjectStore<K extends keyof IBookshelfStoreMapping>(
  key: K,
): TypedDecorator<IBookshelfStoreMapping[K]> {
  return $lazyInjectNamed('StoreModule', key);
}
