import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VInput = _resolveComponent("VInput")!
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!
  const _directive_root_class = _resolveDirective("root-class")!

  return (_openBlock(), _createBlock(_component_ConfirmModal, {
    id: _ctx.id,
    confirm: {
      label: 'Delete',
      handler: _ctx.deleteBook,
      disabled: !_ctx.titlesAreSame,
    },
    title: "Are you sure you want delete this book?",
    class: "danger"
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", null, [
        _cache[1] || (_cache[1] = _createTextVNode("\n      Your book will be fully deleted and it cannot be restored.\n      To confirm the book deletion, enter “")),
        _createElementVNode("rbe-book-title-example", null, _toDisplayString(_ctx.bookTitle), 1),
        _cache[2] || (_cache[2] = _createTextVNode("” below.\n    "))
      ]),
      _cache[3] || (_cache[3] = _createTextVNode()),
      _withDirectives(_createVNode(_component_VInput, {
        ref: "bookTitleInput",
        modelValue: _ctx.typedBookTitle,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.typedBookTitle) = $event)),
        type: "text",
        placeholder: _ctx.bookTitle,
        required: ""
      }, null, 8, ["modelValue", "placeholder"]), [
        [_directive_root_class, 'book-title']
      ])
    ]),
    _: 1
  }, 8, ["id", "confirm"]))
}