<template>
  <div class="flex-centered">
    <p>{{ title }}</p>

    <SkeletonLoader
      v-if="loading"
      class="info-text"
    />
    <p
      v-else
      class="info-text"
    >
      <slot />
    </p>
  </div>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';

@Component({
  components: {
    SkeletonLoader,
  },
})
export default class PaymentInfoItem extends ClientSharedVue {
  @Prop({type: String, required: true})
  public title: string;

  @Prop({type: Boolean, required: true})
  public loading: boolean;
}
</script>

<style lang="scss" scoped>
.info-text {
  font-size: $font-size-lg;

  @include font-family($controls, bold);
}

:deep(.skeleton-loader) {
  margin: $space-xs 0;
  height: 1em;
}
</style>
