import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "slide-entry" }
const _hoisted_2 = { class: "ellipsis" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiLoadingIndicator = _resolveComponent("VuiLoadingIndicator")!

  return (_openBlock(), _createElementBlock("rbe-book-import-entry", _hoisted_1, [
    _createElementVNode("rbe-book-import-content", null, [
      _createElementVNode("rbe-book-cover-loader", null, [
        _createVNode(_component_VuiLoadingIndicator, { class: "lg" }),
        _cache[0] || (_cache[0] = _createTextVNode()),
        _cache[1] || (_cache[1] = _createElementVNode("p", null, "Importing", -1))
      ])
    ]),
    _cache[2] || (_cache[2] = _createTextVNode()),
    _createElementVNode("rbe-book-title", null, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1)
    ])
  ]))
}