import {ResourcesRole} from '@reedsy/utils.reedsy-resources';
import {injectable, named} from 'inversify';
import {BaseRoleAuthPluginFactory} from '@reedsy/studio.shared/store/plugins/role-auth';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';

@injectable()
export class RoleAuthPluginFactory extends BaseRoleAuthPluginFactory {
  @$inject('StoreModule')
  @named(SharedStoreName.User)
  public readonly _user: SharedUserModule;

  @$inject('StoreModule')
  @named(StoreName.CurrentBook)
  public _currentBook: CurrentBookModule;

  public get userRole(): ResourcesRole {
    return this._currentBook.userRole(this._user.uuid);
  }
}
