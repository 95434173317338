<template>
  <rbe-feedback-field>
    <label class="flex-top">
      <span>{{ label }}</span>
    </label>
    <slot />
    <ExpandTransition>
      <rbe-extra-field-wrapper v-if="showExtraField">
        <slot name="extra-field" />
      </rbe-extra-field-wrapper>
    </ExpandTransition>
  </rbe-feedback-field>
</template>

<script lang="ts">
import {
  Component,
  Prop,
} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import ExpandTransition from '@reedsy/studio.shared/components/transitions/expand-transition.vue';

@Component({
  components: {
    ExpandTransition,
  },
})
export default class FeedbackField extends ClientSharedVue {
  @Prop({type: String, required: true})
  public label: string;

  @Prop({type: Boolean, required: true})
  public showExtraField: boolean;
}
</script>

<style lang="scss" scoped>
rbe-feedback-field label > span {
  margin-bottom: $space-sm;
}

rbe-extra-field-wrapper {
  padding-top: $space-base;

  :deep(.input-wrapper) {
    width: 100%;
  }
}
</style>
