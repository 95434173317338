
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import SunIcon from '@reedsy/studio.shared/components/icons/icon-sun.vue';
import MoonIcon from '@reedsy/studio.shared/components/icons/icon-moon.vue';

@Component({
  components: {
    SunIcon,
    MoonIcon,
  },
})
export default class TopBarButton extends BookshelfVue {
  @Prop({type: String, required: true})
  public icon: string;
}
