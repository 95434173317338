
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import ScalingPremiumBadge from './scaling-premium-badge.vue';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import TrialCountdownMixin from '@reedsy/studio.shared/mixins/subscriptions/trial-countdown';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';

@Component({
  components: {
    ScalingPremiumBadge,
  },
})
export default class PremiumAdBanner extends mixins(ClientSharedVue, TrialCountdownMixin) {
  @$lazyInject('SubscriptionModal')
  public $subscriptionModal: ISubscriptionModalService;

  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  public get shouldShow(): boolean {
    if (!this.$subscription.paidFeatures) return false;
    return !this.$subscription.hasAnyPaidFeature || this.$subscription.isTrial;
  }
}
