
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {ITabItem} from '@reedsy/studio.shared/components/tabs/i-tab-item';
import Tabs from '@reedsy/studio.shared/components/tabs/tabs.vue';
import NewExportButton from './new-export-button.vue';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';

@Component({
  components: {
    Tabs,
    NewExportButton,
  },
})
export default class ExportsPageActions extends BookshelfVue {
  public readonly BookshelfRouteName = BookshelfRouteName;

  public tabs: ITabItem[] = [
    {text: 'New export', value: BookshelfRouteName.BookExportSubmission},
    {text: 'Previous exports', value: BookshelfRouteName.BookExportsList},
  ];

  public get activeTab(): BookshelfRouteName {
    return this.$route.name as BookshelfRouteName;
  }

  public set activeTab(routeName: BookshelfRouteName) {
    this.$router.push({
      name: routeName,
    });
  }
}
