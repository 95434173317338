<template>
  <rbe-top-banner>
    <div class="vui-wrapper">
      <slot />
    </div>
  </rbe-top-banner>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';

@Component({})
export default class TopBanner extends BookshelfVue {
}
</script>

<style lang="scss" scoped>
rbe-top-banner {
  color: var(--reedsy-inverted-onDefault);
  background: var(--reedsy-inverted);

  .vui-wrapper {
    container-type: inline-size;
  }
}
</style>
