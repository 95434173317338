import {TrimSize} from '@reedsy/schemas.workers.book-export';
import {ITrimSizeDetailsConfig} from './i-trim-size-config';

export const TRIM_SIZE_CONFIG = Object.freeze({
  [TrimSize.Digest]: {
    graphicalSelectorName: 'Digest',
    name: 'Digest',
    height: 8.5,
    width: 5.5,
  },
  [TrimSize.Pocket]: {
    graphicalSelectorName: 'Pocket',
    name: 'Pocket',
    height: 6.87,
    width: 4.25,
  },
  [TrimSize.Standard]: {
    graphicalSelectorName: 'Standard',
    name: 'Reedsy Standard',
    height: 8,
    width: 5,
  },
  [TrimSize.Trade]: {
    graphicalSelectorName: 'Trade',
    name: 'Trade',
    height: 9,
    width: 6,
  },
} as const satisfies Record<TrimSize, ITrimSizeDetailsConfig>);
