
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';

@Component({
  components: {
    SkeletonLoader,
  },
})
export default class PaymentInfoItem extends ClientSharedVue {
  @Prop({type: String, required: true})
  public title: string;

  @Prop({type: Boolean, required: true})
  public loading: boolean;
}
