import {deepFreeze} from '@reedsy/utils.object';
import {EditorTourName, TourName} from './tour-names';
import {StudioApp} from '@reedsy/studio.shared/services/navigation/studio-app';

export const TOUR_QUERY_KEY = 'tour';

export interface ITourRouteEntry {
  app: StudioApp;
  path: string;
}

export const TOUR_ROUTES = deepFreeze({
  [EditorTourName.PlanFeatures]: {
    app: 'bookEditor',
    path: '/board/:bookUuid',
  },
} as const satisfies Record<TourName, ITourRouteEntry>);

export type TourRoutes = typeof TOUR_ROUTES;
