
import {Component, Provide} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import ValidationMixin from './mixins/validation-mixin';

@Component({})
export default class Form extends ClientSharedVue {
  private registeredFields: Set<ValidationMixin> = new Set();

  @Provide()
  public registerField(field: ValidationMixin): void {
    this.registeredFields.add(field);
  }

  @Provide()
  public unregisterField(field: ValidationMixin): void {
    this.registeredFields.delete(field);
  }

  public async onSubmit(event: SubmitEvent): Promise<void> {
    const isFormValid = this.validate();
    if (!isFormValid) return event.stopImmediatePropagation();
    await this.$nextTick();
    this.resetFields();
  }

  public validate(): boolean {
    let isFormValid = true;
    for (const field of this.registeredFields) {
      field.touched = true;
      isFormValid = field.validateField() && isFormValid;
    }
    return isFormValid;
  }

  private resetFields(): void {
    for (const field of this.registeredFields) {
      field.reset();
    }
  }
}
