import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PeriodToggle = _resolveComponent("PeriodToggle")!
  const _component_FeatureSummaries = _resolveComponent("FeatureSummaries")!
  const _component_AnnualSaving = _resolveComponent("AnnualSaving")!
  const _component_SubscriptionBaseModal = _resolveComponent("SubscriptionBaseModal")!

  return (_openBlock(), _createBlock(_component_SubscriptionBaseModal, {
    id: _ctx.id,
    title: _ctx.title,
    subtitle: "Unlock Studio with powerful add-ons.",
    class: "subscription-features-modal"
  }, {
    "header-append": _withCtx(() => [
      _createVNode(_component_PeriodToggle, {
        modelValue: _ctx.billAnnually,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.billAnnually) = $event))
      }, null, 8, ["modelValue"])
    ]),
    "footer-prepend": _withCtx(() => [
      _createElementVNode("span", null, [
        _createVNode(_component_AnnualSaving),
        _cache[3] || (_cache[3] = _createTextVNode("\n        when you purchase multiple add-ons\n      "))
      ])
    ]),
    action: _withCtx(() => [
      _createElementVNode("button", {
        ref: "continue",
        class: "button reedsy-accented",
        type: "button",
        disabled: !_ctx.hasSelectedAnything,
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.continueToPayment && _ctx.continueToPayment(...args)))
      }, "\n        Continue\n      ", 8, _hoisted_1)
    ]),
    default: _withCtx(() => [
      _cache[4] || (_cache[4] = _createTextVNode()),
      _createVNode(_component_FeatureSummaries, {
        modelValue: _ctx.selectedProducts,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedProducts) = $event))
      }, null, 8, ["modelValue"]),
      _cache[5] || (_cache[5] = _createTextVNode()),
      _cache[6] || (_cache[6] = _createTextVNode())
    ]),
    _: 1
  }, 8, ["id", "title"]))
}