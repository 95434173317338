<template>
  <ConfirmModal
    :id="id"
    title="Resend invitation"
    :cancel="{label: 'Cancel'}"
    :confirm="{
      label: 'Resend',
      handler: resendInvitation,
    }"
  >
    <rbe-confirm-resend-invitation-modal>
      <p>
        Are you sure you want to resend a collaboration invitation to
        <span class="emphasized">{{ context.email }}</span>?
      </p>
    </rbe-confirm-resend-invitation-modal>
  </ConfirmModal>
</template>

<script lang="ts">
import {Component, Prop, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ConfirmModal from '@reedsy/studio.shared/components/modals/templates/confirm-modal.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {PropType} from 'vue';
import {IConfirmResendInvitationModalArgs} from '@reedsy/studio.shared/components/modals/i-confirm-resend-invitation-modal-args';
import Notify from '@reedsy/studio.shared/services/notify/notify';
import {$lazyInject} from '@reedsy/studio.shared/inversify.config';
import IApi from '@reedsy/studio.shared/services/api/i-api';

@Component({
  components: {
    ConfirmModal,
  },
})
export default class ConfirmResendInvitationModal extends mixins(ModalMixin, ClientSharedVue) {
  @$lazyInject('Api')
  public readonly api: IApi;

  @Prop({type: Object as PropType<IConfirmResendInvitationModalArgs>, default: {}})
  public context: IConfirmResendInvitationModalArgs;

  public readonly cancelable = true;

  public async resendInvitation(): Promise<void> {
    try {
      await this.api.resendInvitationEmail({
        bookId: this.context.bookId,
        invitationId: this.context.invitationId,
      });

      Notify.success({message: `Invitation sent to ${this.context.email}`});
    } catch (error) {
      Notify.error({message: `Cannot send invitation to ${this.context.email}`});
      throw error;
    }
  }
}
</script>

<style lang="scss" scoped>
.emphasized {
  @include font-family($controls, bold);
}
</style>
