import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddOnToggle = _resolveComponent("AddOnToggle")!
  const _component_PlainModal = _resolveComponent("PlainModal")!

  return (_openBlock(), _createBlock(_component_PlainModal, {
    id: _ctx.id,
    headless: true,
    class: "trial-started-modal accent-premium extra-wide no-padding"
  }, {
    default: _withCtx(() => [
      _createElementVNode("rbe-trial-started", null, [
        _createElementVNode("rbe-toggles", null, [
          _createVNode(_component_AddOnToggle, {
            title: "Studio Pro",
            "model-value": true,
            disabled: true
          }),
          _cache[3] || (_cache[3] = _createTextVNode()),
          _createVNode(_component_AddOnToggle, {
            title: "Outlining",
            "model-value": true,
            disabled: true
          })
        ]),
        _cache[6] || (_cache[6] = _createTextVNode()),
        _createElementVNode("h1", null, "\n        Your " + _toDisplayString(_ctx.config.stripe.trialDays) + " day trial has started\n      ", 1),
        _cache[7] || (_cache[7] = _createTextVNode()),
        _createElementVNode("p", null, [
          _cache[4] || (_cache[4] = _createTextVNode("\n        You now have access to Studio Pro, and the Outlining add-ons.\n        ")),
          (_ctx.tourBookUuid)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, "\n          Take a quick tour of our premium add-ons or jump straight into\n          writing.\n        "))
            : _createCommentVNode("", true)
        ]),
        _cache[8] || (_cache[8] = _createTextVNode()),
        (_ctx.tourBookUuid)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("button", {
                ref: "startTour",
                class: "button reedsy-accented",
                type: "button",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.startTour && _ctx.startTour(...args)))
              }, "\n          Take a tour\n        ", 512),
              _cache[5] || (_cache[5] = _createTextVNode()),
              _createElementVNode("button", {
                class: "button-subtle",
                type: "button",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
              }, "\n          Skip tour\n        ")
            ], 64))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "button reedsy-accented",
              type: "button",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }, "\n          Let’s go!\n        "))
      ])
    ]),
    _: 1
  }, 8, ["id"]))
}