import {RouterPlugin} from '@reedsy/studio.shared/store/plugins/router-plugin';
import {injectable} from 'inversify';
import {isBookshelfRoute} from '@reedsy/studio.home.bookshelf/router/helpers/is-bookshelf-route';
import {IBookshelfRoute} from '@reedsy/studio.home.bookshelf/router/i-bookshelf-route';

@injectable()
export default class BookshelfRouterPlugin extends RouterPlugin<IBookshelfRoute> {
  protected override shouldApplyBefore(to: IBookshelfRoute, _from: IBookshelfRoute): boolean {
    return isBookshelfRoute(to);
  }

  protected override shouldApplyAfter(to: IBookshelfRoute, _from: IBookshelfRoute): boolean {
    return isBookshelfRoute(to);
  }
}
