<template>
  <MainLayout v-if="book">
    <template #top-part>
      <rbe-top-part-wrapper class="themed dark-theme">
        <BookTopBar />
        <BookSkeletonLoader
          v-if="isLoading"
          data-test="skeleton-loader"
        />
        <BookTopInfo
          v-else
          :book="book"
        >
          <template #book-subtitle>
            <RouterView
              name="routeDescription"
            />
          </template>
        </BookTopInfo>
      </rbe-top-part-wrapper>
    </template>
    <template #above-content>
      <RouterView
        v-if="!isLoading"
        name="aboveContent"
      />
    </template>
    <rbe-book-content class="overflow-content">
      <RouterView
        v-if="!isLoading"
        data-test="router-view"
      />
    </rbe-book-content>
  </MainLayout>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import MainLayout from '@reedsy/studio.home.bookshelf/components/layouts/main-layout.vue';
import BookSkeletonLoader from '@reedsy/studio.home.bookshelf/components/book-skeleton-loader/book-skeleton-loader.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfLayoutModule} from '@reedsy/studio.home.bookshelf/store/modules/layout';
import BookTopBar from '@reedsy/studio.home.bookshelf/components/top-bar/book-top-bar/book-top-bar.vue';
import BookTopInfo from '@reedsy/studio.home.bookshelf/components/book-top-info/book-top-info.vue';
import {IBookshelfBook} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-book';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';
import ExportsPageActions from '@reedsy/studio.home.bookshelf/components/exports-page-actions/exports-page-actions.vue';

@Component({
  components: {
    MainLayout,
    BookSkeletonLoader,
    BookTopBar,
    BookTopInfo,
    ExportsPageActions,
  },
})
export default class Book extends BookshelfVue {
  @$lazyInjectStore(StoreName.Layout)
  public $layout: BookshelfLayoutModule;

  @$lazyInjectStore(StoreName.CurrentBook)
  public $currentBook: CurrentBookModule;

  public get isLoading(): boolean {
    return this.$layout.isLoadingBooksList || this.$layout.isLoadingBookData;
  }

  public get book(): IBookshelfBook {
    return this.$currentBook.data;
  }
}
</script>
