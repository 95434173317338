import {injectable, named} from 'inversify';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import RouterPlugin from '@reedsy/studio.home.bookshelf/store/plugins/router-plugin';
import {IBookshelfRoute} from '@reedsy/studio.home.bookshelf/router/i-bookshelf-route';
import {BookshelfLayoutModule} from '@reedsy/studio.home.bookshelf/store/modules/layout';

@injectable()
export default class SetLayoutFlagsPluginFactory extends RouterPlugin {
  @$inject('StoreModule')
  @named(StoreName.Layout)
  public readonly _layout: BookshelfLayoutModule;

  public override async beforeEach(to: IBookshelfRoute): Promise<void> {
    if (to.matched.map((match) => match.name).includes(BookshelfRouteName.Bookshelf)) {
      this._layout.DARK_CONTENT_IS_ALLOWED(true);
      this._layout.LAYOUT_TOP_IS_COLLAPSIBLE(true);
    } else {
      this._layout.DARK_CONTENT_IS_ALLOWED(false);
      this._layout.LAYOUT_TOP_IS_COLLAPSIBLE(false);
    }
  }
}
