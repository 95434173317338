import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-weight-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconArrowLeft = _resolveComponent("VuiIconArrowLeft")!
  const _component_PeriodPicker = _resolveComponent("PeriodPicker")!
  const _component_SubscriptionBaseModal = _resolveComponent("SubscriptionBaseModal")!

  return (_openBlock(), _createBlock(_component_SubscriptionBaseModal, {
    id: _ctx.id,
    class: "subscription-payment-modal equal-columns"
  }, {
    title: _withCtx(() => [
      _createElementVNode("rbe-subscription-back", null, [
        _createElementVNode("button", {
          type: "button",
          class: "button-with-icon",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backToPreview && _ctx.backToPreview(...args)))
        }, [
          _createVNode(_component_VuiIconArrowLeft),
          _cache[2] || (_cache[2] = _createTextVNode()),
          _cache[3] || (_cache[3] = _createElementVNode("span", null, "Go back", -1))
        ])
      ])
    ]),
    right: _withCtx(() => _cache[15] || (_cache[15] = [
      _createTextVNode("\n      TODO: [subscriptions] Add payment form\n    ")
    ])),
    default: _withCtx(() => [
      _cache[16] || (_cache[16] = _createTextVNode()),
      _createElementVNode("rbe-subscription-payment", null, [
        _cache[10] || (_cache[10] = _createElementVNode("rbe-payment-section", null, [
          _createElementVNode("h1", null, "\n          Confirm your subscription\n        "),
          _createTextVNode(),
          _createElementVNode("p", null, "\n          Please enter your card details to enable the add-ons you selected.\n        ")
        ], -1)),
        _cache[11] || (_cache[11] = _createTextVNode()),
        _createElementVNode("rbe-payment-section", null, [
          _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Billing frequency", -1)),
          _cache[5] || (_cache[5] = _createTextVNode()),
          _createVNode(_component_PeriodPicker, {
            modelValue: _ctx.billAnnually,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.billAnnually) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _cache[12] || (_cache[12] = _createTextVNode()),
        _createElementVNode("rbe-payment-section", null, [
          _cache[8] || (_cache[8] = _createElementVNode("h2", null, "Your subscription", -1)),
          _cache[9] || (_cache[9] = _createTextVNode()),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeProductsInfo, (product) => {
            return (_openBlock(), _createElementBlock("rbe-selected-product", {
              key: product,
              class: "flex-justified"
            }, [
              _createElementVNode("span", _hoisted_1, _toDisplayString(product.title), 1),
              _cache[6] || (_cache[6] = _createTextVNode()),
              _cache[7] || (_cache[7] = _createElementVNode("span", null, "TODO: [subscriptions] price", -1))
            ]))
          }), 128))
        ]),
        _cache[13] || (_cache[13] = _createTextVNode()),
        _cache[14] || (_cache[14] = _createElementVNode("rbe-payment-section", null, [
          _createElementVNode("rbe-price-item", null, [
            _createElementVNode("span", null, "Subtotal"),
            _createTextVNode(),
            _createElementVNode("span", null, "TODO: [subscriptions] price")
          ]),
          _createTextVNode(),
          _createElementVNode("rbe-price-item", null, [
            _createElementVNode("span", null, "Bundle discount"),
            _createTextVNode(),
            _createElementVNode("span", null, "- TODO: [subscriptions] price")
          ]),
          _createTextVNode(),
          _createElementVNode("hr"),
          _createTextVNode(),
          _createElementVNode("rbe-price-item", { class: "price-headline" }, [
            _createElementVNode("span", null, "Total"),
            _createTextVNode(),
            _createElementVNode("span", null, "TODO: [subscriptions] price")
          ]),
          _createTextVNode(),
          _createElementVNode("rbe-price-item", null, [
            _createElementVNode("span", null, "Next billing date"),
            _createTextVNode(),
            _createElementVNode("span", null, "TODO: [subscriptions] billing date")
          ])
        ], -1))
      ]),
      _cache[17] || (_cache[17] = _createTextVNode())
    ]),
    _: 1
  }, 8, ["id"]))
}