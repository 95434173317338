<template>
  <span class="vui-icon">
    <svg
      width="6px"
      height="12px"
      viewBox="0 0 6 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Generator: Sketch 61.2 (89653) - https://sketch.com -->
      <title>icon_drag</title>
      <desc>Created with Sketch.</desc>
      <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g
          id="icon-stroke"
          transform="translate(-234.000000, -289.000000)"
          stroke="#979797"
        >
          <g id="sidebar-body">
            <g>
              <path
                d="M235,299.5 C234.723858,299.5 234.5,299.723858 234.5,300 C234.5,300.276142 234.723858,300.5 235,300.5 C235.276142,300.5 235.5,300.276142 235.5,300 C235.5,299.723858 235.276142,299.5 235,299.5 Z M239,299.5 C238.723858,299.5 238.5,299.723858 238.5,300 C238.5,300.276142 238.723858,300.5 239,300.5 C239.276142,300.5 239.5,300.276142 239.5,300 C239.5,299.723858 239.276142,299.5 239,299.5 Z M235,294.5 C234.723858,294.5 234.5,294.723858 234.5,295 C234.5,295.276142 234.723858,295.5 235,295.5 C235.276142,295.5 235.5,295.276142 235.5,295 C235.5,294.723858 235.276142,294.5 235,294.5 Z M239,294.5 C238.723858,294.5 238.5,294.723858 238.5,295 C238.5,295.276142 238.723858,295.5 239,295.5 C239.276142,295.5 239.5,295.276142 239.5,295 C239.5,294.723858 239.276142,294.5 239,294.5 Z M235,289.5 C234.723858,289.5 234.5,289.723858 234.5,290 C234.5,290.276142 234.723858,290.5 235,290.5 C235.276142,290.5 235.5,290.276142 235.5,290 C235.5,289.723858 235.276142,289.5 235,289.5 Z M239,289.5 C238.723858,289.5 238.5,289.723858 238.5,290 C238.5,290.276142 238.723858,290.5 239,290.5 C239.276142,290.5 239.5,290.276142 239.5,290 C239.5,289.723858 239.276142,289.5 239,289.5 Z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </span>
</template>
