
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import IconDrop from '@reedsy/studio.shared/components/icons/icon-drop.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({
  components: {
    IconDrop,
  },
})
export default class ScreenEdgeArrow extends ClientSharedVue {}
