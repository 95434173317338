
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import ErrorMessage from './error-message.vue';
import BaseInput from './base-input.vue';
import BaseTextarea from './textarea/base-textarea.vue';
import {INPUT_FIELD_REF} from './mixins/validation-mixin';

@Component({
  inheritAttrs: false,
  components: {
    ErrorMessage,
    BaseInput,
    BaseTextarea,
  },
})
export default class Input extends ClientSharedVue {
  @Model
  public value: any;

  @Prop({type: String, default: 'BaseInput'})
  public component: string;

  public readonly inputFieldRef = INPUT_FIELD_REF;
  public error = {hasError: false, message: ''};

  public get hasInputGroup(): boolean {
    return !!(this.$slots.left || this.$slots.right);
  }
}
