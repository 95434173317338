<template>
  <a
    :class="networkClass"
    class="button share-button button-with-icon"
    target="_blank"
    :href="url"
  >
    <span>{{ networkName }}</span>
    <component
      :is="networkIcon"
      class="vui-icon"
    />
  </a>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {INetworkDetails, SocialNetwork, SUPPORTED_NETWORKS} from './supported-networks';

@Component({
  components: {
  },
})
export default class ShareButton extends BookshelfVue {
  @Prop({type: String, required: true})
  public network: SocialNetwork;

  @Prop({type: String, default: ''})
  public url: string;

  public get networkClass(): string {
    return `button-${this.network}`;
  }

  public get networkDetails(): INetworkDetails {
    return SUPPORTED_NETWORKS[this.network];
  }

  public get networkIcon(): string {
    return this.networkDetails.icon;
  }

  public get networkName(): string {
    return this.networkDetails.name;
  }
}
</script>

<style lang="scss" scoped>
.share-button {
  .button-icon {
    margin-left: $space-sm;

    @include size($space-base);

    .vui-icon {
      color: var(--reedsy-fgColor-light);
    }
  }
}
</style>
