
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {SubscriptionProduct} from '@reedsy/utils.subscription';
import FeatureSummary from './feature-summary.vue';
import {FEATURE_SUMMARIES} from './feature-summaries';
import {IProductSummary} from './product-summary.interface';

@Component({
  components: {
    FeatureSummary,
  },
})
export default class FeatureSummaries extends ClientSharedVue {
  @Model({type: Object, default: {}})
  public selectedProducts: Partial<Record<SubscriptionProduct, boolean>>;

  @Prop({type: Boolean, default: false})
  public featurePreview: boolean;

  public get summaries(): ReadonlyArray<IProductSummary> {
    if (!this.featurePreview) return FEATURE_SUMMARIES;

    return FEATURE_SUMMARIES.filter((summary) => summary.product);
  }
}
