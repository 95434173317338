import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex-justified" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "flex-justified" }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaymentInfoItem = _resolveComponent("PaymentInfoItem")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, { class: "bordered payment-info-panel no-gutter reedsy-accented neutral" }, {
    footer: _withCtx(() => [
      _createElementVNode("rbe-footer", _hoisted_3, [
        _createElementVNode("p", null, [
          _createElementVNode("a", {
            ref: "stripe-link",
            class: "link",
            target: "_blank",
            href: _ctx.billingPortalUrl,
            "aria-label": "Stripe dashboard"
          }, "Log in to the Stripe dashboard", 8, _hoisted_4),
          _cache[2] || (_cache[2] = _createTextVNode(" to manage your plan,\n          update payment methods and view invoices.\n        "))
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("rbe-payment-info", _hoisted_1, [
        _createVNode(_component_PaymentInfoItem, {
          ref: "next-payment-info",
          title: "Next billing date",
          loading: _ctx.loading
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.subscription.nextBillingDate), 1)
          ]),
          _: 1
        }, 8, ["loading"]),
        _cache[0] || (_cache[0] = _createTextVNode()),
        _createVNode(_component_PaymentInfoItem, {
          ref: "price-info",
          title: "Subscription",
          loading: _ctx.loading
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.subscription.nextBillingPrice), 1)
          ]),
          _: 1
        }, 8, ["loading"]),
        _cache[1] || (_cache[1] = _createTextVNode()),
        _createElementVNode("a", {
          ref: "payment-settings-link",
          href: _ctx.billingPortalUrl,
          target: "_blank",
          class: "reedsy-accented button accent-primary"
        }, "\n        Payment settings\n      ", 8, _hoisted_2)
      ]),
      _cache[3] || (_cache[3] = _createTextVNode())
    ]),
    _: 1
  }))
}