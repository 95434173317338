<template>
  <Tabs
    v-model="activeTab"
    :tabs="tabs"
    class="page-selector"
  />
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Tabs from '@reedsy/studio.shared/components/tabs/tabs.vue';
import {ITabItem} from '@reedsy/studio.shared/components/tabs/i-tab-item';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import {intersection} from '@reedsy/utils.iterable';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {PERMISSIONS_REQUIREMENTS} from '@reedsy/studio.isomorphic/utils/permissions-requirements';

@Component({
  components: {
    Tabs,
  },
})
export default class BookPageSelector extends BookshelfVue {
  @$lazyInjectStore(SharedStoreName.User)
  public readonly $user: SharedUserModule;

  @$lazyInjectStore(StoreName.CurrentBook)
  public $book: CurrentBookModule;

  public get tabs(): ITabItem[] {
    const tabs = [
      {text: 'Settings', value: BookshelfRouteName.BookSettings},
      {text: 'Exports', value: BookshelfRouteName.BookExports},
    ];

    if (
      this.$book.hasMinimumPermission(this.$user.uuid, PERMISSIONS_REQUIREMENTS.userAccessManagement)
    ) {
      tabs.push({text: 'Access', value: BookshelfRouteName.BookAccess});
    }

    return tabs;
  }

  public get activeTab(): BookshelfRouteName {
    const routesNames = this.tabs.map((tab) => tab.value as BookshelfRouteName);
    const matchedRouteNames = this.$route.matched.map((route) => route.name as BookshelfRouteName);

    const [selectedRoute] = intersection(matchedRouteNames, routesNames);
    return selectedRoute;
  }

  public set activeTab(routeName: BookshelfRouteName) {
    this.$router.push({
      name: routeName,
    });
  }
}
</script>
