import {injectable, named} from 'inversify';
import RouterPlugin from '@reedsy/studio.home.bookshelf/store/plugins/router-plugin';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {BookshelfCollaboratorsModule} from '@reedsy/studio.home.bookshelf/store/modules/collaborators';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfLayoutModule} from '@reedsy/studio.home.bookshelf/store/modules/layout';

@injectable()
export default class LoadCurrentUserPluginFactory extends RouterPlugin {
  @$inject('StoreModule')
  @named(StoreName.Layout)
  public readonly _layout: BookshelfLayoutModule;

  @$inject('StoreModule')
  @named(StoreName.Collaborators)
  public readonly _collaborators: BookshelfCollaboratorsModule;

  public override async beforeEach(): Promise<void> {
    await this._collaborators.initialise();
    this._layout.IS_LOADING_CURRENT_USER(false);
  }
}
