import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "offline-modal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiLoadingIndicator = _resolveComponent("VuiLoadingIndicator")!
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!

  return (_openBlock(), _createBlock(_component_ConfirmModal, {
    id: _ctx.id,
    title: "Offline",
    class: "accent-secondary"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_VuiLoadingIndicator),
        _cache[0] || (_cache[0] = _createTextVNode()),
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text" }, "\n        Oops! Looks like you’re offline. Trying to reconnect...\n      ", -1))
      ])
    ]),
    _: 1
  }, 8, ["id"]))
}