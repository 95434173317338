export default async function cancelableModals(
  modals: Record<string, () => Promise<{default: any}>>,
): Promise<Record<string, boolean>> {
  const cancelable: Record<string, boolean> = {};

  await Promise.all(Object.entries(modals).map(async ([type, importer]) => {
    const imported = await importer();
    const Component = imported.default;
    cancelable[type] = !!Component.data().cancelable;
  }));

  return cancelable;
}
