import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "feature-summaries" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeatureSummary = _resolveComponent("FeatureSummary")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summaries, (summary) => {
      return (_openBlock(), _createBlock(_component_FeatureSummary, {
        key: summary.product,
        ref_for: true,
        ref: summary.product,
        modelValue: _ctx.selectedProducts[summary.product],
        "onUpdate:modelValue": ($event: any) => ((_ctx.selectedProducts[summary.product]) = $event),
        summary: summary,
        "is-preview": _ctx.featurePreview
      }, null, 8, ["modelValue", "onUpdate:modelValue", "summary", "is-preview"]))
    }), 128))
  ]))
}