<template>
  <Panel
    title="Export options"
    class="export-type-selector-panel"
  >
    <p>
      Every book written or uploaded with our editor can be perfectly converted,
      ready for submission to online ebook stores and print on demand services.
      Select the export format and options you would like.
    </p>

    <TabOptions
      v-model="exportType"
      :options="exportOptions"
      class="export-type-options"
    />
  </Panel>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Panel from '@reedsy/studio.shared/components/panel/panel.vue';
import {ExportType} from '@reedsy/schemas.workers.book-export';
import TabOptions from '@reedsy/studio.home.bookshelf/components/tab-options/tab-options.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {BookExportSubmissionModule} from '@reedsy/studio.home.bookshelf/store/modules/book-export-submission';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';

import PDF from '@/assets/inlined/export-type/pdf.svg?inline';
import EPUB from '@/assets/inlined/export-type/epub.svg?inline';

@Component({
  components: {
    Panel,
    TabOptions,
  },
})
export default class ExportTypeSelector extends BookshelfVue {
  @$lazyInjectStore(StoreName.BookExportSubmission)
  public $bookExportSubmission: BookExportSubmissionModule;

  public readonly exportOptions = [
    {
      description: '',
      image: PDF,
      title: 'Print-ready PDF',
      value: ExportType.Pdf,
    },
    {
      description: '',
      image: EPUB,
      title: 'EPUB',
      value: ExportType.EPub,
    },
  ] as const;

  public get exportType(): ExportType {
    return this.$bookExportSubmission.exportType;
  }

  public set exportType(exportType: ExportType) {
    this.$bookExportSubmission.EXPORT_TYPE(exportType);
  }
}
</script>

<style lang="scss" scoped>
.export-type-selector-panel {
  .export-type-options {
    margin-top: $space-md;
  }
}
</style>
