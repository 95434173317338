
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Panel from '@reedsy/studio.shared/components/panel/panel.vue';

@Component({
  components: {
    Panel,
  },
})
export default class InfoPanel extends BookshelfVue {
  @Prop({type: String, required: true})
  public title: string;
}
