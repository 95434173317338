import {IBuildSignInUrlOptions} from './i-build-sign-in-url-options';
import {getAuthBaseUrl} from './get-auth-base-url';

export function buildSignInUrl(optionsOverride?: IBuildSignInUrlOptions): string {
  const options: Required<IBuildSignInUrlOptions> = {
    afterSignInPath: '/',
    ...optionsOverride,
  };

  const url = getAuthBaseUrl();
  url.pathname = 'sign_in/';
  url.searchParams.set('next', options.afterSignInPath);

  return url.toString();
}
