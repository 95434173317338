import {Constructor} from '@reedsy/utils.types';
import {getModule, VuexModule} from '@reedsy/vuex-module-decorators';
import {Store} from 'vuex';
import {injectable} from 'inversify';
import {IBookshelfBookShareDb} from '@reedsy/studio.home.bookshelf/services/i-bookshelf-sharedb';
import {IBookshelfBookModule} from './i-bookshelf-book-module';
import {IInitBookData} from './i-book-data-store-coordinator';
import {IBookshelfBookShareDbManager} from '@reedsy/studio.home.bookshelf/services/i-bookshelf-book-sharedb-manager';

@injectable()
export class BookDataStore<T extends VuexModule & IBookshelfBookModule> {
  private module: T = null;
  private moduleName = '';
  private connectionLeaseId: string = null;

  public constructor(
    private readonly store: Store<any>,
    private readonly dataModuleFactory: BookDataModuleFactory<T>,
    private readonly bookShareDbManager: IBookshelfBookShareDbManager,
  ) {}

  public get current(): T {
    return this.module;
  }

  public get created(): boolean {
    return !!this.current;
  }

  public createModule(initBookData: IInitBookData): T {
    if (this.created) return;
    const {shareDb, leaseId} = this.bookShareDbManager.leaseConnection(initBookData.bookUuid);
    this.connectionLeaseId = leaseId;

    const DataModule = this.dataModuleFactory(initBookData, shareDb, this.store);
    this.moduleName = (DataModule as any)._vmdModuleName;
    this.module = getModule(DataModule);
    return this.module;
  }

  public removeModule(): void {
    if (!this.created) return;
    this.store.unregisterModule(this.moduleName);
    this.module = null;
    this.moduleName = '';
    this.bookShareDbManager.freeConnectionLease(this.connectionLeaseId);
    this.connectionLeaseId = null;
  }
}

export type BookDataModuleFactory<T extends VuexModule & IBookshelfBookModule = VuexModule & IBookshelfBookModule> = (
  initBookData: IInitBookData,
  bookShareDb: IBookshelfBookShareDb,
  store: Store<any>,
) => Constructor<T>;
