import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiToggle = _resolveComponent("VuiToggle")!
  const _component_AnnualSaving = _resolveComponent("AnnualSaving")!

  return (_openBlock(), _createElementBlock("rbe-period-toggle", null, [
    _createElementVNode("button", {
      class: "reedsy-accented",
      type: "button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.annual = false))
    }, "\n      Billed monthly\n    "),
    _cache[4] || (_cache[4] = _createTextVNode()),
    _createVNode(_component_VuiToggle, {
      modelValue: _ctx.annual,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.annual) = $event))
    }, null, 8, ["modelValue"]),
    _cache[5] || (_cache[5] = _createTextVNode()),
    _createElementVNode("button", {
      class: "reedsy-accented",
      type: "button",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.annual = true))
    }, [
      _cache[3] || (_cache[3] = _createTextVNode("\n      Annually\n      ")),
      _createVNode(_component_AnnualSaving)
    ])
  ]))
}