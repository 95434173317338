import mergeAttributes from './merge-attributes';

export default abstract class JsxComponent {
  public abstract render(): any;
  public constructor(public readonly $props: any = {}) {}

  public doRender(): any {
    const element = this.render();
    mergeAttributes(element, this.$props);
    return element;
  }
}
