<template>
  <PlainModal
    :id="id"
    :headless="true"
    class="trial-started-modal accent-premium extra-wide no-padding"
  >
    <rbe-trial-started>
      <rbe-toggles>
        <AddOnToggle
          title="Studio Pro"
          :model-value="true"
          :disabled="true"
        />
        <AddOnToggle
          title="Outlining"
          :model-value="true"
          :disabled="true"
        />
      </rbe-toggles>

      <h1>
        Your {{ config.stripe.trialDays }} day trial has started
      </h1>

      <p>
        You now have access to Studio Pro, and the Outlining add-ons.
        <span v-if="tourBookUuid">
          Take a quick tour of our premium add-ons or jump straight into
          writing.
        </span>
      </p>

      <template v-if="tourBookUuid">
        <button
          ref="startTour"
          class="button reedsy-accented"
          type="button"
          @click="startTour"
        >
          Take a tour
        </button>

        <button
          class="button-subtle"
          type="button"
          @click="close"
        >
          Skip tour
        </button>
      </template>
      <template v-else>
        <button
          class="button reedsy-accented"
          type="button"
          @click="close"
        >
          Let’s go!
        </button>
      </template>
    </rbe-trial-started>
  </PlainModal>
</template>

<script lang="ts">
import {Component, Inject, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import PlainModal from '@reedsy/studio.shared/components/modals/templates/plain-modal.vue';
import AddOnToggle from './add-on-toggle.vue';
import {config} from '@reedsy/studio.shared/config';
import {$lazyInject} from '@reedsy/studio.shared/inversify.config';
import {EditorTourName} from '@reedsy/studio.shared/services/spotlight-tour/tour-names';
import {ISpotlightTour} from '@reedsy/studio.shared/services/spotlight-tour/i-spotlight-tour';

@Component({
  components: {
    AddOnToggle,
    PlainModal,
  },
})
export default class SubscriptionTrialStarted extends mixins(ModalMixin, ClientSharedVue) {
  @$lazyInject('SpotlightTour')
  public $tour: ISpotlightTour;

  @Inject
  public tourBookUuid: string;

  public readonly config = config;
  public readonly cancelable = true;

  public startTour(): void {
    this.close();
    // TODO: [subscriptions] subscription-specific tour?
    this.$tour.start(EditorTourName.PlanFeatures, {bookUuid: this.tourBookUuid});
  }
}
</script>

<style lang="scss" scoped>
.trial-started-modal {
  rbe-trial-started {
    padding: 2rem;
    width: 37rem;
    max-width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $space-sm;
    margin: auto;
  }

  rbe-toggles {
    $size: 15rem;

    position: relative;
    height: $size;
    width: 21rem;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $space-sm;
    margin: $space-base auto;

    & > * {
      position: relative;
    }

    &::before {
      @include size($size);

      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: var(--reedsy-neutral-muted);
      border-radius: 50%;
    }
  }

  h1 {
    font-size: $font-size-xxl;
    text-align: center;
  }

  p {
    margin-bottom: $space-base;
  }
}
</style>
