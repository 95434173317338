<template>
  <rbe-add-on-toggle>
    <h1>
      {{ title }}
    </h1>

    <VuiToggle
      v-model="enabled"
      label="Add-on enabled"
      class="label-left"
    />
  </rbe-add-on-toggle>
</template>

<script lang="ts">
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({})
export default class AddOnToggle extends ClientSharedVue {
  @Prop({type: String, required: true})
  public title: string;

  @Model
  public enabled: boolean;
}
</script>

<style lang="scss" scoped>
rbe-add-on-toggle {
  display: flex;
  justify-content: space-between;
  padding: $space-base;
  border: $border-width-lg solid var(--local-color-default);
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow-sm;
  background: var(--reedsy-plain);

  h1 {
    font-size: $font-size-lg;
  }

  &[disabled='true'] :deep(.vui-toggle) {
    opacity: 1;
  }
}
</style>
