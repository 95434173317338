
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import {MetaRawLocation} from '@reedsy/studio.shared/router/reedsy-router';
import {$lazyInject} from '@reedsy/studio.shared/inversify.config';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';

@Component({})
export default class LayoutFooter extends BookshelfVue {
  @$lazyInject('Navigation')
  public navigation: INavigation;

  public get currentYear(): number {
    return new Date().getFullYear();
  }

  public get home(): MetaRawLocation {
    return {
      name: BookshelfRouteName.BooksList,
    };
  }
}
