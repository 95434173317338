import {Component, Emit, mixins, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {TextSanitizer} from '@reedsy/utils.string';
import ValidationMixin from '@reedsy/studio.shared/components/forms/mixins/validation-mixin';
import refEl from '@reedsy/studio.shared/utils/vue/ref-el';

@Component({})
export class ValidatableInput extends mixins(ValidationMixin) {
  @Prop({required: true})
  public override readonly modelValue: any;

  public get value(): any {
    return this.modelValue;
  }

  public set value(value: any) {
    this.validateField();
    this.update(value);
  }

  public onBlur(): void {
    this.touched = true;
    this.validateField();
  }

  public updateValueFromComposition(): void {
    setTimeout(() => {
      const input = refEl(this.$refs[this.inputFieldRef]) as HTMLInputElement;
      this.value = input.value;
    });
  }

  @Emit('update:modelValue')
  private update(value: any): string {
    return TextSanitizer.sanitize(value.toString());
  }
}
