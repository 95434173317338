
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';

@Component({})
export default class BookCreationButtons extends BookshelfVue {
  @$lazyInjectStore(StoreName.Modals)
  public $modals: BookshelfModalsModule;

  public async openNewBookModal(): Promise<void> {
    await this.$modals.open('CreateBook');
  }

  public async openImportBookModal(): Promise<void> {
    await this.$modals.open({
      type: 'ImportBook',
      context: {},
    });
  }
}
