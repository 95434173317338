import {DeepReadonly} from 'ts-essentials';
import {ISharedConfig} from '@reedsy/studio.isomorphic/config';

export interface ILink {
  url: string;
}

export interface IRootApp {
  origin: string;
}

interface IClientConfigBase {
  crisp: {
    websiteId: string;
  };
  app: {
    bookViewer: {
      basePath: string;
    };
    home: {
      bookshelfBasePath: string;
      developmentLoginPath: string;
    };
    server: {
      api: string;
      ws: string;
    };
  };
  reedsyApps: {
    studioLandingPage: ILink;
    marketplace: IRootApp;
    blog: IRootApp;
    learning: ILink;
    discovery: ILink;
    live: ILink;
    prompts: ILink;
    faq: ILink;
    whatsNew: ILink;
  };
  externalWebsites: {
    blurbUploader: ILink;
  };
  cdn: {
    host: string;
    workerHost: string;
  };
  collaborationBeta: {
    userIds: string[];
  };
  documentHistory: {
    epoch: number;
  };
  hotjar: {
    siteId: number;
    version: number;
    enabledForUsers: string[];
  };
  logging: {
    maxDebugEvents: number;
  };
  ui: {
    debounce: {
      fast: number;
      base: number;
      slow: number;
    };
    defaultLocale: string;
    vue: {
      router: boolean;
    };
  };
  validations: {
    maxBookTitleLength: number;
    maxBookSubtitleLength: number;
  };
  stripe: {
    customerPortalURL: string;
  };
  gtm: {
    containerId: string;
    tagId: string;
    tagScriptSrc: string;
  };
}

export type IClientConfig = DeepReadonly<IClientConfigBase> & ISharedConfig;

// Injected at build-time by Webpack's DefinePlugin to capture ENV variables
declare const BUILD_TIME_CONFIG: IClientConfig;
const clientConfig: IClientConfig = BUILD_TIME_CONFIG;

export const config = clientConfig;
