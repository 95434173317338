import {objectKeys} from '@reedsy/utils.object';
import {App, defineAsyncComponent} from 'vue';
import {modalComponentName} from './modal-component-name';

export function registerModalComponents(app: App, componentMap: Record<string, any>): void {
  for (const key of objectKeys(componentMap)) {
    const name = modalComponentName(key);
    if (!app.component(name)) app.component(name, defineAsyncComponent(componentMap[key]));
  }
}
