
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import LazyRenderer from '@reedsy/studio.shared/components/lazy-renderer/lazy-renderer.vue';

@Component({
  components: {
    LazyRenderer,
  },
})
export default class BookshelfEntry extends BookshelfVue {}
