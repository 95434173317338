import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "button-content" }
const _hoisted_3 = { class: "spinner-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiLoadingIndicator = _resolveComponent("VuiLoadingIndicator")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["loading-button reedsy-accented", {loading: _ctx.loading}]),
    disabled: _ctx.loading || null
  }, [
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _cache[0] || (_cache[0] = _createTextVNode()),
    _createElementVNode("span", _hoisted_3, [
      _createVNode(_component_VuiLoadingIndicator, { class: "sm" })
    ])
  ], 10, _hoisted_1))
}