<template>
  <ConfirmModal
    :id="id"
    :confirm="{
      label: 'Create book',
      handler: createBook,
    }"
    title="Create new book"
    class="create-book-modal"
  >
    <label
      class="input-label"
      for="book-title"
    >
      Title
    </label>
    <VInput
      id="book-title"
      v-model="title"
      type="text"
      required
      :custom-validators="[noEmptyValidator]"
      :maxlength="maxTitleLength"
      placeholder="Enter a book title..."
    />

    <label
      class="input-label subtitle-label optional"
      for="book-subtitle"
    >
      Subtitle
    </label>
    <VInput
      id="book-subtitle"
      v-model="subtitle"
      type="text"
      placeholder="Enter a book subtitle..."
      :maxlength="maxSubtitleLength"
    />
  </ConfirmModal>
</template>

<script lang="ts">
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ConfirmModal from '@reedsy/studio.shared/components/modals/templates/confirm-modal.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import BaseInput from '@reedsy/studio.shared/components/forms/base-input.vue';
import {$lazyInject} from '@reedsy/studio.shared/inversify.config';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import Notify from '@reedsy/studio.shared/services/notify/notify';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {config} from '@reedsy/studio.shared/config';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {BookDetailsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-details/book-details';
import {BookshelfStoreListener} from '@reedsy/studio.home.bookshelf/store/bookshelf-store-listener';
import {NotifyError} from '@reedsy/studio.shared/utils/decorators/notify-error';
import {BookshelfModule} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf';
import {IBookshelfBook} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-book';
import {noEmpty} from '@reedsy/studio.shared/components/forms/input-validators/no-empty';
import {Store} from 'vuex';
import {StudioAnalyticsManager} from '@reedsy/studio.shared/services/analytics/studio-analytics-manager';
import {GoogleAnalyticsEvent} from '@reedsy/utils.analytics';

@Component({
  components: {
    ConfirmModal,
    BaseInput,
  },
})
export default class CreateBookModal extends mixins(ModalMixin, BookshelfVue) {
  @$lazyInjectStore(StoreName.BookDetails)
  public $bookDetails: BookDetailsModule;

  @$lazyInjectStore(StoreName.Bookshelf)
  public $bookshelf: BookshelfModule;

  @$lazyInject('StoreListener')
  public $storeListener: BookshelfStoreListener;

  @$lazyInject('Store')
  public $store: Store<any>;

  @$lazyInject('Api')
  public api: IApi;

  @$lazyInject('StudioAnalyticsManager')
  public analytics: StudioAnalyticsManager;

  public readonly maxTitleLength = config.validations.maxBookTitleLength;
  public readonly maxSubtitleLength = config.validations.maxBookSubtitleLength;
  public readonly noEmptyValidator = noEmpty;

  public readonly cancelable = true;
  public title = '';
  public subtitle = '';

  @NotifyError('Cannot create the book. Please try again later.')
  public async createBook(): Promise<void> {
    const book = await this.api.createBook({
      title: this.title,
      subtitle: this.subtitle || undefined,
    });

    await this.ensureBookIsLoaded(book._id);
    this.$bookshelf.setActiveEntry({
      entryId: book._id,
      clearSearch: true,
    });

    this.analytics.trackEvent(GoogleAnalyticsEvent.BookCreated);
    Notify.success({message: 'Book has been created.'});
  }

  private async ensureBookIsLoaded(bookId: string): Promise<void> {
    if (this.$bookDetails.details(bookId)) return;

    return new Promise((resolve) => {
      const unsubscribe = this.$store.watch(
        (state, getters) => {
          const books = getters[`${StoreName.Bookshelf}/books`] as IBookshelfBook[];
          return books.some((book) => book.id === bookId);
        },
        () => {
          unsubscribe();
          resolve();
        },
      );
    });
  }
}
</script>

<style lang="scss" scoped>
.create-book-modal {
  .input-wrapper  {
    width: 100%;
  }

  label:first-child {
    margin-top: 0;
  }

  .subtitle-label {
    margin-top: $space-md;
  }
}
</style>
