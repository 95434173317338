
import {Component, Model} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import AnnualSaving from '@reedsy/studio.shared/components/modals/components/subscription/annual-saving.vue';

@Component({
  components: {
    AnnualSaving,
  },
})
export default class PeriodToggle extends ClientSharedVue {
  @Model
  public annual: boolean;
}
