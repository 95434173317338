
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({})
export default class InfoTip extends ClientSharedVue {
  public tooltipBoundary: Element = null;

  public mounted(): void {
    this.tooltipBoundary = this.$el.parentElement;
  }
}
