export enum Orientation {
  Portrait = 'orientation-portrait',
  LandscapeLeft = 'orientation-landscape-left',
  LandscapeRight = 'orientation-landscape-right',
}

export const OrientationTypes: any = {
  0: Orientation.Portrait,
  90: Orientation.LandscapeLeft,
  '-90': Orientation.LandscapeRight,
};
