import {injectable, named} from 'inversify';
import {IMetaRoute} from '@reedsy/studio.shared/router/reedsy-router';
import {$inject} from '@reedsy/studio.shared/types';
import {SharedBrowserModule} from '@reedsy/studio.shared/store/modules/browser';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {RouterPlugin} from '@reedsy/studio.shared/store/plugins/router-plugin';

@injectable()
export default class BrowserRoutePluginFactory extends RouterPlugin {
  @$inject('StoreModule')
  @named(SharedStoreName.Browser)
  public readonly _browser: SharedBrowserModule;

  public override async beforeEach(to: IMetaRoute): Promise<void> {
    this._browser.ROUTE({
      path: to.path,
      hash: to.hash,
      query: to.query,
      params: to.params,
      fullPath: to.fullPath,
    });
    this._browser.PATH(to.fullPath);
  }
}
