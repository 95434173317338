import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("rbe-grid-line", null, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.withButton)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "button reedsy-accented inverted",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showMore && _ctx.showMore(...args)))
            }, "\n        Load more books\n      "))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}