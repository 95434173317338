export class HTMLImage {
  public static async fetch(url: string): Promise<string> {
    if (!url) return null;
    return new Promise((resolve, reject) => {
      const image = new window.Image();
      image.onload = () => resolve(url);
      image.onerror = () => reject(new Error('Image could not be loaded'));
      image.src = url;
    });
  }

  public static isCached(url: string): boolean {
    const img = new Image();
    img.src = url;
    const complete = img.complete;
    img.src = '';
    return complete;
  }
}
