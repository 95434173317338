import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {DAYS} from '@reedsy/utils.date';
import {countPluralize} from '@reedsy/studio.shared/utils/number/count-pluralize';
import {reactiveDate} from '@reedsy/studio.shared/utils/vue/reactive-date';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';

@Component({})
export default class TrialCountdownMixin extends ClientSharedVue {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $trialCountdown_subscription: SharedSubscriptionModule;

  public get trialMessage(): string {
    if (this.trialDaysLeft <= 1) return 'Last day of your trial';
    return `${countPluralize(this.trialDaysLeft, 'trial day')} left`;
  }

  public get trialDaysLeft(): number {
    const now = reactiveDate('minutes').value;
    let daysLeft = (this.$trialCountdown_subscription.trialEnd.getTime() - now.getTime()) / DAYS;

    // When a user starts a trial, the server and client times may not match perfectly, briefly
    // showing 31 days and immediately clocking down to 30, which is a bit confusing. Let's just
    // advance the trial clock by half an hour until the final day to avoid this .
    if (daysLeft > 1) daysLeft -= 0.5 / 24;

    return Math.ceil(daysLeft);
  }
}
