
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Avatar from '@reedsy/studio.shared/components/avatar/avatar.vue';
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';

@Component({
  components: {
    Avatar,
    SkeletonLoader,
  },
})
export default class CollaboratorPlaceholder extends BookshelfVue {

}
