<template>
  <Panel
    class="info-panel flat"
  >
    <h1>{{ title }}</h1>
    <p class="info-panel-description">
      <slot />
    </p>
  </Panel>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Panel from '@reedsy/studio.shared/components/panel/panel.vue';

@Component({
  components: {
    Panel,
  },
})
export default class InfoPanel extends BookshelfVue {
  @Prop({type: String, required: true})
  public title: string;
}
</script>

<style lang="scss" scoped>
.info-panel {
  background-color: var(--reedsy-neutral);
}

h1 {
  margin-bottom: $space-md;
  font-size: $font-size-base;
}
</style>
