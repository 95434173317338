import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/inlined/file-icons/doc.svg'


const _hoisted_1 = { class: "selected-file-info" }
const _hoisted_2 = {
  class: "ellipsis",
  "data-test": "import-file-name"
}
const _hoisted_3 = {
  key: 1,
  "data-test": "initalizing-import"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiProgressBar = _resolveComponent("VuiProgressBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
    _cache[2] || (_cache[2] = _createTextVNode()),
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.importFile.name), 1),
    _cache[3] || (_cache[3] = _createTextVNode()),
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (!_ctx.isUploading)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "select-different-file",
              title: "Select a different file",
              type: "button",
              "data-test": "different-file-button",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clearFileSelection && _ctx.clearFileSelection(...args)))
            }, "\n        Select a different file\n      "))
          : (_ctx.isUploadingFinished)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, "\n        Almost there...\n      "))
            : (_openBlock(), _createBlock(_component_VuiProgressBar, {
                key: 2,
                percentage: _ctx.progressPercentage,
                "data-test": "progress-bar"
              }, null, 8, ["percentage"]))
      ]),
      _: 1
    })
  ]))
}