<template>
  <img src="@/assets/inlined/help/icon-info.svg">
</template>

<script lang="ts">
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';

@Component({})
export default class InfoIcon extends BookshelfVue {}
</script>
