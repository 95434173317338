import {injectable, named} from 'inversify';
import RouterPlugin from '@reedsy/studio.home.bookshelf/store/plugins/router-plugin';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookDetailsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-details/book-details';
import {UserBookMetadataModule} from '@reedsy/studio.home.bookshelf/store/modules/user-book-metadata';
import {BookWordCountGoalsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-word-count-goals';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {BookshelfLayoutModule} from '@reedsy/studio.home.bookshelf/store/modules/layout';
import {BooksImportsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-imports';
import {UserWordCountGoalsModule} from '@reedsy/studio.home.bookshelf/store/modules/user-word-count-goals/user-word-count-goals';
import {UserBookSettingsModule} from '@reedsy/studio.home.bookshelf/store/modules/user-book-settings';

@injectable()
export default class InitBooksPluginFactory extends RouterPlugin {
  @$inject('StoreModule')
  @named(StoreName.BookWordCountGoals)
  public readonly _bookWordCountGoals: BookWordCountGoalsModule;

  @$inject('StoreModule')
  @named(StoreName.UserWordCountGoals)
  public readonly _userWordCountGoals: UserWordCountGoalsModule;

  @$inject('StoreModule')
  @named(StoreName.UserBookSettings)
  public readonly _userBookSettings: UserBookSettingsModule;

  @$inject('StoreModule')
  @named(StoreName.UserBookMetadata)
  public readonly _userBookMetadata: UserBookMetadataModule;

  @$inject('StoreModule')
  @named(StoreName.BookDetails)
  public readonly _booksDetails: BookDetailsModule;

  @$inject('StoreModule')
  @named(StoreName.BooksImports)
  public readonly _bookImports: BooksImportsModule;

  @$inject('StoreModule')
  @named(StoreName.Layout)
  public readonly _layout: BookshelfLayoutModule;

  public override async afterEach(): Promise<void> {
    await this.loadContent();
  }

  private async loadContent(): Promise<void> {
    await Promise.all([
      this._booksDetails.initialise(),
      this._userBookMetadata.initialise(),
      this._bookWordCountGoals.initialise(),
      this._bookImports.initialise(),
    ]);

    this._layout.IS_LOADING_BOOKS_LIST(false);

    // This is only needed in book view, but we can initialise it async to preload the data and allow
    // book settings to be open a bit faster
    this._userWordCountGoals.initialise();
    this._userBookSettings.initialise();
  }
}
