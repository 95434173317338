
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {IBookshelfEntry} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-entry';
import {IBookshelfBook} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-book';
import BookCover from '@reedsy/studio.home.bookshelf/components/book-cover/book-cover.vue';
import {$lazyInject} from '@reedsy/studio.home.bookshelf/inversify.config';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import ShareIndicator from '@reedsy/studio.home.bookshelf/components/share-indicator/share-indicator.vue';

@Component({
  components: {
    BookCover,
    ShareIndicator,
  },
})
export default class BookEntry extends BookshelfVue {
  @$lazyInject('Navigation')
  public navigation: INavigation;

  @Prop({type: Object, required: true})
  public entry: IBookshelfEntry;

  public get book(): IBookshelfBook {
    return this.entry.item as IBookshelfBook;
  }

  public get bookEditorLink(): string {
    return this.navigation.getBookEditorUrl(this.book.uuid);
  }
}
