<template>
  <rbe-export-submission-creator>
    <LayoutWithRightSideBar>
      <template #left-side>
        <ExportTypeSelector class="export-creation-panel" />
        <SubmitPanel class="export-creation-panel visible-until-sm" />
        <BookFormattingSelector class="export-creation-panel" />
        <ExpandTransition>
          <rbe-panel-wrapper v-if="isPdfExportType">
            <TrimSizeSelector
              class="trim-size-selector"
              data-test="trim-size-selector"
            />
          </rbe-panel-wrapper>
        </ExpandTransition>
        <ThemeSelector class="export-creation-panel" />
      </template>
      <template #right-side>
        <SubmitPanel class="right-side-panel" />
      </template>
    </LayoutWithRightSideBar>
    <BookBackup class="export-creation-panel" />
  </rbe-export-submission-creator>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import ExportTypeSelector from './export-type-selector/export-type-selector.vue';
import BookFormattingSelector from './book-formatting-selector/book-formatting-selector.vue';
import ThemeSelector from './theme-selector/theme-selector.vue';
import SubmitPanel from './submit-panel/submit-panel.vue';
import BookBackup from './book-backup/book-backup.vue';
import TrimSizeSelector from './trim-size-selector/trim-size-selector.vue';
import {ExportType} from '@reedsy/schemas.workers.book-export';
import ExpandTransition from '@reedsy/studio.shared/components/transitions/expand-transition.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookExportSubmissionModule} from '@reedsy/studio.home.bookshelf/store/modules/book-export-submission';
import LayoutWithRightSideBar from '@reedsy/studio.home.bookshelf/components/layout-with-right-side-bar/layout-with-right-side-bar.vue';

@Component({
  components: {
    ExportTypeSelector,
    BookFormattingSelector,
    ThemeSelector,
    TrimSizeSelector,
    SubmitPanel,
    BookBackup,
    ExpandTransition,
    LayoutWithRightSideBar,
  },
})
export default class ExportSubmissionCreator extends BookshelfVue {
  @$lazyInjectStore(StoreName.BookExportSubmission)
  public $bookExportSubmission: BookExportSubmissionModule;

  public get isPdfExportType(): boolean {
    return this.$bookExportSubmission.exportType === ExportType.Pdf;
  }
}
</script>

<style lang="scss" scoped>
rbe-export-submission-creator {
  :deep(.expand-enter-active),
  :deep(.expand-leave-active) {
    overflow: initial;
  }

  :deep(rbe-layout-with-right-side-bar) {
    rbe-right-column {
      @include screen-less-than(md) {
        display: none;
      }
    }
  }
}

rbe-panel-wrapper {
  // Added so that the dropdown doesn't hide behind the next panel
  position: relative;
  z-index: 1;
}

.submit-book-export-panel {
  position: sticky;
  top: $space-md;
  z-index: $z-index-sticky-panel;

  &.right-side-panel {
    top: $space-xl;
  }
}

.export-creation-panel, .trim-size-selector {
  margin-bottom: $space-xl;
}

</style>
