
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {ExportType} from '@reedsy/schemas.workers.book-export';
import {IBookExport} from '@reedsy/reedsy-sharedb/lib/common/book/book-export';
import {IBookExportData} from '@reedsy/reedsy-sharedb/lib/common/book/book-export-data';
import {ExportState} from '@reedsy/reedsy-sharedb/lib/common/book-export/export-state';

import DOCX from '@/assets/inlined/exports/docx.svg?inline';
import EPUB from '@/assets/inlined/exports/epub.svg?inline';
import PDF from '@/assets/inlined/exports/pdf.svg?inline';

const EXPORT_TYPE_ICON_MAPPING = {
  [ExportType.Docx]: DOCX,
  [ExportType.EPub]: EPUB,
  [ExportType.Pdf]: PDF,
} as const satisfies Record<ExportType, string>;

@Component({})
export default class ExportTypeIndicator extends BookshelfVue {
  @Prop({type: Object, required: true})
  public bookExport: IBookExport;

  public get exportData(): IBookExportData {
    return this.bookExport.exportData;
  }

  public get exportType(): ExportType {
    return this.exportData.type;
  }

  public get imageSrc(): string {
    return EXPORT_TYPE_ICON_MAPPING[this.exportType];
  }

  public get isPending(): boolean {
    return this.exportData.state === ExportState.Pending;
  }

  public get isFailed(): boolean {
    return this.exportData.state === ExportState.Failed;
  }
}
