<template>
  <rbe-layout-content>
    <slot />
  </rbe-layout-content>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';

@Component({})
export default class LayoutContent extends BookshelfVue {
}
</script>

<style lang="scss" scoped>
rbe-layout-content {
  position: relative;
  background-color: var(--bookshelf-layout-background);
  width: 100%;
  height: 100%;
}
</style>
