<template>
  <ConfirmModal
    :id="id"
    :confirm="{
      label: 'Reload',
      handler: reload,
    }"
    title="Update available"
    class="accent-secondary"
  >
    We recently made changes to Reedsy Studio. Please reload
    your browser to update to the latest version and continue working
    on your book. Don’t worry, any recent changes have been saved 😎
  </ConfirmModal>
</template>

<script lang="ts">
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import ConfirmModal from '@reedsy/studio.shared/components/modals/templates/confirm-modal.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import {$lazyInject} from '@reedsy/studio.shared/inversify.config';

@Component({
  components: {
    ConfirmModal,
  },
})
export default class UpdateAvailableModal extends mixins(ModalMixin, ClientSharedVue) {
  @$lazyInject('Navigation')
  public readonly navigation: INavigation;

  public readonly cancelable = false;

  public reload(): void {
    this.navigation.reloadPage();
  }
}
</script>
