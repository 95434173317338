<template>
  <ConfirmModal
    :id="id"
    title="Revoke permissions"
    :cancel="{label: 'Cancel'}"
    :confirm="{
      label: 'Revoke',
      handler: revokePermissions,
    }"
    class="danger"
  >
    <rbe-confirm-revoke-access-modal>
      <p>
        Are you sure you want to revoke access to the book
        “<span class="emphasized">{{ context.book.title }}</span>”
        for <span class="emphasized">{{ context.collaborator.name }}</span>?
      </p>
    </rbe-confirm-revoke-access-modal>
  </ConfirmModal>
</template>

<script lang="ts">
import {Component, Prop, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ConfirmModal from '@reedsy/studio.shared/components/modals/templates/confirm-modal.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {PropType} from 'vue';
import {$lazyInject} from '@reedsy/studio.shared/inversify.config';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import {IConfirmRevokeAccessArgs} from '@reedsy/studio.shared/components/modals/i-confirm-revoke-access-args';
import Notify from '@reedsy/studio.shared/services/notify/notify';

@Component({
  components: {
    ConfirmModal,
  },
})
export default class ConfirmRevokeAccessModal extends mixins(ModalMixin, ClientSharedVue) {
  @$lazyInject('Api')
  public readonly $api: IApi;

  @Prop({type: Object as PropType<IConfirmRevokeAccessArgs>, default: {}})
  public context: IConfirmRevokeAccessArgs;

  public readonly cancelable = true;

  public async revokePermissions(): Promise<void> {
    try {
      await this.$api.revokeAccess(
        this.context.book.uuid,
        this.context.collaborator.uuid,
      );
      Notify.success({
        message: `Revoked book access for ${this.context.collaborator.name}`,
      });
    } catch (error) {
      Notify.error({
        message: `Cannot revoke book access for ${this.context.collaborator.name}`,
      });
      throw error;
    }
  }
}
</script>

<style lang="scss" scoped>
.emphasized {
  @include font-family($controls, bold);
}
</style>
