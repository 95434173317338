import {injectable, named} from 'inversify';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';
import {BookDataStoreCoordinator} from '@reedsy/studio.home.bookshelf/store/helpers/book-data-store-coordinator';
import {decodeUUID} from '@reedsy/utils.uuid';
import RouterPlugin from '@reedsy/studio.home.bookshelf/store/plugins/router-plugin';
import {BookshelfLayoutModule} from '@reedsy/studio.home.bookshelf/store/modules/layout';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import {IBookshelfRoute} from '@reedsy/studio.home.bookshelf/router/i-bookshelf-route';
import {BookDetailsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-details/book-details';
import {UserWordCountGoalsModule} from '@reedsy/studio.home.bookshelf/store/modules/user-word-count-goals/user-word-count-goals';
import {UserBookSettingsModule} from '@reedsy/studio.home.bookshelf/store/modules/user-book-settings';
import {BookActionsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-actions/book-actions';
import {isBookshelfBookRoute} from '@reedsy/studio.home.bookshelf/router/helpers/is-bookshelf-book-route';

@injectable()
export default class InitBookPluginFactory extends RouterPlugin {
  @$inject('StoreModule')
  @named(StoreName.BookActions)
  public readonly _bookActions: BookActionsModule;

  @$inject('StoreModule')
  @named(StoreName.CurrentBook)
  public readonly _currentBook: CurrentBookModule;

  @$inject('StoreModule')
  @named(StoreName.UserWordCountGoals)
  public readonly _userWordCountGoals: UserWordCountGoalsModule;

  @$inject('StoreModule')
  @named(StoreName.UserBookSettings)
  public readonly _userBookSettings: UserBookSettingsModule;

  @$inject('Api')
  public readonly _api: IApi;

  @$inject('StoreModule')
  @named(StoreName.Layout)
  public readonly _layout: BookshelfLayoutModule;

  @$inject('StoreModule')
  @named(StoreName.BookDetails)
  public readonly _bookDetails: BookDetailsModule;

  @$inject('BookshelfBookDataStoreCoordinator')
  public readonly _bookDataStoreCoordinator: BookDataStoreCoordinator;

  public override async beforeEach(to: IBookshelfRoute, from: IBookshelfRoute): Promise<void> {
    const fromBookUuid = decodeUUID(from.params.bookUuid);
    const toBookUuid = decodeUUID(to.params.bookUuid);
    const isGoingToBookshelfBook = isBookshelfBookRoute(to);
    const isComingFromBookshelfBook = isBookshelfBookRoute(from);

    // We shouldn't re-initialise book data if we going around the same book
    if (fromBookUuid === toBookUuid) return;
    if (isComingFromBookshelfBook) await this.removeBookData();
    if (isGoingToBookshelfBook) await this.loadBookData(toBookUuid);
  }

  protected override shouldApplyBefore(to: IBookshelfRoute, from: IBookshelfRoute): boolean {
    return isBookshelfBookRoute(to) || isBookshelfBookRoute(from);
  }

  private async loadBookData(bookUuid: string): Promise<void> {
    this._layout.IS_LOADING_BOOK_DATA(true);

    const bookId = await this.getBookId(bookUuid);
    if (!bookId) return;

    await Promise.all([
      this._userWordCountGoals.initialise(),
      this._bookDataStoreCoordinator.initialiseBookData({
        bookId,
        bookUuid,
      }),
    ]);
    this._currentBook.UUID(bookUuid);
    await this._bookActions.ensureUserBookSettingsCreated(bookId);
    this._layout.IS_LOADING_BOOK_DATA(false);
  }

  private async getBookId(bookUuid: string): Promise<string> {
    await this._bookDetails.initialise();
    return this._bookDetails.bookId(bookUuid);
  }

  private async removeBookData(): Promise<void> {
    this._layout.IS_LOADING_BOOK_DATA(true);
    await this._bookDataStoreCoordinator.removeBookData();
    this._currentBook.UUID(null);
  }
}
