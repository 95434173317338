<template>
  <rbe-info-placeholder class="flex-left">
    <Avatar
      class="avatar-placeholder"
      placeholder
    />
    <SkeletonLoader class="loader loader-name" />
  </rbe-info-placeholder>
  <SkeletonLoader class="loader loader-text" />
  <SkeletonLoader class="loader loader-menu" />
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Avatar from '@reedsy/studio.shared/components/avatar/avatar.vue';
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';

@Component({
  components: {
    Avatar,
    SkeletonLoader,
  },
})
export default class CollaboratorPlaceholder extends BookshelfVue {

}
</script>

<style lang="scss" scoped>
rbe-info-placeholder {
  gap: $space-sm;

  .loader-name {
    width: 50%;
  }
}

.avatar-placeholder {
  grid-area: name;
  flex-grow: 0;
  flex-shrink: 0;
}

.loader {
  width: 7rem;
}

</style>
