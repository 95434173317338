
import {
  Component,
  Prop,
} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import ExpandTransition from '@reedsy/studio.shared/components/transitions/expand-transition.vue';

@Component({
  components: {
    ExpandTransition,
  },
})
export default class FeedbackField extends ClientSharedVue {
  @Prop({type: String, required: true})
  public label: string;

  @Prop({type: Boolean, required: true})
  public showExtraField: boolean;
}
