import {SHARED_MODAL_COMPONENT_MAP} from './modal-component-map';
import {config} from '@reedsy/studio.shared/config';
import cancelableModals from '@reedsy/studio.shared/components/modals/helper/cancelable-modals';

const promise = cancelableModals(SHARED_MODAL_COMPONENT_MAP);

const SHARED_CANCELABLE_MODALS = config.env.test ?
  promise.then((modals) => ({
    ...modals,
    test: true,
  })) :
  /* istanbul ignore next */
  promise;

export default SHARED_CANCELABLE_MODALS;
