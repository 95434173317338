import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "book-title" }
const _hoisted_2 = { class: "ellipsis author-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("rbe-book-title", null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _cache[0] || (_cache[0] = _createTextVNode()),
    _createElementVNode("rbe-author-name-wrapper", null, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.authorName), 1)
    ])
  ]))
}