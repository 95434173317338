<template>
  <ImageUploader
    ref="imageUploader"
    :url="coverUrl"
    :is-uploading="isUploadingCover"
    :disabled="isRemovingCover || null"
    :upload-progress="progressPercentage"
    @error="handleError"
    @file-updated="uploadBookCover"
    @remove-file="removeBookCover"
  />
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import ImageUploader from './image-uploader.vue';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import Notify from '@reedsy/studio.shared/services/notify/notify';
import {AxiosError, AxiosProgressEvent} from 'axios';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';
import {NotifyError} from '@reedsy/studio.shared/utils/decorators/notify-error';
import {ConversionErrorCode} from '@reedsy/studio-images-api.isomorphic';
import {dig} from '@reedsy/utils.dig';
import {IMAGE_UPLOAD_ERROR_MESSAGE_MAPPING} from '@reedsy/studio.shared/utils/error-messages/image-upload-error-message-mapping';

@Component({
  components: {
    ImageUploader,
  },
})
export default class BookCoverUploader extends BookshelfVue {
  @$lazyInjectStore(StoreName.CurrentBook)
  public $currentBook: CurrentBookModule;

  @$lazyInject('Api')
  public api: IApi;

  public isRemovingCover = false;
  public progressPercentage: number = null;
  public get isUploadingCover(): boolean {
    return typeof this.progressPercentage === 'number';
  }

  public get bookId(): string {
    return this.$currentBook.id;
  }

  public get coverUrl(): string {
    return this.$currentBook.data.coverUrl;
  }

  @NotifyError('Cannot remove book cover.')
  public async removeBookCover(): Promise<void> {
    this.isRemovingCover = true;

    try {
      await this.api.deleteBookCover(this.bookId);
      Notify.success({message: 'Book cover removed.'});
    } finally {
      this.isRemovingCover = false;
    }
  }

  public async uploadBookCover(coverFile: File): Promise<void> {
    this.progressPercentage = 0;

    try {
      await this.api.uploadBookCover(this.bookId, coverFile, {
        onUploadProgress: this.onUploadProgress,
      });
      Notify.success({message: 'Book cover uploaded.'});
    } catch (error) {
      this.handleError(error);
    } finally {
      this.progressPercentage = null;
    }
  }

  public handleError(error: AxiosError): void {
    const serverErrorCode = dig(error, 'response', 'data') as ConversionErrorCode;
    const errorMessage = IMAGE_UPLOAD_ERROR_MESSAGE_MAPPING[serverErrorCode] || 'Cannot upload book cover';
    Notify.error({message: errorMessage});
  }

  private onUploadProgress(progressEvent: AxiosProgressEvent): void {
    this.progressPercentage = progressEvent.loaded / progressEvent.total * 100;
  }
}
</script>
