import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hidden-until-md" }
const _hoisted_2 = { class: "visible-until-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("rbe-book-top-info-grid", null, [
    _createElementVNode("rbe-book-top-info-row", null, [
      _createElementVNode("rbe-book-cover-wrapper", _hoisted_1, [
        _renderSlot(_ctx.$slots, "book-cover", {}, undefined, true)
      ]),
      _cache[1] || (_cache[1] = _createTextVNode()),
      _createElementVNode("rbe-book-info-right-side", null, [
        _createElementVNode("rbe-book-titles", null, [
          _renderSlot(_ctx.$slots, "book-titles", {}, undefined, true)
        ]),
        _cache[0] || (_cache[0] = _createTextVNode()),
        _createElementVNode("rbe-action", _hoisted_2, [
          _renderSlot(_ctx.$slots, "action", {}, undefined, true)
        ])
      ])
    ])
  ]))
}