import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "flex-centered" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PeriodToggle = _resolveComponent("PeriodToggle")!
  const _component_CompactFeatureSummary = _resolveComponent("CompactFeatureSummary")!
  const _component_AnnualSaving = _resolveComponent("AnnualSaving")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("rbe-manage-addons-panel", null, [
      _createElementVNode("rbe-addon-selector", _hoisted_1, [
        _createVNode(_component_PeriodToggle),
        _cache[0] || (_cache[0] = _createTextVNode()),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableSummaries, (summary) => {
          return (_openBlock(), _createBlock(_component_CompactFeatureSummary, {
            key: summary.product,
            ref_for: true,
            ref: summary.product,
            "model-value": true,
            summary: summary
          }, null, 8, ["summary"]))
        }), 128))
      ]),
      _cache[2] || (_cache[2] = _createTextVNode()),
      (_ctx.billingPeriodHasChanged || _ctx.activeFeaturesHaveChanged)
        ? (_openBlock(), _createElementBlock("rbe-update-subscription", _hoisted_2, _cache[1] || (_cache[1] = [
            _createStaticVNode("<rbe-price-info data-v-50e42bf0><rbe-price-item data-v-50e42bf0><span data-v-50e42bf0>Subtotal</span> <span data-v-50e42bf0>TODO: [subscriptions] price</span></rbe-price-item> <rbe-price-item data-v-50e42bf0><span data-v-50e42bf0>Discounts</span> <span data-v-50e42bf0>- TODO: [subscriptions] price</span></rbe-price-item> <hr data-v-50e42bf0> <rbe-price-item class=\"price-headline\" data-v-50e42bf0><span data-v-50e42bf0>Your subscription</span> <span data-v-50e42bf0>TODO: [subscriptions] price</span></rbe-price-item> <rbe-price-item data-v-50e42bf0><span data-v-50e42bf0>Next billing date</span> <span data-v-50e42bf0>TODO: [subscriptions] billing date</span></rbe-price-item></rbe-price-info> <rbe-price-confirm data-v-50e42bf0><span data-v-50e42bf0>\n            On TODO: [subscriptions] date you subscription will change from\n            TODO: [subscriptions] price/mo to TODO: [subscriptions] price/mo.\n            You can continue to use existing add-ons until this date.\n          </span> <button type=\"button\" class=\"button reedsy-accented\" data-v-50e42bf0>\n            Update subscription\n          </button></rbe-price-confirm>", 3)
          ])))
        : _createCommentVNode("", true)
    ]),
    _cache[4] || (_cache[4] = _createTextVNode()),
    (_ctx.availableSummaries.length > 1)
      ? (_openBlock(), _createElementBlock("rbe-additional-savings", _hoisted_3, [
          _createVNode(_component_AnnualSaving),
          _cache[3] || (_cache[3] = _createTextVNode("\n      when you purchase multiple add-ons\n    "))
        ]))
      : _createCommentVNode("", true)
  ]))
}