import {unsafeDig} from '@reedsy/utils.dig';
import {clone} from '@reedsy/utils.clone';
import {Op} from 'sharedb/lib/client';
import Delta from '@reedsy/quill-delta';
import {isRichTextSubtype} from '@reedsy/studio.shared/services/sharedb/is-rich-text-subtype';

/**
 * Applies an op in-place based on json0 syntax, which is different to json0.apply(), which
 * may not mutate the original object
 */
export default function applyOp(json0: any, op: Op): void {
  op = clone(op);

  const path = op.p as string[];
  const key = path.pop();
  const parent = unsafeDig(json0, ...path);
  const parentKey = path.pop();
  const grandparent = unsafeDig(json0, ...path);

  if (parent === undefined) return;

  if ('oi' in op) {
    parent[key] = op.oi;
    return;
  }

  if ('li' in op) {
    (parent as any[]).splice(parseInt(key), 0, op.li);
    return;
  }

  if ('od' in op) {
    delete parent[key];
    return;
  }

  if ('ld' in op) {
    (parent as any[]).splice(parseInt(key), 1);
    return;
  }

  if ('lm' in op) {
    (parent as any[]).splice(parseInt(op.lm), 0, (parent as any[]).splice(parseInt(key), 1)[0]);
    return;
  }

  if ('si' in op) {
    const updatedString = parent.substring(0, key) + op.si + parent.substring(key);
    grandparent[parentKey] = updatedString;
    return;
  }

  if ('sd' in op) {
    const updatedString = parent.substring(0, key) + parent.substring(key + op.sd.length);
    grandparent[parentKey] = updatedString;
    return;
  }

  if (isRichTextSubtype(op)) {
    const ops = new Delta(parent[key]).compose(new Delta(op.o)).ops;
    parent[key].ops = ops;
    return;
  }

  throw new Error('Unrecognised op type');
}
