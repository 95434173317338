import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "desynchronisation-modal" }
const _hoisted_2 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!

  return (_openBlock(), _createBlock(_component_ConfirmModal, {
    id: _ctx.id,
    title: "Oops, something went wrong!",
    class: "accent-secondary"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[1] || (_cache[1] = _createElementVNode("p", null, "\n        Your last change couldn’t be saved. Please reach out to our support team and let\n        us know what you were trying to insert or change.\n      ", -1)),
        _cache[2] || (_cache[2] = _createTextVNode()),
        _cache[3] || (_cache[3] = _createElementVNode("p", null, "\n        Don’t worry, your book is fine — the only thing that will be lost is the last\n        thing you did, like inserting, deleting, or formatting a selection.\n      ", -1)),
        _cache[4] || (_cache[4] = _createTextVNode()),
        _cache[5] || (_cache[5] = _createElementVNode("p", null, "\n        You can reload to fix this issue.\n      ", -1)),
        _cache[6] || (_cache[6] = _createTextVNode()),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            ref: "reload",
            class: "button reedsy-accented neutral",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.reload && _ctx.reload(...args)))
          }, "\n          Reload\n        ", 512)
        ])
      ])
    ]),
    _: 1
  }, 8, ["id"]))
}