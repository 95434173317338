export function classesToObject(classes: string | string[] | Record<string, any>): Record<string, boolean> {
  const obj: Record<string, boolean> = {};
  if (typeof classes === 'string') classes = classes.trim().split(/\s+/);
  if (Array.isArray(classes)) {
    classes.forEach((c) => obj[c] = true);
  } else {
    for (const key in classes) obj[key] = !!classes[key];
  }
  return obj;
}
