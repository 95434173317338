import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex-centered" }
const _hoisted_2 = {
  key: 1,
  class: "info-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonLoader = _resolveComponent("SkeletonLoader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, _toDisplayString(_ctx.title), 1),
    _cache[0] || (_cache[0] = _createTextVNode()),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_SkeletonLoader, {
          key: 0,
          class: "info-text"
        }))
      : (_openBlock(), _createElementBlock("p", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
  ]))
}