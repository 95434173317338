
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import PremiumIcon from './premium-icon.vue';

@Component({
  components: {
    PremiumIcon,
  },
})
export default class PremiumBadgeInfo extends ClientSharedVue {
}
