import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["data-test-size-name", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("rbe-export-trim-sizes", null, [
    (_ctx.trimSizes)
      ? (_openBlock(), _createElementBlock("rbe-export-sizes-graphics", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trimSizes, (size) => {
            return (_openBlock(), _createElementBlock("div", {
              key: size.sizeId,
              class: _normalizeClass([{
          'active': (size.sizeId === _ctx.trimSize),
        }, "export-size-option"]),
              style: _normalizeStyle({
          height: `${ size.percentageHeight }%`,
          width: `${ size.percentageWidth }%`,
        }),
              "data-test": "trim-size",
              "data-test-size-name": size.name,
              onClick: ($event: any) => (_ctx.trimSize = size.sizeId)
            }, null, 14, _hoisted_2))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _cache[2] || (_cache[2] = _createTextVNode()),
    _createElementVNode("rbe-export-sizes-legend", null, [
      _createElementVNode("strong", null, _toDisplayString(_ctx.activeSizeDetails.graphicalSelectorName), 1),
      _cache[1] || (_cache[1] = _createTextVNode()),
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, "\n          Width: " + _toDisplayString(_ctx.activeSizeDetails.width) + "”\n        ", 1),
        _cache[0] || (_cache[0] = _createTextVNode()),
        _createElementVNode("li", null, "\n          Height: " + _toDisplayString(_ctx.activeSizeDetails.height) + "”\n        ", 1)
      ])
    ])
  ]))
}