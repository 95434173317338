import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiPageFilters = _resolveComponent("VuiPageFilters")!

  return (_openBlock(), _createElementBlock("rbe-bookshelf-page-filter", {
    class: _normalizeClass(["themed", {
      'dark-theme': _ctx.$layout.contentIsDark,
      'light-theme': !_ctx.$layout.contentIsDark
    }])
  }, [
    _createVNode(_component_VuiPageFilters, {
      ref: "pageFilter",
      count: _ctx.bookCount,
      toggles: _ctx.toggles,
      "items-plural": "books",
      "items-singular": "book",
      "search-placeholder": "Title...",
      search: _ctx.$bookshelf.searchValue,
      "is-loading": _ctx.$layout.isLoadingBooksList,
      class: "bookshelf-books-page-filter",
      "onUpdate:search": _ctx.updateSearchValue,
      onToggle: _ctx.changeArchiveRoute
    }, null, 8, ["count", "toggles", "search", "is-loading", "onUpdate:search", "onToggle"])
  ], 2))
}