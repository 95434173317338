import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "hidden-until-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookBackLink = _resolveComponent("BookBackLink")!
  const _component_BookPageSelector = _resolveComponent("BookPageSelector")!
  const _component_ContinueWritingButton = _resolveComponent("ContinueWritingButton")!

  return (_openBlock(), _createElementBlock("rbe-book-top-bar", null, [
    _createElementVNode("rbe-controls-wrapper", null, [
      _createVNode(_component_BookBackLink, { class: "book-back-link" }),
      _cache[1] || (_cache[1] = _createTextVNode()),
      _createElementVNode("rbe-right-side", null, [
        _createElementVNode("rbe-book-page-selector", null, [
          _createVNode(_component_BookPageSelector)
        ]),
        _cache[0] || (_cache[0] = _createTextVNode()),
        _createElementVNode("rbe-continue-writing-button", _hoisted_1, [
          _createVNode(_component_ContinueWritingButton, {
            book: _ctx.$currentBook.data
          }, null, 8, ["book"])
        ])
      ])
    ])
  ]))
}