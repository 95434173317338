import {$inject} from '@reedsy/studio.shared/types';
import {injectable, named} from 'inversify';
import {IStorePluginFactory} from '@reedsy/studio.shared/store/plugins/i-store-plugin-factory';
import {IBookshelfAuthPayload} from '@reedsy/reedsy-sharedb/lib/common/auth-payload';
import {MESSAGE_ACTIONS} from 'sharedb';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import IShareDbConnection from '@reedsy/studio.shared/services/sharedb/i-sharedb-connection';
import {deepEqual} from '@reedsy/utils.deep-equal';

@injectable()
export abstract class AbstractSubscriptionFeaturesPluginFactory implements IStorePluginFactory {
  public abstract readonly _shareDbConnection: IShareDbConnection<any>;

  @$inject('StoreModule')
  @named(SharedStoreName.Subscription)
  public readonly _subscription: SharedSubscriptionModule;

  public plugin(): void {
    this._shareDbConnection.on('receive', async ({data}) => {
      if (data.a !== MESSAGE_ACTIONS.handshake) return;
      const auth = data.$reedsy.auth as IBookshelfAuthPayload;
      if (!deepEqual(this._subscription.paidFeatures, auth.paidFeatures)) {
        this._subscription.PAID_FEATURES(auth.paidFeatures);
      }
      await this._subscription.fetchTrialEnd();
    });
  }
}
