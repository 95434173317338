export enum SocialNetwork {
  Facebook = 'facebook',
  LinkedIn = 'linkedin',
  Twitter = 'twitter',
}

export const SUPPORTED_NETWORKS: Record<SocialNetwork, INetworkDetails> = {
  [SocialNetwork.Facebook]: {
    icon: 'VuiIconFacebook',
    name: 'Facebook',
  },
  [SocialNetwork.LinkedIn]: {
    icon: 'VuiIconLinkedin',
    name: 'LinkedIn',
  },
  [SocialNetwork.Twitter]: {
    icon: 'VuiIconTwitter',
    name: 'Twitter',
  },
};

export interface INetworkDetails {
  icon: string;
  name: string;
}
