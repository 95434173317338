<template>
  <VDropdown
    class="info-tip"
    :boundary="tooltipBoundary"
    v-bind="$attrs"
  >
    <slot name="trigger">
      <button class="button button-xs reedsy-accented neutral button-icon bordered">
        <VuiIconInfo class="icon-sm" />
      </button>
    </slot>

    <template #popper>
      <rbe-info-tip-content>
        <slot />
      </rbe-info-tip-content>
    </template>
  </VDropdown>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({})
export default class InfoTip extends ClientSharedVue {
  public tooltipBoundary: Element = null;

  public mounted(): void {
    this.tooltipBoundary = this.$el.parentElement;
  }
}
</script>

<style lang="scss">
.info-tip {
  display: flex;
}

rbe-info-tip-content {
  @include font-family($controls);

  max-width: 22rem;
  padding: $space-sm;
}
</style>
