
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import ExportTypeIndicator from './export-type-indicator.vue';
import {IBookExport} from '@reedsy/reedsy-sharedb/lib/common/book/book-export';
import {IBookExportData} from '@reedsy/reedsy-sharedb/lib/common/book/book-export-data';
import ExportAction from './export-action/export-action.vue';
import longDate from '@reedsy/studio.shared/filters/date-with-year';
import {ExportType} from '@reedsy/schemas.workers.book-export';
import {ExportState} from '@reedsy/reedsy-sharedb/lib/common/book-export/export-state';
import {$lazyInject} from '@reedsy/studio.home.bookshelf/inversify.config';
import {BookDataStoreCoordinator} from '@reedsy/studio.home.bookshelf/store/helpers/book-data-store-coordinator';

const EXPORT_TYPE_DISPLAY_VALUES: Record<ExportType, string> = {
  [ExportType.Docx]: 'DOCX',
  [ExportType.Pdf]: 'PDF',
  [ExportType.EPub]: 'EPUB',
};

@Component({
  components: {
    ExportTypeIndicator,
    ExportAction,
  },
})
export default class ExportsListEntry extends BookshelfVue {
  @$lazyInject('BookshelfBookDataStoreCoordinator')
  public $bookDataStoreCoordinator: BookDataStoreCoordinator;

  @Prop({type: Object, required: true})
  public bookExport: IBookExport;

  public get exportData(): IBookExportData {
    return this.bookExport.exportData;
  }

  public get exportType(): ExportType {
    return this.exportData.type;
  }

  public get exportState(): ExportState {
    return this.exportData.state;
  }

  public get title(): string {
    return this.exportData.options.title;
  }

  public get isFailed(): boolean {
    return this.exportState === ExportState.Failed;
  }

  public get isPending(): boolean {
    return this.exportData.state === ExportState.Pending;
  }

  public get exportTypeDisplayValue(): string {
    return EXPORT_TYPE_DISPLAY_VALUES[this.exportType];
  }

  public get createdAt(): string {
    return longDate(this.bookExport.exportData.createdAt);
  }

  public get hasDownloadLink(): boolean {
    return this.exportState == ExportState.Processed;
  }

  public get downloadExportUrl(): string {
    return this.hasDownloadLink ?
      this.$bookDataStoreCoordinator
        .getModule('bookExports')
        .downloadExportLink(this.bookExport._id) : '';
  }

  public get wrapperComponent(): string {
    return this.hasDownloadLink ? 'a' : 'div';
  }

  public get tooltipOptions(): any {
    return {
      content: `
          Try to export book again, if problem persists,
          please contact our support team using help button.
      `,
      placement: 'bottom-start',
      popperClass: 'failed-export-popper',
      arrowPadding: 12,
    };
  }
}
