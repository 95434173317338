import {injectable, named} from 'inversify';
import RouterPlugin from '@reedsy/studio.home.bookshelf/store/plugins/router-plugin';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {IBookshelfShareDbConnection} from '@reedsy/studio.home.bookshelf/services/bookshelf-sharedb-connection.interface';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfLayoutModule} from '@reedsy/studio.home.bookshelf/store/modules/layout';
import {BookshelfStoreListener} from '@reedsy/studio.home.bookshelf/store/bookshelf-store-listener';

@injectable()
export default class InitBookshelfShareDbConnectionPluginFactory extends RouterPlugin {
  @$inject('BookshelfShareDbConnection')
  public readonly _shareDbConnection: IBookshelfShareDbConnection;

  @$inject('StoreModule')
  @named(StoreName.Layout)
  public readonly _layout: BookshelfLayoutModule;

  @$inject('StoreListener')
  public readonly _storeListener: BookshelfStoreListener;

  public override async beforeEach(): Promise<void> {
    if (this._shareDbConnection.connection) return;

    this._shareDbConnection.init();

    this._storeListener.subscribe(
      StoreName.Layout,
      'IS_LOADING_CURRENT_USER',
      () => this._shareDbConnection.doneInitialLoad(),
      {once: true},
    );
  }
}
