
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import BookTopInfo from '@reedsy/studio.home.bookshelf/components/book-top-info/book-top-info.vue';
import Panel from '@reedsy/studio.shared/components/panel/panel.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';

@Component({
  components: {
    BookTopInfo,
    Panel,
  },
})
export default class BookArchivePanel extends BookshelfVue {
  @$lazyInjectStore(StoreName.CurrentBook)
  public $currentBook: CurrentBookModule;

  @$lazyInjectStore(StoreName.Modals)
  public $modals: BookshelfModalsModule;

  public get archiveActionTitle(): string {
    const action = this.isHidden ? 'Restore' : 'Archive';

    return `${action} book`;
  }

  public get showDeleteButton(): boolean {
    return this.$currentBook.hasCurrentUserPermissionTo('deleteBook');
  }

  private get isHidden(): boolean {
    return this.$currentBook.isHidden;
  }

  public showArchiveBookConfirmationModal(): void {
    this.$modals.open({
      type: 'ConfirmBookArchive',
      context: {
        bookId: this.$currentBook.id,
      },
    });
  }

  public showDeletionBookConfirmationModal(): void {
    this.$modals.open('ConfirmBookDeletionModal');
  }
}
