<template>
  <rbe-notifications-preferences>
    <SettingsPanelTitle>
      Preferences
    </SettingsPanelTitle>
    <ItemsBox class="filled">
      <VuiCheckbox
        v-model="isEmailEnabled"
        label="Email notifications"
      />
    </ItemsBox>
  </rbe-notifications-preferences>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import SettingsPanelTitle from '@reedsy/studio.home.bookshelf/components/settings-panel-title/settings-panel-title.vue';
import ItemsBox from '@reedsy/studio.shared/components/items-box.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {UserSettingsModule} from '@reedsy/studio.home.bookshelf/store/modules/user-settings';
import {NotificationMedium} from '@reedsy/reedsy-sharedb/lib/common/user-settings/notification-medium';

@Component({
  components: {
    SettingsPanelTitle,
    ItemsBox,
  },
})
export default class NotificationsPreferences extends BookshelfVue {
  @$lazyInjectStore(StoreName.UserSettings)
  public readonly userSettings: UserSettingsModule;

  public get isEmailEnabled(): boolean {
    return !this.userSettings.settings.notifications.disabledMediums[NotificationMedium.Email];
  }

  public set isEmailEnabled(value: boolean) {
    this.userSettings.toggleNotificationMedium({
      medium: NotificationMedium.Email,
      enable: value,
    });
  }
}
</script>
