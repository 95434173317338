
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';

@Component({})
export default class BookTitle extends BookshelfVue {
  @Prop({type: String, required: true})
  public title: string;

  @Prop({type: Object, required: true})
  public author: IUserInfo;

  public get firstName(): string {
    return this.author.firstName || '';
  }

  public get lastName(): string {
    return this.author.lastName;
  }

  public get authorName(): string {
    const firstInitial = this.firstName[0];
    return [firstInitial, this.lastName]
      .filter(Boolean)
      .join('. ');
  }
}
