import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, {
    "body-title": "Archive book",
    class: "archive-panel"
  }, {
    default: _withCtx(() => [
      _cache[3] || (_cache[3] = _createElementVNode("p", null, "\n      Archived books can be restored at any time from your bookshelf.\n    ", -1)),
      _cache[4] || (_cache[4] = _createTextVNode()),
      _createElementVNode("rbe-book-actions", null, [
        _createElementVNode("button", {
          ref: "archiveButton",
          type: "button",
          class: "button reedsy-accented neutral",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showArchiveBookConfirmationModal && _ctx.showArchiveBookConfirmationModal(...args)))
        }, _toDisplayString(_ctx.archiveActionTitle), 513),
        _cache[2] || (_cache[2] = _createTextVNode()),
        (_ctx.showDeleteButton)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              ref: "deleteButton",
              type: "button",
              class: "delete-book-button button reedsy-accented danger bordered",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showDeletionBookConfirmationModal && _ctx.showDeletionBookConfirmationModal(...args)))
            }, "\n        Delete permanently\n      ", 512))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}