
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import ShareButton from './share-button.vue';
import {SocialNetwork} from './supported-networks';

@Component({
  components: {
    ShareButton,
  },
})
export default class FacebookShareButton extends BookshelfVue {
  @Prop({type: String, required: true})
  public sharedLink: string;

  public readonly SocialNetwork = SocialNetwork;

  public get url(): string {
    return `http://www.facebook.com/sharer.php?u=${this.sharedLink}`;
  }
}
