
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import Overlay from '@reedsy/studio.shared/components/overlay.vue';
import IModal from '@reedsy/studio.shared/store/modules/modals/i-modal';
import {Key} from '@reedsy/studio.shared/utils/keyboard/key';
import handleKeyboardEvent from '@reedsy/studio.shared/utils/keyboard/handle-keyboard-event';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedModalsModule} from '@reedsy/studio.shared/store/modules/modals';
import {modalComponentName} from './modal-component-name';

@Component({
  components: {
    Overlay,
  },
})
export default class ModalContainer extends ClientSharedVue {
  @$lazyInjectStore(SharedStoreName.Modals)
  public $modals: SharedModalsModule;

  public get modals(): IModal[] {
    return this.$modals.modals;
  }

  public mounted(): void {
    window.addEventListener('keydown', (event) => handleKeyboardEvent(
      event,
      {key: Key.Escape, handler: this.closeAll},
    ));
  }

  public modalComponent(type: string): string {
    return modalComponentName(type);
  }

  public closeAll(): void {
    this.$modals.CLOSE_ALL();
  }
}
