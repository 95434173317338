
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import ConfirmModal from '@reedsy/studio.shared/components/modals/templates/confirm-modal.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import BaseInput from '@reedsy/studio.shared/components/forms/base-input.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';
import {BookshelfModule} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf';
import {SmartQuotes} from '@reedsy/utils.string';

@Component({
  components: {
    ConfirmModal,
    BaseInput,
  },
})
export default class ConfirmBookDeletionModal extends mixins(ModalMixin, BookshelfVue) {
  @$lazyInjectStore(StoreName.CurrentBook)
  public $currentBook: CurrentBookModule;

  @$lazyInjectStore(StoreName.Bookshelf)
  public $bookshelf: BookshelfModule;

  public readonly cancelable = true;
  public typedBookTitle = '';
  public bookTitle = '';

  public get titlesAreSame(): boolean {
    return this.normalizeTitle(this.bookTitle) === this.normalizeTitle(this.typedBookTitle);
  }

  public mounted(): void {
    this.bookTitle = this.$currentBook.data.title;
  }

  public async deleteBook(): Promise<void> {
    await this.$bookshelf.deleteBook(this.$currentBook.id);
  }

  private normalizeTitle(title: string): string {
    return SmartQuotes.remove(title.trim());
  }
}
