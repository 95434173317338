import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VInput = _resolveComponent("VInput")!
  const _component_LoadingButton = _resolveComponent("LoadingButton")!
  const _component_VForm = _resolveComponent("VForm")!
  const _component_Panel = _resolveComponent("Panel")!
  const _directive_root_class = _resolveDirective("root-class")!

  return (_openBlock(), _createBlock(_component_Panel, {
    title: "Settings",
    class: "settings-panel"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_VForm, {
        class: "parent-content",
        onSubmit: _ctx.updateBook
      }, {
        default: _withCtx(() => [
          _cache[3] || (_cache[3] = _createElementVNode("label", {
            class: "input-label",
            for: "book-title"
          }, "\n        Title\n      ", -1)),
          _cache[4] || (_cache[4] = _createTextVNode()),
          _withDirectives(_createVNode(_component_VInput, {
            id: "book-title",
            ref: "bookTitleInput",
            modelValue: _ctx.title,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
            type: "text",
            placeholder: "Enter a book title...",
            required: "",
            maxlength: _ctx.maxTitleLength
          }, null, 8, ["modelValue", "maxlength"]), [
            [_directive_root_class, 'input-full']
          ]),
          _cache[5] || (_cache[5] = _createTextVNode()),
          _cache[6] || (_cache[6] = _createElementVNode("label", {
            class: "input-label optional",
            for: "book-subtitle"
          }, "\n        Subtitle\n      ", -1)),
          _cache[7] || (_cache[7] = _createTextVNode()),
          _withDirectives(_createVNode(_component_VInput, {
            id: "book-subtitle",
            ref: "bookSubtitleInput",
            modelValue: _ctx.subtitle,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.subtitle) = $event)),
            type: "text",
            placeholder: "Enter a book subtitle...",
            maxlength: _ctx.maxSubtitleLength
          }, null, 8, ["modelValue", "maxlength"]), [
            [_directive_root_class, 'input-full']
          ]),
          _cache[8] || (_cache[8] = _createTextVNode()),
          _createElementVNode("rbe-loading-button-wrapper", null, [
            _createVNode(_component_LoadingButton, {
              ref: "saveBookDetailsButton",
              loading: _ctx.isSaving,
              class: "button"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("\n          Save\n        ")
              ])),
              _: 1
            }, 8, ["loading"])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }))
}