<template>
  <transition name="fade">
    <VuiLoadingIndicator v-if="show" />
  </transition>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {timeout} from '@reedsy/utils.timeout';

@Component({})
export default class DelayedLoadingIndicator extends ClientSharedVue {
  @Prop({required: false, default: 500})
  public delay: number;

  public show = false;

  public async mounted(): Promise<void> {
    await timeout(this.delay);
    this.show = true;
  }
}
</script>
