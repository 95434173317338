import {IBookImport} from '@reedsy/reedsy-sharedb/lib/common/book/book-imports';
import {IBookshelfBook} from './i-bookshelf-book';

export enum BookshelfEntryType {
  Book = 'Book',
  BookImport = 'BookImport',
}

type EntryTypeMap = {
  [BookshelfEntryType.Book]: IBookshelfBook;
  [BookshelfEntryType.BookImport]: IBookImport;
};

export interface IBookshelfEntry<T extends BookshelfEntryType = BookshelfEntryType> {
  id: string;
  item: EntryTypeMap[T];
  type: T;
}
