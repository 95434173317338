import { vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    ref: _ctx.inputFieldRef,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
    class: _normalizeClass({touched: _ctx.touched}),
    spellcheck: false,
    onCompositionupdate: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateValueFromComposition && _ctx.updateValueFromComposition(...args))),
    onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
  }, null, 34)), [
    [_vModelText, _ctx.value]
  ])
}