<template>
  <footer class="hidden-until-sm">
    <rbe-footer-content class="vui-wrapper">
      <rbe-left-side>
        <RouterLink
          ref="logoLink"
          :to="home"
          title="Reedsy"
          class="logo-link"
        >
          <img
            src="@/assets/inlined/logo/reedsy-gray.svg"
            class="logo"
          >
        </RouterLink>
        <span>&copy; <span data-test-id="copy-date">{{ currentYear }}</span></span>
      </rbe-left-side>
      <rbe-right-side>
        <a
          :href="navigation.termsOfUseUrl"
          target="_blank"
        >Terms</a>
        <p class="separator">
          •
        </p>
        <a
          :href="navigation.privacyUrl"
          target="_blank"
        >Privacy</a>
      </rbe-right-side>
    </rbe-footer-content>
  </footer>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import {MetaRawLocation} from '@reedsy/studio.shared/router/reedsy-router';
import {$lazyInject} from '@reedsy/studio.shared/inversify.config';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';

@Component({})
export default class LayoutFooter extends BookshelfVue {
  @$lazyInject('Navigation')
  public navigation: INavigation;

  public get currentYear(): number {
    return new Date().getFullYear();
  }

  public get home(): MetaRawLocation {
    return {
      name: BookshelfRouteName.BooksList,
    };
  }
}
</script>

<style lang="scss" scoped>
footer {
  border-top: $border;

  rbe-footer-content {
    padding-bottom: $space-lg;
    padding-top: $space-lg;
    display: flex;
    justify-content: space-between;

    rbe-left-side,
    rbe-right-side {
      display: flex;
      align-items: center;
    }

    rbe-left-side {
      color: var(--reedsy-form-info-textColor);

      .logo-link {
        display: flex;
        align-items: center;
        margin-right: $space-sm;

        .logo {
          height: $font-size-xxl;
        }
      }
    }

    rbe-right-side {
      gap: $space-sm;

      a {
        @include font-family($controls);

        &:hover {
          text-decoration: underline;
        }
      }

      .separator {
        @include font-family($controls, bold);
      }
    }
  }
}
</style>
