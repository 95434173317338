import { resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BooksPageFilter = _resolveComponent("BooksPageFilter")!
  const _component_WelcomeBar = _resolveComponent("WelcomeBar")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_MainLayout = _resolveComponent("MainLayout")!

  return (_openBlock(), _createBlock(_component_MainLayout, {
    class: _normalizeClass({
      'full-width-content': _ctx.isBooksListMobileLayout,
      'remove-vertical-scroll': _ctx.isBooksListMobileLayout
    })
  }, {
    "above-content": _withCtx(() => [
      _createVNode(_component_BooksPageFilter)
    ]),
    "top-part": _withCtx(() => [
      _createVNode(_component_WelcomeBar)
    ]),
    default: _withCtx(() => [
      _cache[0] || (_cache[0] = _createTextVNode()),
      _cache[1] || (_cache[1] = _createTextVNode()),
      _createElementVNode("rbe-bookshelf-content", null, [
        _createVNode(_Transition, {
          name: "fade",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_RouterView)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}