import {VuexModule, Action, Mutation} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import IScreenState from './i-screen-state';
import {ScreenSize} from './screen-size';
import {Orientation, OrientationTypes} from './orientation-classes';
import SASS from '@reedsy/studio.shared/utils/sass';
import IRoute from './i-route';
import {IBreakpointMutation} from './i-breakpoint-mutation';
import {Store} from 'vuex';
import {injectable} from 'inversify';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {$inject} from '@reedsy/studio.shared/types';

@injectable()
export class SharedBrowserModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,
  ) {
    @Module({name: SharedStoreName.Browser, store})
    class SharedBrowser extends VuexModule {
      public screen: IScreenState = {
        greaterThanOrEqual: {
          sm: false,
          md: false,
          lg: false,
        },
        lessThan: {
          sm: false,
          md: false,
          lg: false,
        },
        orientation: null,
      };

      public path = '';
      public route: IRoute = null;

      @Mutation
      public PATH(path: string): void {
        this.path = path;
      }

      @Mutation
      public ROUTE(route: IRoute): void {
        this.route = route;
      }

      @Action
      public setScreenWidth(px: number): void {
        Object.values(ScreenSize).forEach((size) => {
          this.setGreaterThan({size, value: px >= SASS.breakpoint[size]});
          this.setLessThan({size, value: px < SASS.breakpoint[size]});
        });
      }

      @Action
      public setOrientation(orientation: number): void {
        if (orientation === this.screen.orientation) return;
        this.SET_ORIENTATION(orientation);

        Object.values(Orientation).forEach((classString: string) => {
          document.body.classList.toggle(classString, OrientationTypes[`${orientation}`] === classString);
        });
      }

      @Mutation
      public _SET_GREATER_THAN({size, value}: IBreakpointMutation): void {
        this.screen.greaterThanOrEqual[size] = value;
      }

      @Mutation
      public _SET_LESS_THAN({size, value}: IBreakpointMutation): void {
        this.screen.lessThan[size] = value;
      }

      @Mutation
      private SET_ORIENTATION(orientation: number): void {
        this.screen.orientation = orientation;
      }

      @Action
      private setGreaterThan({size, value}: IBreakpointMutation): void {
        if (this.screen.greaterThanOrEqual[size] === value) return;
        this._SET_GREATER_THAN({size, value});
      }

      @Action
      private setLessThan({size, value}: IBreakpointMutation): void {
        if (this.screen.lessThan[size] === value) return;
        this._SET_LESS_THAN({size, value});
      }
    }

    this.Module = SharedBrowser;
  }
}

export type SharedBrowserModule = InstanceType<SharedBrowserModuleFactory['Module']>;
