
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import TopBanner from './top-banner.vue';
import TrialCountdownMixin from '@reedsy/studio.shared/mixins/subscriptions/trial-countdown';
import PremiumAdBanner from '@reedsy/studio.shared/components/subscriptions/premium-ad-banner.vue';

@Component({
  components: {
    PremiumAdBanner,
    TopBanner,
  },
})
export default class PremiumBanner extends mixins(BookshelfVue, TrialCountdownMixin) {
}
