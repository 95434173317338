
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import PlainModal from '@reedsy/studio.shared/components/modals/templates/plain-modal.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {UserSettingsModule} from '@reedsy/studio.home.bookshelf/store/modules/user-settings';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {WritingStage} from '@reedsy/reedsy-sharedb/lib/common/user-settings/writing-stage';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import {enumValues} from '@reedsy/utils.enum';

@Component({
  components: {
    PlainModal,
  },
})
export default class AfterSignUpSurvey extends mixins(ModalMixin, BookshelfVue) {
  @$lazyInjectStore(StoreName.UserSettings)
  public $userSettings: UserSettingsModule;

  @$lazyInjectStore(SharedStoreName.User)
  public $user: SharedUserModule;

  public selection: WritingStage = null;
  public readonly WRITING_STAGE_TEXTS = {
    [WritingStage.StartingToWrite]: 'I’m just starting to write ✍️',
    [WritingStage.WorkingOnExistingProject]: 'I’m working on an existing project 📃',
    [WritingStage.ReadyToPublish]: 'I’m ready to format my book for publication 📚',
  } as const satisfies Record<WritingStage, string>;

  public get firstName(): string {
    return this.$user.info.firstName;
  }

  public get hasSelection(): boolean {
    return !!this.selection;
  }

  public get availableWritingStages(): WritingStage[] {
    return enumValues(WritingStage);
  }

  public async updateWritingStage(): Promise<void> {
    await this.$userSettings.setWritingStage(this.selection);
    await this.close();
  }
}
