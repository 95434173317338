<template>
  <rbe-export-type-indicator
    :class="{
      loading: isPending,
      error: isFailed
    }"
  >
    <img
      :src="imageSrc"
      :alt="exportType"
    >
    <rbe-indicator-overlay>
      <VuiLoadingIndicator v-if="isPending" />
      <VuiIconWarning
        v-else
        class="icon-xl warning-icon"
      />
    </rbe-indicator-overlay>
  </rbe-export-type-indicator>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {ExportType} from '@reedsy/schemas.workers.book-export';
import {IBookExport} from '@reedsy/reedsy-sharedb/lib/common/book/book-export';
import {IBookExportData} from '@reedsy/reedsy-sharedb/lib/common/book/book-export-data';
import {ExportState} from '@reedsy/reedsy-sharedb/lib/common/book-export/export-state';

import DOCX from '@/assets/inlined/exports/docx.svg?inline';
import EPUB from '@/assets/inlined/exports/epub.svg?inline';
import PDF from '@/assets/inlined/exports/pdf.svg?inline';

const EXPORT_TYPE_ICON_MAPPING = {
  [ExportType.Docx]: DOCX,
  [ExportType.EPub]: EPUB,
  [ExportType.Pdf]: PDF,
} as const satisfies Record<ExportType, string>;

@Component({})
export default class ExportTypeIndicator extends BookshelfVue {
  @Prop({type: Object, required: true})
  public bookExport: IBookExport;

  public get exportData(): IBookExportData {
    return this.bookExport.exportData;
  }

  public get exportType(): ExportType {
    return this.exportData.type;
  }

  public get imageSrc(): string {
    return EXPORT_TYPE_ICON_MAPPING[this.exportType];
  }

  public get isPending(): boolean {
    return this.exportData.state === ExportState.Pending;
  }

  public get isFailed(): boolean {
    return this.exportData.state === ExportState.Failed;
  }
}
</script>

<style lang="scss" scoped>
rbe-export-type-indicator {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: $border-radius-base;

  &.error {
    box-shadow: $box-shadow-base;
  }

  &.loading,
  &.error {
    img {
      opacity: 0.5;
    }

    rbe-indicator-overlay {
      display: flex;
    }
  }

  img {
    height: auto;
    width: $export-type-indicator-width;
    box-shadow: $box-shadow-base;
    border-radius: $border-radius-base;
  }

  rbe-indicator-overlay {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;

    .warning-icon {
      color: var(--reedsy-danger);
    }
  }
}
</style>
