import ReedsyAnalyticsManager from '@reedsy/analytics-manager';
import {IClientConfig} from '@reedsy/studio.shared/config';
import {$inject} from '@reedsy/studio.shared/types';
import {deepInjectable} from '@reedsy/utils.inversify';
import IApi from '@reedsy/studio.shared/services/api/i-api';
import {one} from '@reedsy/utils.fluent-date';
import {named} from 'inversify';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedUserModule} from '@reedsy/studio.shared/store/modules/user';
import {objectKeys} from '@reedsy/utils.object';
import {GoogleAnalyticsEvent} from '@reedsy/utils.analytics';
import loggerFactory from '@reedsy/studio.shared/services/logger/logger-factory';

const logger = loggerFactory.create('StudioAnalyticsManager');

@deepInjectable()
export class StudioAnalyticsManager extends ReedsyAnalyticsManager {
  public constructor(
    @$inject('Config')
    config: IClientConfig,

    @$inject('Api')
    private readonly api: IApi,

    @$inject('StoreModule')
    @named(SharedStoreName.User)
    private readonly user: SharedUserModule,
  ) {
    super({
      gtm: {
        id: config.gtm.containerId,
        src: config.gtm.tagScriptSrc,
      },
    });
  }

  public triggerAnalytics(): void {
    this.checkAnalytics();
    // Make sure we trigger this once per day in case users just
    // leave the same tab open all the time
    setInterval(() => this.checkAnalytics(), +one.day);
  }

  // Beware: this may never resolve if clients have an ad blocker
  public trackEvent(event: GoogleAnalyticsEvent): Promise<void> {
    return new Promise((resolve, reject) => {
      const eventCallback: GACallback = (_id, {tags}) => {
        const anyFailures = tags.some(({status}) => status !== 'success');
        if (anyFailures) return reject(new Error('Failed GA send'));
        resolve();
      };

      this.pushEvent(event, {
        user_email: this.user.info.email,
        eventCallback,
      } as any);
    });
  }

  private async checkAnalytics(): Promise<void> {
    const response = await this.user.fetchUserAnalyticsEvents();
    // Didn't get a lock, so another client is processing events already
    if (!response?.lockUntil) return;
    const {events, lockUntil} = response;

    const sentEvents: GoogleAnalyticsEvent[] = [];
    const trackEvent = async (event: GoogleAnalyticsEvent): Promise<void> => {
      try {
        await this.trackEvent(event);
        sentEvents.push(event);
      } catch (error) {
        logger.debug(error);
        return null;
      }
    };

    const unsentEvents = objectKeys(events).filter((event) => !events[event].sentAt);
    await Promise.all(unsentEvents.map(trackEvent));

    await this.api.markUserAnalyticsEventsAsSent({sentEvents, lockUntil: lockUntil.toISOString()});
  }
}

type GAPayload = {id: string; status: string; executionTime: number};
type GACallback = (id: string, payload: {tags: GAPayload[]}) => void;
