import EventEmitter from 'eventemitter3';
import {Connection} from 'sharedb/lib/client';
import {SocketStateEvent} from '@reedsy/studio.shared/services/sharedb/sharedb-connection-event';
import IShareDbConnection from '@reedsy/studio.shared/services/sharedb/i-sharedb-connection';
import {deepInjectable} from '@reedsy/utils.inversify';

@deepInjectable()
export default abstract class AbstractShareDbConnection<TInitData>
  extends EventEmitter
  implements IShareDbConnection<TInitData> {
  public abstract init(initData?: TInitData): void;

  public connection: Connection;
  public socketConnected = false;
  private _incompatibleApi = false;

  public get wantsConnection(): boolean {
    return !!this.connection;
  }

  public get incompatibleApi(): boolean {
    return this._incompatibleApi;
  }

  public setIncompatibleApi(): void {
    this._incompatibleApi = true;
  }

  public doneInitialLoad(): void {
    // No op
  }

  protected setSocketConnected(socketConnected: boolean): void {
    if (this.socketConnected === socketConnected) return;
    this.socketConnected = socketConnected;

    this.emit(SocketStateEvent.ChangeState, this.socketConnected);
  }
}
