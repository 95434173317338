<template>
  <PlainModal
    :id="id"
    headless
    class="wide transparent full-height"
  >
    <div class="export-started-modal-content">
      <img
        alt="Typewriter"
        src="@/assets/inlined/export-started-modal/typewriter.png"
        width="240"
      >
      <h1 class="title text-light">
        Your book is being typeset!
      </h1>

      <p class="description text-light font-size-large">
        You will receive an email shortly with a link
        to download your fully formatted files.
        Share this milestone with your networks!
      </p>
      <div class="share-wrapper">
        <FacebookShareButton
          class="button-lg button-full-xs"
          :shared-link="sharedLink"
        />

        <LinkedInShareButton
          class="button-lg button-full-xs"
          :description="sharedText"
          title="Reedsy"
          :shared-link="sharedLink"
        />

        <TwitterShareButton
          class="button-lg button-full-xs"
          hashtags="amwriting"
          :title="sharedText"
          via="ReedsyHQ"
          :shared-link="sharedLink"
        />
      </div>

      <button
        class="button button-lg"
        title="Close"
        type="button"
        data-test="close-button"
        @click="close"
      >
        Close
      </button>
    </div>
  </PlainModal>
</template>

<script lang="ts">
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import PlainModal from '@reedsy/studio.shared/components/modals/templates/plain-modal.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import FacebookShareButton from './facebook-share-button.vue';
import LinkedInShareButton from './linked-in-share-button.vue';
import TwitterShareButton from './twitter-share-button.vue';
import {SocialNetwork} from './supported-networks';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';

@Component({
  components: {
    PlainModal,
    FacebookShareButton,
    LinkedInShareButton,
    TwitterShareButton,
  },
})
export default class ExportStartedModal extends mixins(ModalMixin, BookshelfVue) {
  @$lazyInjectStore(StoreName.Modals)
  public $modals: BookshelfModalsModule;

  public readonly SocialNetwork = SocialNetwork;

  public sharedText = 'Never thought my book could look so good! ' +
  'Thanks @ReedsyHQ for professionally typesetting my manuscript for free! 🌟';

  public sharedLink = 'https://reedsy.com/write-a-book';

  public override close(): void {
    this.$modals.CLOSE(this.id);
    this.$router.push({
      name: BookshelfRouteName.BookExportsList,
    });
  }
}
</script>

<style lang="scss" scoped>
.export-started-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .description, .title {
    margin-top: $space-lg;
    text-align: center;
  }

  .share-wrapper {
    padding: $space-lg;

    .share-button {
      margin: $space-sm;
    }
  }

  .text-light {
    color: var(--reedsy-fgColor-light);
  }
}
</style>
