import {IBillingInterval, SupportedCurrency} from '@reedsy/schemas.editor-collections';

const INTERVAL_DISPLAY: Record<IBillingInterval, string> = {
  month: 'mo',
  year: 'yr',
};

export function formatSubscriptionPrice(
  currency: SupportedCurrency,
  price: number,
  interval: IBillingInterval,
): string {
  const formattedPrice = price.toLocaleString('en-US', {
    style: 'currency',
    currency,
  });
  return `${formattedPrice}/${INTERVAL_DISPLAY[interval]}`;
}
