export enum SourceType {
  CaptionNormalizer = 'caption-normalizer',
  ChapterSplitter = 'chapter-splitter',
  ConsistencyCheck = 'consistency-check',
  FindAndReplace = 'find-and-replace',
  History = 'history',
  LeftSidebar = 'left-sidebar',
  MoveBlockFormats = 'move-block-formats',
  PlannerTrash = 'planner-trash',
  Quill = 'quill',
  SmartText = 'smart-text',
  SpellingAndGrammar = 'spelling-and-grammar',
  Store = 'store',
  TrackChanges = 'track-changes',
  TimestampUpdate = 'timestamp-update',
  CheckIn = 'check-in',
}
