import {ComponentOptions, createApp} from 'vue';

export default function extractTemplate(Component: ComponentOptions<any>): string {
  const component = createApp(Component);
  const element = document.createElement('div');
  component.mount(element);
  const html = element.innerHTML;
  component.unmount();
  return html;
}
