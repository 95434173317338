
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import InviteCollaboratorsPanel from '@reedsy/studio.home.bookshelf/components/invite-collaborators-panel/invite-collaborators-panel.vue';
import ManageAccessPanel from '@reedsy/studio.home.bookshelf/components/manage-access-panel/manage-access-panel.vue';
import LayoutWithRightSideBar from '@reedsy/studio.home.bookshelf/components/layout-with-right-side-bar/layout-with-right-side-bar.vue';
import InfoPanel from '@reedsy/studio.home.bookshelf/components/info-panel/info-panel.vue';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';

@Component({
  components: {
    InviteCollaboratorsPanel,
    ManageAccessPanel,
    LayoutWithRightSideBar,
    InfoPanel,
  },
})
export default class BookAccess extends BookshelfVue {
  @$lazyInjectStore(StoreName.CurrentBook)
  public $currentBook: CurrentBookModule;

  @$lazyInjectStore(StoreName.Modals)
  public $modals: BookshelfModalsModule;

  public openInviteCollaboratorModal(): void {
    this.$modals.open({
      type: 'InviteCollaborator',
      context: {
        book: {
          id: this.$currentBook.id,
          uuid: this.$currentBook.uuid,
        },
      },
    });
  }
}
