
import {Component, Model} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {TrimSize} from '@reedsy/schemas.workers.book-export';
import {TRIM_SIZE_DETAILS_OBJECT} from './trim-sizes-details';
import {clone} from '@reedsy/utils.clone';
import {ITrimSizeDetails} from './i-trim-size-details';

@Component({})
export default class ExportTrimSizes extends BookshelfVue {
  @Model({type: String})
  public trimSize: TrimSize;

  public trimSizes = clone(TRIM_SIZE_DETAILS_OBJECT);

  public get activeSizeDetails(): ITrimSizeDetails {
    return this.trimSizes[this.trimSize];
  }
}
