
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import IUserInfo from '@reedsy/studio.shared/models/i-user-info';
import BookTitle from './book-title.vue';

@Component({
  components: {
    BookTitle,
  },
})
export default class NoBookCoverPlaceholder extends BookshelfVue {
  @Prop({type: String, required: true})
  public title: string;

  @Prop({type: Object, required: true})
  public author: IUserInfo;
}
