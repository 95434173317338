<template>
  <div class="input-wrapper">
    <component :is="hasInputGroup ? 'VuiInputGroup' : 'div'">
      <slot name="left" />
      <component
        :is="component"
        v-bind="$attrs"
        :ref="inputFieldRef"
        v-model="value"
        :class="{invalid: error.hasError}"
        @validation-error="error = $event"
      />
      <slot name="right" />
    </component>

    <ErrorMessage
      :error="error.message"
    />
  </div>
</template>

<script lang="ts">
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import ErrorMessage from './error-message.vue';
import BaseInput from './base-input.vue';
import BaseTextarea from './textarea/base-textarea.vue';
import {INPUT_FIELD_REF} from './mixins/validation-mixin';

@Component({
  inheritAttrs: false,
  components: {
    ErrorMessage,
    BaseInput,
    BaseTextarea,
  },
})
export default class Input extends ClientSharedVue {
  @Model
  public value: any;

  @Prop({type: String, default: 'BaseInput'})
  public component: string;

  public readonly inputFieldRef = INPUT_FIELD_REF;
  public error = {hasError: false, message: ''};

  public get hasInputGroup(): boolean {
    return !!(this.$slots.left || this.$slots.right);
  }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  display: inline-block;
  position: relative;
  z-index: 0;

  &.input-full {
    width: 100%;
  }
}
</style>
