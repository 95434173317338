import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonLoader = _resolveComponent("SkeletonLoader")!

  return (_openBlock(), _createElementBlock("rbe-book-loader", null, [
    _createVNode(_component_SkeletonLoader, { class: "book-cover" }),
    _cache[0] || (_cache[0] = _createTextVNode()),
    _createVNode(_component_SkeletonLoader, { class: "book-title" })
  ]))
}