import {ResourcesRole} from '@reedsy/utils.reedsy-resources';

export const permissionLevels: Record<ResourcesRole, number> = {
  [ResourcesRole.None]: -1,
  [ResourcesRole.Reader]: 1,
  [ResourcesRole.Commenter]: 2,
  [ResourcesRole.Editor]: 3,
  [ResourcesRole.CoAuthor]: 4,
  [ResourcesRole.Owner]: 5,
};
