
// TODO: Improve / investigate the transition group animation on scroll
// https://github.com/reedsy/reedsy-editor/pull/6787#discussion_r1297274863
// https://github.com/reedsy/reedsy-editor/pull/6787#discussion_r1296157261
//
// TODO: Add transition group with shuffle animation as vue 3 migration broke it!

import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import BookEntry from './book-entry.vue';
import BookCreationButtons from './book-creation-buttons.vue';
import BookImportEntry from './book-import-entry.vue';
import GridLine from './grid-line.vue';
import BookSkeletonLoader from './book-skeleton-loader.vue';
import NoBooksPlaceholder from '@reedsy/studio.home.bookshelf/components/no-books-placeholder/no-books-placeholder.vue';

@Component({
  components: {
    BookEntry,
    BookImportEntry,
    BookCreationButtons,
    GridLine,
    BookSkeletonLoader,
    NoBooksPlaceholder,
  },
})
export default class BooksGrid extends BookshelfVue {
  @Prop({type: Boolean, required: true})
  public isEmpty: boolean;
}
