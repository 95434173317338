import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-left" }
const _hoisted_2 = { class: "ellipsis email" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconCross = _resolveComponent("VuiIconCross")!

  return (_openBlock(), _createElementBlock("rbe-invitee", {
    class: _normalizeClass({error: _ctx.hasError})
  }, [
    _createElementVNode("rbe-invitee-details", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.email), 1),
      _cache[1] || (_cache[1] = _createTextVNode()),
      _createElementVNode("button", {
        class: "button-icon button-xs reedsy-accented neutral",
        type: "button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.remove && _ctx.remove(...args)))
      }, [
        _createVNode(_component_VuiIconCross, { class: "icon-sm" })
      ])
    ])
  ], 2))
}