import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContextMenu = _resolveComponent("ContextMenu")!

  return (_openBlock(), _createBlock(_component_ContextMenu, null, {
    default: _withCtx(() => [
      _createElementVNode("button", {
        ref: "revoke",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openConfirmRevokePermissionsModal && _ctx.openConfirmRevokePermissionsModal(...args)))
      }, "\n      Revoke\n    ", 512)
    ]),
    _: 1
  }))
}