import SHARED_CANCELABLE_MODALS from '@reedsy/studio.shared/components/modals/cancelable-modals';
import {SharedModalContextMap} from '@reedsy/studio.shared/components/modals/modal-type-object-map';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {injectable} from 'inversify';
import {Store} from 'vuex';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {SharedModals} from './base';
import {$inject} from '@reedsy/studio.shared/types';
import IModal from './i-modal';

@injectable()
export class SharedModalsModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,

    @$inject('ModalsData')
    modalsData: Record<string, IModal>,
  ) {
    @Module({name: SharedStoreName.Modals, store})
    class SharedModalsModule extends SharedModals<SharedModalContextMap> {
      public override readonly modalsById: {[id: string]: IModal} = modalsData;
      protected override readonly cancelableModals = SHARED_CANCELABLE_MODALS;
    }

    this.Module = SharedModalsModule;
  }
}

export type SharedModalsModule = InstanceType<SharedModalsModuleFactory['Module']>;
