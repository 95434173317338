
import {Component, Ref, Watch} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {BookshelfEntryType, IBookshelfEntry} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-entry';
import BookImportEntry from './book-import-entry.vue';
import BookEntry from './book-entry.vue';
import ScreenEdgeArrow from '@reedsy/studio.shared/components/screen-edge-arrow/screen-edge-arrow.vue';
import {Swiper} from 'swiper';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModule} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf';

const BOOKSHELF_ENTRY_COMPONENT_MAPPING = Object.freeze({
  [BookshelfEntryType.Book]: 'BookEntry',
  [BookshelfEntryType.BookImport]: 'BookImportEntry',
} as const satisfies Record<BookshelfEntryType, string>);

const SHOW_MORE_ENTRIES_THRESHOLD = 3;

interface ISwiperHtmlElement extends HTMLElement {
  swiper: Swiper;
}

@Component({
  components: {
    BookImportEntry,
    BookEntry,
    ScreenEdgeArrow,
  },
})
export default class BooksSlider extends BookshelfVue {
  @$lazyInjectStore(StoreName.Bookshelf)
  public $bookshelf: BookshelfModule;

  @Ref('swiper')
  public swiperElement: ISwiperHtmlElement;

  public swiper: Swiper;
  public readonly componentMap = BOOKSHELF_ENTRY_COMPONENT_MAPPING;

  public get bookshelfEntries(): IBookshelfEntry[] {
    return this.$bookshelf.displayedBookshelfEntries;
  }

  public mounted(): void {
    this.swiper = this.swiperElement.swiper;
  }

  @Watch('bookshelfEntries', {immediate: true})
  public async updateSlides(): Promise<void> {
    await this.$nextTick();
    this.swiper?.update();
  }

  @Watch('$bookshelf.activeEntryIndex')
  public async setSwiperIndex(): Promise<void> {
    this.slideTo(this.$bookshelf.activeEntryIndex);
  }

  public updateActiveEntry({detail}: any): void {
    const {activeIndex} = detail[0];
    const entryId = this.bookshelfEntries[activeIndex]?.id;
    this.$bookshelf.setActiveEntry({entryId});
    this.showMoreEntriesIfNeeded();
  }

  private showMoreEntriesIfNeeded(): void {
    const index = this.swiper.activeIndex;
    if (index <= this.bookshelfEntries.length - SHOW_MORE_ENTRIES_THRESHOLD) return;
    this.$bookshelf.showMoreBookshelfEntries();
  }

  private slideTo(index: number): void {
    if (!this.swiper) return;
    if (this.swiper.activeIndex === index) return;
    this.swiper.slideTo(index);
  }
}
