<template>
  <Panel
    class="trim-size-selector"
  >
    <PanelBodyTitle class="no-top-space">
      Trim sizes
    </PanelBodyTitle>
    <rbe-trim-size-selector-content>
      <rbe-trim-size-selector-left-column>
        <rbe-trim-size-dropdown-wrapper>
          <p>We have kept things simple. Choose from standard US trade book formats.</p>
          <Dropdown
            ref="trimSizeDropdown"
            v-model="trimSize"
            v-root-class="'trim-size-dropdown'"
            :options="dropDownOptions"
          />
        </rbe-trim-size-dropdown-wrapper>
        <rbe-export-sizes-row class="visible-until-sm">
          <rbe-export-sizes-wrapper class="mobile">
            <ExportTrimSizes v-model="trimSize" />
          </rbe-export-sizes-wrapper>
        </rbe-export-sizes-row>
      </rbe-trim-size-selector-left-column>
      <rbe-trim-size-selector-right-column class="hidden-until-md">
        <ExportTrimSizes
          ref="exportTrimSizes"
          v-model="trimSize"
        />
      </rbe-trim-size-selector-right-column>
    </rbe-trim-size-selector-content>
  </Panel>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import Panel from '@reedsy/studio.shared/components/panel/panel.vue';
import ExportTrimSizes from './export-trim-sizes.vue';
import {TrimSize} from '@reedsy/schemas.workers.book-export';
import Dropdown from '@reedsy/studio.shared/components/forms/dropdown/dropdown.vue';
import {IDropdownOption} from '@reedsy/studio.shared/components/forms/dropdown/i-option';
import {TRIM_SIZE_DETAILS} from './trim-sizes-details';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {BookExportSubmissionModule} from '@reedsy/studio.home.bookshelf/store/modules/book-export-submission';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import PanelBodyTitle from '@reedsy/studio.shared/components/panel/panel-body-title.vue';

@Component({
  components: {
    Panel,
    ExportTrimSizes,
    Dropdown,
    PanelBodyTitle,
  },
})
export default class TrimSizeSelector extends BookshelfVue {
  @$lazyInjectStore(StoreName.BookExportSubmission)
  public $bookExportSubmission: BookExportSubmissionModule;

  public get dropDownOptions(): IDropdownOption[] {
    return TRIM_SIZE_DETAILS
      .map((trimSizeDetails) => {
        return {
          value: trimSizeDetails.sizeId,
          text: `${trimSizeDetails.name} (${trimSizeDetails.width}” x ${trimSizeDetails.height}”)`,
        };
      });
  }

  public get trimSize(): TrimSize {
    return this.$bookExportSubmission.trimSize;
  }

  public set trimSize(trimSize: TrimSize) {
    this.$bookExportSubmission.TRIM_SIZE(trimSize);
  }
}
</script>

<style lang="scss" scoped>
.trim-size-selector {
  rbe-trim-size-selector-content {
    display: flex;

    rbe-trim-size-selector-left-column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      padding-right: $space-md;

      @include screen-less-than(md) {
        width: 100%;
      }

      .trim-size-dropdown {
        margin-top: $space-md;
      }
    }

    rbe-export-sizes-row {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    rbe-trim-size-selector-right-column, rbe-export-sizes-wrapper {
      width: 35%;

      &.mobile {
        margin: $space-md;

        @include screen-less-than(sm) {
          width: 55%;
        }
      }
    }
  }
}
</style>
