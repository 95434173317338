<template>
  <textarea
    :ref="inputFieldRef"
    v-model="value"
    :class="{touched}"
    :spellcheck="false"
    @compositionupdate="updateValueFromComposition"
    @blur="onBlur"
  />
</template>

<script lang="ts">
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import {ValidatableInput} from '@reedsy/studio.shared/components/forms/mixins/validatable-input';

@Component({})
export default class Textarea extends mixins(ValidatableInput) {}
</script>
