import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelBodyTitle = _resolveComponent("PanelBodyTitle")!
  const _component_LoadingButton = _resolveComponent("LoadingButton")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, { class: "submit-book-export-panel" }, {
    default: _withCtx(() => [
      _createVNode(_component_PanelBodyTitle, { class: "no-top-space" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("\n      Free conversion to EPUB and Print-ready PDF\n    ")
        ])),
        _: 1
      }),
      _cache[2] || (_cache[2] = _createTextVNode()),
      _cache[3] || (_cache[3] = _createElementVNode("p", null, "Configure your export options, then typeset your book.", -1)),
      _cache[4] || (_cache[4] = _createTextVNode()),
      _createVNode(_component_LoadingButton, {
        class: "button export-button",
        type: "button",
        "data-test": "export-book-button",
        loading: _ctx.isSubmittingExportRequest,
        onClick: _ctx.exportBook
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("\n      Export book\n    ")
        ])),
        _: 1
      }, 8, ["loading", "onClick"])
    ]),
    _: 1
  }))
}