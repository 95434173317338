
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import BookTopInfo from '@reedsy/studio.home.bookshelf/components/book-top-info/book-top-info.vue';
import Panel from '@reedsy/studio.shared/components/panel/panel.vue';
import LoadingButton from '@reedsy/studio.shared/components/loading-button.vue';
import {config} from '@reedsy/studio.shared/config';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';
import {BookDetailsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-details/book-details';

@Component({
  components: {
    BookTopInfo,
    Panel,
    LoadingButton,
  },
})
export default class BookSettingsPanel extends BookshelfVue {
  @$lazyInjectStore(StoreName.CurrentBook)
  public $currentBook: CurrentBookModule;

  @$lazyInjectStore(StoreName.BookDetails)
  public $bookDetails: BookDetailsModule;

  public readonly maxTitleLength = config.validations.maxBookTitleLength;
  public readonly maxSubtitleLength = config.validations.maxBookSubtitleLength;

  public title = '';
  public subtitle = '';

  public get isSaving(): boolean {
    return this.$bookDetails.isSaving;
  }

  public mounted(): void {
    this.title = this.$currentBook.data.title;
    this.subtitle = this.$currentBook.data.subtitle;
  }

  public async updateBook(): Promise<void> {
    await this.$bookDetails.updateBookDetails({
      bookId: this.$currentBook.id,
      body: {
        title: this.title,
        subtitle: this.subtitle,
      },
    });
  }
}
