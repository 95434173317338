import {Path} from 'sharedb';
import {ICollectionTypeMap, Json0Collection} from '@reedsy/reedsy-sharedb/lib/common/collection-types';

export type SubtypeCollection = Exclude<
  Json0Collection,
  'bookContents' // This has its own subtype definition
>;

export interface ISubtypeId {
  collection: SubtypeCollection;
  id: string;
  subpath: Path;
}

export function buildSubtypeId<
  C extends SubtypeCollection,
  T extends ICollectionTypeMap[C],
  K1 extends keyof T &(string | number),
  K2 extends keyof T[K1] & (string | number),
  K3 extends keyof T[K1][K2] & (string | number),
  K4 extends keyof T[K1][K2][K3] & (string | number),
>(collection: C, id: string, key1: K1, key2?: K2, key3?: K3, key4?: K4): string {
  return stringifySubtypeId(collection, id, key1, key2, key3, key4);
}

export function stringifySubtypeId(collection: SubtypeCollection, id: string, ...keys: Path): string {
  const subtypeId: ISubtypeId = {
    collection,
    id,
    subpath: keys.filter((key) => key !== undefined),
  };

  return JSON.stringify(subtypeId);
}

export function parseSubtypeId(id: string): ISubtypeId {
  try {
    const parsed = JSON.parse(id);
    return typeof parsed === 'object' ? parsed : null;
  } catch {
    return null;
  }
}
