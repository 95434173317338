import InitBookPluginFactory from './init-book';
import InitBooksPluginFactory from './init-books';
import InitBookshelfShareDbConnectionPluginFactory from './init-bookshelf-sharedb-connection-plugin-factory';
import LoadCurrentUserPluginFactory from './load-current-user';
import {BookshelfQueryParamsPluginFactory} from './bookshelf-query-params/bookshelf-query-params-plugin';
import InitialiseCrispPluginFactory from './crisp/initialise-crisp';
import SetShowArchivedFlagPluginFactory from './set-show-archived-flag';
import SetLayoutFlagsPluginFactory from './set-layout-flags';
import BrowserTitlePluginFactory from './browser-title';
import AuthAppCallbacksPluginFactory from './auth-app-callbacks/auth-app-callbacks';
import {OfflineModalPluginFactory} from './offline-modal';
import CloseModalsPluginFactory from './close-modals';
import {SubscriptionFeaturesPluginFactory} from './subscription-features';
import LoadUserSettingsPluginFactory from './load-user-settings/load-user-settings';
import {InitInvitationAcceptanceInfoPluginFactory} from './init-invitation-acceptance-info';
import {RoleAuthPluginFactory} from './role-auth';
import AfterSignUpSurveyPluginFactory from './after-sign-up-survey';
import ValidRoutePluginFactory from './valid-route';
import {TenHoursSurveyPluginFactory} from './ten-hours-survey';

export const PLUGINS = Object.freeze([
  CloseModalsPluginFactory,
  SetLayoutFlagsPluginFactory,
  AuthAppCallbacksPluginFactory,
  InitBookshelfShareDbConnectionPluginFactory,
  LoadCurrentUserPluginFactory,
  LoadUserSettingsPluginFactory,
  AfterSignUpSurveyPluginFactory,
  OfflineModalPluginFactory,
  InitialiseCrispPluginFactory,
  BookshelfQueryParamsPluginFactory,
  SetShowArchivedFlagPluginFactory,
  InitBooksPluginFactory,
  InitBookPluginFactory,
  BrowserTitlePluginFactory,
  SubscriptionFeaturesPluginFactory,
  InitInvitationAcceptanceInfoPluginFactory,
  RoleAuthPluginFactory,
  ValidRoutePluginFactory,
  TenHoursSurveyPluginFactory,
]);
