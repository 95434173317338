
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import ExportsList from '@reedsy/studio.home.bookshelf/components/exports-list/exports-list.vue';
import BlurbPrintOnDemand from '@reedsy/studio.home.bookshelf/components/blurb-print-on-demand/blurb-print-on-demand.vue';

@Component({
  components: {
    ExportsList,
    BlurbPrintOnDemand,
  },
})
export default class BookExportsList extends BookshelfVue {
}
