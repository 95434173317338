<template>
  <div class="skeleton-loader" />
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({})
export default class SkeletonLoader extends ClientSharedVue {}
</script>

<style lang="scss" scoped>
.skeleton-loader {
  height: 1rem;
  width: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: var(--studio-skeletonLoader-background);

  &::after {
    position: absolute;
    inset: 0;
    transform: translateX(-100%);
    background-image: var(--studio-skeletonLoader-gradient);
    animation: skeleton-loader-shimmer 2s infinite;
    content: '';
  }

  @keyframes skeleton-loader-shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
