<template>
  <router-link
    :to="bookshelfRoute"
    class="book-back-button-link"
  >
    <span class="button button-icon reedsy-accented translucent">
      <VuiIconArrowLeft />
    </span>
    <p class="back-text hidden-until-md">
      Back
    </p>
  </router-link>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import {MetaRawLocation} from '@reedsy/studio.shared/router/reedsy-router';

@Component({})
export default class BookBackLink extends BookshelfVue {
  public get bookshelfRoute(): MetaRawLocation {
    return {
      name: BookshelfRouteName.Bookshelf,
    };
  }
}
</script>

<style lang="scss" scoped>
.book-back-button-link {
  display: flex;
  align-items: center;
  padding-left: $space-md;
  padding-right: 0;
  position: relative;

  @include screen-more-than(sm) {
    $line-width: $border-width-lg;

    padding-top: $space-md;
    padding-bottom: $space-md;
    padding-right: calc(math.div($line-width, 2) + $space-lg);

    &::after {
      position: absolute;
      content: '';
      width: $line-width;
      background-color: var(--reedsy-dividerColor);
      height: 2rem;
      right: math.div($line-width, -2);
    }
  }

  .button.button-icon {
    margin-right: $space-md;
  }
}
</style>
