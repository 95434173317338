import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal-close-button button button-icon button-sm reedsy-accented",
  type: "button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconCross = _resolveComponent("VuiIconCross")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createVNode(_component_VuiIconCross)
  ]))
}