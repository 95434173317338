import {ComponentInternalInstance} from 'vue';

export default function vueStack(element: Element): string {
  let stack = '';
  while (element) {
    const vue: ComponentInternalInstance = (element as any).__vueParentComponent;
    if (vue?.vnode.el === element) {
      const name = vue.type.name;
      if (name) stack = `${name} ${stack}`;
    }
    element = element.parentElement;
  }
  return stack;
}
