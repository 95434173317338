import {IMetaLocation} from '@reedsy/studio.shared/router/reedsy-router';
import {injectable, named} from 'inversify';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import RouterPlugin from '@reedsy/studio.home.bookshelf/store/plugins/router-plugin';
import {IBookshelfRoute} from '@reedsy/studio.home.bookshelf/router/i-bookshelf-route';
import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {isBookshelfBookRoute} from '@reedsy/studio.home.bookshelf/router/helpers/is-bookshelf-book-route';
import {decodeUUID} from '@reedsy/utils.uuid';
import {BookDetailsModule} from '@reedsy/studio.home.bookshelf/store/modules/book-details/book-details';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import {BookshelfStoreListener} from '@reedsy/studio.home.bookshelf/store/bookshelf-store-listener';
import {Store} from 'vuex';

@injectable()
export default class ValidRoutePluginFactory extends RouterPlugin {
  @$inject('StoreListener')
  public readonly _storeListener: BookshelfStoreListener;

  @$inject('Store')
  public readonly _store: Store<any>;

  @$inject('StoreModule')
  @named(StoreName.BookDetails)
  public readonly _$bookDetails: BookDetailsModule;

  @$inject('Navigation')
  public readonly _navigation: INavigation;

  public override setup(): void {
    const subscriber = (): void => {
      const route = this._router.currentRoute.value;
      if (!this.shouldApplyBefore(route)) return;
      const redirect = this.beforeEach(route);
      this._router.replace(redirect);
    };

    this._store.watch(() => this._$bookDetails.books, subscriber, {deep: true});
  }

  public override beforeEach(to: IBookshelfRoute): IMetaLocation {
    const bookUuid = decodeUUID(to.params.bookUuid);
    if (this.isValidContent(bookUuid)) return;
    return {path: '/'};
  }

  protected override shouldApplyBefore(to: IBookshelfRoute): boolean {
    return isBookshelfBookRoute(to);
  }

  private isValidContent(bookUuid: string): boolean {
    return this._$bookDetails.books.some(({details}) => details.uuid === bookUuid);
  }
}
