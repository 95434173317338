<template>
  <rbe-error-message-box class="panel flex-left">
    <VuiIconWarning
      v-if="withIcon"
      ref="icon"
      class="warning-icon icon-lg"
    />
    <p>
      <slot />
    </p>
  </rbe-error-message-box>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({})
export default class ErrorMessageBox extends ClientSharedVue {
  @Prop({type: Boolean, default: false})
  public withIcon: string;
}
</script>

<style lang="scss" scoped>
rbe-error-message-box {
  gap: $space-md;
  margin-top: $space-md;
  background-color: var(--reedsy-danger);
  color: var(--reedsy-danger-onDefault);
}

.warning-icon {
  flex-grow: 0;
  flex-shrink: 0;
}
</style>
