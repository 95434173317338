<template>
  <rbe-book-skeleton-loader>
    <SkeletonLoader class="title-loader" />
    <SkeletonLoader class="title-loader" />
  </rbe-book-skeleton-loader>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';

@Component({
  components: {
    SkeletonLoader,
  },
})
export default class BookSkeletonLoader extends BookshelfVue {
}
</script>

<style lang="scss" scoped>
rbe-book-skeleton-loader {
  border: $border;
  border-radius: $border-radius-base;
  height: $lg-cover-height;
  width: $lg-cover-width;
  background-color: var(--reedsy-neutral);
  padding-top: $space-lg;
  box-sizing: border-box;

  .title-loader {
    margin-left: $space-md;
    width: 60%;

    &:first-child {
      width: 80%;
    }
  }
}
</style>
