<template>
  <div>
    <rbe-manage-addons-panel>
      <rbe-addon-selector class="flex-centered">
        <PeriodToggle />

        <CompactFeatureSummary
          v-for="summary in availableSummaries"
          :key="summary.product"
          :ref="summary.product"
          :model-value="true"
          :summary="summary"
        />
      </rbe-addon-selector>

      <rbe-update-subscription v-if="billingPeriodHasChanged || activeFeaturesHaveChanged">
        <rbe-price-info>
          <rbe-price-item>
            <span>Subtotal</span>
            <span>TODO: [subscriptions] price</span>
          </rbe-price-item>
          <rbe-price-item>
            <span>Discounts</span>
            <span>- TODO: [subscriptions] price</span>
          </rbe-price-item>

          <hr>

          <rbe-price-item class="price-headline">
            <span>Your subscription</span>
            <span>TODO: [subscriptions] price</span>
          </rbe-price-item>
          <rbe-price-item>
            <span>Next billing date</span>
            <span>TODO: [subscriptions] billing date</span>
          </rbe-price-item>
        </rbe-price-info>

        <rbe-price-confirm>
          <span>
            On TODO: [subscriptions] date you subscription will change from
            TODO: [subscriptions] price/mo to TODO: [subscriptions] price/mo.
            You can continue to use existing add-ons until this date.
          </span>

          <button
            type="button"
            class="button reedsy-accented"
          >
            Update subscription
          </button>
        </rbe-price-confirm>
      </rbe-update-subscription>
    </rbe-manage-addons-panel>

    <rbe-additional-savings v-if="availableSummaries.length > 1">
      <AnnualSaving />
      when you purchase multiple add-ons
    </rbe-additional-savings>
  </div>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {$lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import PeriodToggle from '@reedsy/studio.shared/components/modals/components/subscription/features/period-toggle.vue';
import CompactFeatureSummary from '@reedsy/studio.shared/components/modals/components/subscription/features/compact-feature-summary.vue';
import {FEATURE_SUMMARIES} from '@reedsy/studio.shared/components/modals/components/subscription/features/feature-summaries';
import AnnualSaving from '@reedsy/studio.shared/components/modals/components/subscription/annual-saving.vue';

@Component({
  components: {
    PeriodToggle,
    CompactFeatureSummary,
    AnnualSaving,
  },
})
export default class ManageAddonsPanel extends ClientSharedVue {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  public readonly availableSummaries = FEATURE_SUMMARIES.filter((summary) => summary.product);

  public get activeFeaturesHaveChanged(): boolean {
    // TODO: [subscriptions] get value
    return true;
  }

  public get billingPeriodHasChanged(): boolean {
    // TODO: [subscriptions] get value
    return true;
  }
}
</script>

<style lang="scss" scoped>
rbe-manage-addons-panel {
  border: $border-lg;
  border-radius: $border-radius-lg;
  background-color: var(--reedsy-neutral-muted);
  overflow: hidden;

  rbe-addon-selector {
    padding: $space-base;
    gap: $space-base;
  }

  rbe-update-subscription {
    background-color: var(--reedsy-plain);

    rbe-price-info {
      padding: $space-base;
      display: flex;
      flex-direction: column;
      gap: $space-sm;
      border-top: $border-lg;
      border-bottom: $border;

      hr {
        color: $border-color;
        width: 100%;
        height: $border-width;
      }

      rbe-price-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &.price-headline {
          @include font-family($controls, bold);

          font-size: $font-size-lg;
        }
      }
    }

    rbe-price-confirm {
      display: flex;
      flex-direction: row;
      gap: $space-base;
      padding: $space-base;
      align-items: flex-start;

      @include screen-less-than(sm) {
        flex-direction: column-reverse;

        button {
          width: 100%;
        }
      }

      button {
        white-space: nowrap;
      }
    }
  }
}

rbe-additional-savings {
  text-align: center;
  margin-top: $space-base;
}
</style>
