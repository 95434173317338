
import {Component, mixins} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import PlainModal from '@reedsy/studio.shared/components/modals/templates/plain-modal.vue';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import FacebookShareButton from './facebook-share-button.vue';
import LinkedInShareButton from './linked-in-share-button.vue';
import TwitterShareButton from './twitter-share-button.vue';
import {SocialNetwork} from './supported-networks';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModalsModule} from '@reedsy/studio.home.bookshelf/store/modules/modals';

@Component({
  components: {
    PlainModal,
    FacebookShareButton,
    LinkedInShareButton,
    TwitterShareButton,
  },
})
export default class ExportStartedModal extends mixins(ModalMixin, BookshelfVue) {
  @$lazyInjectStore(StoreName.Modals)
  public $modals: BookshelfModalsModule;

  public readonly SocialNetwork = SocialNetwork;

  public sharedText = 'Never thought my book could look so good! ' +
  'Thanks @ReedsyHQ for professionally typesetting my manuscript for free! 🌟';

  public sharedLink = 'https://reedsy.com/write-a-book';

  public override close(): void {
    this.$modals.CLOSE(this.id);
    this.$router.push({
      name: BookshelfRouteName.BookExportsList,
    });
  }
}
