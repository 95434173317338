import {objectKeys} from '@reedsy/utils.object';
import {IDropdownOption} from '@reedsy/studio.shared/components/forms/dropdown/i-option';

export function asDropdownOptions<T extends string>(textMapping: Record<T, string>): IDropdownOption[] {
  return objectKeys(textMapping).map((optionValue) => {
    const optionText = textMapping[optionValue as keyof Record<T, string>];

    return {
      value: optionValue,
      text: optionText,
    };
  });
}
