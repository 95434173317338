import BlockControls from '@reedsy/studio.shared/services/quill/helpers/block-controls/block-controls';
import SASS from '@reedsy/studio.shared/utils/sass';

class VideoPlayer extends HTMLElement {
  public static styleString = `
  rbe-video-player {
    display: flex;
    position: relative;
  }

  iframe {
    width: calc(100% - var(--block-controls-action-size) - ${SASS.space.xs}px);
    aspect-ratio: 16/9;
    border-radius: ${SASS.borderRadius.lg}px;
  }

  .ql-block-controls {
    position: absolute;
    top: 0;
    right: 0;
  }

  :host([readonly]) .ql-block-controls {
    display: none;
  }

  :host([readonly]) iframe {
    width: 100%
  }

  .vui-icon {
    display: inline-flex;
    height: ${SASS.space.base}px;
    width: ${SASS.space.base}px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    transition: background-color .3s ease 0ms;
  }

  svg {
    fill: currentColor;
    width: 100%;
    height: 100%;
  }

  .vui-icon #icon-fill {
    fill: currentColor;
  }

  .vui-icon #icon-stroke {
    stroke: currentColor;
  }

  button {
    background: transparent;
    cursor: pointer;
    border: none;
    padding: 0;
  }
  `;

  public constructor() {
    super();

    const shadow = this.attachShadow({mode: 'open'});

    const baseStyleSheet = new CSSStyleSheet();
    baseStyleSheet.replaceSync(VideoPlayer.styleString);

    const controlsStyleSheet = new CSSStyleSheet();
    controlsStyleSheet.replaceSync(BlockControls.styleString);

    shadow.adoptedStyleSheets.push(baseStyleSheet, controlsStyleSheet);

    shadow.appendChild(
      <rbe-video-player>
        <BlockControls onClose={this.remove.bind(this)} />
        <iframe
          allowfullscreen="true"
          frameborder="0"
        />
      </rbe-video-player>,
    );
  }

  public connectedCallback(): void {
    this.shadowRoot.querySelector('iframe').src = this.getAttribute('src');
  }
}

customElements.define('video-player', VideoPlayer);
