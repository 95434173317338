import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VInput = _resolveComponent("VInput")!
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!

  return (_openBlock(), _createBlock(_component_ConfirmModal, {
    id: _ctx.id,
    confirm: {
      label: 'Create book',
      handler: _ctx.createBook,
    },
    title: "Create new book",
    class: "create-book-modal"
  }, {
    default: _withCtx(() => [
      _cache[2] || (_cache[2] = _createElementVNode("label", {
        class: "input-label",
        for: "book-title"
      }, "\n      Title\n    ", -1)),
      _cache[3] || (_cache[3] = _createTextVNode()),
      _createVNode(_component_VInput, {
        id: "book-title",
        modelValue: _ctx.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
        type: "text",
        required: "",
        "custom-validators": [_ctx.noEmptyValidator],
        maxlength: _ctx.maxTitleLength,
        placeholder: "Enter a book title..."
      }, null, 8, ["modelValue", "custom-validators", "maxlength"]),
      _cache[4] || (_cache[4] = _createTextVNode()),
      _cache[5] || (_cache[5] = _createElementVNode("label", {
        class: "input-label subtitle-label optional",
        for: "book-subtitle"
      }, "\n      Subtitle\n    ", -1)),
      _cache[6] || (_cache[6] = _createTextVNode()),
      _createVNode(_component_VInput, {
        id: "book-subtitle",
        modelValue: _ctx.subtitle,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.subtitle) = $event)),
        type: "text",
        placeholder: "Enter a book subtitle...",
        maxlength: _ctx.maxSubtitleLength
      }, null, 8, ["modelValue", "maxlength"])
    ]),
    _: 1
  }, 8, ["id", "confirm"]))
}