import {VALID_LINK_PROTOCOLS} from '@reedsy/studio.shared/services/quill/modules/links/valid-protocols';
import {URL} from 'whatwg-url';
import {INVALID_URL_MESSAGE} from '@reedsy/studio.shared/components/forms/error-message';
import {ValidatableInput} from './validatable-input';

class UrlInput extends ValidatableInput {
  public override readonly invalidMessage = INVALID_URL_MESSAGE;

  public constructor() {
    super();
    this.type = 'url';
  }

  protected override isValid(): boolean {
    try {
      const url = new URL(this.value);
      const [protocol] = url.protocol.split(':');
      if (!VALID_LINK_PROTOCOLS.has(protocol.toLowerCase())) throw new Error('Invalid protocol');
    } catch {
      return false;
    }

    return true;
  }
}

customElements.define('url-input', UrlInput, {extends: 'input'});
