<template>
  <img src="@/assets/inlined/help/ricardo-avatar.jpg">
</template>

<script lang="ts">
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';

@Component({})
export default class RicardoIcon extends BookshelfVue {
}
</script>

<style lang="scss" scoped>
img {
  border-radius: 50%;
}
</style>
