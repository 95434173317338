enum StoreName {
  Modals = 'bookshelf_modals',
  Bookshelf = 'bookshelf_bookshelf',
  BookActions = 'bookshelf_bookActions',
  BookDetails = 'bookshelf_bookDetails',
  BooksTotalWordCounts = 'bookshelf_booksTotalWordCounts',
  BookWordCountGoals = 'bookshelf_bookWordCountGoals',
  Collaborators = 'bookshelf_collaborators',
  ShareDb = 'bookshelf_sharedb',
  CurrentBook = 'bookshelf_currentBook',
  Layout = 'bookshelf_layout',
  UserBookMetadata = 'bookshelf_userBookMetadata',
  UserSettings = 'bookshelf_userSettings',
  Feedback = 'bookshelf_feedback',
  UserBookSettings = 'bookshelf_userBookSettings',
  UserWordCountGoals = 'bookshelf_userWordCountGoals',
  BooksImports = 'bookshelf_booksImports',
  BookExportSubmission = 'bookshelf_bookExportSubmission',
  ExternalNavigation = 'bookshelf_externalNavigation',
  InvitationApprovalFlow = 'bookshelf_invitationApprovalFlow',
}

export default StoreName;
