<template>
  <Panel class="bordered payment-info-panel no-gutter reedsy-accented neutral">
    <rbe-payment-info class="flex-justified">
      <PaymentInfoItem
        ref="next-payment-info"
        title="Next billing date"
        :loading="loading"
      >
        {{ subscription.nextBillingDate }}
      </PaymentInfoItem>

      <PaymentInfoItem
        ref="price-info"
        title="Subscription"
        :loading="loading"
      >
        {{ subscription.nextBillingPrice }}
      </PaymentInfoItem>

      <a
        ref="payment-settings-link"
        :href="billingPortalUrl"
        target="_blank"
        class="reedsy-accented button accent-primary"
      >
        Payment settings
      </a>
    </rbe-payment-info>

    <template #footer>
      <rbe-footer class="flex-justified">
        <p>
          <a
            ref="stripe-link"
            class="link"
            target="_blank"
            :href="billingPortalUrl"
            aria-label="Stripe dashboard"
          >Log in to the Stripe dashboard</a> to manage your plan,
          update payment methods and view invoices.
        </p>
      </rbe-footer>
    </template>
  </Panel>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {$lazyInject, $lazyInjectStore} from '@reedsy/studio.shared/inversify.config';
import Panel from '@reedsy/studio.shared/components/panel/panel.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import Navigation from '@reedsy/studio.shared/services/navigation';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedSubscriptionModule} from '@reedsy/studio.shared/store/modules/subscription';
import {ICurrentSubscription} from '@reedsy/studio.shared/store/modules/subscription/current-subscription.interface';
import PaymentInfoItem from './payment-info-item.vue';

@Component({
  components: {
    Panel,
    PaymentInfoItem,
  },
})
export default class PaymentInfo extends ClientSharedVue {
  @$lazyInjectStore(SharedStoreName.Subscription)
  public $subscription: SharedSubscriptionModule;

  @$lazyInject('Navigation')
  public $navigation: Navigation;

  public subscription: ICurrentSubscription = null;

  public get loading(): boolean {
    return !this.subscription;
  }

  public get billingPortalUrl(): string {
    return this.$navigation.billingPortalUrl;
  }

  public async created(): Promise<void> {
    this.subscription = await this.$subscription.fetchCurrentSubscriptionInfo();
  }
}
</script>

<style lang="scss" scoped>
$panel-padding: 0.5rem;
$panel-border-radius: 0.5rem;

@include screen-less-than(md) {
  rbe-payment-info {
    flex-direction: column;
    gap: $space-base;
  }
}

.payment-info-panel {
  :deep(.panel-body) {
    padding: $space-xl $space-base;
  }
}

rbe-footer {
  background-color: var(--local-color-muted);
  padding: $space-base;
  border-bottom-left-radius: $panel-border-radius;
  border-bottom-right-radius: $panel-border-radius;

  p {
    font-size: $font-size-sm;
  }
}
</style>
