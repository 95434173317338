import {getAuthBaseUrl} from './get-auth-base-url';
import {IBuildSignUpUrlOptions} from './i-build-sign-up-url-options';

export function buildSignUpUrl(): string {
  const options: Required<IBuildSignUpUrlOptions> = {
    afterSignInPath: '/',
  };

  const url = getAuthBaseUrl();
  url.pathname = 'sign_up/';
  url.searchParams.set('next', options.afterSignInPath);

  return url.toString();
}
