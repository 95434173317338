
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import CheckInNotificationsSettings from './check-in-notifications-settings.vue';
import NotificationsPreferences from './notifications-preferences.vue';

@Component({
  components: {
    CheckInNotificationsSettings,
    NotificationsPreferences,
  },
})
export default class UserNotificationsSettings extends BookshelfVue {
}
