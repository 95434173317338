
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BaseTextarea from './base-textarea.vue';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({
  components: {
    BaseTextarea,
  },
})
export default class Textarea extends ClientSharedVue {}
