<template>
  <Panel
    title="Exported files"
    class="exports-list"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <template v-if="hasExports">
        <transition-group
          name="list-complete"
          tag="rbe-exports-list"
        >
          <rbe-export-list-entry-wrapper
            v-for="bookExport in bookExports"
            :key="bookExport._id"
            class="list-complete-item"
          >
            <ExportListEntry
              class="exports-list-entry"
              :book-export="bookExport"
            />
          </rbe-export-list-entry-wrapper>
        </transition-group>
      </template>
      <EmptyListPlaceholder v-else />
    </transition>
    <template #footer>
      <p>
        We are striving to improve our typesetting capabilities.
        If there are errors with your downloaded files,
        please reach out to us via Help! button and we and our support team will assist you.
      </p>
    </template>
  </Panel>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import ExportTypeIndicator from './export-type-indicator.vue';
import ExportListEntry from './exports-list-entry.vue';
import {IBookExport} from '@reedsy/reedsy-sharedb/lib/common/book/book-export';
import Panel from '@reedsy/studio.shared/components/panel/panel.vue';
import EmptyListPlaceholder from './empty-list-placeholder.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {CurrentBookModule} from '@reedsy/studio.home.bookshelf/store/modules/current-book';

@Component({
  components: {
    ExportTypeIndicator,
    ExportListEntry,
    Panel,
    EmptyListPlaceholder,
  },
})
export default class ExportsList extends BookshelfVue {
  @$lazyInjectStore(StoreName.CurrentBook)
  public $currentBook: CurrentBookModule;

  public get bookExports(): IBookExport[] {
    return this.$currentBook.exports;
  }

  public get hasExports(): boolean {
    return !!this.$currentBook.exports.length;
  }
}
</script>

<style lang="scss" scoped>
.exports-list {
  margin-top: $space-xxl;

  @include transition(flex);

  rbe-exports-list {
    width: 100%;
    height: 100%;
    position: relative;

    .list-complete-item {
      right: 0;
      top: initial;
      width: 100%;
    }

    rbe-export-list-entry-wrapper {
      padding: $space-md 0;
      border-bottom: $border;
      box-sizing: border-box;

      &:first-child {
        padding-top: 0;
      }
    }
  }
}
</style>
