import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "emphasized" }
const _hoisted_2 = { class: "emphasized" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!

  return (_openBlock(), _createBlock(_component_ConfirmModal, {
    id: _ctx.id,
    title: "Revoke permissions",
    cancel: {label: 'Cancel'},
    confirm: {
      label: 'Revoke',
      handler: _ctx.revokePermissions,
    },
    class: "danger"
  }, {
    default: _withCtx(() => [
      _createElementVNode("rbe-confirm-revoke-access-modal", null, [
        _createElementVNode("p", null, [
          _cache[0] || (_cache[0] = _createTextVNode("\n        Are you sure you want to revoke access to the book\n        “")),
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.context.book.title), 1),
          _cache[1] || (_cache[1] = _createTextVNode("”\n        for ")),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.context.collaborator.name), 1),
          _cache[2] || (_cache[2] = _createTextVNode("?\n      "))
        ])
      ])
    ]),
    _: 1
  }, 8, ["id", "confirm"]))
}