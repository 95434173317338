
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import InfoPanel from '@reedsy/studio.home.bookshelf/components/info-panel/info-panel.vue';

@Component({
  components: {
    InfoPanel,
  },
})
export default class ImageResolutionInfoPanel extends BookshelfVue {
}
