import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.userInfo || _ctx.placeholder)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["avatar", {
      placeholder: _ctx.placeholder
    }]),
        style: _normalizeStyle(_ctx.avatarStyles),
        title: _ctx.name
      }, [
        (!_ctx.placeholder)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.avatarSrc)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.avatarSrc,
                    alt: _ctx.name
                  }, null, 8, _hoisted_2))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    ref: "initials",
                    class: "initials"
                  }, _toDisplayString(_ctx.initials), 513)),
              _cache[0] || (_cache[0] = _createTextVNode()),
              (_ctx.activityIndicator)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(["activity-indicator", {
          active: _ctx.active
        }])
                  }, null, 2))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true)
      ], 14, _hoisted_1))
    : _createCommentVNode("", true)
}