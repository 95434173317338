
import {Component, mixins, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import ModalMixin from '@reedsy/studio.shared/components/modals/mixins/modal-mixin';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import SubscriptionBaseModal from '@reedsy/studio.shared/components/modals/components/subscription/subscription-base.vue';
import {PropType} from 'vue';
import {ISubscriptionPaymentModalArgs} from './subscription-payment-modal-args.interface';
import {$lazyInject} from '@reedsy/studio.shared/inversify.config';
import {ISubscriptionModalService} from '@reedsy/studio.shared/services/subscriptions/i-subscription-modal-service';
import {FEATURE_SUMMARIES} from './feature-summaries';
import {IProductSummary} from './product-summary.interface';
import PeriodPicker from './period-picker.vue';

@Component({
  components: {
    SubscriptionBaseModal,
    PeriodPicker,
  },
})
export default class SubscriptionPayment extends mixins(ModalMixin, ClientSharedVue) {
  @Prop({type: Object as PropType<ISubscriptionPaymentModalArgs>, default: {}})
  public context: ISubscriptionPaymentModalArgs;

  @$lazyInject('SubscriptionModal')
  public $subscriptionModal: ISubscriptionModalService;

  public readonly cancelable = true;
  public billAnnually = false;

  public get activeProductsInfo(): ReadonlyArray<IProductSummary> {
    return FEATURE_SUMMARIES.filter(({product}) => this.context.products.has(product));
  }

  public created(): void {
    this.billAnnually = this.context.billAnnually;
  }

  public backToPreview(): void {
    this.$subscriptionModal.openFeatures({products: new Set(this.context.products)});
  }
}
