import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/inlined/bookshelf-no-results.svg'


const _hoisted_1 = { class: "flex-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("rbe-no-collaborators-placeholder", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("img", {
      src: _imports_0,
      alt: "No collaborators placeholder"
    }, null, -1),
    _createTextVNode(),
    _createElementVNode("p", null, "You are not currently working with any collaborators.", -1)
  ])))
}