<template>
  <rbe-book-progress>
    <transition
      name="fade"
      mode="out-in"
    >
      <rbe-book-progress-content v-if="hasWordCountLoaded">
        <VuiProgressBar
          v-if="bookWordGoal"
          class="bg-grey book-progress-bar sm"
          :percentage="progressPercentage"
        />
        <p class="book-progress-text">
          {{ wordCountText }}
        </p>
      </rbe-book-progress-content>
      <SkeletonLoader
        v-else
        class="skeleton-loader-word-count"
      />
    </transition>
  </rbe-book-progress>
</template>

<script lang="ts">
import {Component, Prop, Watch} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import MainLayout from '@reedsy/studio.home.bookshelf/components/layouts/main-layout.vue';
import {IBookshelfBook} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-book';
import largeNumber from '@reedsy/studio.shared/filters/large-number';
import pluralize from 'pluralize';
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BooksTotalWordCountsModule} from '@reedsy/studio.home.bookshelf/store/modules/books-total-word-counts';

@Component({
  components: {
    MainLayout,
    SkeletonLoader,
  },
})
export default class BookProgress extends BookshelfVue {
  @$lazyInjectStore(StoreName.BooksTotalWordCounts)
  public $totalWordCounts: BooksTotalWordCountsModule;

  @Prop({type: Object, required: true})
  public book: IBookshelfBook;

  public initialAnimationDone = false;

  public get bookWordGoal(): number {
    return this.book.wordCountGoal.targetCount;
  }

  public get hasWordCountLoaded(): boolean {
    return this.$totalWordCounts.hasWordCountLoaded(this.book.id);
  }

  public get bookWordCount(): number {
    return this.book.totalWordCount;
  }

  public get wordCountText(): string {
    return `${largeNumber(this.bookWordCount)} ${pluralize('word', this.bookWordCount)}`;
  }

  public get progressPercentage(): number {
    if (!this.initialAnimationDone) return 0;
    const progressPercentage = this.bookWordCount / this.bookWordGoal * 100;
    return Math.min(progressPercentage, 100);
  }

  @Watch('hasWordCountLoaded', {immediate: true})
  public async watchHasWordCountLoaded(isLoaded: boolean): Promise<void> {
    if (!isLoaded) {
      this.initialAnimationDone = false;
      return;
    }

    await this.$nextTick();
    // This ensures that we animate the progress bar, on when the bar is loaded
    this.initialAnimationDone = true;
  }

  public async mounted(): Promise<void> {
    this.$totalWordCounts.ensureTotalWordCountsLoaded(this.book.id);
  }
}
</script>

<style lang="scss" scoped>
rbe-book-progress {
  .book-progress-bar :deep(.progress) {
    background-color: var(--bookshelf-progress-bar-color);
  }

  .book-progress-bar {
    height: $bookshelf-book-progress-height;
  }

  .book-progress-text {
    margin-top: $space-sm;
    font-size: $font-size-xs;
  }

  .skeleton-loader-word-count {
    height: 1.2rem;

    @include screen-more-than(sm) {
      width: 50%;
    }
  }
}
</style>
