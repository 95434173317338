<template>
  <LazyRenderer class="lazy-rendered-entry">
    <rbe-bookshelf-entry>
      <slot />
      <rbe-entry-bottom class="hide-on-hover">
        <slot name="bottom" />
      </rbe-entry-bottom>
      <rbe-entry-bottom class="show-on-hover">
        <slot name="bottom-hover" />
      </rbe-entry-bottom>
    </rbe-bookshelf-entry>
  </LazyRenderer>
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import LazyRenderer from '@reedsy/studio.shared/components/lazy-renderer/lazy-renderer.vue';

@Component({
  components: {
    LazyRenderer,
  },
})
export default class BookshelfEntry extends BookshelfVue {}
</script>

<style lang="scss" scoped>
.lazy-rendered-entry {
  height: auto;
  aspect-ratio: $cover-aspect-ratio;

  rbe-bookshelf-entry {
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: $box-shadow-base;
    border-radius: $border-radius-lg;

    @include transition((transform, box-shadow));

    .show-on-hover {
      display: flex;
      opacity: 0;

      @include transition(opacity);
    }

    .hide-on-hover {
      display: flex;
    }

    $scale-factor: 1.05;

    &:hover,
    &:focus-within  {
      transform: scale($scale-factor);
      box-shadow: $box-shadow-bookshelf-hover;

      .show-on-hover {
        opacity: 1;
      }

      .hide-on-hover {
        display: none;
      }
    }

    rbe-entry-bottom {
      @include transition(opacity);

      position: absolute;
      bottom: 0;
      width: 100%;
      min-height: 30%;
    }
  }
}
</style>
