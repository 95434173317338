import {ResourcesRole} from '@reedsy/utils.reedsy-resources';
import {IContributors} from '@reedsy/reedsy-sharedb/lib/common/book/contributors';
import {objectKeys} from '@reedsy/utils.object';

export function getUserRoleFromContributors(contributors: IContributors, userUuid: string): ResourcesRole {
  if (!contributors || !userUuid) return null;

  for (const role of objectKeys(contributors)) {
    if (contributors[role].includes(userUuid)) {
      return role;
    }
  }

  return null;
}
