<template>
  <div
    class="overlay"
  />
</template>

<script lang="ts">
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';

@Component({})
export default class Overlay extends ClientSharedVue {}
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  inset: 0;
  background: var(--studio-overlayColor);
}
</style>
