import {VuexModule, Mutation, Action} from '@reedsy/vuex-module-decorators';
import {Module} from '@reedsy/studio.shared/store/vuex-decorators';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {injectable} from 'inversify';
import {IModuleFactory} from '@reedsy/studio.shared/store/modules/i-module-factory';
import {$inject} from '@reedsy/studio.shared/types';
import {Store} from 'vuex';

@injectable()
export class SharedModeModuleFactory implements IModuleFactory {
  public readonly Module;

  public constructor(
    @$inject('Store')
    store: Store<any>,
  ) {
    @Module({name: SharedStoreName.Mode, store})
    class SharedMode extends VuexModule {
      public readOnly = false;
      public offline = false;

      @Mutation
      public OFFLINE(offline: boolean): void {
        this.offline = offline;
      }

      @Mutation
      public _READ_ONLY(readOnly: boolean): void {
        this.readOnly = readOnly;
      }

      @Action
      public setReadOnly(readOnly: boolean): void {
        if (readOnly === this.readOnly) return;
        this._READ_ONLY(readOnly);
      }
    }

    this.Module = SharedMode;
  }
}

export type SharedModeModule = InstanceType<SharedModeModuleFactory['Module']>;
