<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <a
      v-if="book"
      :href="bookEditorLink"
      class="button reedsy-accented continue-writing-button"
      target="_blank"
    >
      Continue writing
      <VuiIconExternal />
    </a>
    <SkeletonLoader
      v-else
      class="button-loader"
    />
  </transition>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import {$lazyInject} from '@reedsy/studio.home.bookshelf/inversify.config';
import {INavigation} from '@reedsy/studio.shared/services/navigation/i-navigation';
import {IBookshelfBook} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf/i-bookshelf-book';
import SkeletonLoader from '@reedsy/studio.shared/components/skeleton-loader/skeleton-loader.vue';

@Component({
  components: {
    SkeletonLoader,
  },
})
export default class ContinueWritingButton extends BookshelfVue {
  @Prop({type: Object})
  public book: IBookshelfBook;

  @$lazyInject('Navigation')
  public navigation: INavigation;

  public get bookEditorLink(): string {
    return this.navigation.getBookEditorUrl(this.book.uuid);
  }
}
</script>

<style lang="scss" scoped>
a.continue-writing-button .vui-icon {
  margin-left: $space-sm;
}

.button-loader {
  width: 12rem;
  height: 2.3rem;
}
</style>
