import {LoggerFactory} from '@reedsy/reedsy-logger-js';
import {config} from '@reedsy/studio.shared/config';
import {EVENTS} from '@reedsy/studio.shared/utils/debug/debug-event';
import {dig} from '@reedsy/utils.dig';
import {IGNORED_ERRORS} from '@reedsy/studio.shared/errors/ignored-errors';
import {Payload} from 'rollbar';
import {merge} from '@reedsy/utils.object';
import {Logger} from '@reedsy/reedsy-logger-js';

const IGNORED_ROLLBAR_MESSAGES: readonly RegExp[] = Object.freeze([
  /Object/,
  ...IGNORED_ERRORS,
]);

const REEDSY_SOURCE_CODE = /\/reedsy-editor\.[a-f0-9]+\.js/;

class ConfigurableLoggerFactory extends LoggerFactory {
  private payload: Payload = {};

  public updatePayload(update: Payload): void {
    this.payload = merge(this.payload, update);
  }

  public override create(name?: string): Logger {
    const logger = super.create(name);
    logger.configureRollbar({payload: this.payload});
    return logger;
  }
}

const loggerFactory = new ConfigurableLoggerFactory({
  consoleEnabled: config.env.development,
  consoleLevels: true,
  environment: config.rollbar.environment,
  rollbarAccessToken: config.rollbar.accessToken,
  rollbarEnabled: config.rollbar.enabled,
  logLevel: config.logging.level,
  rollbarConfig: {
    payload: {
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: config.app.version,
          guess_uncaught_frames: true,
        },
      },
    },
    hostSafeList: [
      'editor.staging.reedsy.com',
      'editor.reedsy.com',
    ],
    // Assume args take the form from reedsy-logger-js: [message, error, info]
    checkIgnore(isUncaught: boolean, args: [string, Error, any], payload: any): boolean {
      const [message, error] = args;
      if (!isReedsyError(error)) payload.level = 'warn';
      return IGNORED_ROLLBAR_MESSAGES.some((ignored) => ignored.test(message));
    },
    transform(payload: any): void {
      payload.events = EVENTS;
    },
  },
});

function isReedsyError(error: Error): boolean {
  if (typeof dig(error, 'stack') !== 'string') return false;
  const stackLines = error.stack.split('\n');
  // Check the top two lines, assuming stack can look like:
  // Error: Some error
  //   at a.method (reedsy-editor.abc.js:123:456)
  for (let i = 0; i < 2; i++) {
    if (REEDSY_SOURCE_CODE.test(stackLines[i])) return true;
  }
  return false;
}

export default loggerFactory;
