
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookshelfModule} from '@reedsy/studio.home.bookshelf/store/modules/bookshelf';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import {BookshelfRouteName} from '@reedsy/studio.shared/router/route-names/bookshelf-route-name';
import {BookshelfLayoutModule} from '@reedsy/studio.home.bookshelf/store/modules/layout';

@Component({})
export default class BooksPageFilter extends BookshelfVue {
  @$lazyInjectStore(StoreName.Bookshelf)
  public $bookshelf: BookshelfModule;

  @$lazyInjectStore(StoreName.Layout)
  public $layout: BookshelfLayoutModule;

  public get isArchivedBooks(): boolean {
    return this.$route.name === BookshelfRouteName.ArchivedBooks;
  }

  public get toggles(): any[] {
    return [
      {filter: 'stateSearch', isActive: this.isArchivedBooks, label: 'View archive', value: 'archived'},
    ];
  }

  public get bookCount(): number {
    return this.$bookshelf.bookshelfEntries.length;
  }

  public updateSearchValue(value: string): void {
    this.$bookshelf.search(value);
  }

  public changeArchiveRoute(): void {
    if (this.isArchivedBooks) {
      this.$router.push({name: BookshelfRouteName.BooksList});
    } else {
      this.$router.push({name: BookshelfRouteName.ArchivedBooks});
    }
  }
}
