import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PremiumBadge = _resolveComponent("PremiumBadge")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PremiumBadge, {
      size: "xs",
      class: "visible-until-container-xs"
    }),
    _cache[0] || (_cache[0] = _createTextVNode()),
    _createVNode(_component_PremiumBadge, {
      size: "sm",
      class: "hidden-until-container-sm"
    })
  ], 64))
}