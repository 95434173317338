import {ComponentPublicInstance} from 'vue';

export default function refEl(ref: any): HTMLElement {
  return getElement(ref) as HTMLElement;
}

function getElement(ref: VueRef): Element {
  if (!ref) return null;
  if (ref instanceof Element) return ref;
  if (Array.isArray(ref)) {
    if (!ref[0]) return null;
    if (ref[0] instanceof Element) return ref[0];
    return ref[0].$el;
  }
  return ref.$el;
}

export type VueRef = Element | ComponentPublicInstance | (Element | ComponentPublicInstance)[];
