import 'reflect-metadata';
import '@reedsy/studio.shared/utils/html/jsx/render-jsx';
import '@reedsy/studio.shared/polyfills';
import '@reedsy/studio.shared/style/shared-styles';
import '@reedsy/studio.shared/store/vuex-decorators';
import '@reedsy/studio.home.bookshelf/style/bookshelf.scss';
import '@reedsy/studio.shared/custom-elements';
import {createApp} from 'vue';
import {bookshelfContainer} from './inversify.config';
import {clientSharedContainer} from '@reedsy/studio.shared/inversify.config';
import BookshelfApp from './BookshelfApp.vue';
import router from './router';
import ErrorHandler from '@reedsy/studio.shared/plugins/error-handler';
import ReedsyVui from '@reedsy/vui';
import VInput from '@reedsy/studio.shared/components/forms/input.vue';
import VTextarea from '@reedsy/studio.shared/components/forms/textarea/textarea.vue';
import VFileInput from '@reedsy/studio.shared/components/forms/file-input/file-input.vue';
import VForm from '@reedsy/studio.shared/components/forms/form.vue';
import {registerModalComponents} from '@reedsy/studio.shared/components/modals/register-modal-components';
import Vue3TouchEvents from 'vue3-touch-events';
import FloatingVue from 'floating-vue';
import {MODAL_COMPONENT_MAP} from './components/modals/modal-component-map';
import {rootClass} from '@reedsy/studio.shared/directives/root-class';
import {register as registerSwiper} from 'swiper/element/bundle';
import {initialiseOsDarkModeListener} from '@reedsy/studio.shared/utils/is-os-dark-mode';

initialiseOsDarkModeListener();
registerSwiper();
clientSharedContainer.bind('Router')
  .toConstantValue(router);

const storeWrapper = bookshelfContainer.get('StoreWrapper');
const {store} = storeWrapper;

[
  ...clientSharedContainer.getAll('SharedStorePlugins'),
  ...bookshelfContainer.getAll('BookshelfStorePlugins'),
].forEach((factory) => factory.plugin());

const app = createApp(BookshelfApp);

app.use(store);
app.use(router);
app.use(FloatingVue);
app.use(ErrorHandler);
app.use(ReedsyVui);
app.use(Vue3TouchEvents, {
  disableClick: true,
  swipeTolerance: 100,
});
app.component('VInput', VInput);
app.component('VTextarea', VTextarea);
app.component('VFileInput', VFileInput);
app.component('VForm', VForm);
registerModalComponents(app, MODAL_COMPONENT_MAP);

app.directive('root-class', rootClass);

app.mount('#reedsy-bookshelf');
