import {ResourcesRole} from '@reedsy/utils.reedsy-resources';

export const PERMISSIONS_REQUIREMENTS = {
  deleteBook: ResourcesRole.Owner,
  userAccessManagement: ResourcesRole.Owner,
  bookExport: ResourcesRole.CoAuthor,
  previewUrls: ResourcesRole.CoAuthor,
  archiveBook: ResourcesRole.Owner,
} as const satisfies Record<string, ResourcesRole>;

export type IAction = keyof typeof PERMISSIONS_REQUIREMENTS;
