import {$inject} from '@reedsy/studio.home.bookshelf/types';
import {injectable} from 'inversify';
import BookshelfBookShareDbConnection from '@reedsy/studio.home.bookshelf/services/bookshelf-book-sharedb-connection';
import {AbstractSubscriptionFeaturesPluginFactory} from '@reedsy/studio.shared/store/plugins/subscription-features';

@injectable()
export class SubscriptionFeaturesPluginFactory extends AbstractSubscriptionFeaturesPluginFactory {
  @$inject('BookshelfShareDbConnection')
  public readonly _shareDbConnection: BookshelfBookShareDbConnection;
}
