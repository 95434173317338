import {by} from '@reedsy/utils.sort';
import {entries} from '@reedsy/utils.iterable';
import {TRIM_SIZE_CONFIG} from './trim-size-config';
import {ITrimSizeDetails} from './i-trim-size-details';
import {TrimSize} from '@reedsy/schemas.workers.book-export';

function processTrimSizes(): ITrimSizeDetails[] {
  return entries(TRIM_SIZE_CONFIG)
    .map(([sizeId, trimSizeDetails]) => {
      return {
        ...trimSizeDetails,
        sizeId: sizeId,
      };
    })
    .sort(by('height', {descending: true}))
    .map((trimSizeDetails, _index, sorted) => {
      const biggestSize = sorted[0];
      return {
        ...trimSizeDetails,
        percentageWidth: trimSizeDetails.width * 100 / biggestSize.width,
        percentageHeight: trimSizeDetails.height * 100 / biggestSize.height,
      };
    });
}

export const TRIM_SIZE_DETAILS = processTrimSizes();
export const TRIM_SIZE_DETAILS_OBJECT = TRIM_SIZE_DETAILS.reduce(
  (record, details) => {
    record[details.sizeId] = details;
    return record;
  },
  {} as any as Record<TrimSize, ITrimSizeDetails>,
);
