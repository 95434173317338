
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import ExportTypeSelector from './export-type-selector/export-type-selector.vue';
import BookFormattingSelector from './book-formatting-selector/book-formatting-selector.vue';
import ThemeSelector from './theme-selector/theme-selector.vue';
import SubmitPanel from './submit-panel/submit-panel.vue';
import BookBackup from './book-backup/book-backup.vue';
import TrimSizeSelector from './trim-size-selector/trim-size-selector.vue';
import {ExportType} from '@reedsy/schemas.workers.book-export';
import ExpandTransition from '@reedsy/studio.shared/components/transitions/expand-transition.vue';
import {$lazyInjectStore} from '@reedsy/studio.home.bookshelf/inversify.config';
import StoreName from '@reedsy/studio.home.bookshelf/store/store-name';
import {BookExportSubmissionModule} from '@reedsy/studio.home.bookshelf/store/modules/book-export-submission';
import LayoutWithRightSideBar from '@reedsy/studio.home.bookshelf/components/layout-with-right-side-bar/layout-with-right-side-bar.vue';

@Component({
  components: {
    ExportTypeSelector,
    BookFormattingSelector,
    ThemeSelector,
    TrimSizeSelector,
    SubmitPanel,
    BookBackup,
    ExpandTransition,
    LayoutWithRightSideBar,
  },
})
export default class ExportSubmissionCreator extends BookshelfVue {
  @$lazyInjectStore(StoreName.BookExportSubmission)
  public $bookExportSubmission: BookExportSubmissionModule;

  public get isPdfExportType(): boolean {
    return this.$bookExportSubmission.exportType === ExportType.Pdf;
  }
}
