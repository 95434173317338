import {injectable, named} from 'inversify';
import {$inject} from '@reedsy/studio.shared/types';
import {IStorePluginFactory} from '@reedsy/studio.shared/store/plugins/i-store-plugin-factory';
import {SharedStoreName} from '@reedsy/studio.shared/store/store-name';
import {SharedBrowserModule} from '@reedsy/studio.shared/store/modules/browser';
import {Store} from 'vuex';
import {orientation} from 'o9n';

@injectable()
export default class OrientationPluginFactory implements IStorePluginFactory {
  @$inject('StoreModule')
  @named(SharedStoreName.Browser)
  public readonly _Browser: SharedBrowserModule;

  @$inject('Store')
  public readonly _store: Store<any>;

  public plugin(): void {
    const setOrientation = (): void => {
      this._Browser.setOrientation(window.orientation || orientation.angle);
    };

    // Run once on store initialisation to set initial size
    const unsubscribe = this._store.subscribe(() => {
      setOrientation();
      unsubscribe();
    });

    window.addEventListener('orientationchange', setOrientation);
  }
}
