<template>
  <rbe-currency-selector class="flex-justified">
    Switch currency
    <VuiDropdown
      ref="dropdown"
      v-model="selectedCurrency"
      :options="currenciesOptions"
    />
  </rbe-currency-selector>
</template>

<script lang="ts">
import {Component, Model, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {SupportedCurrency} from '@reedsy/schemas.editor-collections';
import {IDropdownOption} from '@reedsy/studio.shared/components/forms/dropdown/i-option';
import {by} from '@reedsy/utils.sort';
import {readableCurrency} from '@reedsy/studio.shared/utils/currency/readable-currency';

@Component({})
export default class SubscriptionTrial extends ClientSharedVue {
  @Model({type: String})
  public selectedCurrency: SupportedCurrency;

  @Prop({type: Array, required: true})
  public availableCurrencies: SupportedCurrency[];

  public get currenciesOptions(): IDropdownOption[] {
    return this.availableCurrencies
      .map((currencyCode) => ({
        value: currencyCode,
        text: readableCurrency(currencyCode),
      }))
      .sort(by('text'));
  }
}
</script>

<style lang="scss" scoped>
rbe-currency-selector {
  gap: $space-sm;
}
</style>
