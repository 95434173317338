export enum Key {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Backspace = 'Backspace',
  Delete = 'Delete',
  Enter = 'Enter',
  Escape = 'Escape',
  PageDown = 'PageDown',
  PageUp = 'PageUp',
  Space = 'Space',
  Tab = 'Tab',
  Unidentified = 'Unidentified',
}
