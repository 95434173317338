import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VuiIconPeople = _resolveComponent("VuiIconPeople")!
  const _component_Avatar = _resolveComponent("Avatar")!

  return (_ctx.isShared)
    ? (_openBlock(), _createElementBlock("rbe-share-indicator", {
        key: 0,
        class: _normalizeClass(_ctx.size)
      }, [
        (_ctx.isCurrentUserOwner)
          ? (_openBlock(), _createBlock(_component_VuiIconPeople, {
              key: 0,
              ref: "people-icon",
              class: _normalizeClass({
        [`icon-${_ctx.size}`]: !!_ctx.size
      })
            }, null, 8, ["class"]))
          : (_openBlock(), _createBlock(_component_Avatar, {
              key: 1,
              ref: "avatar",
              "user-info": _ctx.bookOwner,
              bordered: "",
              class: _normalizeClass(["collaborator-avatar", _ctx.size])
            }, null, 8, ["user-info", "class"]))
      ], 2))
    : _createCommentVNode("", true)
}