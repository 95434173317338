<template>
  <rbe-premium-badge-info>
    <VuiIconInfo class="info-icon icon-sm" />
    <PremiumIcon />
  </rbe-premium-badge-info>
</template>

<script lang="ts">
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {Component} from '@reedsy/studio.shared/utils/vue/decorators';
import PremiumIcon from './premium-icon.vue';

@Component({
  components: {
    PremiumIcon,
  },
})
export default class PremiumBadgeInfo extends ClientSharedVue {
}
</script>

<style lang="scss" scoped>
rbe-premium-badge-info {
  cursor: pointer;
  color: var(--reedsy-accent-premium-onEmphasis);
  background: var(--reedsy-accent-premium-emphasis);
  border-radius: 9999px;
  display: inline-flex;
  align-items: center;
  gap: $space-xxs;
  padding-left: $space-xs;

  @include font-family($controls, bold);
  @include transition(background-color);

  &:hover {
    &, :deep(.icon-circle) {
      box-shadow: inset 0 0 0 $space-base var(--reedsy-shade-muted);
    }
  }

  :deep(.premium-icon) .vui-icon.icon-circle {
    margin: 0;
  }

  .info-icon {
    height: 0.625rem;
  }
}
</style>
