import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MobileBooksList = _resolveComponent("MobileBooksList")!
  const _component_BooksGrid = _resolveComponent("BooksGrid")!

  return (_openBlock(), _createElementBlock("rbe-books-list", null, [
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.$layout.isMobile)
          ? (_openBlock(), _createBlock(_component_MobileBooksList, {
              key: 0,
              class: "mobile-view"
            }))
          : (_openBlock(), _createBlock(_component_BooksGrid, {
              key: 1,
              class: "desktop-book-list"
            }))
      ]),
      _: 1
    })
  ]))
}