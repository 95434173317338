import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookTitle = _resolveComponent("BookTitle")!

  return (_openBlock(), _createElementBlock("rbe-no-book-cover-placeholder", null, [
    _createElementVNode("rbe-cover-wrapper", null, [
      _createVNode(_component_BookTitle, {
        title: _ctx.title,
        author: _ctx.author
      }, null, 8, ["title", "author"]),
      _cache[0] || (_cache[0] = _createTextVNode()),
      _createElementVNode("rbe-slot-wrapper", null, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _cache[1] || (_cache[1] = _createTextVNode()),
      _cache[2] || (_cache[2] = _createElementVNode("rbe-spacer", null, null, -1))
    ]),
    _cache[3] || (_cache[3] = _createTextVNode()),
    _createElementVNode("rbe-book-cover-bottom", null, [
      _renderSlot(_ctx.$slots, "bottom", {}, undefined, true)
    ])
  ]))
}