
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {config} from '@reedsy/studio.shared/config';

@Component({})
export default class PremiumIcon extends ClientSharedVue {
  @Prop({default: 'xs'})
  public size: string;

  public readonly config = config;
}
