<template>
  <rbe-books-grid-wrapper>
    <transition
      name="fade"
      mode="out-in"
    >
      <NoBooksPlaceholder
        v-if="isEmpty"
      />
      <rbe-books-grid v-else>
        <BookCreationButtons class="grid-item" />
        <slot />
      </rbe-books-grid>
    </transition>
  </rbe-books-grid-wrapper>
</template>

<script lang="ts">
// TODO: Improve / investigate the transition group animation on scroll
// https://github.com/reedsy/reedsy-editor/pull/6787#discussion_r1297274863
// https://github.com/reedsy/reedsy-editor/pull/6787#discussion_r1296157261
//
// TODO: Add transition group with shuffle animation as vue 3 migration broke it!

import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import BookshelfVue from '@reedsy/studio.home.bookshelf/bookshelf-vue';
import BookEntry from './book-entry.vue';
import BookCreationButtons from './book-creation-buttons.vue';
import BookImportEntry from './book-import-entry.vue';
import GridLine from './grid-line.vue';
import BookSkeletonLoader from './book-skeleton-loader.vue';
import NoBooksPlaceholder from '@reedsy/studio.home.bookshelf/components/no-books-placeholder/no-books-placeholder.vue';

@Component({
  components: {
    BookEntry,
    BookImportEntry,
    BookCreationButtons,
    GridLine,
    BookSkeletonLoader,
    NoBooksPlaceholder,
  },
})
export default class BooksGrid extends BookshelfVue {
  @Prop({type: Boolean, required: true})
  public isEmpty: boolean;
}
</script>

<style lang="scss">
rbe-books-grid {
  display: grid;
  width: 100%;
  row-gap: 2.5rem;
  grid-template-columns: repeat(auto-fill, minmax($books-grid-min-cover-size, 1fr));
  grid-column-gap: $space-lg;
  justify-items: center;
  padding-bottom: 2rem;

  @include transition(all);

  rbe-grid-line {
    grid-column: 1 / -1;
    width: 100%;
  }

  .last-line {
    margin-top: 1.5rem;
  }

  .grid-item {
    width: 100%;

    @include transition((opacity, transform));
  }

  .shuffle-enter-from {
    opacity: 0;
  }

  .shuffle-leave-from,
  .shuffle-enter-to {
    opacity: 1;
  }

  .shuffle-leave-active {
    opacity: 0;
    position: absolute;
  }

  @for $i from 2 through 15 {
    .shuffle-move:nth-child(#{$i}) {
      @include transition((transform), $transition-time-base, #{$i * 0.02}s);
    }
  }
}
</style>
