import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "modal-head" }
const _hoisted_2 = { class: "ellipsis" }
const _hoisted_3 = {
  key: 0,
  class: "modal-footer"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalCloseButton = _resolveComponent("ModalCloseButton")!
  const _component_LoadingButton = _resolveComponent("LoadingButton")!
  const _component_VForm = _resolveComponent("VForm")!

  return (_openBlock(), _createBlock(_component_VForm, {
    ref: "form",
    class: "modal reedsy-accented",
    disabled: _ctx.confirming || null,
    onSubmit: _ctx.submit
  }, {
    default: _withCtx(() => [
      _createElementVNode("header", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _cache[1] || (_cache[1] = _createTextVNode()),
        (_ctx.cancelable)
          ? (_openBlock(), _createBlock(_component_ModalCloseButton, {
              key: 0,
              disabled: _ctx.confirming || null,
              onClick: _ctx.close
            }, null, 8, ["disabled", "onClick"]))
          : _createCommentVNode("", true)
      ]),
      _cache[4] || (_cache[4] = _createTextVNode()),
      _createElementVNode("rbe-modal-content", null, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _cache[5] || (_cache[5] = _createTextVNode()),
      (_ctx.confirm || _ctx.cancelable)
        ? (_openBlock(), _createElementBlock("footer", _hoisted_3, [
            (_ctx.hasExtraActions)
              ? (_openBlock(), _createElementBlock("rbe-modal-actions", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "extra-actions")
                ]))
              : _createCommentVNode("", true),
            _cache[2] || (_cache[2] = _createTextVNode()),
            (_ctx.cancelable)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  ref: "cancel",
                  class: "button reedsy-accented neutral",
                  type: "button",
                  disabled: _ctx.confirming || _ctx.cancelDisabled || null,
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                }, _toDisplayString(_ctx.cancelLabel), 9, _hoisted_5))
              : _createCommentVNode("", true),
            _cache[3] || (_cache[3] = _createTextVNode()),
            (_ctx.confirm)
              ? (_openBlock(), _createBlock(_component_LoadingButton, {
                  key: 2,
                  ref: "confirm",
                  type: "submit",
                  disabled: _ctx.confirming || _ctx.confirmDisabled || null,
                  loading: _ctx.confirming,
                  class: "button"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.confirm.label), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "loading"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["disabled", "onSubmit"]))
}