export type DebouncedFunction = (...args: any[]) => Promise<void>;
export interface IDebounceOptions {
  delay?: number;
  runOnHidden?: boolean;
}

export default function(
  fn: (...args: any[]) => void,
  optionsOrDelay: number | IDebounceOptions = {},
): DebouncedFunction {
  const options = typeof optionsOrDelay === 'number' ? {delay: optionsOrDelay} : optionsOrDelay;
  if (typeof options.delay !== 'number') options.delay = 100;

  let timeoutId: ReturnType<typeof setTimeout> = null;
  let visibilityListener: () => void;
  let pagehideListener: () => void;
  const resolves: (() => void)[] = [];

  return function(this: any, ...args: any[]): Promise<void> {
    function clearListeners(): void {
      clearTimeout(timeoutId);
      window.removeEventListener('visibilitychange', visibilityListener);
      window.removeEventListener('pagehide', pagehideListener);
    }

    return new Promise((resolve) => {
      clearListeners();
      resolves.push(resolve);

      const runCallback = (): void => {
        clearListeners();
        fn.apply(this, args);
        resolves.forEach((resolve) => resolve());
      };

      if (options.runOnHidden) {
        visibilityListener = () => {
          if (document.visibilityState === 'visible') return;
          runCallback();
        };

        pagehideListener = runCallback;

        window.addEventListener('visibilitychange', visibilityListener);
        // iOS does not play nicely with visibilitychange for some navigation
        // events, but should work with pagehide
        // https://tech.trivago.com/2020/11/17/exploring-the-page-visibility-api-for-detecting-page-background-state/
        window.addEventListener('pagehide', pagehideListener);
      }

      timeoutId = setTimeout(runCallback, options.delay);
    });
  };
}
