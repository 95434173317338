<template>
  <rbe-period-summary
    class="reedsy-accented product vui-tab-option"
    :class="{active: selected}"
  >
    <div>{{ periodTitle }}</div>

    <span
      class="reedsy-accented"
      :class="{'neutral-highContrast font-weight-normal': interval === 'month'}"
    >
      {{ discount }}
    </span>

    <VuiIconTick class="icon-tick reedsy-accented plain" />
  </rbe-period-summary>
</template>

<script lang="ts">
import {Component, Prop} from '@reedsy/studio.shared/utils/vue/decorators';
import {ClientSharedVue} from '@reedsy/studio.shared/client-shared-vue';
import {IBillingInterval} from '@reedsy/schemas.editor-collections';

@Component({})
export default class PeriodSummary extends ClientSharedVue {
  @Prop({type: Boolean})
  public selected: boolean;

  @Prop({type: String})
  public interval: IBillingInterval;

  public get periodTitle(): string {
    return this.interval === 'year' ? 'Annually' : 'Monthly';
  }

  public get discount(): string {
    return this.interval === 'year' ? 'Save 10%' : 'No discount';
  }
}
</script>

<style lang="scss" scoped>
rbe-period-summary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: $border-radius-lg;
  padding: $space-sm;
  gap: 0;
  border: $border-width-lg solid var(--reedsy-neutral);
  line-height: 1.5;

  &.active,
  &:hover {
    border: $border-width-lg solid var(--local-color-default);
  }

  &:hover {
    --local-color-default: var(--local-color-emphasis);
  }

  &.active {
    box-shadow: $box-shadow-base;
  }

  span {
    color: var(--local-color-emphasis);
  }
}
</style>
