import {Ref} from 'vue';
import {NavigationFailure, NavigationGuardReturn, RouteLocationNormalized, RouteLocationRaw, Router, RouterOptions, createRouter} from 'vue-router';

type NavigationFunction<T extends RouteLocationRaw = RouteLocationRaw> =
  (to: T) => Promise<NavigationFailure | void | undefined>;

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ReedsyRouter<T extends IMetaRoute = IMetaRoute> extends Router {
  currentRoute: Ref<T>;
  push: NavigationFunction<MetaRawLocation>;
  replace: NavigationFunction<MetaRawLocation>;
  beforeEach(
    guard: MetaNavigationGuard,
    preventUnsubscribe?: boolean,
  ): () => void;
  afterEach(
    guard: (to: T, from: T, failure?: NavigationFailure) => void,
    preventUnsubscribe?: boolean,
  ): () => void;
  unsubscribeAllGuards(): void;
}

export function createReedsyRouter<T extends IMetaRoute = IMetaRoute>(options?: RouterOptions): ReedsyRouter<T> {
  const unsubscribes: Set<() => void> = new Set();
  const router = createRouter(options) as ReedsyRouter<T>;

  router.push = wrapNavigation(router.push.bind(router));
  router.replace = wrapNavigation(router.replace.bind(router));

  const beforeEach = router.beforeEach.bind(router);

  let $meta: IRouterMeta = {};

  router.beforeEach = (guard, preventUnsubscribe = false) => {
    const wrappedGuard: MetaNavigationGuard<T> = async (to, from) => {
      to.$meta = $meta;
      const redirection = await guard(to, from);
      if (typeof redirection !== 'object' || redirection instanceof Error) return redirection;
      if ('$meta' in redirection) $meta = {...$meta, ...redirection.$meta};
      return redirection;
    };

    const unsubscribe = beforeEach(wrappedGuard);
    if (!preventUnsubscribe) unsubscribes.add(unsubscribe);
    return unsubscribe;
  };

  const afterEach = router.afterEach.bind(router);
  router.afterEach = (guard, preventUnsubscribe = false) => {
    const unsubscribe = afterEach(guard);
    if (!preventUnsubscribe) unsubscribes.add(unsubscribe);
    return unsubscribe;
  };

  afterEach(() => {
    $meta = {};
  });

  router.unsubscribeAllGuards = () => {
    unsubscribes.forEach((unsubscribe) => unsubscribe());
    unsubscribes.clear();
  };

  return router;

  function wrapNavigation(fn: NavigationFunction): NavigationFunction {
    return async (to: MetaRawLocation) => {
      if (!to) return null;
      $meta = typeof to === 'object' ? {...to.$meta} : {};
      if (typeof to === 'object') {
        if (to.hash && !to.hash.startsWith('#')) to.hash = '#' + to.hash;
      }
      const result = await fn(to);
      // Wait for a setTimeout to allow the anchor scroll to work
      await new Promise((resolve) => setTimeout(resolve));
      return result;
    };
  }
}

export type MetaRawLocation = string | IMetaLocation;

type WithoutString<T> = T extends string ? never : T;
export type IMetaLocation = WithoutString<RouteLocationRaw> & {
  $meta?: IRouterMeta;
};

export interface IMetaRoute extends RouteLocationNormalized {
  $meta?: IRouterMeta;
}

export type MetaNavigationGuardReturn = NavigationGuardReturn | IMetaLocation;
export type MetaNavigationGuard<T extends IMetaRoute = IMetaRoute> = (
  to: T,
  from: T,
) => MetaNavigationGuardReturn | Promise<MetaNavigationGuardReturn>;

export interface IRouterMeta {
  ignoreSaveGuard?: boolean;
  ignoreTimestamp?: boolean;
}
