import {injectable} from 'inversify';
import {IWebSocketFactory} from './i-websocket-factory';
import WebSocket from '@reedsy/reconnecting-websocket';

@injectable()
export class WebSocketFactory implements IWebSocketFactory {
  public connect(...params: ConstructorParameters<typeof WebSocket>): WebSocket {
    return new WebSocket(...params);
  }
}
