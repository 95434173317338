import {RouterPlugin} from '@reedsy/studio.shared/store/plugins/router-plugin';
import {IMetaRoute} from '@reedsy/studio.shared/router/reedsy-router';
import {SharedModals} from '@reedsy/studio.shared/store/modules/modals/base';
import {deepEqual} from '@reedsy/utils.deep-equal';
import {NavigationGuardReturn} from 'vue-router';

export default abstract class CloseModalsPluginFactory extends RouterPlugin {
  public abstract $modals: SharedModals<any>;

  public override beforeEach(to: IMetaRoute, from: IMetaRoute): NavigationGuardReturn {
    if (
      from.name === to.name &&
      deepEqual(from.params, to.params)
    ) return;

    const modals = this.$modals.modals;
    if (modals.some((modal) => !modal.cancelable)) return false;
    this.$modals.CLOSE_ALL();
  }
}
